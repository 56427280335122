export const formatError = (
  err: any,
  t: (key: string, options: any) => string,
  namespace: string = 'errors',
): string => {
  const ns = { ns: namespace };
  let msg = typeof err === 'string' ? err : err?.message ? err.message : JSON.stringify(err);
  msg = msg?.replace(/:/g, ' - ');
  return t(msg, ns).endsWith(msg) ? msg : t(msg, ns);
};

import React, { useEffect, useMemo, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { SearchBar } from 'components/SearchBar';
import { Pagination } from 'components/Pagination';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { Checkbox } from 'components/Inputs/Checkbox';
import { Role, PermissionsRole, PermissionsEmployee } from 'types/types';
import { PolicyDialogProps } from '..';
import ModalBase from 'components/ModalBase';
import { CONTROLLER } from 'constants/common';
import EmployeesDialog from '../Employees';
import { isEmpty } from 'lodash';
import ModalSelectStore from 'components/ModalSelectStore';
import { EmployeeUpdates } from 'stores/CoordinatorsStore';
import { notify } from 'utils/lib/notify';
import { formatError } from 'utils/lib/formatError';

interface Props {}

interface DialogProps {
  visible?: boolean;
  roleId?: Role['id'];
}

const defaultDialog: DialogProps = {
  visible: false,
  roleId: undefined,
};

const Dialog = observer(({ visible, onClose }: Props & PolicyDialogProps) => {
  const {
    usersStore,
    coordinatorsStore,
    coordinatorsStore: { editingCoordinator: user },
  } = useStores();
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const { t } = useTranslation(['coordinators', 'confirmation']);
  const isPersonalManagerPolicy = usersStore.customer?.is_personal_manager_policy;

  useEffect(() => {
    if (visible) {
      coordinatorsStore?.permissionsRolesState
        ?.fetchList(false, { coordinator_id: user?.id }, 'id desc', 0, coordinatorsStore?.limit || 10, true, false)
        .then(() => setIsDataReady(true));
    } else {
      setIsDataReady(false);
    }
  }, [visible]);

  const [employeesDialog, setEmployeesDialog] = useState<DialogProps>(defaultDialog);

  const updateEmployeesDialog = (updates: Partial<DialogProps>): void => {
    setEmployeesDialog((...prev) => ({ ...prev, ...updates }));
  };

  const updatePermissions = async (updates: any, add: boolean): Promise<void> => {
    await coordinatorsStore.updateRole({
      role_id: updates.role_id,
      manage_all: add ? 1 : 0,
      coordinator_id: user?.id,
    } as any);
    const { rolesErrors = {} } = coordinatorsStore || {};
    if (rolesErrors[updates?.role_id]) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedChangeGroupAccess', { ns: 'errors' })} \"${updates?.role_name || ''}\": ${formatError(rolesErrors[updates?.role_id], t)}`,
        type: 'danger',
      });
    }
  };

  const updateEmployeePermissions = async (updates: PermissionsEmployee, add: boolean): Promise<void> => {
    await coordinatorsStore.updateEmployee(
      {
        employee_id: updates.employee_id,
        manage_employee: add ? 1 : 0,
        coordinator_id: user?.id,
      } as EmployeeUpdates,
      'coordinator_users',
    );
    const { employeesErrors = {} } = coordinatorsStore || {};
    if (updates.employee_id && employeesErrors[updates.employee_id]) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedChangeUserAccess', { ns: 'errors' })} \"${updates?.employee_name || ''}\": ${formatError(employeesErrors[updates?.employee_id], t)}`,
        type: 'danger',
      });
    }
  };

  const onChangeAll = async (value: 0 | 1): Promise<void> => {
    await coordinatorsStore?.updateEditingCoordinator({ manage_all_roles: value });
    if (coordinatorsStore?.addingError) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: formatError(coordinatorsStore?.addingError, t),
        type: 'danger',
      });
      return;
    }
    coordinatorsStore.fetchPermissionsRoles({
      coordinator_id: user?.id,
      limit: coordinatorsStore.permissionsRolesState?.limit || 10,
      page: coordinatorsStore.permissionsRolesState?.page || 0,
    });
  };

  const usersFilter = useMemo(() => ({ coordinator_id: user?.id }), [user?.id]);

  return (
    <>
      <ModalBase onClose={onClose} title={t('dialog.select_group')} visible={visible}>
        <main>
          <div className="container1">
            <div className="area">
              <SearchBar
                containerClass="mb-5"
                store={coordinatorsStore?.permissionsRolesState}
                placeholder={t('group.search_placeholder')}
              />
            </div>
            {!isPersonalManagerPolicy && (
              <div className="area">
                <ModalSelectStore
                  store={coordinatorsStore.roleEmployeesState}
                  customUid={'coordinator_users'}
                  placeholder={t('employees.search_placeholder')}
                  filter={usersFilter}
                  customOptionRender={(record) => (
                    <Checkbox
                      containerProps={{ onClick: (e) => e.stopPropagation() }}
                      checked={!!record.manage_employee}
                      label={record?.employee_name}
                      containerClass="flex items-center"
                      labelClass="w-full"
                      onChange={({ target }) => updateEmployeePermissions(record, target.checked)}
                    />
                  )}
                />
              </div>
            )}
            {coordinatorsStore?.isFetchingPermissionRoles || !isDataReady ? (
              <span>{t('loading')}</span>
            ) : (
              <div className="area overflow-hidden">
                <div className="overflow-x-auto flex">
                  <Table
                    stickyHeader
                    tableClass="table w-full"
                    colClasses={['w-full']}
                    columnsKeys={['name']}
                    data={coordinatorsStore?.permissionsRolesState?.items || []}
                    translateFunc={(key: string) => (key && key !== CONTROLLER ? t(`group.${key}`) : '')}
                    customTdInnerRenderer={{
                      name: (record: PermissionsRole) => (
                        <div className="flex justify-between">
                          <Checkbox
                            disabled={!isEmpty(coordinatorsStore?.savingRoles) || !!record?.is_default}
                            color="red"
                            checked={(record?.employee_access || 0) > 0 || !!record?.manage_all || !!record?.is_default}
                            label={
                              <>
                                {record?.role_name}&nbsp;&nbsp;&nbsp;
                                {record?.is_default ? (
                                  <span className="status status-default">{t('group.default')}</span>
                                ) : (
                                  ''
                                )}
                              </>
                            }
                            containerClass="flex items-center"
                            labelClass="w-full"
                            onChange={({ target }) => updatePermissions(record, target.checked)}
                          />
                          {record?.is_default || isPersonalManagerPolicy ? (
                            <></>
                          ) : (
                            <button
                              onClick={() => updateEmployeesDialog({ visible: true, roleId: record?.role_id })}
                              type="button"
                              className="opacity-50"
                            >
                              {t('group.add_user')}
                            </button>
                          )}
                        </div>
                      ),
                    }}
                    customThInnerRenderer={{
                      name: (_: Role) => (
                        <Checkbox
                          checked={!!user?.manage_all_roles}
                          label={t(`group.all`)}
                          containerClass="flex items-center"
                          labelClass="w-full"
                          onChange={({ target }) => (+target.checked ? onChangeAll(1) : onChangeAll(0))}
                        />
                      ),
                    }}
                  >
                    <div className="area-inner">
                      <Pagination
                        compact
                        withFilter={{
                          coordinator_id: user?.id,
                        }}
                        store={coordinatorsStore?.permissionsRolesState}
                      />
                    </div>
                  </Table>
                </div>
              </div>
            )}
          </div>
        </main>
        {/* <div className="flex justify-end space-x-5">
          <button className="btn btn-light" style={{ marginLeft: 20, position: 'fixed', left: 0 }} type="button" onClick={resetPolicyPermissions}>{t('dialog.reset')}</button>
          <button className="btn btn-light" type="button" onClick={onClose}>{t('dialog.close')}</button>
        </div> */}
      </ModalBase>
      <EmployeesDialog
        withFilter={{
          coordinator_id: user?.id,
        }}
        onClose={() => updateEmployeesDialog(defaultDialog)}
        visible={!!employeesDialog.visible}
        roleId={employeesDialog.roleId}
      />
    </>
  );
});

export default Dialog;

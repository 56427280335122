export default {
  title: 'Избранное',
  action: {
    add: 'Добавить адрес',
  },
  search_placeholder: 'Поиск',
  loading: 'Загрузка данных',
  error: 'Ошибка',
  thead: {
    name: 'Название',
    address: 'Адрес',
  },
  dialog: {
    title_new: 'Новый адрес',
    title_edit: 'Редактирование',
    name: 'Название',
    name_placeholder: 'Введите название',
    address_placeholder: 'Введите адрес',
    address: 'Адрес',
    search_placeholder: 'Поиск',
    map: {
      title: 'Выберите адрес',
    },
  },
};

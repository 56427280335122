import { observable, makeObservable } from 'mobx';
import { RootStoreInterface } from '../../interfaces';

class TranslationsStore {
  rootStore: RootStoreInterface;

  @observable
  translations: any = {};

  @observable
  currentLocale: string = 'ru_RU';

  @observable
  baseLocale: string = 'ru_RU';

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore;

    makeObservable(this);

    this.loadTranslations();
  }

  async loadTranslations() {
    this.translations = {
      en_US: require('../../locales/en_US.json'),
      ru_RU: require('../../locales/ru_RU.json'),
    };
  }

  get(msg_id: string, default_msg_id?: string): string {
    const def_msg_id = default_msg_id ? default_msg_id : msg_id;
    const currentLocale =
      this.rootStore.modulesStore.usersStore && this.rootStore.modulesStore.usersStore.locale
        ? this.rootStore.modulesStore.usersStore.locale
        : this.baseLocale;
    if (this.translations[currentLocale] && this.translations[currentLocale][msg_id])
      return this.translations[currentLocale][msg_id];
    if (this.translations[this.baseLocale] && this.translations[this.baseLocale][msg_id])
      return this.translations[this.baseLocale][msg_id];
    if (this.translations[currentLocale] && this.translations[currentLocale][def_msg_id])
      return this.translations[currentLocale][def_msg_id];
    if (this.translations[this.baseLocale] && this.translations[this.baseLocale][def_msg_id])
      return this.translations[this.baseLocale][def_msg_id];
    return def_msg_id;
  }
}

export default TranslationsStore;

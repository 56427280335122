import FASpinner from 'components/FASpinner';
import ModalBase from 'components/ModalBase';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';
import { Coordinator } from '.';
import PolicyDialog from './Policy/index';
import ClassesDialog from './Classes/index';
import { Checkbox } from 'components/Inputs/Checkbox';
import { isEmailValid } from 'utils/lib/regExp/isEmailValid';

interface DialogProps {
  visible: boolean;
  onCancel: any;
  onAdd?: any;
  onClose?: any;
  editing?: boolean;
}

interface Validation {
  email?: string;
  submit?: string;
}

const fields = ['create_favourites', 'view_analytics', 'create_coordinators', 'manage_promocodes'];

const Dialog = observer(({ visible, onCancel, onAdd, onClose, editing }: DialogProps) => {
  const {
    usersStore,
    coordinatorsStore,
    coordinatorsStore: { editingCoordinator: user },
  } = useStores();

  const [errors, setErrors] = useState<Validation>({});
  const { t } = useTranslation(['coordinators', 'validation']);
  const [newCoordinator, setNewCoordinator] = useState<Partial<Coordinator>>({
    name: user?.name || '',
    email: user?.email || '',
  });

  const [savingKeys, setSavingKeys] = useState<('name' | 'email')[]>([]);

  useEffect(() => {
    setNewCoordinator({
      name: coordinatorsStore?.editingCoordinator?.name || '',
      email: coordinatorsStore?.editingCoordinator?.email || '',
    });
  }, [coordinatorsStore?.editingCoordinator]);

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible]);

  const onFocus = (): void => {
    setErrors({});
  };

  const updatePermissions = (updates: Partial<Coordinator['permissions']>): void => {
    coordinatorsStore?.updateEditingCoordinator({ permissions: { ...(user?.permissions || {}), ...updates } });
  };
  const updateDetails = (updates: Partial<Coordinator>): void => {
    coordinatorsStore?.updateEditingCoordinator({ ...updates });
  };

  const saveField = async (key: 'name' | 'email'): Promise<void> => {
    if (key === 'email' && !validateEmail()) return;
    setSavingKeys((prev) => [...prev, key]);
    await coordinatorsStore?.updateEditingCoordinator({ [key]: newCoordinator[key] });
    if (coordinatorsStore.addingError) {
      setErrors((err: any) => ({
        ...err,
        submit: formatError(coordinatorsStore.addingError, t),
      }));
    }
    setSavingKeys((prev) => prev.filter((k) => k !== key));
  };

  const validateEmail = (): boolean => {
    if (isEmailValid(newCoordinator?.email)) {
      return true;
    }
    setErrors((err: any) => ({
      ...err,
      email: t('error.email', { ns: 'validation' }),
    }));
    return false;
  };

  const add = useCallback(
    async (e: any): Promise<void> => {
      if (usersStore?.me?.role?.name === 'coordinator' && !usersStore?.me?.detail?.permissions?.create_coordinators) {
        return;
      }
      e.preventDefault();
      if (!validateEmail()) return;
      const data: any = {
        email: newCoordinator?.email?.trim(),
        name: newCoordinator?.name?.trim(),
        // permissions: user?.permissions
      };
      // if (editing) {
      //     await user?.update(data)
      // } else {
      const added = await coordinatorsStore.addRecord(data);
      // }
      if (coordinatorsStore?.addingError) {
        setErrors((err: any) => ({
          ...err,
          submit: formatError(coordinatorsStore.addingError, t),
        }));
        return;
      } else {
        coordinatorsStore.setEditingCoordinator(coordinatorsStore.recordHandlers(added));
        // onClose();
        if (onAdd) {
          onAdd(data);
        }
      }
    },
    [newCoordinator],
  );

  const cancel = (): void => {
    setErrors({});
    if (onCancel) {
      onCancel();
    }
  };

  const [policyDialog, setPolicyDialog] = useState<boolean>(false);
  const openPolicyDialog = (): void => {
    setPolicyDialog(true);
  };
  const closePolicyDialog = (): void => {
    setPolicyDialog(false);
  };

  const [classesDialog, setClassesDialog] = useState<boolean>(false);
  const openClassesDialog = (): void => {
    setClassesDialog(true);
  };
  const closeClassesDialog = (): void => {
    setClassesDialog(false);
  };

  const renderCheckbox = (field: string, index?: number, arr?: any[], isUpdatingDetails?: boolean): JSX.Element => (
    <div className="mb-4">
      <Checkbox
        checked={isUpdatingDetails ? !!user && !!user[field] : !!user?.permissions && !!user.permissions[field]}
        label={t(`dialog.${field}`)}
        labelClass="text-opacity-40"
        onChange={({ target }) =>
          isUpdatingDetails
            ? updateDetails({ [field]: +target.checked })
            : updatePermissions({ [field]: +target.checked })
        }
      />
    </div>
  );

  return (
    <>
      <ModalBase onClose={onClose} title={user?.id ? t('action.edit') : t('action.add')} visible={visible}>
        <form>
          <div className="mb-6">
            {errors.submit && <p className="error mb-5">{errors.submit}</p>}
            <div className="mb-5">
              <label className="form-label">{t('dialog.full_name')}</label>
              <div className="flex">
                <input
                  className="form-control form-control--lg"
                  value={newCoordinator?.name || ''}
                  onFocus={onFocus}
                  onChange={({ target }) => setNewCoordinator((prev) => ({ ...prev, name: target.value }))}
                  type="text"
                  id="name"
                  placeholder={t('dialog.full_name_placeholder')}
                />
                {user?.name && user.name !== newCoordinator?.name && (
                  <button
                    className="btn btn-blue ml-2"
                    type="button"
                    onClick={() => saveField('name')}
                    disabled={savingKeys.includes('name')}
                  >
                    <FASpinner containerClass="mr-2" show={savingKeys.includes('name')} />
                    {t('dialog.save')}
                  </button>
                )}
              </div>
            </div>
            <div className="mb-5">
              <label className="form-label">
                {t('dialog.email')}
                {errors.email}
              </label>
              <div className="flex">
                <input
                  className="form-control form-control--lg"
                  value={newCoordinator?.email || ''}
                  onFocus={onFocus}
                  onChange={({ target }) => setNewCoordinator((prev) => ({ ...prev, email: target.value }))}
                  type="text"
                  placeholder={t('dialog.email_placeholder')}
                />
                {user?.email && user.email !== newCoordinator?.email && (
                  <button
                    className="btn btn-blue ml-2"
                    type="button"
                    onClick={() => saveField('email')}
                    disabled={savingKeys.includes('email')}
                  >
                    <FASpinner containerClass="mr-2" show={savingKeys.includes('email')} />
                    {t('dialog.save')}
                  </button>
                )}
              </div>
              {errors.email && <span className="error">{errors.email}</span>}
            </div>
            {editing && (
              <div className="mb-5">
                <label className="form-label">{t('dialog.permissions')}</label>
                {renderCheckbox('create_policy_groups')}
                {!!user?.permissions?.create_policy_groups ? renderCheckbox('create_cost_centers') : null}
                {fields.map(renderCheckbox)}
                {renderCheckbox('create_employees')}
                {!!user?.permissions?.create_employees
                  ? renderCheckbox('manage_can_order', undefined, undefined, true)
                  : null}
                {!!user?.permissions?.create_employees
                  ? renderCheckbox('manage_can_order_others', undefined, undefined, true)
                  : null}
              </div>
            )}
            {editing && (
              <div className="mb-5">
                <label className="form-label">{t('dialog.employees_policy')}</label>
                {/* <div className="mb-4">
                                {user?.permissions?.employees_ids?.length ? (
                                    (<>{t('dialog.selected_employees')}: {user?.permissions?.employees_ids?.length} </>)
                                ) :
                                    (<>{t('dialog.select_employees')} </>)
                                }
                                (<span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={openEmployeesDialog}>{t('dialog.select')} </span>)
                            </div> */}

                <div className="mb-4">
                  {user?.count_roles_access ? (
                    <>
                      {t('dialog.selected_roles')}: {user.count_roles_access}{' '}
                    </>
                  ) : (
                    <>{t('dialog.select_roles')} </>
                  )}
                  (
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={openPolicyDialog}>
                    {t('dialog.select')}{' '}
                  </span>
                  )
                </div>

                <div className="mb-4">
                  {user?.permissions?.classes_ids?.length ? (
                    <>
                      {t('dialog.selected_classes')}: {user?.permissions?.classes_ids?.length}{' '}
                    </>
                  ) : (
                    <>{t('dialog.select_classes')} </>
                  )}
                  (
                  <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={openClassesDialog}>
                    {t('dialog.select')}{' '}
                  </span>
                  )
                </div>
              </div>
            )}
          </div>
          {!editing && (
            <div className="flex justify-end space-x-5">
              <button className="btn btn-light" type="button" onClick={cancel}>
                {t('dialog.cancel')}
              </button>
              <button
                className="btn btn-blue"
                type="button"
                onClick={add}
                disabled={
                  !newCoordinator?.email?.trim() ||
                  !newCoordinator?.name?.trim() ||
                  coordinatorsStore.isAddingInProgress
                }
              >
                <FASpinner containerClass="mr-2" show={coordinatorsStore.isAddingInProgress} />
                {t('dialog.save')}
              </button>
            </div>
          )}
        </form>
      </ModalBase>

      <PolicyDialog visible={policyDialog} onClose={closePolicyDialog} />

      <ClassesDialog isOpen={classesDialog} onClose={closeClassesDialog} />
    </>
  );
});

export default Dialog;

import { makeObservable } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';

class PromocodesOrdersStore extends BaseStore {
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['PromocodesOrders']);
    makeObservable(this);
  }
}

export default PromocodesOrdersStore;

import React, { useEffect, useState } from 'react';
import ModalSelect from 'components/ModalSelect';
import { Option } from '../../types/types';
import { useTranslation } from 'react-i18next';

interface PaginationProps {
  store?: any;
  onChangeLength?: any;
  onChangePage?: any;
  hideItemsLength?: boolean;
  compact?: boolean;
  useCache?: boolean;
  withFilter?: any;
  orderBy?: string;
  limit?: number;
  isDetail?: boolean;
  isAppend?: boolean;
}

export const Pagination: React.FC<PaginationProps> = ({
  store,
  onChangeLength,
  onChangePage,
  hideItemsLength,
  compact = false,
  useCache = false,
  withFilter = {},
  orderBy = 'id desc',
  isDetail = true,
  isAppend = false,
}: PaginationProps) => {
  const { t } = useTranslation('pagination');

  const changeLength = (option: Option): void => {
    if (onChangeLength) onChangeLength(option.value);
    setLimit(option.value);
    store.setFilter(
      useCache,
      withFilter,
      orderBy,
      store?.page,
      option?.value || store?.limit || 10,
      isDetail,
      isAppend,
    );
  };

  const changePage = (page: number): void => {
    if (onChangePage) onChangePage(page);
    store.setFilter(useCache, withFilter, orderBy, page, limit || store?.limit || 10, isDetail, isAppend);
  };
  const [total, setTotal] = useState(store?.total);
  const [limit, setLimit] = useState(store?.limit);

  useEffect(() => {
    setTotal(store.total);
    setLimit(store.limit);
  }, [store?.total, store?.limit]);

  return (
    <div className="bg-graylight bg-opacity-[94] text-gray rounded-lg py-4 lg:py-3 px-4 md:px-5 flex items-center">
      <div className={`items-center pagination-settings space-x-2.5 hidden md:${hideItemsLength ? 'hidden' : 'flex'}`}>
        <label id="rowsperpage-label">{t('rows_number')}:</label>
        {limit > total ? (
          <span>{total}</span>
        ) : (
          <div className="dropdown">
            <ModalSelect
              mode="dropdown"
              containerClass="w-16"
              optionClass="text-center"
              options={[
                { id: 0, value: 10, label: 10 },
                { id: 1, value: 25, label: 25 },
                { id: 2, value: 50, label: 50 },
                { id: 3, value: 100, label: 100 },
              ]}
              value={limit}
              onChange={changeLength}
            />
          </div>
        )}
      </div>
      <nav className={`mx-auto ${compact ? '' : 'md:mr-0'}`} aria-label="Переход по страницам">
        <ul className="pagination">
          <li
            className="pagination-item"
            onClick={() => {
              if (store.page) changePage((store.page || 0) - 1);
            }}
          >
            <a
              className={`pagination-link pagination-link--prev ${store.page ? '' : 'disabled'}`}
              href="javascript:void(0)"
            >
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path d="m6.256 10.629 6.23 6.12a.913.913 0 0 0 1.258 0 .875.875 0 0 0 0-1.237L8.134 10l5.61-5.512a.875.875 0 0 0 0-1.236.913.913 0 0 0-1.259 0l-6.229 6.14a.874.874 0 0 0 0 1.237Z"></path>
              </svg>
              <span>{t('back')}</span>
            </a>
          </li>
          <li
            className="pagination-item"
            onClick={() => {
              if (limit <= total) changePage((store.page || 0) + 1);
            }}
          >
            <a
              className={`pagination-link pagination-link--next ${limit > total ? 'disabled' : ''}`}
              href="javascript:void(0)"
            >
              <span>{t('forward')}</span>
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path d="m13.744 9.371-6.23-6.12a.913.913 0 0 0 -1.258 0 .874.874 0 0 0 0 1.237l5.61 5.512-5.61 5.512a.875.875 0 0 0 0 1.236.913.913 0 0 0 1.259 0l6.229-6.14a.875.875 0 0 0 0-1.237z"></path>
              </svg>
            </a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

import AddressAutoComplete from 'components/AddressAutoComplete';
import FASpinner from 'components/FASpinner';
import ModalBase from 'components/ModalBase';
import { MOSCOW_CENTER } from 'constants/map';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import { useWindowSize } from 'hooks/useWindowSize';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { YMaps, Map, ZoomControl } from 'react-yandex-maps';
import { useStores } from 'stores';
import { Place } from 'types/types';
import { Address } from '..';
import { ReactComponent as CurrentLocationShadowPin } from '../../NewOrder/assets/svg/current-location-shadow.svg';
import { ReactComponent as CurrentLocationPin } from '../../NewOrder/assets/svg/current-location.svg';

interface AddressMapProps {
  visible: boolean;
  address?: Address;
  onClose: any;
  onSelect?: any;
}

const zoomDuration = 200;

export const AddressMap = ({ visible, address, onClose, onSelect }: AddressMapProps): JSX.Element => {
  const [isMapMoving, setIsMapMoving] = useState<boolean>(false);
  const shouldUpdateAddress = useRef<boolean>(false);
  const { t } = useNestedTranslation(['favourites.dialog', 'actions']);
  const [currentPlace, setCurrentPlace] = useState(address?.place || null);
  const timerIdRef = useRef<NodeJS.Timer>();
  const [center, setCenter] = useState<any[]>();
  const [isMapMoved, setIsMapMoved] = useState<boolean>(false);
  const mapApiRef = useRef<any>();
  const [isMapReady, setIsMapReady] = useState<boolean>(false);
  const { geocodeStore } = useStores();
  const windowSize = useWindowSize();
  const bottomSheetContentRef = useRef<HTMLDivElement>(null);
  const prevCenterRef = useRef<any[]>([]);
  const waitRef = useRef<number>(0);

  useEffect(() => {
    if (center && !isMapMoving && isMapMoved) {
      onRegionChangeComplete({ lat: center[0], lng: center[1] });
      setCenter(undefined);
    }
  }, [center, isMapMoved, isMapMoving]);

  const onRegionChangeComplete = async (newregion) => {
    shouldUpdateAddress.current = true;
    if (isMapMoving) return;
    const place: Place = await geocodeStore.geocode({ location: newregion });
    if (!shouldUpdateAddress.current) return;
    setCurrentPlace({ ...place, ...newregion });
  };

  useEffect(() => {
    if (address && !currentPlace && visible) {
      setCurrentPlace(address?.place);
    }
  }, [address, visible]);

  const select = (): void => {
    onSelect(currentPlace);
    close();
  };

  const close = (): void => {
    setCurrentPlace(null);
    onClose();
  };

  const onBoundsChange = ({ originalEvent }): void => {
    // prevent "boundschange" event on scroll and if address is selected from autocomplete
    if (
      prevCenterRef.current?.length > 0 &&
      (Math.abs(+originalEvent.newCenter[0] - +prevCenterRef.current[0]) < 0.00002,
      Math.abs(+originalEvent.newCenter[1] - +prevCenterRef.current[1]) < 0.00002)
    )
      return;
    if (timerIdRef.current) clearTimeout(timerIdRef.current);
    timerIdRef.current = setTimeout(() => {
      const coords = [originalEvent.newCenter[0], originalEvent.newCenter[1]];
      onMouseUp();
      setCenter(coords);
      prevCenterRef.current = coords;
    }, 300);
  };

  const onMouseDown = (): void => {
    if (timerIdRef.current) clearTimeout(timerIdRef.current);
    setIsMapMoving(true);
    shouldUpdateAddress.current = false;
  };

  const onMouseUp = (): void => {
    setIsMapMoving(false);
    setIsMapMoved(true);
  };

  const onActionBegin = (): void => {
    if (timerIdRef.current) clearTimeout(timerIdRef.current);
    setCenter(undefined);
    setIsMapMoving(true);
    shouldUpdateAddress.current = false;
  };

  const onMapInstanceInit = useCallback(
    (() => {
      return (ref: any): void => {
        mapApiRef.current = ref;
        if (!ref) return;
        ref.events?.add('mousedown', onMouseDown);
        ref.events?.add('mouseup', onMouseUp);
        ref.events?.add('boundschange', onBoundsChange);
        ref.events?.add('actionbegin', onActionBegin);
        ref.events?.add('dblclick', onDblClick);
        ref.events?.add('wheel', onWheel);
      };
    })(),
    [],
  );

  const onDblClick = (e): void => {
    if (!mapApiRef?.current) return;
    // increase map zoom by 1
    mapApiRef.current?.setZoom(mapApiRef.current?.getZoom() + 1, { duration: 100 });
  };

  const onWheel = useCallback((e): void => {
    if (!mapApiRef?.current) return;
    // remove event listener to wait for the end of the setZoom animation. Helps to avoid next animation start while previous animation is in progress.
    mapApiRef.current?.events?.remove('wheel', onWheel);
    // increase map zoom by 1 if deltaY > 0, else decrease by 1
    mapApiRef.current?.setZoom(mapApiRef.current?.getZoom() + (e?.get('deltaY') > 0 ? 1 : -1), {
      duration: zoomDuration,
      checkZoomRange: true,
    });
    // set event listener for the mouse wheel when animation ends
    setTimeout(() => mapApiRef.current?.events?.add('wheel', onWheel), zoomDuration);
  }, []);

  const setMapCenter = useCallback(async (center: (number | string)[], zoom: number = 17): Promise<void> => {
    await mapApiRef.current?.setCenter(center, zoom, {
      checkZoomRange: true,
      duration: 700,
    });
  }, []);

  const selectAddress = (place) => {
    if (!place) return;
    setCurrentPlace(place);
    const currentCenter = [place?.lat, place?.lng];
    // prevent "boundschange" event
    prevCenterRef.current = currentCenter;
    setMapCenter(currentCenter);
  };

  return (
    <ModalBase
      onAnimationCloseEnd={() => setIsMapReady(false)}
      modalWrapperClass="max-w-none ml-4 mr-4"
      bodyClass={`flex flex-grow ${(windowSize?.width || 0) < 992 && 'p-0'}`}
      contentClass="absolute top-0 bottom-0 flex flex-col"
      visible={visible}
      onClose={close}
      title={t('map.title')}
    >
      <div className="flex flex-grow flex-col">
        <div className="flex flex-grow relative">
          <YMaps>
            <Map
              style={{ width: '100%', height: '100%' }}
              defaultState={{
                center: currentPlace?.lat ? [currentPlace?.lat, currentPlace?.lng] : MOSCOW_CENTER,
                zoom: 17,
                behaviors: ['drag'],
              }}
              instanceRef={onMapInstanceInit}
              onLoad={() => setIsMapReady(true)}
              options={{
                yandexMapDisablePoiInteractivity: true,
              }}
            >
              <ZoomControl />
            </Map>
            {isMapMoving && isMapReady && (
              <div className="absolute location-pin" style={{ transform: 'translate(-50%, -90%)' }}>
                <CurrentLocationShadowPin color="#FDCD03" />
              </div>
            )}
            {isMapReady && windowSize && (windowSize.width || 0) > 991 && (
              <div className="flex absolute ml-3 mt-3">
                <div
                  className="min-h-12 px-3 lg:w-full mr-1 md:w-[30.25rem] border border-graystroke bg-white bg-opacity-94 rounded-lg"
                  style={{ zIndex: 9, width: 400 }}
                >
                  <AddressAutoComplete
                    className="pl-1 border-0 border-b-0"
                    defaultValue={currentPlace?.address || ''}
                    placeholder={t('search_placeholder')}
                    style={{ flexShrink: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.1' }}
                    onSelect={selectAddress}
                  />
                </div>
                <button className="btn h-12 btn-yellow w-36 font-medium" onClick={select} type="button">
                  {t('confirm', { ns: 'actions' })}
                </button>
              </div>
            )}
            {!isMapMoving && isMapReady && (
              <div className={`absolute location-pin`} style={{ transform: 'translate(-50%, -100%)' }}>
                <CurrentLocationPin color="#FDCD03" />
              </div>
            )}
          </YMaps>
          <FASpinner
            show={!isMapReady}
            containerClass="flex justify-center items-center absolute w-full h-full"
            className="fa-2x"
          />
        </div>
        {(windowSize?.width || 0) < 992 && (
          <div
            ref={bottomSheetContentRef}
            className="fixed flex"
            style={{
              zIndex: 10,
              backgroundColor: 'white',
              left: 0,
              paddingTop: 20,
              bottom: 0,
              width: '100%',
              borderRadius: '0.5rem',
            }}
          >
            <div className="mb-5 flex flex-grow px-4 lg:px-5 flex-col justify-between">
              <div className="mb-5 lg:w-full md:mx-auto md:w-[30.25rem] relative border border-graystroke bg-graylight bg-opacity-94 rounded-lg">
                <AddressAutoComplete
                  className="px-3 border-0 border-b-0"
                  defaultValue={currentPlace?.address || ''}
                  placeholder={t('search_placeholder')}
                  style={{ flexShrink: 1, borderBottom: '1px solid rgba(0, 0, 0, 0.1' }}
                  onSelect={(place) => {
                    selectAddress(place);
                    if (bottomSheetContentRef.current) bottomSheetContentRef.current.style.top = 'unset';
                  }}
                  onFocus={() => {
                    if (bottomSheetContentRef.current) bottomSheetContentRef.current.style.top = '52px';
                  }}
                />
              </div>
              <div className="flex justify-center">
                <button
                  className="btn btn-yellow btn-lg w-full md:w-[30.25rem] lg:w-full"
                  disabled={!currentPlace}
                  onClick={select}
                >
                  {t('confirm', { ns: 'actions' })}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </ModalBase>
  );
};

import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';

interface TitleProps {}

const Title = ({}: TitleProps) => {
  const { rolesStore } = useStores();
  const { t } = useTranslation('policy');

  return rolesStore.activeGroup === null ? (
    <div className="-mt-3.5 mb-6 lg:hidden flex text-xs leading-5">
      <a className="text-gray" href="#">
        {t('title')}
      </a>
      <span className="wclassName=-5 text-center">&nbsp;&nbsp;/&nbsp;&nbsp;</span>
      <span className="text-black">{t('title_new')}</span>
    </div>
  ) : (
    <div className="mb-6 lg:hidden">
      <h2 className="text-2lg leading-6 font-medium text-black">{t('title')}</h2>
    </div>
  );
};

export default Title;

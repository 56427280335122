//import AsyncStorage from '@react-native-async-storage/async-storage';

class Storage {
  async getItem(item: string): Promise<string> {
    return localStorage.getItem(item) || '';
  }

  async setItem(item: string, value: string): Promise<void> {
    localStorage.setItem(item, value);
  }

  async removeItem(item: string): Promise<void> {
    localStorage.removeItem(item);
  }
}

export default new Storage();

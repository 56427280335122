import FASpinner from 'components/FASpinner';
import ModalBase from 'components/ModalBase';
import ModalSelectStore from 'components/ModalSelectStore';
import { GROUPS_LIST_LIMIT_PER_RAGE } from 'constants/policy';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';
import { notify } from 'utils/lib/notify';

interface AddUserDialogProps {
  onCancel: any;
  onClose: any;
  visible: boolean;
}

const AddUserDialog = observer(({ onClose, onCancel, visible }: AddUserDialogProps): JSX.Element | null => {
  const { t } = useNestedTranslation(['policy.current.users.dialog', 'actions', 'errors']);
  const { ordersStore, employeesStore, rolesStore } = useStores();
  const { activeGroup } = rolesStore || {};
  const isNewGroup: boolean = activeGroup?.id === 0;
  const [employee, setEmployee] = useState<any>();
  const [disableClose, setDisableClose] = useState<boolean>(false);

  const selectEmployee = (emplID: number, employee): void => {
    setEmployee(employee);
  };

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible]);

  const onConfirm = async (): Promise<void> => {
    // if (isNewGroup) {
    //     rolesStore.addUser(employee);
    // } else {
    setDisableClose(true);
    await employeesStore?.updateRecord(employee.id, { role_id: activeGroup.id });
    if (employeesStore?.addingError) {
      notify({
        type: 'danger',
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedUserAdding', { ns: 'errors' })} \"${employee?.name || ''}\": ${formatError(employeesStore?.addingError, t)}`,
      });
      setDisableClose(false);
      return;
    }
    // update groups to show correct count of users
    rolesStore.fetchList(false, {}, 'id desc', rolesStore?.page || 0, GROUPS_LIST_LIMIT_PER_RAGE, true, false);
    // }
    setDisableClose(false);
    close();
  };

  const cancel = (): void => {
    setEmployee(undefined);
    onCancel();
  };

  const close = (): void => {
    onClose();
    setEmployee(undefined);
  };

  return (
    <ModalBase onClose={close} title={t('title')} visible={visible} contentClass="overflow-visible">
      <ModalSelectStore
        inputWrapperClass="h-12 dark-form-control"
        inputClass="dark-form-control"
        store={ordersStore}
        fieldName={'employee_id'}
        placeholder={t('placeholder')}
        value={employee?.id}
        onChange={selectEmployee}
      />
      <div className="flex justify-end space-x-5 mt-5">
        <button type="button" onClick={cancel} className="btn btn-light">
          {t('cancel', { ns: 'actions' })}
        </button>
        <button
          disabled={employeesStore?.isAddingInProgress || !employee}
          type="button"
          onClick={onConfirm}
          className="btn btn-blue"
        >
          <FASpinner containerClass="mr-2" show={employeesStore?.isAddingInProgress} />
          {t('yes', { ns: 'actions' })}
        </button>
      </div>
    </ModalBase>
  );
});

export default AddUserDialog;

import { SearchBar } from 'components/SearchBar';
import React, { useEffect, useState } from 'react';
import { Pagination } from '../../components/Pagination';
import { useStores } from 'stores';
import Dialog from './dialog';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { useWindowSize } from 'hooks/useWindowSize';
import { CONTROLLER } from 'constants/common';
import EditDelete from 'components/EditDelete';
import FASpinner from 'components/FASpinner';
import { MD_WIDTH, XL_WIDTH } from 'constants/screenResolution';
import ConfirmModal from 'components/Modal/confirmModal';
import { notify } from 'utils/lib/notify';
import { formatError } from 'utils/lib/formatError';

export interface Address {
  id?: string | number;
  name?: string;
  place?: any;
  update?: any;
  delete?: any;
  employee_id?: string | number;
}

interface AdderssDialog {
  address: Address | null | undefined;
  isOpen: boolean;
}

const Favourites = observer((): JSX.Element => {
  const { favouritesStore, usersStore } = useStores();
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const { t } = useTranslation('favourites');
  const windowSize = useWindowSize();
  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [addressDialog, setAddressDialog] = useState<AdderssDialog>({
    address: null,
    isOpen: false,
  });
  const [deletingAddress, setDeletingAddress] = useState<any>();

  useEffect(() => {
    const isCompany = usersStore?.me?.role?.name === 'customer' || usersStore?.me?.role?.name === 'coordinator';
    favouritesStore
      .fetchList(false, isCompany ? { employee_id: -1 } : {}, 'id desc', 0, favouritesStore?.limit || 10, true, false)
      .then(() => setIsDataReady(true));
  }, []);

  const closeDialog = (): void => {
    setAddressDialog({
      address: null,
      isOpen: false,
    });
  };

  const showDialog = (address: Address | null = null): void => {
    setAddressDialog({
      address,
      isOpen: true,
    });
  };

  const getTableHeight = (): number | string => {
    if (!windowSize?.height) return 'auto';
    return windowSize.height - 178 - ((windowSize.width || 0) >= XL_WIDTH ? 0 : 24);
  };

  const renderTdName = (address: any): JSX.Element => (
    <div className="break-word">
      <div>{address.name}</div>
      {(windowSize.width || 0) < MD_WIDTH && (
        <div className="mt-1">
          <span>{address?.place?.address}</span>
        </div>
      )}
    </div>
  );

  const renderThName = () => (
    <>
      <div>
        {t('thead.name')} /{t('thead.address')}
      </div>
    </>
  );

  const renderController = (address?: Address | null): JSX.Element => (
    <>
      {(usersStore?.me?.role?.name !== 'employee' || address?.employee_id === usersStore?.me?.detail?.id) &&
        (usersStore?.isCustomer || usersStore?.me?.detail?.permissions?.create_favourites ? (
          <div className="flex items-center justify-end">
            {isDeleting && address?.id === deletingAddress?.id ? (
              <FASpinner show />
            ) : (
              <EditDelete onEdit={() => showDialog(address)} onDelete={() => onDelete(address)} />
            )}
          </div>
        ) : null)}
    </>
  );

  const onDelete = (address?: Address | null): void => {
    setDeletingAddress(address);
    setVisibleConfirm(true);
  };

  const closeConfirm = (): void => {
    setDeletingAddress(null);
    setVisibleConfirm(false);
  };

  const onAddressDelete = async (e: any): Promise<void> => {
    e.stopPropagation();
    setIsDeleting(true);
    closeConfirm();
    await deletingAddress?.delete();
    if (favouritesStore.deletingError) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedAddressDeletion', { ns: 'errors' })} \"${deletingAddress?.name || ''}\": ${formatError(favouritesStore.deletingError, t)}`,
        type: 'danger',
      });
    }
    setIsDeleting(false);
  };

  return (
    <main>
      <section className="pt-6 lg:pt-7.5">
        <div className="container flex-grow flex flex-col">
          <div className="mb-6 lg:hidden">
            <h2 className="text-2lg leading-6 font-medium text-black">{t('title')}</h2>
          </div>
          {usersStore?.isCustomer || usersStore?.me?.detail?.permissions?.create_favourites ? (
            <div className="mb-6 lg:mb-5 flex table-bar">
              <SearchBar containerClass="w-44 md:w-80" store={favouritesStore} placeholder={t('search_placeholder')} />
              <div className="flex space-x-4 lg:space-x-5 ml-4 md:ml-auto">
                <div>
                  <button
                    className="btn btn-blue px-1.5 w-10 md:px-4 md:w-auto"
                    type="button"
                    onClick={() => showDialog()}
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path d="M10 2A7.953 7.953 0 0 0 4.34 4.342c-3.12 3.12-3.122 8.195-.001 11.316A7.951 7.951 0 0 0 9.998 18c2.14 0 4.15-.832 5.661-2.344 3.121-3.118 3.121-8.193 0-11.314A7.956 7.956 0 0 0 10 2Zm0 1.359c1.776 0 3.445.69 4.698 1.944a6.648 6.648 0 0 1 0 9.393 6.605 6.605 0 0 1-4.7 1.945 6.6 6.6 0 0 1-4.697-1.944 6.649 6.649 0 0 1 .001-9.394A6.604 6.604 0 0 1 10 3.359Zm0 3.287a.68.68 0 0 0-.68.68V9.32H7.324a.68.68 0 1 0 0 1.358H9.32v1.996a.68.68 0 1 0 1.36 0v-1.996h1.996a.68.68 0 1 0 0-1.358H10.68V7.325a.68.68 0 0 0-.68-.68Z"></path>
                    </svg>
                    <span className="hidden md:block md:ml-1.5">{t('action.add')}</span>
                  </button>
                </div>
              </div>
            </div>
          ) : null}
          {favouritesStore.isFetchingListInProgress || !isDataReady ? (
            <span>{t('loading')}</span>
          ) : (
            <div className="area mb-5 flex-grow flex flex-col overflow-hidden">
              <div className="flex" style={{ maxHeight: getTableHeight() }}>
                <Table
                  stickyHeader
                  tableClass="w-full"
                  data={favouritesStore?.list}
                  colClasses={(windowSize.width || 0) < MD_WIDTH ? undefined : ['w-[14.75rem]', '']}
                  columnsKeys={
                    (windowSize.width || 0) < MD_WIDTH ? ['name', CONTROLLER] : ['name', 'address', CONTROLLER]
                  }
                  keySelectors={{ address: 'place.address' }}
                  translateFunc={(key) => (key && key !== CONTROLLER ? t(`thead.${key}`) : '')}
                  customTdInnerRenderer={{
                    name: renderTdName,
                    [CONTROLLER]: renderController,
                  }}
                  customThInnerRenderer={{
                    ...((windowSize.width || 0) < MD_WIDTH ? { name: renderThName } : {}),
                  }}
                >
                  <div className="area-inner">
                    <Pagination store={favouritesStore} />
                  </div>
                </Table>
              </div>
            </div>
          )}
        </div>
      </section>
      <Dialog
        visible={addressDialog.isOpen}
        address={addressDialog.address}
        onCancel={closeDialog}
        onClose={closeDialog}
      />
      <ConfirmModal
        title={t('deletion_title', { ns: 'confirmation' })}
        text={t('deletion_confirmation', { ns: 'confirmation' })}
        visible={visibleConfirm}
        onCancel={closeConfirm}
        onClose={closeConfirm}
        onConfirm={onAddressDelete}
      />
    </main>
  );
});

export default Favourites;

import FASpinner from 'components/FASpinner';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';
import { Address } from '.';
import { AddressMap } from './map';
import ModalBase from 'components/ModalBase';
import { useNestedTranslation } from 'hooks/useNestedTranslations';

interface DialogProps {
  visible: boolean;
  onCancel: any;
  onAdd?: any;
  onClose?: any;
  address?: Address | null;
}

interface Validation {
  address?: string;
  submit?: string;
}

const Dialog = observer(({ visible, onCancel, onAdd, onClose, address }: DialogProps) => {
  const getAddressData = (): any => ({ ...(address ? address : {}) });
  const { favouritesStore } = useStores();
  const [visibleMap, setVisibleMap] = useState<boolean>(false);
  const [currentAddress, setCurrentAddress] = useState<Address>(getAddressData());
  const [errors, setErrors] = useState<Validation>({});
  const { t } = useNestedTranslation(['favourites.dialog', 'validation', 'actions', 'errors']);

  const resetErrors = (): void => setErrors({});

  const openMap = (): void => {
    setVisibleMap(true);
    resetErrors();
  };
  const closeMap = (): void => setVisibleMap(false);

  useEffect(() => {
    if (visible) {
      setCurrentAddress(getAddressData());
    }
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible]);

  const handleChangeByField = (data: Partial<any>): void => {
    setCurrentAddress((prevCoord) => ({
      ...prevCoord,
      ...data,
    }));
  };

  const onFocus = (): void => {
    setErrors({});
  };

  const add = async (e: any): Promise<void> => {
    e.preventDefault();
    if (!currentAddress) return;
    const data = {
      place: currentAddress.place,
      name: currentAddress?.name?.trim(),
    };
    if (onAdd) {
      onAdd(data);
    }
    if (address) {
      await address.update(data);
    } else {
      await favouritesStore.addRecord(data);
    }
    if (favouritesStore.addingError) {
      setErrors((err: any) => ({
        ...err,
        submit: formatError(favouritesStore.addingError, t),
      }));
      return;
    } else {
      setCurrentAddress(getAddressData());
      onClose();
    }
  };

  const cancel = (): void => {
    setCurrentAddress({});
    setErrors({});
    if (onCancel) {
      onCancel();
    }
  };

  return (
    <>
      <ModalBase visible={visible} title={t(address ? 'title_edit' : 'title_new')} onClose={cancel}>
        <form>
          <div className="mb-6">
            {errors.submit && <p className="error mb-5">{errors.submit}</p>}
            <div className="mb-5">
              <label className="form-label">{t('name')}</label>
              <input
                className="form-control form-control--lg"
                value={currentAddress?.name}
                onFocus={onFocus}
                onChange={(e) => handleChangeByField({ name: e.target.value })}
                type="text"
                id="name"
                placeholder={t('name_placeholder')}
              />
            </div>
            <div className="mt-5">
              <span className="form-label">{t('address')}</span>
              <div onClick={openMap} className={`form-control form-control--lg flex items-center justify-between`}>
                <span
                  className={`one-line-text mr-3 ${currentAddress?.place?.address ? '' : 'opacity-50'}`}
                  style={{ flexShrink: 1 }}
                >
                  {currentAddress?.place?.address || t('address_placeholder')}
                </span>
                <div className="opacity-50">
                  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                    <path
                      fill="#444444"
                      d="M8 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM13.2 5.3c0.4 0 0.7 0.3 1.1 0.3-0.3 0.4-1.6 0.4-2-0.1 0.3-0.1 0.5-0.2 0.9-0.2zM1 8c0-0.4 0-0.8 0.1-1.3 0.1 0 0.2 0.1 0.3 0.1 0 0 0.1 0.1 0.1 0.2 0 0.3 0.3 0.5 0.5 0.5 0.8 0.1 1.1 0.8 1.8 1 0.2 0.1 0.1 0.3 0 0.5-0.6 0.8-0.1 1.4 0.4 1.9 0.5 0.4 0.5 0.8 0.6 1.4 0 0.7 0.1 1.5 0.4 2.2-2.5-1.2-4.2-3.6-4.2-6.5zM8 15c-0.7 0-1.5-0.1-2.1-0.3-0.1-0.2-0.1-0.4 0-0.6 0.4-0.8 0.8-1.5 1.3-2.2 0.2-0.2 0.4-0.4 0.4-0.7 0-0.2 0.1-0.5 0.2-0.7 0.3-0.5 0.2-0.8-0.2-0.9-0.8-0.2-1.2-0.9-1.8-1.2s-1.2-0.5-1.7-0.2c-0.2 0.1-0.5 0.2-0.5-0.1 0-0.4-0.5-0.7-0.4-1.1-0.1 0-0.2 0-0.3 0.1s-0.2 0.2-0.4 0.1c-0.2-0.2-0.1-0.4-0.1-0.6 0.1-0.2 0.2-0.3 0.4-0.4 0.4-0.1 0.8-0.1 1 0.4 0.3-0.9 0.9-1.4 1.5-1.8 0 0 0.8-0.7 0.9-0.7s0.2 0.2 0.4 0.3c0.2 0 0.3 0 0.3-0.2 0.1-0.5-0.2-1.1-0.6-1.2 0-0.1 0.1-0.1 0.1-0.1 0.3-0.1 0.7-0.3 0.6-0.6 0-0.4-0.4-0.6-0.8-0.6-0.2 0-0.4 0-0.6 0.1-0.4 0.2-0.9 0.4-1.5 0.4 1.1-0.8 2.5-1.2 3.9-1.2 0.3 0 0.5 0 0.8 0-0.6 0.1-1.2 0.3-1.6 0.5 0.6 0.1 0.7 0.4 0.5 0.9-0.1 0.2 0 0.4 0.2 0.5s0.4 0.1 0.5-0.1c0.2-0.3 0.6-0.4 0.9-0.5 0.4-0.1 0.7-0.3 1-0.7 0-0.1 0.1-0.1 0.2-0.2 0.6 0.2 1.2 0.6 1.8 1-0.1 0-0.1 0.1-0.2 0.1-0.2 0.2-0.5 0.3-0.2 0.7 0.1 0.2 0 0.3-0.1 0.4-0.2 0.1-0.3 0-0.4-0.1s-0.1-0.3-0.4-0.3c-0.1 0.2-0.4 0.3-0.4 0.6 0.5 0 0.4 0.4 0.5 0.7-0.6 0.1-0.8 0.4-0.5 0.9 0.1 0.2-0.1 0.3-0.2 0.4-0.4 0.6-0.8 1-0.8 1.7s0.5 1.4 1.3 1.3c0.9-0.1 0.9-0.1 1.2 0.7 0 0.1 0.1 0.2 0.1 0.3 0.1 0.2 0.2 0.4 0.1 0.6-0.3 0.8 0.1 1.4 0.4 2 0.1 0.2 0.2 0.3 0.3 0.4-1.3 1.4-3 2.2-5 2.2z"
                    ></path>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-end space-x-5">
            <button className="btn btn-light" type="button" onClick={cancel}>
              {t('cancel', { ns: 'actions' })}
            </button>
            <button
              className="btn btn-blue"
              type="submit"
              onClick={add}
              disabled={
                !currentAddress?.place?.address?.trim() ||
                !currentAddress?.name?.trim() ||
                favouritesStore.isAddingInProgress
              }
            >
              <FASpinner containerClass="mr-2" show={favouritesStore.isAddingInProgress} />
              {address ? t('save', { ns: 'actions' }) : t('add', { ns: 'actions' })}
            </button>
          </div>
        </form>
      </ModalBase>
      <AddressMap
        visible={visibleMap}
        onClose={closeMap}
        address={currentAddress}
        onSelect={(place: any) => handleChangeByField({ place })}
      />
    </>
  );
});

export default Dialog;

import ModalSelect, { ModalSelectProps } from 'components/ModalSelect';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface EditDeleteProps {
  onEdit?: any;
  onDelete?: any;
  children?: React.ReactChild | React.ReactChild[];
  appendChildren?: boolean;
}

const EditDelete = ({
  onDelete,
  onEdit,
  children,
  appendChildren = false,
  ...rest
}: EditDeleteProps & ModalSelectProps): JSX.Element => {
  const { t } = useTranslation('actions');
  const renderCustomContent = (): JSX.Element => (
    <div className="edit-delete-container min-w-24">
      {onEdit && (
        <button className="edit-delete-item" onClick={onEdit} type="button">
          {t('edit')}
        </button>
      )}
      {onDelete && (
        <button className="edit-delete-item" onClick={onDelete} type="button">
          {t('delete')}
        </button>
      )}
      {children && appendChildren && children}
    </div>
  );

  return (
    <ModalSelect customContent={renderCustomContent()} containerClass="edit-delete" withPointer {...rest}>
      {children && !appendChildren ? (
        <>{children}</>
      ) : (
        <button className="w-5 h-5 text-black text-opacity-40" type="button" aria-label="Открыть меню">
          <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path d="M10 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM10 18a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM10 12a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
          </svg>
        </button>
      )}
    </ModalSelect>
  );
};

export default EditDelete;

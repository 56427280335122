import FASpinner from 'components/FASpinner';
import ModalBase, { ModalBaseProps } from 'components/ModalBase';
import { observer } from 'mobx-react-lite';
import { Address } from 'modules/Favourites';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';

interface FavouritesModalProps {
  onSelect: any;
}

const FavouritesModal = observer(
  ({ onSelect, ...modalProps }: FavouritesModalProps & Omit<ModalBaseProps, 'children'>): JSX.Element => {
    const { favouritesStore, ordersStore } = useStores();
    const { t } = useTranslation('validation');
    useEffect(() => {
      if (modalProps?.visible) {
        const { employee_id, customer_id } = ordersStore?.newOrderInfo || {};
        if (customer_id || employee_id) {
          favouritesStore.fetchList(
            false,
            {
              employee_id: ['in', [...(customer_id ? [customer_id * -1] : []), ...(employee_id ? [employee_id] : [])]],
            },
            'id desc',
            0,
            100,
            true,
            false,
          );
        } else {
          favouritesStore.resetList();
        }
      }
    }, [modalProps?.visible]);

    const renderAddress = (address: Address, i: number): JSX.Element => (
      <div
        className={`py-3 ${i && 'border-t'} one-line-text cursor-pointer`}
        onClick={() => {
          onSelect(address.place);
        }}
      >
        <b>{address?.name || ''}</b>
        <br />
        {address?.place?.address || ''}
      </div>
    );

    return (
      <ModalBase {...modalProps}>
        <div className="overflow-y-auto" style={{ height: window.innerHeight * 0.7 }}>
          {favouritesStore?.fetchingListError && !favouritesStore?.isFetchingListInProgress ? (
            <span className="error">{formatError(favouritesStore.fetchingListError, t)}</span>
          ) : null}
          {favouritesStore?.isFetchingListInProgress ? (
            <FASpinner show containerClass="flex justify-center items-center flex-grow h-full" className="fa-2x" />
          ) : null}
          {favouritesStore?.list?.length > 0 && !favouritesStore?.isFetchingListInProgress
            ? favouritesStore.list.map(renderAddress)
            : null}
        </div>
      </ModalBase>
    );
  },
);

export default FavouritesModal;

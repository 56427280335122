import { useNestedTranslation } from 'hooks/useNestedTranslations';
import React from 'react';

interface AddRuleButtonProps {
  onInlineModalShow?: any;
  onModalShow?: any;
}

const AddRuleButton = ({ onInlineModalShow, onModalShow }: AddRuleButtonProps): JSX.Element => {
  const { t } = useNestedTranslation('policy.current.rules.actions');
  return (
    <>
      <div className="lg:hidden">
        <button
          className="btn btn-link"
          type="button"
          onClick={onModalShow} /*@click="isOpen = true; document.body.classList.add('overflow-hidden')"*/
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="12" fill="#FDCD03"></circle>
            <path
              d="M19 12L12 12L5 12"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M12 19L12 12L12 5"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <span>{t('add')}</span>
        </button>
      </div>
      <div className="hidden lg:block">
        <button
          className="btn btn-link"
          type="button"
          onClick={
            onInlineModalShow
          } /*@click="isOpen = true; const pos = $event.target.closest('.btn').getBoundingClientRect(); $refs.addRule.style.left = pos.left + 410 + 'px'; $refs.addRule.style.top = pos.top - 96 + 'px'"*/
        >
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="12" fill="#FDCD03"></circle>
            <path
              d="M19 12L12 12L5 12"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M12 19L12 12L12 5"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <span>{t('add')}</span>
        </button>
      </div>
    </>
  );
};

export default AddRuleButton;

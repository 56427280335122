import { observable, action, runInAction, reaction, makeObservable } from 'mobx';
import WebApiClient from '../WebApi';
import AuthApi from './api';
import Storage from 'utils/storage';
import { StoreInterface } from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';
import * as Sentry from '@sentry/react';
import { Ride } from '../../types/types';

class AuthStore implements StoreInterface {
  rootStore: RootStoreInterface;

  @observable authApi: AuthApi;
  @observable signinError = undefined;
  @observable isSigninInProgress = false;
  @observable isSigninByPhoneSmsSent = false;
  @observable isAuthenticated = false;

  @observable signUpError?: string;
  @observable logoutError?: string;
  @observable isRequestPasswordEmailError?: boolean;
  //@observable accessTokenDecoded;

  @observable isLogoutInProgress?: boolean;
  @observable isSignUpInProgress?: boolean;
  @observable isSignUpSuccess?: boolean;
  @observable isRequestPasswordEmailInProgress?: boolean;
  @observable isRequestPasswordEmailSuccess?: boolean;
  @observable isAccountActivation?: boolean;
  @observable accountActivationError?: string;
  @observable fetchingAccessTokenInfoError?: string;
  @observable isResetPasswordInProgress?: boolean;
  @observable isResetPasswordSuccess?: boolean;
  @observable isResetPasswordError?: boolean;
  @observable isUnderImpersonate?: boolean;
  @observable impersonateError?: string;
  @observable isVerifyInProgress?: boolean;
  @observable isVerifyRequired?: boolean;
  @observable verifyError?: string;
  @observable refreshingTokenError?: string;
  @observable unAuthedOrderInfo?: Ride;
  @observable unAuthedOrderError?: boolean;
  @observable isGettingPhone: boolean = false;
  @observable driverPhone: string = '';
  /*
    isRequestPasswordEmailError: observable,
    accessTokenDecoded: observable,
    isSignUpInProcess: observable,
    isSignUpSuccess: observable,
    isRequestPasswordEmailInProcess: observable,
    isRequestPasswordEmailSuccess: observable,
    isAccountActivation: observable,
    accountActivationError: observable,
    fetchingAccessTokenInfoError: observable,
    isResetPasswordInProcess: observable,
    isResetPasswordSuccess: observable,
    isResetPasswordError: observable,
    isUnderImpersonate: observable,
    impersonateError: observable,

    isAuthenticated: computed,

    userVerify: action,
    isVerifyInProgress: observable,
    isVerifyRequired: observable,
    verifyError: observable,


    fetchClientAccessToken: action,
    refreshAccessToken: action,
    refreshingTokenError: observable,
    fetchAccessTokenInfo: action,
    authenticate: action,
    impersonate: action,
    loginByPassword: action,
    loginByActivationCode: action,
    logoutOrEscapeImpersonate: action,
    logout: action,
    requestResetPassword: action,
    signUpByPassword: action,
    resetPassword: action,
    escapeImpersonate: action
     */

  /*


    isSigninInProgress: string | undefined;

    loginError: string | undefined;


    signUpError: string | undefined;

    logoutError: object | undefined;

    isRequestPasswordEmailError: string | undefined;



    isClientAccessTokenFetched = false;

    accessTokenDecoded: AccessTokenDecoded = {
        role: undefined,
        expiredAt: undefined,
        issuedAt: undefined,
        issuer: undefined,
        jti: undefined,
        ownerId: undefined,
        type: undefined,
        coId: undefined,
        ownUid: undefined,
    };

    @observable
    isLogoutInProcess = false;

    isSignUpInProcess = false;

    isVerifyRequired = false;

    isSignUpSuccess = false;

    isRequestPasswordEmailInProcess = false;

    isRequestPasswordEmailSuccess = false;

    isAccountActivation = false;

    accountActivationError: string | undefined = undefined;

    refreshingTokenError: any;

    fetchingAccessTokenInfoError: any;

    isResetPasswordInProcess = false;

    isResetPasswordSuccess = false;

    isResetPasswordError: string | undefined = undefined;

    isUnderImpersonate = false;

    impersonateError: any;
     */

  constructor(rootStore: RootStoreInterface) {
    this.rootStore = rootStore;
    this.authApi = new AuthApi();

    makeObservable(this);

    // handle failed access token info fetching
    reaction(
      () => this.fetchingAccessTokenInfoError,
      (fetchingAccessTokenInfoError) => {
        if (fetchingAccessTokenInfoError) {
          this.refreshAccessToken();
        }
      },
    );

    // handle failed access token refreshing
    reaction(
      () => this.refreshingTokenError,
      (refreshingTokenError) => {
        if (refreshingTokenError) {
          this.fetchClientAccessToken();
        }
      },
    );

    // hanlde successfull login and logout
    /*
        reaction(
            () => this.isAuthenticated,
            (isAuthenticated) => {
                if (isAuthenticated) {
                    // clear errors after successfull authentication
                    this.fetchingAccessTokenInfoError = undefined;
                    this.refreshingTokenError = undefined;

                    // set jti as csrf verification param
                    const { jti } = this.accessTokenDecoded;
                    if (jti) {
                        this.rootStore.authApi.setJti(jti);
                    }
                } else {
                    // run authentication after logout or something that cleared accessTokenDecoded
                  //  this.authenticate();
                }
            },
        );
         */

    /*
        // hanlde jti changed
        reaction(
            () => this.accessTokenDecoded.jti,
            (jti) => {
                // set jti as csrf verification param
                if (jti) {
                    this.rootStore.authApi.setJti(jti);
                }
            },
        );
        */

    /*
        // handle soon access token expiration time
        reaction(
            () => this.accessTokenDecoded.expiredAt,
            (expiredAt) => {
                if (expiredAt) {
                    const expiredAtDate = fromUnixTime(parseInt(expiredAt, 10));
                    if (differenceInCalendarDays(expiredAtDate, new Date()) < 2) {
                        // refresh access token if it expires in less than 2 days
                        this.refreshAccessToken();
                    }
                }
            },
        );
         */
  }

  @action
  async refreshAccessToken(): Promise<void> {
    alert('TODO: refreshAccessToken');
  }

  @action
  async fetchClientAccessToken(): Promise<void> {
    alert('TODO: fetchClientAccessToke');
  }

  @action
  async userSignInByPhone(phone: string): Promise<void> {
    runInAction(() => {
      this.isSigninInProgress = true;
      this.isSigninByPhoneSmsSent = false;
      this.signinError = undefined;
    });

    try {
      await this.authApi.signInByPhone(phone);

      runInAction(() => {
        this.isSigninInProgress = false;
        this.isSigninByPhoneSmsSent = true;
      });
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.isSigninInProgress = false;
        this.isSigninByPhoneSmsSent = false;
        this.signinError = err;
      });
    }
  }
  @action
  captureError(err: any) {
    if (process.env.REACT_APP_API_BASE_URL && process.env.REACT_APP_API_BASE_URL.toString().indexOf('stage') < 0) {
      Sentry.captureException(err);
    }
  }
  @action
  async userSignInByPhoneCheck(phone: string, smsCode: string): Promise<void> {
    runInAction(() => {
      this.isSigninInProgress = true;
      this.signinError = undefined;
    });

    try {
      const result = await this.authApi.signInByPhoneCheck(phone, smsCode);

      await this.setAuthorizationToken(result.access_token);
      runInAction(() => {
        this.isSigninInProgress = false;
      });
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.isSigninInProgress = false;
        this.signinError = err;
      });
    }
  }

  async checkAuth() {
    if (this.isAuthenticated) return true;

    //AsyncStorage.getItem("access_token").then (accessToken=>{
    //    this.setAuthorizationToken(accessToken);
    //});

    if (await Storage.getItem('access_token')) {
      return this.setAuthorizationToken(await Storage.getItem('access_token'));
    }

    return false;
  }

  @action
  async setAuthorizationToken(token: string) {
    console.log('SET AUTH TOKEN');
    await Storage.setItem('access_token', token);
    const status = await WebApiClient.checkToken();
    if (status) {
      await this.rootStore.userAuthorized();
      runInAction(() => {
        this.isAuthenticated = true;
      });
      return true;
    } else {
      await Storage.removeItem('access_token');
      return false;
    }
  }

  @action
  async getOrderInfo(id: string | number): Promise<any> {
    try {
      this.unAuthedOrderError = false;
      const data = await this.authApi.getOrderInfo(id);
      this.unAuthedOrderInfo = {
        ...data?.order,
        track: data?.route_points,
        current_location: data?.current_location,
        ...(this.driverPhone
          ? {
              driver_phone: this.driverPhone,
            }
          : {}),
      };
    } catch (e) {
      console.error('Error: order info', e);
      this.unAuthedOrderError = true;
    }
    return;
  }

  @action
  async getDriverPhone(id: string | number): Promise<any> {
    try {
      runInAction(() => {
        this.driverPhone = '';
        this.isGettingPhone = true;
      });
      const { phone } = (await this.authApi.getDriverPhone(id)) || {};
      if (phone) {
        this.driverPhone = phone;
        runInAction(() => {
          this.unAuthedOrderInfo = { ...this.unAuthedOrderInfo, driver_phone: phone };
        });
      }
    } catch (e) {
      console.error('Error: publish order', e);
    }
    runInAction(() => {
      this.isGettingPhone = false;
    });
    return;
  }
  async userSignOut(): Promise<void> {
    this.isLogoutInProgress = true;
    this.logoutError = undefined;
    try {
      runInAction(() => {
        Storage.removeItem('access_token');
        Storage.removeItem('websocket_uuid');

        //this.rootStore.modulesStore.usersStore.me = {}; //TODO. fix it!
        //this.rootStore.authApi.setToken("");
        //localStorage.removeItem("access_token");

        this.isAuthenticated = false;

        this.rootStore.resetStores();
      });
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.logoutError = err;
      });
    }

    runInAction(() => {
      this.isLogoutInProgress = false;
    });
  }

  /*
    async decodeAccessToken(accessToken: string) {
        try {
            console.log("UUUUUU", accessToken);
            console.log(jwt);
            console.log("!!!!!!!");
            const accessTokenDecoded = await jwt.decode(accessToken);
            console.log("PPPPPPPPP", accessTokenDecoded)
            if (!accessTokenDecoded || typeof accessTokenDecoded !== 'object') {
                return {};
            }

            const { expiredAt } = accessTokenDecoded;
            if (!expiredAt) {
                return {};
            }

            if (accessTokenDecoded.expiredAt > Math.floor(Date.now() / 1000)) {
                // not expired
                return accessTokenDecoded;
            }


            // expired
            return {};
        } catch (err) {
            console.error("DDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDDD", err);
            // invalid jwt
            return {};
        }
    }
 */
}

/*
decorate(AuthStore, {
    authApi: observable,
    signinError: observable,
    signUpError: observable,
    logoutError: observable,
    isRequestPasswordEmailError: observable,
    accessTokenDecoded: observable,
    isSigninInProgress: observable,
    isLogoutInProcess: observable,
    isSignUpInProcess: observable,
    isSignUpSuccess: observable,
    isRequestPasswordEmailInProcess: observable,
    isRequestPasswordEmailSuccess: observable,
    isAccountActivation: observable,
    accountActivationError: observable,
    fetchingAccessTokenInfoError: observable,
    isResetPasswordInProcess: observable,
    isResetPasswordSuccess: observable,
    isResetPasswordError: observable,
    isUnderImpersonate: observable,
    impersonateError: observable,

    isAuthenticated: computed,

    userVerify: action,
    isVerifyInProgress: observable,
    isVerifyRequired: observable,
    verifyError: observable,


    fetchClientAccessToken: action,
    refreshAccessToken: action,
    refreshingTokenError: observable,
    fetchAccessTokenInfo: action,
    authenticate: action,
    impersonate: action,
    loginByPassword: action,
    loginByActivationCode: action,
    logoutOrEscapeImpersonate: action,
    logout: action,
    requestResetPassword: action,
    signUpByPassword: action,
    resetPassword: action,
    escapeImpersonate: action
});

 */

export default AuthStore;

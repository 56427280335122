import { observable, action, runInAction, makeObservable } from 'mobx';
import BaseStore from '../BaseStore';
import Storage from '../../utils/storage';
import { RootStoreInterface } from '../../interfaces';

class WelcomeStore extends BaseStore {
  @observable isWelcomeShowed = false;

  constructor(rootStore: RootStoreInterface) {
    super(rootStore, {});
    makeObservable(this);
    this.init();
  }

  async init() {
    this.isWelcomeShowed = (await Storage.getItem('isWelcomeShowed')) === '1';
  }

  @action
  setWelcomeShowed() {
    runInAction(async () => {
      this.isWelcomeShowed = true;
      await Storage.setItem('isWelcomeShowed', '1');
    });
  }
}

export default WelcomeStore;

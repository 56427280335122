export default {
  analytics: 'Analytics',
  employees: 'Employees',
  coordinators: 'Coordinators',
  favourites: 'Favourites',
  rides: 'Orders',
  rides_policy: 'Orders policy',
  promocodes: 'Promocodes',
  reports: 'Reports',
  new_order: 'New order',
  exit: 'Log out',
  info: {
    manager: 'Manager',
    phone: 'Contact center phone',
    feedback: 'Feedback',
    send_feedback: 'Send',
    sent_feedback_text:
      'Thank you for your request. The manager will contact you on a weekday from 10:00 to 18:00. For urgent questions, please contact support by phone.',
    sent_feedback_error: 'Sorry, we were unable to send your notification. Please try again.',
    manager_email: 'Manager email',
  },
  settings: {
    title: 'Settings',
    limit: {
      title: 'Limit',
      of: 'of',
      remainder: {
        group: 'Remaining corporate limit including VAT',
        personal: 'Remaining personal limit including VAT',
      },
    },
    lang: 'Interface language',
    password: {
      current: 'Current password',
      current_placeholder: 'Enter the current password',
      new: 'New password',
      new_placeholder: 'Enter a new password',
      new_again: 'Repeat new password',
      new_again_placeholder: 'Re-enter the new password',
    },
    actions: {
      cancel: 'Cancel',
      save: 'Save',
    },
  },
};

import { makeObservable, observable, runInAction, action } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';
import RolesCostCentersAPI from './api';
import { CostCenter } from '../../modules/Policy/costCentersDialog';
import { Employee, Promocode } from 'types/types';
import { Ride } from 'types/types';
import { omitEmptyValues } from 'utils/lib/omitEmptyValues';
import { isEmpty } from 'lodash';

export default class RolesCostCentersStore extends BaseStore {
  api: RolesCostCentersAPI;
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['RolesCostCenters']);
    this.api = new RolesCostCentersAPI(rootStore.specifications['RolesCostCenters'].url);
    this.setApiClient(this.api);
    makeObservable(this);
  }

  @observable listsByRoleId: any = {};
  @observable loadingListId: string | number = '';

  @action
  async fetchListByRoleId(role_id: number | string): Promise<void> {
    runInAction(() => {
      this.loadingListId = role_id;
    });
    try {
      await this.fetchList(false, { role_id }, 'id desc', 0, 100, true, false);
      if (this.list) {
        runInAction(() => {
          this.listsByRoleId = {
            ...this.listsByRoleId,
            [role_id]: this.list,
          };
        });
      }
    } catch (err) {
      console.error(err);
      this.captureError(err);
    }
    runInAction(() => {
      this.loadingListId = '';
    });
  }

  @observable isFieldsFetching: boolean = false;
  @observable isFieldsFetched: boolean = false;
  @observable fieldsFetchingError: string = '';
  @observable cost_centers: CostCenter[] = [];
  @action
  resetLists(): void {
    runInAction(() => {
      this.listsByRoleId = {};
    });
  }
  @action
  async fetchFields(params: {
    phone: Employee['phone'];
    promo_id: Promocode['id'];
    customer_id: Ride['customer_id'];
    employee_id: Employee['id'];
  }): Promise<void> {
    const payload = omitEmptyValues(params);
    if (isEmpty(payload)) return;
    runInAction(() => {
      this.fieldsFetchingError = '';
      this.isFieldsFetching = true;
      this.isFieldsFetched = false;
    });

    try {
      const response = await this.api.getFields(payload);
      runInAction(() => {
        this.cost_centers = response;
        this.isFieldsFetching = false;
        this.isFieldsFetched = true;
      });
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.fieldsFetchingError = err as string;
        this.isFieldsFetching = false;
        this.isFieldsFetched = true;
      });
    }
  }
}

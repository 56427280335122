import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import FASpinner from 'components/FASpinner';
import ModalBase from 'components/ModalBase';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';
import TimeRangeComponent from './TimeRangeComponent';

export interface Time {
  hour: number;
  mins: number;
}

export interface TimeRange {
  since: Time | null;
  until: Time | null;
}

export interface DayCardValue {
  since?: Time | null;
  until?: Time | null;
  intervals?: TimeRange[];
  disabled?: boolean;
}

interface DayCardProps {
  day: string;
  dayKey: string;
  dayLong: string;
  active?: boolean;
  onClick?: any;
  value: DayCardValue;
  isWeekendDay?: boolean;
  inProgress?: boolean;
}

interface Validation {
  submit?: string;
}

const defaultDay = {
  since: {
    hour: 0,
    mins: 1,
  },
  until: {
    hour: 23,
    mins: 59,
  },
};

const defaultEmptyDay = {
  since: null,
  until: null,
};

export const DayCard = observer(
  ({
    day,
    dayLong,
    active = false,
    onClick,
    value,
    dayKey,
    isWeekendDay,
    inProgress = false,
  }: DayCardProps): JSX.Element | null => {
    const opacity = active && !inProgress ? 1 : 0.5;
    const { rolesStore } = useStores();
    const [errors, setErrors] = useState<Validation>({});
    const { activeGroup } = rolesStore || {};
    const [visible, setVisible] = useState<boolean>(false);
    const getInitialInteravls = () =>
      value?.intervals
        ? [...value.intervals, defaultEmptyDay]
        : [
            {
              since: value?.since || { hour: 0, mins: 0 },
              until: value?.until || { hour: 0, mins: 0 },
            },
            defaultEmptyDay,
          ];
    const [intervals, setIntervals] = useState<TimeRange[]>(getInitialInteravls());
    const { t } = useNestedTranslation(['policy.current.rules.schedule', 'actions', 'errors']);
    const currentTimeRef = useRef<{ time: 'since' | 'until'; index: number; changed: boolean }>();

    useEffect(() => {
      setIntervals(getInitialInteravls());
    }, [value, dayKey]);

    const formatDaySchedule = (time: Time | null): string => {
      if (!time) return '';
      let hour: string = time.hour?.toString();
      hour = hour.length > 1 ? hour : `0${hour}`;
      let mins: string = time.mins?.toString();
      mins = mins.length > 1 ? mins : `0${mins}`;
      return `${hour}:${mins}`;
    };

    const close = (): void => {
      resetCurrentIndex();
      setErrors({});
      setVisible(false);
      setIntervals(getInitialInteravls());
    };

    const resetCurrentIndex = (): void => {
      if (currentTimeRef.current)
        currentTimeRef.current = {
          ...currentTimeRef.current,
          index: 0,
          changed: false,
        };
    };

    const saveTime = async (): Promise<void> => {
      if (!activeGroup.schedule || !dayKey) return;
      await rolesStore.updateActiveGroup({
        schedule: {
          ...activeGroup.schedule,
          [dayKey]: {
            intervals: filterValidIntervals(),
            disabled: false,
          },
        },
      });
      if (rolesStore.addingError) {
        setErrors({ submit: rolesStore.addingError });
        return;
      }
      setErrors({});
      setVisible(false);
    };

    // Check if intervals has valid value
    const isTimeRangesIncorrect = (): boolean => {
      if (!intervals) return true;
      let isIncorect = false;
      const last = intervals[intervals.length - 1];
      // if last time range isn't comleted and isn't empty, then disable Save button
      if (last?.since?.hour || last?.since?.mins || last?.until?.hour || last?.until?.mins) {
        isIncorect = true;
      }
      const arr = intervals.slice(0, intervals?.length - 1);
      if (!isIncorect) {
        // if one of the intervals since value is after its until value then return true
        arr.forEach((time) => {
          if (!time?.until || !time?.since || time.since?.hour > time.until?.hour) {
            isIncorect = true;
            return;
          }
          if (
            time.since?.hour === time.until?.hour &&
            (time.since?.mins > time.until?.mins || time.since?.mins === time.until?.mins)
          )
            isIncorect = true;
        });
      }
      return isIncorect;
    };

    const onChangeRange = (changed: TimeRange, ind: number) => {
      const intervalsCopy: TimeRange[] = intervals.map((r: TimeRange, i: number) => (i === ind ? changed : r));
      const lastElement: TimeRange = intervalsCopy[intervalsCopy.length - 1];
      if (lastElement.since && lastElement.until) {
        intervalsCopy.push(defaultEmptyDay);
      }
      setIntervals(intervalsCopy);
    };

    // Filter all completed time ranges
    const filterValidIntervals = (): TimeRange[] => intervals?.filter((int) => int?.since && int?.until);
    /**
     * If only first time range is completed and pressed, then reset its value and reset last range if it isn't completed.
     * else remove time range
     * @param i time range index
     * @returns void
     */
    const onRangeButton = (i: number): void => {
      if (filterValidIntervals().length < 2 && i === 0) {
        setIntervals([defaultDay, defaultEmptyDay]);
        return;
      }
      resetCurrentIndex();
      setIntervals((int) => {
        const copy = [...int];
        if (i === int.length - 1) {
          copy.splice(i, 1, defaultEmptyDay);
        } else {
          copy.splice(i, 1);
        }
        return copy;
      });
    };

    return (
      <>
        <div
          onClick={() => {
            if (inProgress) return;
            onClick();
          }}
          className={`relative tariff ${active ? 'tariff-active' : ''} ${inProgress ? 'shadow-thin' : ''}`}
        >
          <span
            className="mb-2 text-black"
            style={{ opacity, fontSize: 28, ...(isWeekendDay ? { color: '#ff6461' } : {}) }}
          >
            {day}
          </span>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setVisible(true);
            }}
            type="button"
            style={{ opacity, width: 100 }}
            className="bg-graylight rounded px-2 py-2 text-black"
          >
            {filterValidIntervals()?.length === 1 || (intervals?.length === 2 && visible) ? (
              <>
                <p className="one-line-text flex justify-center">
                  {t('since')}: &nbsp;<span className="font-semibold">{formatDaySchedule(intervals[0]?.since)}</span>
                </p>
                <p className="one-line-text flex justify-center">
                  {t('until')}: &nbsp;<span className="font-semibold">{formatDaySchedule(intervals[0]?.until)}</span>
                </p>
              </>
            ) : (
              <>
                {filterValidIntervals()?.length}
                <br />
                {t(
                  filterValidIntervals()?.length > 1 && filterValidIntervals()?.length < 5
                    ? 'intervals2_4'
                    : 'intervals',
                )}
              </>
            )}
          </button>
          <FASpinner
            containerClass="absolute flex top-0 left-0 right-0 bottom-0 justify-center items-center z-10"
            className="fa-2x"
            show={inProgress}
          />
        </div>
        <ModalBase disabledClose={rolesStore?.isAddingInProgress} title={dayLong} onClose={close} visible={visible}>
          {errors.submit && <p className="mb-5 error">{formatError(rolesStore?.addingError, t)}</p>}
          {!!intervals?.length &&
            intervals.map((range: TimeRange, ind) => (
              <TimeRangeComponent
                range={range}
                index={ind}
                value={value}
                onChange={onChangeRange}
                onRangeButton={onRangeButton}
              />
            ))}
          <div className="flex justify-end space-x-5 mt-5">
            <button type="button" onClick={close} className="btn btn-light">
              {t('cancel', { ns: 'actions' })}
            </button>
            <button
              disabled={isTimeRangesIncorrect() || rolesStore?.isAddingInProgress}
              type="button"
              onClick={saveTime}
              className="btn btn-blue"
            >
              <FASpinner containerClass="mr-2" show={rolesStore?.isAddingInProgress} />
              {t('save', { ns: 'actions' })}
            </button>
          </div>
        </ModalBase>
      </>
    );
  },
);

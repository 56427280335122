import { Pagination } from 'components/Pagination';
import Table from 'components/Table';
import { CURRENT_CURRENCY } from 'constants/currenciesSigns';
import { useWindowSize } from 'hooks/useWindowSize';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { formatPhone } from 'utils/lib/formatPhone';
import { getRandomId } from 'utils/lib/getRandomId';
import { getStatusClassAndTitle } from 'utils/lib/getStatusClassAndTitle';
import { upperCaseFirstChar } from 'utils/lib/upperCaseFirstChar';
import Filter from './filter';
import { formatDateValue } from 'utils/lib/formatDateValue';
import { Ride } from 'types/types';
import { formatAddress } from '../../utils/lib/formatAddress';

const fullTime = 'DD.MM.YY / HH:mm';

const Reports = observer((): JSX.Element => {
  const { ordersStore, usersStore, departmentsStore } = useStores();
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const [filter, setFilter] = useState<object>({});
  const { t } = useTranslation('reports');
  const windowSize = useWindowSize();
  const loadData = (): Promise<void> => {
    return ordersStore.fetchList(false, filter, 'id desc', ordersStore?.page, ordersStore?.limit, true, false);
  };

  useEffect(() => {
    loadData().then(() => setIsDataReady(true));
  }, []);

  useEffect(() => {
    loadData();
  }, [filter]);

  const renderDestination = (dest: Ride['destination']): React.ReactElement => (
    <div key={getRandomId()} className="flex items-start space-x-1.5">
      <svg className="w-5 h-5 flex-shrink-0" viewBox="0 0 20 20" fill="none" aria-hidden="true">
        <path
          d="M4.167 4.648s1.853-.824 3.5-.824 3.122 1.236 4.666 1.236c.644 0 1.36-.18 1.975-.388.713-.242 1.525.262 1.525 1.014v5.838a.93.93 0 0 1-.544.862c-.69.305-1.916.772-2.956.772-1.544 0-3.225-1.236-4.666-1.236-1.441 0-3.5.824-3.5.824V4.648Z"
          fill="#FDCD03"
        ></path>
        <rect x="4.167" y="3" width="1.167" height="14" rx=".583" fill="#000"></rect>
      </svg>
      <span>{dest?.address || ''}</span>
    </div>
  );

  const renderId = (record: Ride) => {
    let localTime;
    if (record?.local_datetime && moment(record?.local_datetime).isValid()) {
      localTime = moment.utc(record?.local_datetime).format('DD.MM.YY на HH:mm');
    } else if (record?.scheduled_time && moment(record?.scheduled_time).isValid()) {
      localTime = formatDateValue(record?.scheduled_time, {
        format: 'DD.MM.YY на HH:mm',
        offset: record?.city_timezone_offset,
      });
    } else {
      if (record?.ctime) {
        localTime = formatDateValue(record?.ctime, { format: fullTime, offset: record?.city_timezone_offset });
      }
    }
    return (
      <>
        <span className="block">{record?.id}</span>
        <span className="block text-gray">{localTime}</span>
        <span className="block text-gray">
          {upperCaseFirstChar(
            formatDateValue(record.ctime as number, { format: 'dddd', offset: record?.city_timezone_offset }),
          )}
        </span>
      </>
    );
  };

  const renderStatus = (record: Ride) => (
    <span className={`status ride-${getStatusClassAndTitle(record.status_id || '')[0]}`}>
      {t(`status.${record.status_id}`, { ns: 'order' })}
    </span>
  );

  const renderFullName = (record: Ride) => (
    <>
      <span className="block">
        {record?.employee?.is_guest
          ? `${record?.employee_name || ''} ${t('trow.guest')}`
          : record?.employee?.name || ''}
      </span>
      {record?.employee_id}
      <span className="text-gray block">{formatPhone(record?.employee?.phone || record?.phone || '')}</span>
    </>
  );
  const renderDepartmentId = (record: Ride) => (
    <>{departmentsStore.getDepartmentById(record?.employee?.department_id)}</>
  );
  const renderDepartmentCode = (record: Ride) => <>{record?.employee?.department_code}</>;

  const renderAdderss = (record: Ride) => (
    <div className="space-y-1">
      <div className="flex items-start space-x-1.5">
        <svg className="w-5 h-5 flex-shrink-0" viewBox="0 0 20 20" fill="none" aria-hidden="true">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M10 16.416a6.417 6.417 0 1 0 0-12.833 6.417 6.417 0 0 0 0 12.833ZM9.18 7.408l2.89 1.734a1 1 0 0 1 0 1.715l-2.89 1.734a1 1 0 0 1-1.514-.857V8.266a1 1 0 0 1 1.514-.858Z"
            fill="#FDCD03"
          ></path>
          <path
            d="M12.07 9.142 9.182 7.408a1 1 0 0 0-1.514.858v3.467a1 1 0 0 0 1.514.858l2.89-1.734a1 1 0 0 0 0-1.715Z"
            fill="#000"
          ></path>
        </svg>
        <span>{formatAddress(record?.source, t('trow.entrance'))}</span>
      </div>
      {record?.interim_destinations?.map(renderDestination)}
      {renderDestination(record?.destination)}
    </div>
  );

  const renderDetails = (record: Ride) => (
    <div className="space-y-1">
      {record?.personal_coordinator?.coordinator?.name ? (
        <div>
          {t('trow.personal_coordinator')}: <br />
          <span className="text-gray">{record?.personal_coordinator?.coordinator?.name}</span>
        </div>
      ) : null}
      {!!record?.cuser?.name ||
        (record.platform === 'support' && (
          <div>
            {t('trow.orderer')}: <br />{' '}
            <span className="text-gray">{record.platform === 'support' ? t('trow.support') : record.cuser?.name}</span>
          </div>
        ))}
      {!!record?.ctime && (
        <div>
          {t('trow.order_time')}: <br />
          <span className="text-gray">
            {formatDateValue(record.ctime, { format: fullTime, offset: record?.city_timezone_offset })}
          </span>
        </div>
      )}
      {record?.cancelled_time ? (
        <div>
          {t('trow.cancelled_time')}:{' '}
          <span className="text-gray">
            {formatDateValue(record.cancelled_time, { format: fullTime, offset: record?.city_timezone_offset })}
          </span>
        </div>
      ) : null}
      {record.start_transporting_time && record.finished_time ? (
        <div className="one-line-text">
          {t('trow.ride_time')}: <br />
          <span className="text-gray">
            {formatDateValue(record.start_transporting_time, {
              format: 'HH:mm',
              offset: record?.city_timezone_offset,
            })}
            -
            {formatDateValue(record.finished_time, {
              format: 'HH:mm',
              offset: record?.city_timezone_offset,
            })}
          </span>
        </div>
      ) : null}
      {record?.paid_waiting_time_s ? (
        <div>
          {t('trow.paid_waiting')}: <br />
          <span className="text-gray">
            {Math.ceil(Number(record.paid_waiting_time_s) / 60)} {t('trow.minutes_short')}
          </span>
        </div>
      ) : null}
      {record.transporting_time_s ? (
        <div>
          {t('trow.full_time')}: <span className="text-gray">{Math.ceil(record.transporting_time_s / 60)} м.</span>
        </div>
      ) : null}
      {record.distance ? (
        <div>
          {t('trow.distance')}:{' '}
          <span className="text-gray">
            {(Math.round(record.distance / 100) / 10).toFixed(1)} {t('trow.km_short')}
          </span>
        </div>
      ) : null}
      {record.cost_customer_with_vat ? (
        <div>
          {t('trow.cost')}: <br />
          <span className="text-gray">{`${(Number(record?.cost_customer_with_vat) || 0).toFixed(2)} ${CURRENT_CURRENCY}`}</span>
        </div>
      ) : null}
      {record.feedback?.star && (
        <div>
          {t('trow.rating')}: <br />
          <span className="text-gray">{record.feedback?.star}</span>
        </div>
      )}
      {(record.feedback?.improvements?.length || 0) > 0 && (
        <div>
          {t('trow.wishes')}: <br />
          <span className="text-gray">{record.feedback?.improvements?.join(', ')}</span>
        </div>
      )}
      {record.feedback?.status === 'cancelled' && record.feedback?.text && (
        <div>
          {t('trow.cancel_reason')}: <br />
          <span className="text-gray">{record.feedback?.text}</span>
        </div>
      )}
    </div>
  );

  const getTableHeight = (): number | string => {
    if (!windowSize?.height) return 'auto';
    return windowSize.height - 178 - ((windowSize.width || 0) >= 992 ? 0 : 24);
  };

  /*

  curl 'https://swiftdrive.ru/api/v1/orders/export' \
-H 'Connection: keep-alive' \
-H 'Pragma: no-cache' \
-H 'Cache-Control: no-cache' \
-H 'sec-ch-ua: " Not A;Brand";v="99", "Chromium";v="90", "Google Chrome";v="90"' \
-H 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoiYWNjZXNzVG9rZW4iLCJyb2xlIjp7ImlkIjozLCJuYW1lIjoiY3VzdG9tZXIiLCJtb2RlbCI6IkN1c3RvbWVyc01vZGVsIiwiaG9tZV9wYWdlIjoiL2FwcC9hbmFseXRpY3MiLCJwcm9maWxlX3BhZ2UiOm51bGwsImN0aW1lIjpudWxsLCJtdGltZSI6bnVsbCwiYXRpbWUiOm51bGx9LCJvd25lcklkIjoyMywiZXhwaXJlZEF0IjoxOTU5MDQ0Mzc5LCJpc3N1ZWRBdCI6MTY0MzY4NDM3OSwiaXNzdWVyIjoiYXBpIiwianRpIjoiZDI0OTIxMmRlZTRjNiIsImlhdCI6MTY0MzY4NDM3OSwiZXhwIjoxOTU5MDQ0Mzc5fQ.L8PxY5Eyn8mMQATQBHLxd2Mv83cEhmz-xLCppdzss2k' \
-H 'Web-Socket-Id: 40fde0c2-a735-4794-8176-5091dbd3eb08' \
-H 'csrfToken: d249212dee4c6' \
-H 'sec-ch-ua-mobile: ?0' \
-H 'User-Agent: Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/90.0.4430.93 Safari/537.36' \
-H 'Content-Type: application/json;charset=UTF-8' \

-H 'Origin: https://swiftdrive.ru' \
    -H 'Sec-Fetch-Site: same-origin' \
-H 'Sec-Fetch-Mode: cors' \
-H 'Sec-Fetch-Dest: empty' \
-H 'Referer: https://swiftdrive.ru/app/reports/' \
-H 'Accept-Language: ru-RU,ru;q=0.9,en-US;q=0.8,en;q=0.7,ms;q=0.6' \
-H 'Cookie: _ym_uid=1613445631579880513; _ym_d=1613445631; _ga=GA1.2.1765727227.1613445631; _fbp=fb.1.1613445631639.816592993; USER_AUTHORIZED=1' \
--data-raw '{"filter":{},"orderBy":"ctime desc","fields":["id","status","customer","employee_name","employee_phone","class","source_address","destination_address","week_day","creation_date","creation_time","scheduled_date","scheduled_time","start_waiting_date","start_waiting_time","start_date","start_time","finish_date","finish_time","paid_waiting_time_s","driver_name","driver_phone","driver_car","driver_car_number","distance","full_time","cost_customer","cost_customer_with_vat"],"fileType":"xlsx"}' \
--compressed
  */

  return (
    <main className="flex-grow flex flex-col">
      <section className="pt-6 lg:pt-7.5 flex-grow flex flex-col">
        <div className="container flex-grow flex flex-col">
          <div className="mb-6 lg:hidden">
            <h2 className="text-2lg leading-6 font-medium text-black">{t('title')}</h2>
          </div>
          <div className="mb-6 lg:mb-5 flex table-bar">
            {/*
                        <SearchBar store={ordersStore} containerClass='md:w-[370px]' placeholder='Поиск по номеру заказа или имени пассажира' />
                        */}
            <Filter />
          </div>
          {ordersStore.isFetchingNewPage || !isDataReady ? (
            <span>{t('loading')}</span>
          ) : (
            <div className="area mb-5 flex-grow overflow-hidden">
              <div className="flex" style={{ maxHeight: getTableHeight() }}>
                <Table
                  stickyHeader
                  tableClass="w-[86.25rem]"
                  columnsKeys={[
                    'id',
                    'status',
                    'full_name',
                    'address',
                    'class',
                    'comment',
                    usersStore?.isDepartmentsV2 ? 'department_id' : 'department_code',
                    'details',
                  ]}
                  data={ordersStore?.list}
                  translateFunc={(key: string) => t(`thead.${key}`)}
                  colClasses={[
                    'w-[8.75rem]',
                    'w-[6.25rem]',
                    'w-[9.75rem]',
                    'w-[18rem]',
                    'w-[6rem]',
                    'w-[9.75rem]',
                    'w-[11.5rem]',
                    'w-48',
                  ]}
                  keySelectors={{ class: 'class.title' }}
                  customTdInnerRenderer={{
                    id: renderId,
                    status: renderStatus,
                    full_name: renderFullName,
                    address: renderAdderss,
                    ...(usersStore?.isDepartmentsV2
                      ? {
                          department_id: renderDepartmentId,
                        }
                      : {
                          department_code: renderDepartmentCode,
                        }),
                    details: renderDetails,
                  }}
                >
                  <div className="area-inner">
                    <Pagination store={ordersStore} />
                  </div>
                </Table>
              </div>
            </div>
          )}
        </div>
      </section>
    </main>
  );
});

export default Reports;

export default {
  title: 'Promocodes',
  loading: 'Loading data',
  error: 'Error',
  action: {
    add: 'Add promocode',
    edit: 'Editing',
    import: 'Import promocodes',
    export: 'Export',
    exporting: 'Exporting',
    export_orders: 'Export orders',
    activate: 'Activate promocode',
    disable: 'Deactivate',
    enable: 'Enable',
  },
  import: {
    imported_number: 'Records imported',
    failed_number: 'Records skipped',
    import_process: 'Import',
    from: 'from',
    close: 'Close',
    dropzone_text: 'Click or drag a file into this area',
    import_example: 'Import file example',
    import_file_name: 'import',
    errors: 'Import errors',
    line: 'Line',
    end: 'The import has completed',
    success: 'Successfully imported',
    lines: 'lines',
    lines_s1: 'line',
    lines_s2: 'lines',
  },
  thead: {
    code: 'Code',
    activated_time: 'Activated time',
    total_cost: 'Total cost',
    prefix: 'Prefix',
    max_order_cost: 'Max order cost',
    orders_count: 'Orders count',
    max_employee_count: 'Max employee count',
    discount_percent: 'Discount percent',
    used_time: 'Used time',
    used_orders_count: 'Used orders count',
    activated_by_employee_id: 'Activated by employee',
    role: 'Orders policy',
    active_from: 'Active from',
    active_to: 'Active to',
    classes: 'Classes',
  },
  trow: {
    activated: 'Activated',
    deactivated: 'Deactivated',
    employee: 'Employee',
    paid_waiting: 'Paid waiting',
    ride: 'Ride',
    full_time: 'Full time',
    minutes_short: 'm',
    arrival_time: 'Arrival time',
    guest: '(guest)',
  },
  dialog: {
    code: 'Code',
    activated_time: 'Activated time',
    total_cost: 'Total cost',
    prefix: 'Prefix',
    max_order_cost: 'Max order cost',
    orders_count: 'Orders count',
    max_employee_count: 'Max employee count',
    discount_percent: 'Discount percent',
    description: 'Description',
    used_time: 'Used time',
    used_orders_count: 'Used orders count',
    activated_by_employee_id: 'Activated by employee',
    active_from: 'Active from',
    active_to: 'Active to',
    type: 'Type',
    discount: 'discount',
    amount: 'amount',
    add: 'Add',
    save: 'Save',
    cancel: 'Cancel',
    classes: 'Classes',
    classes_all: 'Choose all',
    add_class: 'Add class',
    promocode: 'Promo-code',
    activate: 'Activate',
    payment_method: 'Payment method',
  },
  details: {
    id: 'ID',
    manager: 'Manager',
    status: 'Status',
    start_transporting_time: 'Start time',
    finished_time: 'Finished time',
    cancelled_time: 'Cancellation time',
    order_time: 'Order time',
    time: 'Time',
    source: 'Source',
    interim_destination: 'Stop',
    destination: 'Destination',
    employee: 'Employee',
    phone: 'Phone',
    class: 'Class',
    car: 'Car',
    cost_customer: 'Cost without VAT, rub.',
    cost_customer_with_vat: 'Cost with VAT, rub.',
    customer_name: 'Customer name',
    provider: 'Provider',
    provider_order_id: 'Provider order id',
    comment: 'Your wishes for the driver',
    cancelled_by: 'Who canceled the order',
    orderer: 'Who placed the order',
    additional_fields: 'Additional fields',
  },
  search_placeholder: 'Search',
  filter: {
    title: 'Filters',
    status: 'Status',
    full_name: 'Full name',
    code: 'Code',
    activated_by_employee_id: 'Activated by',
    actions: {
      apply: 'Apply',
    },
  },
};

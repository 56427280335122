import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { Dialog } from './dialog';
import { SearchBar } from 'components/SearchBar';
import { Pagination } from 'components/Pagination';
import ImportDialog from 'components/ImportData/ImportDialog';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import EditDelete from 'components/EditDelete';
import FASpinner from 'components/FASpinner';
import { CONTROLLER } from 'constants/common';
import { formatPhone } from 'utils/lib/formatPhone';
import { notify } from 'utils/lib/notify';
import { formatError } from 'utils/lib/formatError';
import ConfirmModal from 'components/Modal/confirmModal';
import { useWindowSize } from 'hooks/useWindowSize';
import { getRemainder } from 'utils/lib/limit/getRemainder';
import { getLimit } from 'utils/lib/limit/getLimit';
import { ExportButton } from 'components/Buttons/ExportButton';
import { ReactComponent as UserActivity } from '../../assets/svg/user-activity.svg';
import EmployeesStore from 'stores/EmployeesStore';
import { useHistory } from 'react-router-dom';
import Filter from './filter/index';

const employeesv1 = require('../../assets/import-examples/employees-v1.xlsx');
const employeesv2 = require('../../assets/import-examples/employees-v2.xlsx');

interface UserDialog {
  user: any;
  isOpen: boolean;
}

const exportFields = (a: string[] = []) => [
  'id',
  'name',
  'phone',
  'email',
  'code',
  ...a,
  'limit_month_amount',
  'limit_month_amount_used',
  'limit_group_month_amount',
  'limit_group_month_amount_used',
  'role',
  'can_order',
  'can_order_others',
  'active',
  'is_welcome_sms_sent',
  'coordinator',
];

export const Employees: React.FC<{}> = observer(() => {
  const stores = useStores();
  const history = useHistory();
  const { employeesStore }: { employeesStore: EmployeesStore } = stores as any;
  const { usersStore, departmentsStore } = stores as any;
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const [isShowDialog, setShowDialog] = useState<boolean>(false);
  const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
  const { t } = useTranslation(['employees', 'confirmation']);
  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [userDialog, setUserDialog] = useState<UserDialog>({
    user: null,
    isOpen: false,
  });
  const [deletingUser, setDeletingUser] = useState<any>();
  const windowSize = useWindowSize();
  const searchText = useRef<string>('');

  const onSearchChange = (text: string): void => {
    searchText.current = text;
  };

  const onEdit = (user: any): void => {
    setUserDialog({
      user,
      isOpen: true,
    });
  };
  const onDelete = (user: any): void => {
    setDeletingUser(user);
    setVisibleConfirm(true);
  };

  const onResendSMS = async (user: any): Promise<void> => {
    await employeesStore.resendSMS(user.id);
    if (employeesStore.resendSMSError) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedUserResendSMS', { ns: 'errors' })} \"${user?.name || ''}\": ${formatError(employeesStore.resendSMSError, t)}`,
        type: 'danger',
      });
    }
  };

  const closeConfirm = (): void => {
    setVisibleConfirm(false);
    setDeletingUser(null);
  };

  const onEmployeeDelete = async (e: any): Promise<void> => {
    if (!deletingUser) return;
    setIsDeleting(true);
    closeConfirm();
    e.stopPropagation();
    await deletingUser.delete();
    if (employeesStore.deletingError) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedUserDeletion', { ns: 'errors' })} \"${deletingUser?.name || ''}\": ${formatError(employeesStore.deletingError, t)}`,
        type: 'danger',
      });
    }
    setIsDeleting(false);
  };

  useEffect(() => {
    employeesStore
      .fetchList(false, {}, 'id desc', 0, employeesStore?.limit || 10, true, false)
      .then(() => setIsDataReady(true));
  }, []);

  const closeDialog = (): void => {
    setUserDialog({
      user: null,
      isOpen: false,
    });
  };

  useEffect(() => {
    document.body.style.overflow = showImportDialog || isShowDialog ? 'hidden' : 'unset';
  }, [showImportDialog, isShowDialog]);

  const renderOrderRights = (record: any): JSX.Element => (
    <>
      {!!record.can_order && (
        <>
          {t('trow.can_order')}
          <br />
        </>
      )}
      {!!record.can_order_others && <>{t('trow.can_order_others')}</>}
    </>
  );

  const renderNameEndStatus = (record: any): JSX.Element => (
    <>
      {record.name}
      <UserActivity className={`inline status-icon-${record?.active ? 'active' : 'inactive'}`} />
    </>
  );

  const renderController = (record: any): JSX.Element =>
    record?.is_guest ||
    (usersStore?.me?.role?.name === 'coordinator' && !usersStore?.me?.detail?.permissions?.create_employees) ? (
      <div></div>
    ) : (
      <div className="text-right">
        {record?.id === deletingUser?.id && isDeleting ? (
          <FASpinner show />
        ) : (
          <EditDelete
            onEdit={() => onEdit(record)}
            onDelete={() => onDelete(record)}
            closeOnBackdropPress
            appendChildren
          >
            <button className="edit-delete-item" onClick={() => onResendSMS(record)} type="button">
              {t('action.resend_sms')}
            </button>
            <button
              className="edit-delete-item"
              onClick={() => {
                history.push({
                  pathname: '/customer/rides',
                  search: `?cuser_id=${record.user_id}&employee_id=${record.id}`,
                });
              }}
              type="button"
            >
              {t('action.rides')}
            </button>
          </EditDelete>
        )}
      </div>
    );

  const renderLimit = (record: any): JSX.Element => (
    <>
      {getLimit(record)}
      <br />
      {record?.limit_month_amount ? `(${t('trow.limit_personal')})` : ''}
      {!record?.limit_month_amount && record?.limit_group_month_amount && `(${t('trow.limit_group')})`}
      {getRemainder(record) && (
        <>
          <br />
          <br />
          {t('thead.remainder')}: {getRemainder(record)}
        </>
      )}
    </>
  );

  const onImport = async () => {
    await departmentsStore.fetchWholeList();
  };

  const renderDepartment = (record: any): JSX.Element => <>{record.department?.name || record.department_id}</>;
  const renderBranch = (record: any): JSX.Element => (
    <>{departmentsStore.getParentDepartments(record.department?.id || record.department_id)}</>
  );
  const renderDepartmentCode = (record: any): JSX.Element => <>{record?.department_code}</>;
  const renderDepartmentName = (record: any): JSX.Element => <>{record?.department_name}</>;

  const getTableHeight = (): number | string => {
    if (!windowSize?.height) return 'auto';
    return windowSize.height - 178 - ((windowSize.width || 0) >= 992 ? 0 : 24);
  };

  const fields = exportFields(['department_code', 'department_name']);
  return (
    <main>
      <section className="pt-6 lg:pt-7.5">
        <div className="container">
          <div className="mb-6 lg:hidden">
            <h2 className="text-2lg font-medium text-black">{t('title')}</h2>
          </div>
          <div className="mb-6 lg:mb-5 flex table-bar">
            <SearchBar
              onTextChange={onSearchChange}
              containerClass="w-44 md:w-80"
              store={employeesStore}
              placeholder={t('search_placeholder')}
            />
            <Filter />
            {usersStore?.isCustomer || usersStore?.me?.detail?.permissions?.create_employees ? (
              <div className="flex space-x-4 lg:space-x-5 ml-4 md:ml-auto">
                <button
                  className="btn btn-outline-blue px-1.5 w-10 flex items-center lg:px-4 lg:w-auto"
                  type="button"
                  onClick={() => setShowImportDialog(true)}
                >
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M17 17.25a.75.75 0 0 1-.75.75H3.75a.75.75 0 1 1 0-1.5h12.5a.75.75 0 0 1 .75.75ZM10 2a.75.75 0 0 1 .75.75v10.19l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.996 3.997a.747.747 0 0 1-1.068 0L5.47 11.28a.75.75 0 1 1 1.06-1.06l2.72 2.72V2.75A.75.75 0 0 1 10 2Z"></path>
                  </svg>
                  <span className="hidden lg:block lg:ml-1.5">{t('action.import')}</span>
                </button>
                <ExportButton
                  className="flex items-center lg:px-4 lg:w-auto"
                  fields={fields}
                  filter={{ $nodx_search: searchText.current }}
                  store={employeesStore}
                />
                <div>
                  <button
                    className="btn btn-blue px-1.5 w-10 flex items-center md:px-4 md:w-auto"
                    type="button"
                    onClick={() => {
                      setUserDialog({ user: null, isOpen: true });
                    }}
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path d="M10 2A7.953 7.953 0 0 0 4.34 4.342c-3.12 3.12-3.122 8.195-.001 11.316A7.951 7.951 0 0 0 9.998 18c2.14 0 4.15-.832 5.661-2.344 3.121-3.118 3.121-8.193 0-11.314A7.956 7.956 0 0 0 10 2Zm0 1.359c1.776 0 3.445.69 4.698 1.944a6.648 6.648 0 0 1 0 9.393 6.605 6.605 0 0 1-4.7 1.945 6.6 6.6 0 0 1-4.697-1.944 6.649 6.649 0 0 1 .001-9.394A6.604 6.604 0 0 1 10 3.359Zm0 3.287a.68.68 0 0 0-.68.68V9.32H7.324a.68.68 0 1 0 0 1.358H9.32v1.996a.68.68 0 1 0 1.36 0v-1.996h1.996a.68.68 0 1 0 0-1.358H10.68V7.325a.68.68 0 0 0-.68-.68Z"></path>
                    </svg>
                    <span className="hidden md:block md:ml-1.5">{t('action.add')}</span>
                  </button>
                  <Dialog
                    user={userDialog.user}
                    visible={userDialog.isOpen}
                    onCancel={closeDialog}
                    onClose={closeDialog}
                  />
                </div>
              </div>
            ) : null}
          </div>
          {employeesStore.isFetchingListInProgress || !isDataReady ? (
            <span>{t('loading')}</span>
          ) : (
            <div className="area overflow-hidden">
              <div className="overflow-x-auto flex" style={{ maxHeight: getTableHeight() }}>
                <Table
                  stickyHeader
                  tableClass="table w-max-content"
                  keySelectors={{
                    full_name: 'name',
                    role: 'role.name',
                  }}
                  colClasses={[
                    'w-40 xl:w-[13.75rem]',
                    'w-[10.75rem]',
                    'w-[8.75rem]',
                    'w-[7rem]',
                    'w-[8.75rem]',
                    'w-[8.75rem]',
                    'w-[8.75rem]',
                    'w-[8.75rem]',
                    'w-[6.25rem]',
                    'w-[9.75rem]',
                  ]}
                  columnsKeys={[
                    'full_name',
                    'phone',
                    'code',
                    ...(usersStore?.isDepartmentsV2
                      ? ['branch', 'department_id']
                      : ['department_code', 'department_name']),
                    'role',
                    'limit',
                    'order_right',
                    CONTROLLER,
                  ]}
                  data={employeesStore?.list || []}
                  translateFunc={(key: string) => (key && key !== CONTROLLER ? t(`thead.${key}`) : '')}
                  customTdInnerRenderer={{
                    phone: (record: any) => <>{formatPhone(record?.phone ? record.phone.replace(/\D/g, '') : '')}</>,
                    order_right: renderOrderRights,
                    full_name: renderNameEndStatus,
                    limit: renderLimit,
                    ...(usersStore?.isDepartmentsV2
                      ? {
                          branch: renderBranch,
                          department_id: renderDepartment,
                        }
                      : {
                          department_code: renderDepartmentCode,
                          department_name: renderDepartmentName,
                        }),

                    [CONTROLLER]: renderController,
                  }}
                >
                  <div className="area-inner">
                    <Pagination store={employeesStore} />
                  </div>
                </Table>
              </div>
            </div>
          )}
        </div>
        <ImportDialog
          onClose={() => setShowImportDialog(false)}
          store={employeesStore}
          visible={showImportDialog}
          example={usersStore?.isDepartmentsV2 ? employeesv2 : employeesv1}
          onImport={onImport}
          translation="employees"
        />
        <ConfirmModal
          title={t('deletion_title', { ns: 'confirmation' })}
          text={t('deletion_confirmation', { ns: 'confirmation' })}
          visible={visibleConfirm}
          onCancel={closeConfirm}
          onClose={closeConfirm}
          onConfirm={onEmployeeDelete}
        />
      </section>
    </main>
  );
});

export default Employees;

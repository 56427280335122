import { runInAction } from 'mobx';
import BaseStore from '../BaseStore';
import GeocodeAPI, { GeocodeParams } from './api';
import { RootStoreInterface } from '../../interfaces';
import { TGeoLocation } from '../GPSLocationStore';

class GeocodeStore extends BaseStore {
  api: GeocodeAPI;

  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Geocode']);
    this.api = new GeocodeAPI(rootStore.specifications['Geocode'].url);
    this.setApiClient(this.api);
  }

  hasActiveCall: boolean = false;
  async geocode(params: GeocodeParams) {
    if (this.hasActiveCall) return;
    try {
      runInAction(() => {
        this.hasActiveCall = true;
      });
      const place = await this.api.geocode(params);
      const [entrance] = place?.detail?.filter((d) => d.kind === 'entrance') || [];
      if (entrance?.name && place?.address) {
        place.entrance = (entrance.name || '').replace('подъезд', '').trim();
        place.address = place.address?.substring(0, place.address.lastIndexOf(','));
      } else {
        place.entrance = '';
      }
      runInAction(() => {
        this.hasActiveCall = false;
      });
      return place;
    } catch (e) {
      console.error(e);
    }
    runInAction(() => {
      this.hasActiveCall = false;
    });
  }

  // async geocodeReverse(location: { lat: number; lng: number }) {
  //   if (this.hasActiveCall) return;
  //   try {
  //     runInAction(() => {
  //       this.hasActiveCall = true;
  //     });
  //     const data = await this.api.geocode(location);
  //     runInAction(() => {
  //       this.hasActiveCall = false;
  //     });
  //     return data;
  //   } catch (e) {
  //     console.error(e);
  //   }
  //   runInAction(() => {
  //     this.hasActiveCall = false;
  //   });
  // }

  async suggest(part: string, employeeId: number | string, location: TGeoLocation) {
    try {
      const data = await this.api.suggest(part, employeeId, location);
      return data;
    } catch (e) {
      console.error(e);
    }
  }
}

export default GeocodeStore;

import EditDelete from 'components/EditDelete';
import FASpinner from 'components/FASpinner';
import ConfirmModal from 'components/Modal/confirmModal';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';
import { notify } from 'utils/lib/notify';

interface GroupItemProps {
  group: any;
  onGroupPress?: any;
  onEdit: any;
  onDelete: any;
  bottomDivider?: boolean;
  isActive?: boolean;
}

const GroupItem = observer(
  ({ group, onEdit, onDelete, bottomDivider = true, isActive = false }: GroupItemProps): JSX.Element => {
    const { t } = useNestedTranslation(['policy.list.group', 'confirmation', 'errors']);
    const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
    const { rolesStore } = useStores();
    const [isDeleting, setIsDeleting] = useState<boolean>(false);
    const editGroup = (): void => {
      onEdit(group);
    };
    const onDeleteGroup = (): void => {
      setVisibleConfirm(true);
    };
    const getUsersTitle = (num: number): string => {
      if (num === 1) return t('user');
      if (num >= 2 && num <= 4) return t('users_2_4');
      return t('users');
    };

    const closeConfirm = (): void => {
      setVisibleConfirm(false);
    };

    const onConfirmDelete = async (e: any): Promise<void> => {
      setIsDeleting(true);
      e.stopPropagation();
      setVisibleConfirm(false);
      await rolesStore.deleteRecord(group.id);
      if (rolesStore.deletingError) {
        notify({
          title: t('error', { ns: 'errors' }) + '!',
          message: `${t('failedGroupDeletion', { ns: 'errors' })} \"${group?.name || ''}\": ${formatError(rolesStore.deletingError, t)}`,
          type: 'danger',
        });
        setIsDeleting(false);
        return;
      }
      if (onDelete) onDelete(group);
      setIsDeleting(false);
    };

    return (
      <li className={`cursor-pointer px-4 ${isActive || visibleConfirm ? 'active-group' : ''}`} onClick={editGroup}>
        <div
          className={`flex items-center ${bottomDivider && !visibleConfirm ? 'border-b border-b-graystroke' : ''} py-4 md:py-5`}
        >
          <div className="mr-2 md:mr-5">
            <div className="mb-1 text-black">{group.name}</div>
            <div className="flex items-center space-x-1.5">
              <svg className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path d="M10 2C5.609 2 2.036 5.573 2 9.947a7.883 7.883 0 0 0 2.311 5.67c1.494 1.53 3.502 2.365 5.636 2.383H10c4.391 0 7.964-3.573 8-7.947.035-4.409-3.538-8.017-8-8.053Zm-.036 14.916A6.867 6.867 0 0 1 5.45 15.19v-.107c0-.835.249-1.617.711-2.31a4.155 4.155 0 0 1 1.653-1.387 3.37 3.37 0 0 0 2.187.8c.835 0 1.6-.303 2.187-.8a4.155 4.155 0 0 1 1.653 1.386c.462.676.711 1.476.711 2.311v.09a6.956 6.956 0 0 1-4.569 1.724h-.018v.018ZM10 11.19a2.375 2.375 0 0 1-2.364-2.364c0-1.298 1.066-2.383 2.364-2.383a2.375 2.375 0 0 1 2.364 2.365c0 1.298-1.066 2.382-2.364 2.382Zm5.51 2.987a5.066 5.066 0 0 0-.817-1.974 4.913 4.913 0 0 0-1.813-1.6c.32-.515.515-1.137.515-1.777A3.407 3.407 0 0 0 10 5.43a3.407 3.407 0 0 0-3.395 3.396c0 .657.177 1.262.515 1.777a5.208 5.208 0 0 0-1.813 1.6 5.067 5.067 0 0 0-.818 1.974 6.808 6.808 0 0 1-1.404-4.231c.017-3.787 3.128-6.88 6.968-6.88 3.822.017 6.898 3.146 6.88 6.969a6.977 6.977 0 0 1-1.422 4.142Z"></path>
              </svg>
              <span className="whitespace-nowrap">
                {group.employees || '0'} {getUsersTitle(group.employees)}
              </span>
            </div>
          </div>
          <div
            className="ml-auto flex items-center space-x-3 md:space-x-4 lg:space-x-5"
            onClick={(e) => e.stopPropagation()}
          >
            {!!group.default && <span className="status status-default">{t('default')}</span>}
            {isDeleting ? <FASpinner show /> : <EditDelete onEdit={editGroup} onDelete={onDeleteGroup} />}
          </div>
        </div>
        <ConfirmModal
          title={t('deletion_title', { ns: 'confirmation' })}
          text={t('deletion_confirmation', { ns: 'confirmation' })}
          visible={visibleConfirm}
          onCancel={closeConfirm}
          onClose={closeConfirm}
          onConfirm={onConfirmDelete}
        />
      </li>
    );
  },
);

export default GroupItem;

import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Option, StringTypeIdState } from '../../../types/types';
import { useStores } from 'stores';
import MaskedInput from '../../../components/Inputs/MaskedInput';
import ModalSelect from 'components/ModalSelect';
import { useTranslation } from 'react-i18next';
import { parse } from 'date-fns';
import { getRegExpData } from 'utils/lib/regExp/stringCostCenter';
import { DateTimeField } from '../../../components/Inputs/DateTimeField';

interface CostCentersProps {
  costCenters: any[];
  wrapperClass?: string;
  errors?: any;
  onFocus?: any;
  onBlur?: any;
  containerClass?: string;
}

const CostCenters = observer(
  ({
    costCenters = [],
    wrapperClass = '',
    errors = {},
    onFocus = () => {},
    onBlur = () => {},
    containerClass = '',
  }: CostCentersProps) => {
    const { ordersStore } = useStores();
    const [dateVisible, setDateVisible] = useState<boolean>(false);
    const { t } = useTranslation(['validation', 'new_order']);
    if (!costCenters) return null;

    const getList = (list: any[]): Option[] => {
      if (!list || !list.length) return [];
      return [
        ...list.map((title, key) => {
          return { id: `${title}-${key}`, label: title, value: title };
        }),
      ];
    };

    const setCostCenterValue = (item: any, value: any, regExp?: string): void => {
      let newValue = value;
      if (regExp) {
        const {
          stringHasLimit,
          stringLimitMax = 0,
          regexpWithoutLimit,
        }: StringTypeIdState = getRegExpData({ regexp: regExp });
        if (
          (stringHasLimit && (value?.length || 0) > stringLimitMax) ||
          (regExp && !newValue.match(new RegExp(regexpWithoutLimit || regExp, 'giu')))
        ) {
          return;
        }
      }
      ordersStore.updateNewOrderInfo({
        cost_centers: {
          ...ordersStore.newOrderInfo.cost_centers,
          [item.name]: newValue,
        },
      });
    };

    // const getDate = (date: string): string => date ? moment(date).format('DD.MM.YYYY') : '';

    const closeCalendar = (): void => {
      setDateVisible(false);
    };

    const onDateConfirm = (value: Date, name: string): void => {
      setCostCenterValue({ name }, value ? value.getTime() / 1000 : null);
      closeCalendar();
    };

    const getRuleByRegExp = (item): string => {
      const opt = { ns: 'new_order' };
      let msg = t('field_can_contain', opt);
      const {
        stringHasLimit,
        stringLimitMax,
        stringLimitMin = 0,
        digits,
        letters,
        symbols,
      }: StringTypeIdState = getRegExpData({ regexp: item.regexp });
      if (digits && letters && symbols) {
        msg += t('any_chars', opt);
      } else {
        msg += t('only', opt);
        msg += letters ? t('letters', opt) : '';
        msg += digits ? `${letters ? t('and', opt) : ''}${t('digits', opt)}` : '';
        msg += symbols ? `${digits || letters ? t('and', opt) : ''}${t('symbols', opt)}` : '';
      }
      msg = stringHasLimit
        ? `${msg} (${stringLimitMin === stringLimitMax ? `${stringLimitMin} ${t('symbols_count', opt)}` : `${t('min', opt)} ${stringLimitMin} ${t('max', opt)} ${stringLimitMax}`})`
        : msg;
      return msg.trim();
    };

    const renderCustomField = (item: any, form: any) => {
      const list = getList(item.list);
      return (
        <div className={wrapperClass}>
          <div className={containerClass}>
            {item.type_id === 'dropdown' && (
              <div>
                <span className="form-label">{`${item.name}${item.required ? ' *' : ''}`}</span>
                <ModalSelect
                  onChange={(value: Option) => {
                    setCostCenterValue(item, value ? value.value : null);
                  }}
                  options={list}
                  filterable
                  cancelable={!item.required}
                  onBlur={onBlur}
                  onOpen={item.required ? () => onFocus(item.name) : () => {}}
                  toggleWrapperClass={`h-12 dark-form-control ${errors[item.name] ? 'validate-error' : ''}`}
                  value={form && form[item.name]}
                  label={`${item.name}${item.required ? ' *' : ''}`}
                  placeholder={item.required ? '' : `(${t('not_required')})`}
                />
              </div>
            )}
            {!!(item.type_id === 'string' && item.mask) && (
              <div>
                <span className="form-label">{`${item.name}${item.required ? ' *' : ''}`}</span>
                <MaskedInput
                  onChange={({ target }) => setCostCenterValue(item, target.value, item.regexp)}
                  value={form[item.name]}
                  onFocus={item.required ? () => onFocus(item.name) : () => {}}
                  onBlur={onBlur}
                  mask={item.mask}
                  placeholder={item.required ? '' : `(${t('not_required')})`}
                  className={`form-control form-control--lg ${errors[item.name] ? 'validate-error' : ''}`}
                />
              </div>
            )}
            {!!(item.type_id === 'string' && !item.mask) && (
              <div>
                <span className="form-label">{`${item.name}${item.required ? ' *' : ''}`}</span>
                <input
                  className={`form-control form-control--lg ${errors[item.name] ? 'validate-error' : ''}`}
                  onChange={({ target }) => setCostCenterValue(item, target.value, item.regexp)}
                  value={form[item.name] || ''}
                  onFocus={() => onFocus(item.name)}
                  onBlur={onBlur}
                  placeholder={item.required ? '' : `(${t('not_required')})`}
                />
              </div>
            )}
            {item.type_id === 'date' && (
              <DateTimeField
                fieldProps={{
                  title: item.name,
                  isRequired: item.required,
                }}
                onChange={(date) => onDateConfirm(date, item.name)}
                dateFormat={'DD.MM.yyyy'}
                selected={
                  form[item.name] &&
                  new Date(
                    typeof form[item.name] === 'number'
                      ? form[item.name] * 1000
                      : parse(form[item.name], 'dd.MM.yyyy', new Date()).getTime(),
                  )
                }
                placeholderText={t('scheduled_order_placeholder', { ns: 'new_order' })}
              />
            )}
            {errors[item.name] && !item.regexp && <span className="validate-error-message">{errors[item.name]}</span>}
            {!!item.regexp && (
              <span className={`${errors[item.name] ? 'validate-error-message' : ''} inline-block`}>
                {!form[item.name] && errors[item.name] ? errors[item.name] : getRuleByRegExp(item)}
              </span>
            )}
          </div>
        </div>
      );
    };

    return <>{costCenters.map((item) => renderCustomField(item, ordersStore.newOrderInfo.cost_centers || {}))}</>;
  },
);

export default CostCenters;

import BaseCRUD from '../BaseCRUD';
import WebApiClient from '../WebApi';
import { TGeoLocation } from '../GPSLocationStore';
import { objectToNonNullableURLParamsString } from 'utils/lib/query/objectToNonNullableURLParamsString';

export interface GeocodeParams {
  uri?: string;
  address?: string;
  location?: { lat: number; lng: number } | null;
}
class GeocodeAPI extends BaseCRUD {
  locationLength: number = 6;

  async geocode({ location, ...params }: GeocodeParams) {
    const query = objectToNonNullableURLParamsString({
      lng: location?.lng?.toFixed(this.locationLength),
      lat: location?.lat?.toFixed(this.locationLength),
      ...params,
    });
    return await WebApiClient.fetch({
      method: 'get',
      url: `/maps/${this.schema}/?${query}`,
      urlVersion: 2,
    });
  }

  // async geocodeReverse(location: { lat: number; lng: number }) {
  //   const query = new URLSearchParams({
  //     ...(location?.lat ? { lat: location.lat.toString() } : {}),
  //     ...(location?.lng ? { lng: location.lng.toString() } : {}),
  //   }).toString();
  //   return await WebApiClient.fetch({
  //     method: 'get',
  //     url: `/maps/${this.schema}/reverse/?${query}`,
  //     urlVersion: 2,
  //   });
  // }

  async suggest(part: string, employeeId: number | string, location?: TGeoLocation) {
    const query = new URLSearchParams({
      ...(part ? { part } : {}),
      ...(employeeId ? { employee_id: employeeId.toString() } : {}),
      ...(location?.lat ? { lat: location.lat.toString() } : {}),
      ...(location?.lng ? { lng: location.lng.toString() } : {}),
    }).toString();

    return await WebApiClient.fetch({
      method: 'get',
      url: `/maps/${this.schema}/suggest/?${query}`,
      urlVersion: 2,
    });
  }
}

export default GeocodeAPI;

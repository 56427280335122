import ModalSelect from 'components/ModalSelect';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useStores } from 'stores';
import { Location, NumberOrString, Place } from 'types/types';
import { includesItemWithId } from 'utils/lib/includesItemWithId';
import { TGeoLocation } from '../../stores/GPSLocationStore';
import { formatDistance } from '../../utils/lib/formatDistance';

interface GeoOption {
  id: NumberOrString;
  label: any;
  subtitle?: string;
  address?: string;
  uri?: string;
  distance_m?: number;
  detail?: { kind: string; name: string }[];
  value: {
    text: string;
    distance?: number;
  };
}
export interface YandexAutoCompleteProps {
  onSelect?: any;
  debounceTime?: number;
  minLength?: number;
  inputId?: any;
  leftIcon?: () => JSX.Element;
  rightIcon?: () => JSX.Element;
  permanentОptions?: GeoOption[];
  scrollToTopOnFocus?: boolean;
  withEntrance?: boolean;
}
type SuggestItemAddress = {
  label: string;
  value: string;
  coords?: Location;
};
export type SuggestItem = {
  address: string;
  title?: string;
  subtitle?: string;
  uri?: string;
  distance_m?: number;
  detail?: { kind: string; name: string }[];
};
const YandexAutoComplete = ({
  onSelect,
  permanentОptions,
  scrollToTopOnFocus = false,
  debounceTime = 800,
  minLength = 4,
  leftIcon = () => <></>,
  rightIcon = () => <></>,
  withEntrance,
  ...inputProps
}: YandexAutoCompleteProps &
  Omit<
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>,
    'id'
  >): JSX.Element => {
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const selectedValueRef = useRef<any>('');
  const containerRef = useRef<HTMLDivElement>(null);
  const [results, setResults] = useState<GeoOption[]>([]);
  const { t } = useTranslation(['new_order']);
  const {
    geocodeStore,
    ordersStore: { newOrderInfo },
    gpsLocationStore,
  } = useStores();
  const [stateText, setStateText] = useState<string>();
  const [scrollFinished, setScrollFinished] = useState<boolean>(false);

  useEffect(() => {
    if (!gpsLocationStore?.current_location?.lat) {
      gpsLocationStore?.getCurrentLocation();
    }
  }, []);
  // const formatAddress = (place: any): string => {
  //     if (!place) return '';
  //     if (typeof place === 'string') return place;
  //     if (Array.isArray(place)) {
  //         let details: any[] = place;
  //         let shortDetails = details.filter((d: { kind: string, name: string }) => d.kind === 'street' || d.kind === 'locality' || d.kind === 'house');
  //         const house = shortDetails.filter((d: { kind: string, name: string }) => d.kind === 'house')[0];
  //         if (house) {
  //             shortDetails = shortDetails.filter((d: { kind: string, name: string }) => d.kind !== 'house');
  //             shortDetails.splice(1, 0, house);
  //         }
  //         return (shortDetails.length > 0 ? shortDetails : details).map((item) => item.name).reverse().join(', ')
  //     }
  //     const shortPlace = [
  //         place.route,
  //         place.street_number,
  //         place.locality
  //     ].filter((s: string): boolean => !!s);

  //     return (shortPlace.length > 0 ? shortPlace : [place.route, place.street_number, place.political, place.locality, place.province_2 || place.province_1 || place.formatted || place.country]).filter((s: string): boolean => !!s).join(', ');
  // }

  const onClickOutside = (e: any): void => {
    setResults([]);
    if (e.target?.id === 'address' || inputRef.current !== document.activeElement) return;
    if (selectedValueRef.current !== '' && stateText?.trim() !== selectedValueRef.current?.trim()) {
      console.log('clickout-set-0');
      select(null);
      setStateText('');
    } else if (selectedValueRef.current == '') {
      setStateText('');
    }
  };

  useEffect(() => {
    textAreaAdjust();
  }, [stateText]);

  useEffect(() => {
    if (inputProps.defaultValue === '') {
      setStateText('');
      return;
    }
    setStateText(inputProps?.defaultValue?.toString() || stateText || '');
    selectedValueRef.current = inputProps.defaultValue || stateText || '';
  }, [inputProps.defaultValue]);

  const select = async (option: GeoOption | null): Promise<void> => {
    if (inputRef.current) {
      inputRef.current.blur();
    }
    if (!option) {
      if (onSelect && inputRef.current) {
        onSelect({ address: '' }, true);
        setStateText('');
        selectedValueRef.current = '';
      }
      return;
    }
    setResults([]);
    if (permanentОptions?.length && includesItemWithId(permanentОptions, option.id)) {
      onSelect(option);
      return;
    }

    let result;
    const { address = '', uri } = option;
    selectedValueRef.current = address;
    setStateText(address);
    result = await geocodeStore.geocode({ [uri ? 'uri' : 'address']: uri || address }).catch((e: any) => {
      alert(e.message);
      return;
    });
    const place = result as Place;
    selectedValueRef.current = address;
    setStateText(address);
    if (onSelect) {
      onSelect(place, true);
    }
  };

  const suggest = async (text: string): Promise<void> => {
    if ((text && text.length >= minLength) || !text) {
      try {
        const location: TGeoLocation = gpsLocationStore?.currentLocation || {};
        const list = await geocodeStore.suggest(text || '', newOrderInfo?.employee_id, location);
        if (newOrderInfo?.source?.entrance && withEntrance) {
          const isEntrancesExist =
            list
              .reduce(
                (res, i) => [
                  ...res,
                  ...i.detail
                    ?.filter((d) => d.kind === 'entrance')
                    .map((d) => (d.name || '').replace('подъезд', '').trim()),
                ],
                [],
              )
              .indexOf(newOrderInfo?.source?.entrance) >= 0;
          if (!isEntrancesExist && onSelect) {
            console.log('clear entrance!');
            onSelect(
              {
                ...newOrderInfo?.source,
                entrance: '',
              },
              true,
            );
          }
        }

        if (!(inputRef.current === document.activeElement)) {
          setResults([]);
          return;
        }
        setResults(
          list
            .map((item: SuggestItem, index: number) => {
              console.log('item!', item);
              return item
                ? {
                    label: item.title,
                    address: item.address,
                    subtitle: item.subtitle,
                    distance: formatDistance(item.distance_m, t('distance.km'), t('distance.m')),
                    uri: item.uri,
                    value: {
                      text: item.title,
                      distance: item.distance_m,
                    },
                    id: index,
                  }
                : null;
            })
            .filter((o) => !!o),
        );
      } catch (er) {
        console.log(er);
      }
    } else {
      setResults([]);
    }
  };

  const debounceData = useMemo(
    () => debounce(suggest, debounceTime),
    [
      newOrderInfo?.source?.address,
      newOrderInfo?.source?.entrance,
      newOrderInfo?.destination?.address,
      newOrderInfo?.employee_id,
      newOrderInfo?.interim_destinations,
      location,
    ],
  );

  const onTextChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const text = e.target.value || '';
    setStateText(text);
    if (!text) return;
    debounceData(text);
  };

  const onFocus = (e): void => {
    suggest(e.target.value || '');
    if (inputProps.onFocus) {
      inputProps.onFocus(e);
    }
    if (inputRef.current && scrollToTopOnFocus) {
      inputRef.current.scrollIntoView({ block: 'start', inline: 'nearest', behavior: 'smooth' });
      setTimeout(() => {
        setScrollFinished(true);
      }, 800);
    }
  };

  const onBlur = (e): void => {
    if (inputProps.onBlur) {
      inputProps.onBlur(e);
    }
    if (scrollFinished) {
      setScrollFinished(false);
    }
  };

  useEffect(() => {
    if (withEntrance && newOrderInfo?.source?.entrance !== undefined && inputProps.defaultValue) {
      suggest((inputProps.defaultValue as string) || '');
    }
  }, [newOrderInfo?.source?.entrance]);

  function textAreaAdjust() {
    if (!inputRef.current) return;
    inputRef.current.style.height = '1px';
    inputRef.current.style.height = inputRef.current.scrollHeight + 'px';
  }

  const onKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>): void => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <div ref={containerRef} className="w-full">
      <ModalSelect
        onBackdropPress={onClickOutside}
        alwaysBottom
        closeOnBackdropPress
        alwaysOpen={
          (results?.length > 0 || !!permanentОptions?.length) &&
          inputRef.current === document.activeElement &&
          (scrollFinished || !scrollToTopOnFocus)
        }
        options={permanentОptions ? [...permanentОptions, ...results] : results}
        onChange={select}
        optionClass=""
      >
        <div className="flex center-position">
          {leftIcon()}
          <textarea
            rows={1}
            type="text"
            {...inputProps}
            className={`bg-transparent py-3 sm:pr-1 min-h-12 w-full text-black focus:ring-0 outline-none overflow-hidden ${inputProps?.className || ''}`}
            ref={inputRef}
            value={stateText}
            onFocus={onFocus}
            onChange={onTextChange}
            onBlur={onBlur}
            onKeyDown={onKeyDown}
          />
          {rightIcon()}
        </div>
      </ModalSelect>
    </div>
  );
};

export default YandexAutoComplete;

import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { SearchBar } from 'components/SearchBar';
import { Pagination } from 'components/Pagination';
import { useTranslation } from 'react-i18next';
import Table from 'components/Table';
import { Checkbox } from 'components/Inputs/Checkbox';
import { PolicyDialogProps } from '..';
import { PermissionsEmployee, Role } from 'types/types';
import ModalBase from 'components/ModalBase';
import { EmployeeUpdates } from 'stores/CoordinatorsStore';
import { formatError } from 'utils/lib/formatError';
import { notify } from 'utils/lib/notify';

interface Props {
  roleId: Role['id'];
  withFilter?: any;
}

export const EmployeesDialog = observer(({ onClose, visible, roleId, withFilter = {} }: Props & PolicyDialogProps) => {
  const {
    coordinatorsStore,
    coordinatorsStore: { editingCoordinator: user },
  } = useStores();
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const { t } = useTranslation(['coordinators', 'confirmation']);

  useEffect(() => {
    if (visible && roleId) {
      coordinatorsStore.roleEmployeesState
        ?.fetchList(
          false,
          { role_id: roleId, ...withFilter },
          'id desc',
          0,
          coordinatorsStore?.limit || 10,
          true,
          false,
        )
        .then(() => setIsDataReady(true));
    } else {
      setIsDataReady(false);
    }
  }, [visible, roleId]);

  const updatePermissions = async (updates: PermissionsEmployee, add: boolean): Promise<void> => {
    await coordinatorsStore.updateEmployee({
      employee_id: updates.employee_id,
      manage_employee: add ? 1 : 0,
      coordinator_id: user?.id,
    } as EmployeeUpdates);
    const { employeesErrors = {} } = coordinatorsStore || {};
    if (updates.employee_id && employeesErrors[updates.employee_id]) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedChangeUserAccess', { ns: 'errors' })} \"${updates?.employee_name || ''}\": ${formatError(employeesErrors[updates?.employee_id], t)}`,
        type: 'danger',
      });
    }
  };

  const onChangeAll = async (value: 0 | 1): Promise<void> => {
    const role = coordinatorsStore.permissionsRolesState?.items?.find((role) => role?.role_id === roleId);
    if (!role || !roleId) return;
    await coordinatorsStore?.updateRole({
      role_id: roleId,
      coordinator_id: user?.id,
      manage_all: value,
    });
    const { rolesErrors = {} } = coordinatorsStore || {};
    if (rolesErrors[roleId]) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: formatError(rolesErrors[roleId], t),
        type: 'danger',
      });
    }
  };

  const isAllSelected = (): boolean => {
    const role = coordinatorsStore.permissionsRolesState?.items?.find((role) => role?.role_id === roleId);
    if (!role || !role?.role_id) return false;
    return role.employee_access === role.employee_total;
  };

  return (
    <ModalBase onClose={onClose} title={t('dialog.select_employee')} visible={visible}>
      <main>
        <div className="container1">
          <div className="area overflow-hidden">
            <SearchBar
              containerClass=""
              store={coordinatorsStore?.roleEmployeesState}
              placeholder={t('search_placeholder')}
            />
          </div>
          {coordinatorsStore.isFetchingPermissionEmployees || !isDataReady ? (
            <span>{t('loading')}</span>
          ) : (
            <div className="area overflow-hidden">
              <div className="overflow-x-auto flex">
                <Table
                  stickyHeader
                  tableClass="table w-full"
                  colClasses={['w-full']}
                  columnsKeys={['name']}
                  data={coordinatorsStore?.roleEmployeesState?.items || []}
                  translateFunc={(key: string) => t(`thead.${key}`)}
                  customTdInnerRenderer={{
                    name: (record: PermissionsEmployee) => (
                      <Checkbox
                        checked={!!record.manage_employee}
                        label={record?.employee_name}
                        containerClass="flex items-center"
                        labelClass="w-full"
                        onChange={({ target }) => updatePermissions(record, target.checked)}
                      />
                    ),
                  }}
                  customThInnerRenderer={{
                    name: () => (
                      <Checkbox
                        checked={isAllSelected()}
                        label={t(`employees.all`)}
                        containerClass="flex items-center"
                        labelClass="w-full"
                        onChange={({ target }) => (+target.checked ? onChangeAll(1) : onChangeAll(0))}
                      />
                    ),
                  }}
                >
                  <div className="area-inner">
                    <Pagination
                      withFilter={{
                        ...withFilter,
                        role_id: roleId,
                      }}
                      compact
                      store={coordinatorsStore?.roleEmployeesState}
                    />
                  </div>
                </Table>
              </div>
            </div>
          )}
        </div>
      </main>
      {/* <div className="flex justify-end space-x-5">
        <button className="btn btn-light" style={{ marginLeft: 20, position: 'fixed', left: 0 }} type="button" onClick={resetEmployeesPermissions}>{t('dialog.reset')}</button>
        <button className="btn btn-light" type="button" onClick={onClose}>{t('dialog.close')}</button>
      </div> */}
    </ModalBase>
  );
});

export default EmployeesDialog;

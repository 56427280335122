import ModalBase from 'components/ModalBase';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import { CONTACT_PHONE_NUMBER, CONTACT_PHONE_NUMBER_PROMO } from 'constants/info';
import { notify } from 'utils/lib/notify';
import { formatError } from 'utils/lib/formatError';

interface InfoProps {
  onClose: any;
  visible: boolean;
}

const Info = observer(({ onClose, visible }: InfoProps): JSX.Element => {
  const { usersStore } = useStores();
  const { t } = useNestedTranslation(['nav.info', 'notification']);

  const [feedback, setFeedback] = useState('');

  const closeForm = (): void => {
    onClose();
    if (feedback) {
      setFeedback('');
    }
  };
  const sendFeedback = async (): Promise<void> => {
    await usersStore.sendFeedback(feedback);
    const isFailed = !!usersStore.sendFeedbackError;
    const notifyConf: Parameters<typeof notify>[0] = {};

    notifyConf.title = t('title.' + (isFailed ? 'error' : 'success'), { ns: 'notification' }) + '!';
    notifyConf.message = `${isFailed ? t('sent_feedback_error') : t('sent_feedback_text')}`;
    notifyConf.type = isFailed ? 'danger' : 'success';

    notify(notifyConf);

    if (!isFailed) setFeedback('');
  };
  const phone = usersStore?.isPromo ? CONTACT_PHONE_NUMBER_PROMO : CONTACT_PHONE_NUMBER;
  return (
    <ModalBase visible={visible} title={usersStore?.companyName || 'SwiftDrive'} onClose={closeForm}>
      <form className="lg:w-auto mx-auto">
        {usersStore?.me?.detail?.manager?.name ? (
          <div className="mb-6 md:mb-8 lg:mb-6">
            <div className="mb-5">
              <label className="form-label" htmlFor="language">
                {t('manager')}:
              </label>
              <a className="link ml-1" href={`mailto:${usersStore?.me?.detail?.manager?.email}`}>
                {usersStore?.me?.detail?.manager?.name}
              </a>
            </div>
          </div>
        ) : null}
        {usersStore?.me?.detail?.manager?.email ? (
          <div className="mb-6 md:mb-8 lg:mb-6">
            <div className="mb-5">
              <label className="form-label" htmlFor="language">
                {t('manager_email')}:
              </label>
              <a className="link ml-1" href={`mailto:${usersStore?.me?.detail?.manager?.email}`}>
                {usersStore?.me?.detail?.manager?.email}
              </a>
            </div>
          </div>
        ) : null}
        <div className="mb-6 md:mb-8 lg:mb-6">
          <div className="mb-5">
            <label className="form-label" htmlFor="language">
              {t('phone')}:
            </label>
            <a className="link ml-1" href={`tel:${String(phone).replace(/\(|\)|-|\s/g, '')}`}>
              {phone}
            </a>
          </div>
        </div>
        <div className="mb-5">
          <div className="lg:w-full flex align-start flex-col md:mx-auto">
            <label className="form-label" htmlFor="class">
              {t('feedback')}:
            </label>
            {usersStore?.sentFeedback ? (
              <p className={`ml-2 ${usersStore.sendFeedbackError ? 'error' : ''}`}>
                {usersStore.sendFeedbackError ? t('sent_feedback_error') : t('sent_feedback_text')}
              </p>
            ) : (
              <>
                <textarea
                  onChange={({ target }) => setFeedback(target.value)}
                  value={feedback || ''}
                  className="form-control max-h-50 min-h-[72px] ml-2 mr-2 mt-3 w-auto align-self-stretch"
                  rows={2}
                  cols={40}
                />
                <button
                  className={`btn btn-outline-blue mt-3 ml-2`}
                  type="button"
                  onClick={sendFeedback}
                  disabled={!feedback}
                >
                  <span>{t('send_feedback')}</span>
                </button>
              </>
            )}
          </div>
        </div>
      </form>
    </ModalBase>
  );
});

export default Info;

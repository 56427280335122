export const formatDistance = (
  meters: number | undefined,
  metersString: string = '',
  kilometersString: string = '',
): string => {
  if (meters === 0) return `0 ${metersString}`;
  if (!meters) return '';
  if (meters > 1000) return `${(meters / 1000).toFixed(2)} ${metersString}`;
  return `${meters} ${kilometersString}`;
};

import { AxiosResponse } from 'axios';
import WebApiClient from '../WebApi';
export const GRANT_TYPE_PASSWORD = 'password';

interface TokensPair {
  access_token: string;
  refresh_token: string;
}

interface AccessTokenInfo {
  aud: string;
  expiredAt: string;
  issuedAt: string;
  issuer: string;
  jti: string;
  ownerId: string;
  type: string;
  coId: string | undefined;
  ownUid: string | undefined;
}

class AuthApi {
  async signInByEmailPassword(params: { email: string; password: string }): Promise<TokensPair> {
    const { email, password } = params;

    return await WebApiClient.fetch({
      method: 'post',
      url: '/auth/login',
      data: {
        email,
        password,
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
        grant_type: GRANT_TYPE_PASSWORD,
      },
    });
  }

  async signInByPhone(phone: string): Promise<TokensPair> {
    return await WebApiClient.fetch({
      method: 'post',
      url: '/auth/phone',
      data: {
        phone,
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
        grant_type: GRANT_TYPE_PASSWORD,
      },
    });
  }

  async signInByPhoneCheck(phone: string, smsCode: string): Promise<TokensPair> {
    return await WebApiClient.fetch({
      method: 'post',
      url: '/auth/phone/check',
      data: {
        phone,
        sms_code: smsCode,
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
        grant_type: GRANT_TYPE_PASSWORD,
      },
    });
  }

  async verify(uuid: string): Promise<TokensPair> {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/auth/verify/${uuid}`,
    });
  }

  async signInByGoogleOAuth(clientId: string, tokenId: string): Promise<TokensPair> {
    return await WebApiClient.fetch({
      method: 'post',
      url: '/auth/google-oauth',
      data: { clientId, tokenId },
    });
  }

  async signInByFacebookOAuth(clientId: string, tokenId: string): Promise<TokensPair> {
    return await WebApiClient.fetch({
      method: 'post',
      url: '/auth/facebook-oauth',
      data: { clientId, tokenId },
    });
  }
  async signInByVkOAuth(clientId: string, redirectURL: string, tokenId: string): Promise<TokensPair> {
    return await WebApiClient.fetch({
      method: 'post',
      url: '/auth/vk-oauth',
      data: { clientId, redirectURL, tokenId },
    });
  }

  async signUpByEmailPassword(params: {
    name: string;
    email: string;
    password: string;
    backUrl: string;
    utm?: object;
  }): Promise<AxiosResponse> {
    const { name, email, password, backUrl, utm } = params;

    return await WebApiClient.fetch({
      method: 'post',
      url: '/auth/register',
      data: {
        name,
        email,
        password,
        backUrl,
        utm,
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
        grant_type: GRANT_TYPE_PASSWORD,
      },
    });
  }

  async getOrderInfo(id: string | number): Promise<any> {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/orders/share/${id}`,
    });
  }
  async getDriverPhone(id: string | number): Promise<any> {
    return await WebApiClient.fetch({
      method: 'GET',
      url: `/orders/${id}/driver_phone`,
    });
  }

  async requestResetPassword(params: { email: string; backUrl: string }): Promise<AxiosResponse> {
    return await WebApiClient.fetch({
      method: 'post',
      url: '/auth/forgot',
      data: {
        email: params.email,
        backUrl: params.backUrl,
        client_id: process.env.REACT_APP_API_CLIENT_ID,
        client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
        grant_type: GRANT_TYPE_PASSWORD,
      },
    });
  }

  /*
    async refreshAccessToken(params: {
        refreshToken?: string;
        escapeImpersonate?: boolean;
    } = {}): Promise<TokensPair> {
        const { refreshToken, escapeImpersonate } = params;
        console.log(6);
        const response = await WebApiClient.post('/auth/token', {
            client_id: process.env.REACT_APP_API_CLIENT_ID,
            client_secret: process.env.REACT_APP_API_CLIENT_SECRET,
            grant_type: 'refresh_token',
            refresh_token: refreshToken,
            escapeImpersonate,
        });

        const {
                  access_token: newAccessToken,
                  refresh_token: newRefreshToken,
              } = response.data;

        return {
            accessToken: newAccessToken,
            refreshToken: newRefreshToken,
        };
    }
    */
}

export default AuthApi;

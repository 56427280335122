import React from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import MaskedInput from '../../../components/Inputs/MaskedInput';
import { useTranslation } from 'react-i18next';

interface CostCentersProps {
  wrapperClass?: string;
  errors?: any;
  onFocus?: any;
  onBlur?: any;
  containerClass?: string;
}

const CostCenters = observer(
  ({
    wrapperClass = '',
    errors = {},
    onFocus = () => {},
    onBlur = () => {},
    containerClass = '',
  }: CostCentersProps) => {
    const { ordersStore } = useStores();
    const { newOrderInfo } = ordersStore || {};
    const { t } = useTranslation('new_order');

    return (
      <>
        <div className={wrapperClass}>
          <div className={containerClass}>
            <div>
              <span className="form-label">{t('phone_sender') + ' *'}</span>
              <MaskedInput
                onChange={({ target }) =>
                  ordersStore.updateNewOrderInfo({ phone_sender: target.value?.replace(/\D/g, '') })
                }
                value={newOrderInfo?.phone_sender}
                onFocus={() => onFocus('phone_sender')}
                onBlur={onBlur}
                mask="+9 (999) 999-99-99"
                placeholder="+7 (999) 999-99-99"
                className={`form-control form-control--lg ${errors.phone_sender ? 'validate-error' : ''}`}
              />
            </div>
            {errors.phone_sender && <p className="validate-error-message">{errors.phone_sender}</p>}
          </div>
        </div>
        <div className={wrapperClass}>
          <div className={containerClass}>
            <div>
              <span className="form-label">{t('phone_recipient') + ' *'}</span>
              <MaskedInput
                onChange={({ target }) =>
                  ordersStore.updateNewOrderInfo({ phone_recipient: target.value?.replace(/\D/g, '') })
                }
                value={newOrderInfo?.phone_recipient}
                onFocus={() => onFocus('phone_recipient')}
                onBlur={onBlur}
                mask="+9 (999) 999-99-99"
                placeholder="+7 (999) 999-99-99"
                className={`form-control form-control--lg ${errors.phone_recipient ? 'validate-error' : ''}`}
              />
            </div>
            {errors.phone_recipient && <p className="validate-error-message">{errors.phone_recipient}</p>}
          </div>
        </div>
      </>
    );
  },
);

export default CostCenters;

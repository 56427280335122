import React from 'react';
import InputMask, { Props } from 'react-input-mask';

interface MaskedInputProps {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
}

const MaskedInput = ({ inputProps, ...rest }: MaskedInputProps & Props): JSX.Element => {
  return <InputMask {...rest}>{(passedProps: any) => <input {...passedProps} />}</InputMask>;
};

export default MaskedInput;

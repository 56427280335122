import { observable, computed, action, runInAction, makeObservable, reaction, autorun } from 'mobx';
import BaseStore from '../BaseStore';
import OrdersApi from './api';
import { RootStoreInterface } from '../../interfaces';
import { APP_PLATFORM, APP_VERSION } from '../../utils/deviceInfo';
import { debounce, isEmpty, omit } from 'lodash';
import { EstimateInfo, OrderDirections, OrderOptions, Place, Requirements, Ride, TariffClass } from 'types/types';
import { saveFile } from 'utils/lib/fileHandlers/saveFile';
import { operatorListOrdersFilter, operatorListOrdersOrderBy } from '../../modules/Rides/filter';
export type CurrentField = 'source' | 'destination' | 'unset' | 0 | 1 | 2 | 3 | 4;

class OrdersStore extends BaseStore {
  api: OrdersApi;

  @observable isDebouncingFilter: boolean = false;
  debounceParamsUpdate: (
    useCache: boolean,
    filter: any,
    orderBy: string,
    page: number,
    limit: number,
    isDetail: boolean,
    isAppend: boolean,
    isUpdateCurrentRecord: boolean,
  ) => void;

  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Orders']);
    this.api = new OrdersApi(rootStore.specifications['Orders'].url);
    //this.api.setUrlVersion(2); // this.rootStore?.modulesStore?.usersStore?.me?.settings?.orders_api_version);
    this.setApiClient(this.api);

    reaction(
      () => this.estimateInfo.classes,
      (classes) => {
        if (!(this.newOrderInfo?.source && this?.newOrderInfo?.destination) && classes) {
          this.resetEstimateInfo();
        }
      },
    );
    // autorun(() => {
    //   this.startFetchActiveOrdersInterval(
    //     this.rootStore?.modulesStore?.usersStore?.me?.settings?.orders_update_timeout,
    //   );
    // });

    makeObservable(this);

    //TODO: why WebSocket isn't working?
    // setInterval(() => {
    //   if (this.activeOrder) {
    //     console.log("FETCH ACTIVE ORDER");
    //     this.fetchRecord(this.activeOrder.id, true);
    //   }
    // }, 45 * 1000)

    this.debounceParamsUpdate = debounce(
      async (
        useCache: boolean,
        filter: any,
        orderBy: string = 'id desc',
        page: number = 0,
        limit: number = 20,
        isDetail: boolean = false,
        isAppend: boolean = false,
        isUpdateCurrentRecord: boolean = false,
      ) => {
        try {
          runInAction(() => {
            this.isDebouncingFilter = false;
          });
          const newFilter = Object.entries({ ...this.filter, ...filter } || {}).reduce(
            (acc, [key, val]) => (val || key === 'is_disabled' ? { ...acc, [key]: val } : acc),
            {},
          );
          const isNewPage: boolean = page !== this.page;
          if (isNewPage) {
            runInAction(() => {
              this.isFetchingNewPage = true;
            });
          }
          runInAction(() => {
            this.isFetchingFilteredData = true;
          });
          await this.fetchList(useCache, newFilter, orderBy, page, limit, isDetail, isAppend, isUpdateCurrentRecord);
          if (isNewPage) {
            runInAction(() => {
              this.isFetchingNewPage = false;
            });
          }
          runInAction(() => {
            this.isFetchingFilteredData = false;
          });

          this.startFetchActiveOrdersInterval(
            this.rootStore?.modulesStore?.usersStore?.me?.settings?.orders_update_timeout,
          );
        } catch (e) {
          console.log('e -->', e);
          runInAction(() => {
            this.isFetchingNewPage = false;
            this.isFetchingFilteredData = false;
            this.isDebouncingFilter = false;
          });
        }
      },
      2000,
    );
  }

  startFetchActiveOrdersIntervalId: NodeJS.Timer | undefined;

  /**
   * Reload orders list in interval.
   * @param interval interval to reload orders list.
   */
  startFetchActiveOrdersInterval(interval: number = 10) {
    this.cleanFetchActiveOrdersInterval();
    this.startFetchActiveOrdersIntervalId = setInterval(() => {
      if (!this.isDebouncingFilter) {
        this.reloadList();
      }
    }, interval * 1000);
  }
  cleanFetchActiveOrdersInterval() {
    if (this.startFetchActiveOrdersIntervalId) {
      clearInterval(this.startFetchActiveOrdersIntervalId);
    }
  }

  async setFilter(
    useCache: boolean,
    filter: any,
    orderBy: string = 'id desc',
    page: number = 0,
    limit: number = 20,
    isDetail: boolean = false,
    isAppend: boolean = false,
    isUpdateCurrentRecord: boolean = false,
  ) {
    runInAction(() => {
      this.isDebouncingFilter = true;
    });
    this.cleanFetchActiveOrdersInterval();
    this.debounceParamsUpdate(useCache, filter, orderBy, page, limit, isDetail, isAppend, isUpdateCurrentRecord);
  }

  async fetchList(
    useCache: boolean,
    filter: any,
    orderBy: string = 'id desc',
    page: number = 0,
    limit: number = 20,
    isDetail: boolean = false,
    isAppend: boolean = false,
    isUpdateCurrentRecord: boolean = false,
  ): Promise<void> {
    //alert("FETCH LIST");
    if (useCache === true && this.list) {
      return;
    }

    runInAction(() => {
      if (this.rootStore?.modulesStore?.usersStore?.isOperator) {
        if (filter.msk_datetime) delete filter.msk_datetime;
        if (Object.keys(filter).length === 0) {
          filter.msk_datetime = operatorListOrdersFilter.msk_datetime;
        }
        orderBy = operatorListOrdersOrderBy;
      }

      this.filter = filter || {};
      this.orderBy = orderBy || undefined;
      this.page = page;
      this.limit = limit || this.limit || 20;
      this.isDetail = isDetail || false;
      this.fetchingListError = undefined;
      this.isFetchingListInProgress = true;
    });

    const usersStore = this.rootStore?.modulesStore?.usersStore;
    const role = usersStore?.role;
    const is_v2 = !!usersStore?.me?.detail?.customer?.is_use_api_v2;

    try {
      const { items } = await this.apiClient.list(this.filter, this.orderBy, this.page, this.limit, isDetail, {
        role,
        is_v2,
      });
      runInAction(() => {
        if (isAppend) {
          if (!this.list) this.list = [];
          this.list = [...this.list, ...items];
        } else {
          this.list = [...items];
        }
        if (this.savedItem?.id) {
          this.list = this.list.map((item) => (this.savedItem.id === item.id ? { ...item, ...this.savedItem } : item));
        }
        this.total = this.list.length;

        //TODO: it's not correct!!!!!
        this.rootStore.modulesStore.webSocketStore.subscribe(this.specification.moduleName, 'list', this.filter);

        if (this.list && this.list.length) {
          this.list = this.list.map((record: any) => this.recordHandlers(record));
        }
        if (isUpdateCurrentRecord && this.record) {
          const [currentRecord] = this.list.filter((r) => this.record.id === r.id);
          if (currentRecord) {
            this.record = {
              ...this.record,
              ...currentRecord,
            };
          }
        }

        this.isFetchingListInProgress = false;
      });
    } catch (err) {
      console.error(err);
      this.captureError(err);

      runInAction(() => {
        this.fetchingListError = err;
        this.isFetchingListInProgress = false;
      });
      this.rootStore.modulesStore.logsStore.debug(JSON.stringify(err));
    }
  }

  @computed get activeOrder() {
    // TODO comment //this.activeOrdersList;
    const activeOrders: any = this.activeOrdersList;
    //if (this.list && this.list.length)
    //  return {...this.list[0], status_id: 'search' };
    const order = activeOrders ? activeOrders[0] : null;
    return order;
  }

  @computed get activeOrdersList() {
    if (!this.list || !this.list.length) return [];
    return this.list.filter((item: any) => this.specification.statuses.active.includes(item.status_id));
  }

  @computed get feedbackOrder() {
    if (!this.list || !this.list.length) return null;

    if (
      this.list[0].status_id === 'complete' &&
      (!this.list[0].feedback || !Object.keys(this.list[0].feedback)?.length)
    ) {
      return this.list[0];
    }

    return null;
  }

  @action startReloadingList() {
    this.startFetchActiveOrdersInterval(this.rootStore?.modulesStore?.usersStore?.me?.settings?.orders_update_timeout);
  }
  @action stopReloadingList() {
    if (this.startFetchActiveOrdersIntervalId) {
      clearInterval(this.startFetchActiveOrdersIntervalId);
    }
  }

  @action
  setFeedback(star: string, text: string) {
    if (this.feedbackOrder) {
      this.feedbackOrder.update({
        feedback: {
          star,
          text,
        },
      });
      runInAction(() => {
        this.feedbackOrder.feedback = text;
      });
    }
  }

  @action
  async fetchActiveOrders(): Promise<void> {
    /*
  reaction(
      () => this.list,
      (list) => {
          alert(2);
        console.log("REACTION. ACTIVE ORDERS LIST222222222222222222222222");
        runInAction(()=>{
          this.activeOrdersList = list.filter(item=>this.activeOrdersStatuses.includes(item.status_id));
          console.log("LENGTH", list, this.activeOrdersList);
        })
      },
  );
  */
    console.log({ status_id: ['in', this.specification.statuses.active] });
    // TODO
    // return await this.fetchList(false, {status_id:["in",this.specification.statuses.active]});
  }

  @computed get navigationScreen() {
    let screen = '';
    if (this.activeOrder) {
      switch (this.activeOrder.status_id) {
        case 'cancelled':
          screen = 'OrderForm';
          break;
        case 'new':
        case 'search':
          screen = 'CarSearch';
          break;
        case 'driving':
          screen = 'Driving';
          break;
        case 'waiting':
          screen = 'Arrived';
          break;
        case 'transporting':
          screen = 'Riding';
          break;
        default:
          console.log('UNKNOWN STATUS');
          screen = 'OrderForm';
          break;
      }
    } else {
      screen = 'OrderForm';
    }
    return screen;
  }

  @observable
  tracks: { [key: number]: (string | number)[] } = {};

  @action
  async fetchTrack(id: string | number): Promise<any> {
    try {
      const data = await this.api.getOrderTrack(id);
      runInAction(() => {
        this.tracks[id] = data;
      });
    } catch (e) {
      console.error('Error: get order track', e);
    }
    return this.tracks[id];
  }

  @observable
  isPublishing: boolean = false;
  @observable
  isReordering: boolean = false;

  @action
  async publishOrder(id: string | number, info: any): Promise<any> {
    try {
      this.isPublishing = true;
      const data = await this.api.publishOrder(id, info);
      this.isPublishing = false;
    } catch (e) {
      console.error('Error: publish order', e);
    }
    return;
  }
  @action
  async repost(id: string | number): Promise<any> {
    try {
      this.isReordering = true;
      const data = await this.api.repost(id);
      await this.setFilter(false, this.filter, this.orderBy, this.page, this.limit, true, false, true);
      this.isReordering = false;
    } catch (e) {
      this.isReordering = false;
      console.error('Error: repost order', e);
    }
    return;
  }
  @observable isGettingPhone: boolean = false;
  @observable driverPhone: string = '';

  @action
  async getDriverPhone(id: string | number): Promise<any> {
    try {
      runInAction(() => {
        this.driverPhone = '';
        this.savedItem = null;
        this.isGettingPhone = true;
      });
      const { phone } = (await this.api.getDriverPhone(id)) || {};
      if (phone) {
        runInAction(() => {
          this.driverPhone = phone;
          this.savedItem = {
            id,
            driver_phone: phone,
          };
          this.list = this.list.map((ride: Ride) =>
            id === ride.id
              ? {
                  ...ride,
                  driver_phone: phone,
                }
              : ride,
          );
          if (this.record) this.record.driver_phone = phone;
        });
      }
    } catch (e) {
      console.error('Error: publish order', e);
    }
    runInAction(() => {
      this.isGettingPhone = false;
    });
    return;
  }

  @action
  removeTrack(id: string | number): void {
    runInAction(() => {
      this.tracks = omit(this.tracks, id);
    });
  }

  cancelledOrderId: number = 0;
  @action
  cancelActiveOrder(): void {
    if (this.activeOrder) {
      this.cancelledOrderId = this.activeOrder.id;
      this.activeOrder.update({ status_id: 'cancelled' });
      runInAction(() => {
        this.resetNewOrderInfo();
      });
    }
  }

  @action
  async cancelFeedback(feedback: string): Promise<void> {
    if (this.cancelledOrderId > 0) {
      await this.updateRecord(this.cancelledOrderId, { feedback: { text: feedback, status: 'cancelled' } });
      this.cancelledOrderId = 0;
    }
  }

  @observable
  currentField: CurrentField = 'unset';

  @action
  updateCurrentField(field: CurrentField) {
    runInAction(() => {
      this.currentField = field;
    });
  }

  @observable
  currentFieldValue: Place | undefined;

  @action
  updateCurrentFieldValue(value: Place | undefined) {
    runInAction(() => {
      this.currentFieldValue = value;
    });
  }

  @action
  updateCurrentFieldAndPlace(field: CurrentField, place: OrderDirections) {
    runInAction(() => {
      this.currentField = field;
      this.updateNewOrderInfo(place);
    });
  }

  @observable
  newOrderInfo: any = { class_id: 1 };

  @action
  updateNewOrderInfo(updates: any) {
    runInAction(() => {
      this.newOrderInfo = { ...this.newOrderInfo, ...updates };
    });
  }

  @action
  omitNewOrderInfoFields(fields: string | string[]) {
    runInAction(() => {
      this.newOrderInfo = omit(this.newOrderInfo, fields);
    });
  }

  @action
  resetNewOrderInfo() {
    runInAction(() => {
      this.newOrderInfo = { class_id: 1 };
    });
  }

  @action
  addInterimDestination(place: any) {
    runInAction(() => {
      this.newOrderInfo.interim_destinations = [
        ...(this.newOrderInfo.interim_destinations || []),
        ...(this.newOrderInfo.destination ? [this.newOrderInfo.destination] : []),
      ];
      this.newOrderInfo.destination = place;
    });
  }

  @action
  deleteInterimDestination(index) {
    runInAction(() => {
      this.newOrderInfo.interim_destinations = this.newOrderInfo.interim_destinations?.filter(
        (item, indx) => indx != index,
      );
    });
  }

  @action
  deleteSource() {
    runInAction(() => {
      this.newOrderInfo = omit(this.newOrderInfo, ['source']);
    });
  }
  @action
  reorderInterimDestinations(order) {
    runInAction(() => {
      const data = [...(this.newOrderInfo.interim_destinations || []), this.newOrderInfo?.destination];
      const newArr: any[] = [];
      for (let i = 0; i < order.length; i++) {
        newArr.push(data[order[i]]);
      }
      this.newOrderInfo.destination = newArr[newArr.length - 1];
      this.newOrderInfo.interim_destinations = newArr.slice(0, -1);
    });
  }

  @observable estimateInfo: EstimateInfo = {};
  @observable isEstimateInProgress: boolean = false;
  @observable estimateError: string = '';
  @action
  async estimateOrder() {
    runInAction(() => {
      this.isEstimateInProgress = true;
      this.estimateError = '';
      this.estimateInfo = {};
    });
    try {
      const { class_id, ...restNewOrderInfo } = this.newOrderInfo || {};
      const estimateInfo = await this.api.estimate(
        restNewOrderInfo,
        this.rootStore?.modulesStore?.promocodesStore?.chosenPromocode?.id,
      );
      if (estimateInfo) {
        runInAction(() => {
          this.estimateInfo = estimateInfo;
        });
      }
      if (
        this.estimateInfo?.classes &&
        !this.estimateInfo.classes.find((cls: any) => cls.name === 'econom') &&
        this.estimateInfo?.classes?.length
      ) {
        this.updateNewOrderInfo({ class_id: this.estimateInfo.classes[0].id });
      }
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.estimateError = JSON.stringify(e);
      });
    }
    runInAction(() => {
      this.isEstimateInProgress = false;
    });
    return this.estimateInfo;
  }

  @action
  resetEstimateInfo() {
    runInAction(() => {
      this.estimateInfo = {};
    });
  }

  filterRequirements(): Requirements {
    const { class_id, requirements = {} } = this.newOrderInfo || {};
    const { classes = [] } = this.estimateInfo || {};
    return Object.keys(classes.find((c: TariffClass) => c?.id === class_id)?.requirements || {}).reduce(
      (acc, key: string) => (requirements[key] ? { ...acc, [key]: requirements[key] } : acc),
      {},
    );
  }

  @action
  async createOrder(options: OrderOptions = {}) {
    console.log(
      '======================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================================',
    );

    /*
    runInAction(()=>{
      this.list=[{
        ...this.newOrderInfo,
        status_id: 'search',
        performer: {
          "car": "Hyundai Solaris белый Т740ВК750",
          "fullname": "Кенжаев Абдулатиф Абдулахатович",
          "phone": "+79202965452"
        },
        arrival_time_s: 200,
        current_location: {
          lat: this.newOrderInfo.source.lat + 0.001,
          lng: this.newOrderInfo.source.lng + 0.001,
        }
      }];
    });
    console.log(this.list);
    return;
*/
    const d = new Date();
    const timezone_offset = -1 * d.getTimezoneOffset();
    const requirements = this.filterRequirements();

    const newOrder = {
      ...omit(this.newOrderInfo, 'requirements'),
      ...(isEmpty(requirements) ? {} : { requirements }),
      estimate: this.estimateInfo,
      timezone_offset,
      platform: APP_PLATFORM,
      version: APP_VERSION,
      ...(options.skip_check_double
        ? {
            query: {
              skip_check_double: 1,
            },
          }
        : {}),
    };

    console.log('ADD NEW ORDER', newOrder);
    const result = await this.addRecord(newOrder);
    console.log('ADD RECORD RESULT', result);
    if (result && result.status) {
      runInAction(() => {
        this.newOrderInfo = { class_id: 1 };
      });
    }
    return result;
  }

  @action
  async updateOrder(id: Ride['id']): Promise<void> {
    console.log(
      '______________________________________________________________________________________________________________________________',
    );
    const d = new Date();
    const timezone_offset = -1 * d.getTimezoneOffset();
    const newOrder = {
      ...this.newOrderInfo,
      interim_destinations: this.newOrderInfo.interim_destinations?.filter((item: any, index: number) => {
        return index !== this.newOrderInfo.interim_destinations.length - 1;
      }),
      estimate: this.estimateInfo,
      timezone_offset,
      platform: APP_PLATFORM,
      version: APP_VERSION,
    };
    console.log('UPDATE ORDER', newOrder);
    await this.updateRecord(id, newOrder);
    if (!this.addingError) {
      runInAction(() => {
        this.newOrderInfo = { class_id: 1 };
      });
    }
  }

  @action
  async getReceipt(id: number): Promise<void> {
    const data = await this.api.getReceipt(id);
    saveFile(`receipt-${id}.pdf`, data);
  }
  @action
  async getRideLink(id: number): Promise<string> {
    const data = await this.api.getRideLink(id);
    return data?.link;
  }

  @computed get sourceAddress() {
    if (this.activeOrder && this.activeOrder.source) {
      return this.activeOrder.source;
    } else if (this.newOrderInfo && this.newOrderInfo.source) {
      return this.newOrderInfo.source;
      //} else if (this.currentLocation) {
      //  return this.currentLocation;
    } else {
      return null;
    }
  }

  @computed get destinationAddress() {
    if (this.activeOrder && this.activeOrder.destination) {
      return this.activeOrder.destination;
    } else if (this.newOrderInfo && this.newOrderInfo.destination) {
      return this.newOrderInfo.destination;
    } else {
      return null;
    }
  }

  @computed get mapRegion() {
    return this.sourceAddress
      ? {
          latitude: this.sourceAddress.lat,
          longitude: this.sourceAddress.lng,
        }
      : null;
  }
}

export default OrdersStore;

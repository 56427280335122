import React, { CSSProperties } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

interface DnDListProps {
  items: any[];
  onReorder: (list: any[]) => void;
  renderItem?: (provided: any, snapshot: any, item: any, index: number) => JSX.Element;
  droppableProps?: any;
  draggableProps?: any;
  onDragEnd?: any;
  onDragStart?: any;
}

const DnDList = ({
  items,
  onReorder,
  renderItem,
  droppableProps = {},
  draggableProps = {},
  onDragEnd,
  onDragStart,
}: DnDListProps): JSX.Element => {
  const reorder = (list: any[], startIndex: number, endIndex: number) => {
    let order = Array.from(list.keys());
    const listWithIds = list.slice().map((item, ind) => ({ ...item, orderId: ind }));
    const result = [...listWithIds];
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    order = result.map((item: any) => item.orderId);
    return order;
  };

  const renderListItem = (provided: any, snapshot: any, item: any, index: number): JSX.Element => {
    if (renderItem) {
      return renderItem(provided, snapshot, item, index);
    }
    return (
      <div
        ref={provided.innerRef}
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style, index)}
      >
        {'index-'}
        {index}
      </div>
    );
  };

  const onDragEndHandle = (result: any): void => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    if (onDragEnd) {
      onDragEnd();
    }

    const reorderedItems = reorder(items, result.source.index, result.destination.index);
    onReorder(reorderedItems);
  };

  const onDragStartHandle = (): void => {
    if (onDragStart) onDragStart();
  };

  const getListStyle = (isDraggingOver: boolean): CSSProperties => {
    return {};
  };

  const getItemStyle = (isDragging: boolean, draggableStyle: any, index: number): CSSProperties => {
    return {
      padding: 10,
      border: '1px solid rgba(0, 0, 0, 0.2)',
      boxShadow: isDragging ? '0px 10px 10px 0px rgba(0, 0, 0, .2), 0px -10px 10px 0px rgba(0, 0, 0, .2)' : 'none',
      ...draggableStyle,
    };
  };

  return (
    <DragDropContext onDragEnd={onDragEndHandle} onDragStart={onDragStartHandle}>
      <Droppable ignoreContainerClipping droppableId="droppable" {...droppableProps}>
        {(provided: any, snapshot: any) => (
          <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
            {items.map((item: any, index: number) => (
              <Draggable key={item.id} draggableId={item.id} index={index} {...draggableProps}>
                {(provided, snapshot) => renderListItem(provided, snapshot, item, index)}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DnDList;

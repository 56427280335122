export default {
  title: 'New order',
  favourites: 'Favourites',
  edit_title: 'Edit order No. {{id}}',
  source: 'From',
  destination: 'Where',
  interim_destination: 'Stop',
  employee: 'Employee',
  success_msg: 'Your pending order has been successfully {{action}}. The car will arrive at the appointed time',
  changed: 'changed',
  added: 'added',
  guest_full_name: 'Full name of the guest',
  guest: 'Full name',
  phone_number: 'Phone',
  phone_recipient: "Recipient's phone",
  phone_sender: "Sender's phone",
  scheduled_order: 'Pending order',
  choose_payment_method: 'Choose payment method',
  payment_method: 'Payment method',
  manager: 'Manager',
  scheduled_order_placeholder: 'DD.MM.YY/ HH:MM',
  classes_loading: 'Loading classes',
  car_class: 'Vehicle class',
  customer: 'Company',
  wishes: 'Your wishes for the driver',
  actions: {
    save: 'Save',
    new_order: 'New order',
    done: 'Done',
  },
  tariff_mins: 'min',
  without_vat: 'excluding VAT',
  vat: 'with VAT',
  field_can_contain: 'The field may contain: ',
  any_chars: 'any characters ',
  only: 'only ',
  letters: 'letters ',
  digits: 'digits ',
  symbols: 'symbols',
  symbols_count: 'symbols',
  and: 'and ',
  min: 'from',
  max: 'to',
  provider_price_from: 'from',
  provider_time_from: 'from',
  provider_time_minutes: 'min',
  requirements: {
    only_car_delivery: 'Courier by car',
    child_chair: 'Сhild chair',
  },
  entrance: 'Entrance',
  distance: {
    km: 'km',
    m: 'm',
  },
};

import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import { useStores } from 'stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import ModalBase from 'components/ModalBase';
import { handleEmployeeName } from '../../utils/lib/regExp/handleEmployeeName';
import ModalSelectStore from '../../components/ModalSelectStore';
import FASpinner from '../../components/FASpinner';
interface DialogProps {
  onClose: any;
  visible: boolean;
  onCancel: any;
  department_id?: string | number | null;
  store?: any;
  fieldName?: string;
}
interface IDepartment {
  id: string;
  name?: string;
  parent_id?: string;
  cuser_id?: number;
  muser_id?: number;
  customer_id?: number;
  is_disabled?: boolean;
}

const defaultDepartment: IDepartment = {
  id: '',
  name: '',
  parent_id: '',
  is_disabled: false,
};

interface Validation {
  name?: string;
  parent_id?: string;
}

export const Dialog = observer(
  ({ visible, onClose, onCancel, department_id, store, fieldName }: DialogProps): JSX.Element | null => {
    const { departmentsStore, employeesStore } = useStores();

    const [info, setInfo] = useState<IDepartment>(defaultDepartment);
    const [errors, setErrors] = useState<Validation>({});

    useEffect(() => {
      getDepartmentData();
    }, [department_id]);
    const getDepartmentData = async (): Promise<void> => {
      if (department_id) {
        await departmentsStore.fetchRecord(Number(department_id), true);
        if (departmentsStore.record) setInfo(departmentsStore.record as IDepartment);
      }
    };
    const { t } = useTranslation(['departments', 'validation']);

    const resetDepartment = (): void => {
      setInfo(defaultDepartment);
    };

    const handleChangeByField = (data: Partial<IDepartment>): void => {
      let newData = { ...data };
      setInfo((prevInfo) => ({
        ...prevInfo,
        ...newData,
      }));
    };
    const onFocus = (field: 'name' | 'parent_id'): void => {
      if (errors[field]) {
        const err: Validation = { ...errors };
        delete err[field];
        setErrors(err);
      }
    };

    const addDepartment = useCallback(async (): Promise<void> => {
      const { parent_id, name } = info;
      try {
        let id = info.id;
        let res;
        if (info.id) {
          res = await departmentsStore.updateRecord(info.id, { name, parent_id: parent_id || null });
        } else {
          res = await departmentsStore.addRecord({ name, ...(parent_id ? { parent_id } : {}) });
          id = res.id;
        }
        if (!res) {
          setErrors({
            parent_id: String(departmentsStore.addingError) || t('dialog.error'),
          });
        } else {
          if (store && fieldName) {
            const field: any = store.specification.fields.find((field: any) => field?.name === fieldName);
            const uid: string = field ? field?.name + '_id' : '';
            await store.fetchLinkedList(field?.name, { $nodx_search: '' }, [], 'id desc', uid, true);
          }

          onClose(id);
          setErrors({});
          resetDepartment();
        }
      } catch (e) {
        setErrors({
          parent_id: String(departmentsStore.addingError) || t('dialog.error'),
        });
      }
    }, [info, store, fieldName]);
    const cancel = (): void => {
      setErrors({});
      resetDepartment();
      if (onCancel) {
        onCancel();
      }
      onClose();
    };
    return (
      <ModalBase
        visible={visible}
        contentClass="overflow-visible"
        title={t(`action.${department_id ? 'edit' : 'add'}`)}
        onClose={() => {
          onClose();
          setErrors({});
          resetDepartment();
        }}
      >
        <form>
          <fieldset className="mb-1">
            <div className="mb-5">
              <label className="form-label form-label-required" htmlFor="firstname">
                {t('dialog.name')}
              </label>
              <input
                className={`form-control py-3`}
                type="text"
                onChange={(e) => handleChangeByField({ name: handleEmployeeName(e.target.value, true) })}
                id="firstname"
                placeholder={t('dialog.name')}
                value={info.name}
                onFocus={() => onFocus('name')}
              />
              {errors.name && <span className="error">{errors.name}</span>}
            </div>
          </fieldset>
          <div className="mb-5">
            <label className="form-label" /*for="role"*/>{t('dialog.parent_id')}</label>
            <div className="flex">
              <ModalSelectStore
                listProps={{
                  'aria-labelledby': 'role-label',
                  'aria-activedescendant': 'role-option-0',
                }}
                wrapperClass="flex-1"
                store={employeesStore}
                fieldName={'department_id'}
                filter={{}}
                omitValuesOnFocus={[]}
                placeholder={t('dialog.parent_id')}
                value={info.parent_id || ''}
                onChange={(id) => handleChangeByField({ parent_id: id || '' })}
                nested
                defaultValue={info.parent_id || ''}
              />
            </div>
            {errors.parent_id && <p className="error flex-1 mt-3">{errors.parent_id}</p>}
          </div>

          <div className="flex justify-end space-x-5">
            <button className="btn btn-light" onClick={cancel} type="button">
              {t('dialog.cancel')}
            </button>
            <button
              className="btn btn-blue"
              type="button"
              disabled={!info.name || departmentsStore?.isAddingInProgress}
              onClick={addDepartment}
            >
              <FASpinner containerClass="mr-2" show={departmentsStore?.isAddingInProgress} />
              {department_id ? t('dialog.save') : t('dialog.add')}
            </button>
          </div>
        </form>
      </ModalBase>
    );
  },
);

export default {
  title: 'Orders',
  loading: 'Loading data',
  error: 'Error',
  unAuthedOrderError: 'The requested order was not found',
  thead: {
    id: 'ID',
    status: 'Status',
    address: 'Address',
    full_name: 'Full name',
    company: 'Company',
    driver: 'Driver',
    driver_name: 'Driver name',
    driver_car: 'Driver car',
    class: 'Class',
    cost: 'Cost with VAT',
    comment: 'Comment',
    time: 'Time',
  },
  trow: {
    employee: 'Employee',
    paid_waiting: 'Paid waiting',
    ride: 'Ride',
    full_time: 'Full time',
    minutes_short: 'm',
    minutes: 'min',
    arrival_time: 'Arrival time',
    guest: '(guest)',
    support: 'Support',
    entrance: 'entrance',
  },
  info: {
    remaining_time: 'Remaining time',
    will_arrive_soon: 'Driver will arrive soon',
    arrived: 'Driver has arrived',
    search_header: 'Order received',
    search: 'We are looking for a suitable driver',
    'Osearch.vip': 'Order received. We are looking for a suitable driver',
    'search.vip': 'Order received. We are looking for a suitable driver',
    time: 'Time',
    will_arrive: 'Driver will arrive',
    willarrive_vip: 'Через',
    mins: '',
    'mins.s1': 'min',
    'mins.s2': 'min',
    car: 'Car',
    car_number: 'Car number',
    order_id: 'Order number',
    help_phone: 'Contact center phone',
    route: 'Route',
  },
  details: {
    id: 'ID',
    manager: 'Manager',
    status: 'Status',
    start_transporting_time: 'Start time',
    finished_time: 'Finished time',
    cancelled_time: 'Cancellation time',
    order_time: 'Order time',
    time: 'Time',
    source: 'Source',
    interim_destination: 'Stop',
    destination: 'Destination',
    employee: 'Employee',
    phone: 'Phone',
    class: 'Class',
    is_toll_road: 'Toll road',
    is_toll_road_paid_by_driver: 'paid by driver',
    is_toll_road_paid_by_client: 'paid by client',
    car: 'Car',
    cost_customer: 'Cost without VAT, rub.',
    cost_customer_with_vat: 'Cost with VAT, rub.',
    cost_customer_estimate: 'Cost estimate with VAT, rub.',
    cost_customer_estimate_inc_tax: 'Cost estimate with VAT, rub.',
    cost_customer_estimate_exc_tax: 'Cost estimate without VAT, rub.',
    paid_waiting_time_s: 'Paid waiting time',
    customer_name: 'Customer name',
    provider: 'Provider',
    provider_order_id: 'Provider order id',
    comment: 'Your wishes for the driver',
    guest_link: 'Link to guest drive',
    cancelled_by: 'Who canceled the order',
    cancelled_client: 'Canceled by user',
    cancelled_driver: 'Canceled by driver',
    driver_not_found: 'The driver for the order was not found',
    orderer: 'Who placed the order',
    internal_comment: 'Internal comment',
    additional_fields: 'Additional fields',
    actions: {
      cancel: 'Cancel',
      edit: 'Edit',
      order_again: 'Re-order',
      show: 'Show',
      copy: 'Copy',
      return_trip: 'Return Trip',
      employee_rides: 'Employee rides',
      receipt: 'Receipt',
    },
  },
  filter: {
    search_placeholder: 'Search',
    title: 'Filters',
    status: 'Status',
    full_name: 'Full name',
    id: 'ID',
    class: 'Class',
    company: 'Company',
    is_toll_road: 'Toll roads',
    is_promo: 'Promo',
    actions: {
      apply: 'Apply',
    },
  },
};

import { action, makeAutoObservable, observable } from 'mobx';
import { RootStoreInterface } from '../../interfaces';

class MenuStore {
  @observable swipeEnabled = false;

  constructor(rootStore: RootStoreInterface) {
    makeAutoObservable(this);
  }

  @action
  toggleSwipe(enabled: boolean): void {
    this.swipeEnabled = enabled;
  }
}

export default MenuStore;

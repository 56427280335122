import React, { useCallback, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { useTranslation } from 'react-i18next';
import ModalBase from 'components/ModalBase';
import { InputField } from 'components/Inputs/InputField';

const Filter = observer((): JSX.Element => {
  const { promocodesStore: store } = useStores();
  const [filter, setFilter] = useState<any>({ ...(store?.filter || {}) });
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation('promocodes');

  const changeFilter = useCallback(
    (name: string, value: any, shouldCallOnFilterChangeProp: boolean = false) => {
      const newFilter = { ...filter };
      newFilter[name] = value;
      if (shouldCallOnFilterChangeProp) {
        store.setFilter(false, { ...newFilter }, 'id desc', 0, store?.limit || 10, true, false);
      }
      setFilter(newFilter);
    },
    [filter],
  );

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible]);

  const onApply = useCallback((): void => {
    setVisible(false);
    store.setFilter(false, filter, 'id desc', 0, store?.limit || 10, true, false);
  }, [filter]);

  return (
    <div className="flex space-x-4 lg:space-x-5 ml-4 md:ml-auto">
      <div className="xl:hidden">
        <button
          className="btn btn-light px-1.5 w-10 xl:px-4 xl:w-auto"
          type="button"
          onClick={() => setVisible(true)} /*@click="isOpen = true; document.body.classList.add('overflow-hidden')"*/
        >
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
            <path d="M18.22 2.6c-1.14-.44-4.4-.76-8.22-.76-3.83 0-7.08.32-8.22.75-.24.08-.46.22-.63.4a.68.68 0 0 0 0 .86l6.2 8.22v3.45a1.52 1.52 0 0 0 .84 1.36L10.44 18a1.52 1.52 0 0 0 2.2-1.36v-4.57l6.2-8.22a.68.68 0 0 0 0-.86 1.42 1.42 0 0 0-.62-.4Zm-6.53 8.98c-.06.09-.1.2-.1.31v4.75a.46.46 0 0 1-.67.41l-2.25-1.12a.46.46 0 0 1-.26-.41v-3.63a.53.53 0 0 0-.1-.31L3.03 4.56c2.3.33 4.63.48 6.96.45 2.33.03 4.65-.12 6.96-.45l-5.27 7.02ZM10 3.95c-2.44.05-4.87-.12-7.28-.53 2.4-.4 4.84-.58 7.28-.52 2.44-.06 4.87.12 7.28.52-2.4.4-4.84.58-7.28.53Z"></path>
          </svg>
        </button>
        <ModalBase
          title={t('filter.title')}
          onClose={() => {
            setVisible(false);
            setFilter(store?.filter || {});
          }}
          parentClassName="panel"
          visible={visible}
          unmountContentOnHide={false}
          contentAnimationShowClass="bottom-slide-in"
          contentAnimationHideClass="bottom-slide-out"
          contentClass="overflow-visible"
        >
          <form className="md:w-[30.25rem] mx-auto">
            <div className="mb-6">
              <div className="mb-4">
                <InputField
                  onReset={() => changeFilter('code', '')}
                  value={filter?.code || ''}
                  onChange={(e) => changeFilter('code', e.target.value)}
                  containerClass="w-full"
                  placeholder={t('filter.code')}
                />
              </div>
              {/*{!usersStore?.isEmployee && (*/}
              {/*  <div className="mb-4">*/}
              {/*    <ModalSelectStore*/}
              {/*        store={ordersStore}*/}
              {/*        fieldName={"employee_id"}*/}
              {/*        placeholder={t('filter.activated_by_employee_id')}*/}
              {/*        filter={{ id: filter?.employee_id, customer_id: filter?.customer_id }}*/}
              {/*        omitValuesOnFocus={['сщву']}*/}
              {/*        value={filter.activated_by_employee_id}*/}
              {/*        onChange={(id: number) => changeFilter("activated_by_employee_id", id, true)}*/}
              {/*    />*/}
              {/*  </div>*/}
              {/*)}*/}
            </div>
            <div className="flex justify-end space-x-5">
              <button className="btn btn-blue w-full" type="button" onClick={onApply}>
                {t('filter.actions.apply')}
              </button>
            </div>
          </form>
        </ModalBase>
      </div>
      <div className="w-[9.25rem] hidden xl:block">
        <InputField
          onReset={() => changeFilter('code', '', true)}
          value={filter?.code || ''}
          onChange={(e) => changeFilter('code', e.target.value, true)}
          containerClass="w-full"
          placeholder={t('filter.code')}
        />
      </div>
    </div>
  );
});

export default Filter;

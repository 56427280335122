import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import './i18n';
import App from './components/App';
import * as serviceWorker from './serviceWorker';

setTimeout(
  () => {
    document.location.reload();
  },
  1000 * 60 * 60 * 12,
); //reload once per 12 hours

if (process.env.REACT_APP_API_BASE_URL && process.env.REACT_APP_API_BASE_URL.toString().indexOf('stage') < 0) {
  Sentry.init({
    dsn: 'https://5f6bc03adc1f4e01a9ec8bb7926324bc@o1007869.ingest.sentry.io/4504594237030400',
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  });
}

// eslint-disable-next-line no-undef
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

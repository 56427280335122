import React from 'react';
import { Placemark } from 'react-yandex-maps';
import icons from '../../../modules/NewOrder/assets/base64/numbers';
import vipIcons from '../../../modules/NewOrder/assets/base64/vip/numbers';
import { getCoords } from './getCoords';

export const addWayPointsPlacemarks = (places: any[], size: number = 50, isVip) =>
  places.map((place, i) => (
    <Placemark
      geometry={getCoords(place) as number[]}
      options={{
        iconLayout: 'default#image',
        iconImageHref: isVip ? vipIcons[i] : icons[i],
        iconImageSize: [size, size],
        iconImageOffset: [-size / 2, -size / 2],
      }}
    />
  ));

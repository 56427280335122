import React, { HTMLAttributes } from 'react';
import { ReactComponent as DropdownSVG } from '../../../assets/svg/dropdown.svg';

interface DropdownIconProps extends HTMLAttributes<HTMLButtonElement> {
  isOpen?: boolean;
}

export const DropdownIcon = ({ isOpen, ...props }: DropdownIconProps): JSX.Element => (
  <button type="button" {...props}>
    <DropdownSVG className={`dropdown-icon ${isOpen ? 'dropdown-icon_open' : ''}`} />
  </button>
);

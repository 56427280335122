import { iNotification, NOTIFICATION_CONTAINER, Store } from 'react-notifications-component';

export const notify = (options: Omit<iNotification, 'container'> & { container?: NOTIFICATION_CONTAINER }): void => {
  Store.addNotification({
    title: 'Success!',
    message: 'Message',
    type: 'success',
    insert: 'top',
    container: 'top-right',
    animationIn: ['animate__animated', 'animate__fadeIn'],
    animationOut: ['animate__animated', 'animate__fadeOut'],
    dismiss: {
      duration: 15000,
      pauseOnHover: true,
    },
    ...options,
  });
};

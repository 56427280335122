import FASpinner from 'components/FASpinner';
import ModalBase from 'components/ModalBase';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import { RuleRegionMap } from 'modules/Policy/map';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';
import { Region } from '.';

interface ZoneDialogProps {
  zone: any;
  visible: boolean;
  onClose: () => void;
  direction: 'from' | 'to';
  index: number;
}

interface Validation {
  submit?: string;
}

const ZoneDialog = ({ zone, visible, onClose, direction, index }: ZoneDialogProps): JSX.Element => {
  const { t } = useNestedTranslation(['policy.current.rules.zones', 'confirmation', 'errors']);
  const { rolesStore } = useStores();
  const { activeGroup } = rolesStore || {};
  const [errors, setErrors] = useState<Validation>({});
  const resetErrors = (): void => setErrors({});
  const [currentZone, setCurrentZone] = useState<Region | undefined>(zone || undefined);
  const [visibleMap, setVisibleMap] = useState<boolean>(false);
  const [disableClose, setDisableClose] = useState<boolean>(false);

  useEffect(() => {
    zone && setCurrentZone(zone);
  }, [zone]);

  const closeMap = (): void => setVisibleMap(false);

  const openMap = (): void => {
    setVisibleMap(true);
    resetErrors();
  };

  const onStopEditing = (coords): void => {
    setCurrentZone((state: Region | undefined) => ({
      ...(state as Region),
      coords,
    }));
  };

  const onCancel = (): void => {
    setCurrentZone(zone);
    onClose();
    resetErrors();
  };

  const onSave = async (): Promise<void> => {
    setDisableClose(true);
    const region: Region = {
      title: currentZone?.title.trim() || '',
      coords: currentZone?.coords || [],
    };
    await rolesStore.updateActiveGroup({
      zones: {
        ...activeGroup.zones,
        [direction]:
          index === -1
            ? [...activeGroup.zones[direction], region]
            : activeGroup.zones[direction].map((r: Region, i: number) => (i === index ? region : r)),
      },
    });
    if (rolesStore.addingError) {
      setErrors({ submit: rolesStore.addingError });
      setDisableClose(false);
      return;
    }
    if (!zone) {
      setCurrentZone(undefined);
    }
    onClose();
    setDisableClose(false);
  };

  return (
    <>
      <ModalBase
        disabledClose={disableClose}
        title={zone ? t('dialog.title_editing') : t('dialog.title_new')}
        onClose={onCancel}
        visible={visible}
      >
        {!!errors?.submit && <p className="error mb-2">{formatError(errors?.submit, t)}</p>}
        <div>
          <span className="form-label">{t('dialog.region_title')}</span>
          <input
            onFocus={resetErrors}
            className={`form-control form-control--lg`}
            onChange={({ target }) =>
              setCurrentZone((state: Region | undefined) => ({
                ...(state as Region),
                title: target.value,
              }))
            }
            value={currentZone?.title}
          />
        </div>
        <div className="mt-5">
          <span className="form-label">{t('dialog.coords')}</span>
          <div onClick={openMap} className={`form-control form-control--lg flex items-center justify-between`}>
            <span className="one-line-text mr-3" style={{ flexShrink: 1 }}>
              {currentZone?.coords ? JSON.stringify(currentZone.coords) : ''}
            </span>
            <div className="opacity-50">
              <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 16 16">
                <path
                  fill="#444444"
                  d="M8 0c-4.4 0-8 3.6-8 8s3.6 8 8 8 8-3.6 8-8-3.6-8-8-8zM13.2 5.3c0.4 0 0.7 0.3 1.1 0.3-0.3 0.4-1.6 0.4-2-0.1 0.3-0.1 0.5-0.2 0.9-0.2zM1 8c0-0.4 0-0.8 0.1-1.3 0.1 0 0.2 0.1 0.3 0.1 0 0 0.1 0.1 0.1 0.2 0 0.3 0.3 0.5 0.5 0.5 0.8 0.1 1.1 0.8 1.8 1 0.2 0.1 0.1 0.3 0 0.5-0.6 0.8-0.1 1.4 0.4 1.9 0.5 0.4 0.5 0.8 0.6 1.4 0 0.7 0.1 1.5 0.4 2.2-2.5-1.2-4.2-3.6-4.2-6.5zM8 15c-0.7 0-1.5-0.1-2.1-0.3-0.1-0.2-0.1-0.4 0-0.6 0.4-0.8 0.8-1.5 1.3-2.2 0.2-0.2 0.4-0.4 0.4-0.7 0-0.2 0.1-0.5 0.2-0.7 0.3-0.5 0.2-0.8-0.2-0.9-0.8-0.2-1.2-0.9-1.8-1.2s-1.2-0.5-1.7-0.2c-0.2 0.1-0.5 0.2-0.5-0.1 0-0.4-0.5-0.7-0.4-1.1-0.1 0-0.2 0-0.3 0.1s-0.2 0.2-0.4 0.1c-0.2-0.2-0.1-0.4-0.1-0.6 0.1-0.2 0.2-0.3 0.4-0.4 0.4-0.1 0.8-0.1 1 0.4 0.3-0.9 0.9-1.4 1.5-1.8 0 0 0.8-0.7 0.9-0.7s0.2 0.2 0.4 0.3c0.2 0 0.3 0 0.3-0.2 0.1-0.5-0.2-1.1-0.6-1.2 0-0.1 0.1-0.1 0.1-0.1 0.3-0.1 0.7-0.3 0.6-0.6 0-0.4-0.4-0.6-0.8-0.6-0.2 0-0.4 0-0.6 0.1-0.4 0.2-0.9 0.4-1.5 0.4 1.1-0.8 2.5-1.2 3.9-1.2 0.3 0 0.5 0 0.8 0-0.6 0.1-1.2 0.3-1.6 0.5 0.6 0.1 0.7 0.4 0.5 0.9-0.1 0.2 0 0.4 0.2 0.5s0.4 0.1 0.5-0.1c0.2-0.3 0.6-0.4 0.9-0.5 0.4-0.1 0.7-0.3 1-0.7 0-0.1 0.1-0.1 0.2-0.2 0.6 0.2 1.2 0.6 1.8 1-0.1 0-0.1 0.1-0.2 0.1-0.2 0.2-0.5 0.3-0.2 0.7 0.1 0.2 0 0.3-0.1 0.4-0.2 0.1-0.3 0-0.4-0.1s-0.1-0.3-0.4-0.3c-0.1 0.2-0.4 0.3-0.4 0.6 0.5 0 0.4 0.4 0.5 0.7-0.6 0.1-0.8 0.4-0.5 0.9 0.1 0.2-0.1 0.3-0.2 0.4-0.4 0.6-0.8 1-0.8 1.7s0.5 1.4 1.3 1.3c0.9-0.1 0.9-0.1 1.2 0.7 0 0.1 0.1 0.2 0.1 0.3 0.1 0.2 0.2 0.4 0.1 0.6-0.3 0.8 0.1 1.4 0.4 2 0.1 0.2 0.2 0.3 0.3 0.4-1.3 1.4-3 2.2-5 2.2z"
                ></path>
              </svg>
            </div>
          </div>
        </div>
        <div className="flex justify-end mt-5 space-x-5">
          <button className="btn btn-light" type="button" disabled={disableClose} onClick={onCancel}>
            {t('dialog.actions.cancel')}
          </button>
          <button
            disabled={
              !(currentZone?.title?.trim() && currentZone?.coords && currentZone?.coords?.length > 0) || disableClose
            }
            className="btn btn-blue"
            type="button"
            onClick={onSave}
          >
            <FASpinner containerClass="mr-2" show={disableClose} />
            {t(zone ? 'dialog.actions.save' : 'dialog.actions.add')}
          </button>
        </div>
      </ModalBase>
      <RuleRegionMap visible={visibleMap} onClose={closeMap} region={currentZone} onStopEditing={onStopEditing} />
    </>
  );
};

export default ZoneDialog;

import { makeObservable, observable, action, runInAction } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';
import { Coordinator } from 'modules/Coordinators';
import CoordinatorsApi from './api';
import { PermissionsEmployee, PermissionsRole, Role } from 'types/types';
import { omit } from 'lodash';

// Simulate a real store
interface CustomStore<T> {
  filter?: any;
  items: T[];
  linkedList?: { [key: string]: T[] | undefined };
  page: number;
  limit: number;
  total: number;
  // Copy the behavior of methods of real stores
  setFilter: (
    useCache: boolean,
    withFilter: any,
    orderBy: string,
    page: number,
    limit: number,
    isDetail: boolean,
    isAppend: boolean,
  ) => Promise<void>;
  fetchList: (
    useCache: boolean,
    withFilter: any,
    orderBy: string,
    page: number,
    limit: number,
    isDetail: boolean,
    isAppend: boolean,
  ) => Promise<void>;
  // Currently only {filter} and {uid} parameters are used, the rest are not used, but they are intended to support behavior similar to a real store.
  fetchLinkedList?: (
    fieldName: any,
    filter: { coordinator_id: number; $nodx_search?: string; role_id?: number },
    fields?: any,
    orderBy?: string,
    uid?: string,
  ) => Promise<void>;
}

export interface EmployeeUpdates {
  employee_id: Required<PermissionsEmployee>['employee_id'];
  manage_employee: 1 | 0;
  coordinator_id: Coordinator['id'];
}

export interface RoleUpdates {
  manage_all: Required<PermissionsRole>['manage_all'];
  role_id: Required<PermissionsRole>['role_id'];
  coordinator_id: Coordinator['id'];
}

export interface EmployeeResponse {
  employee?: PermissionsEmployee;
  role?: PermissionsRole;
  coordinator: Coordinator;
}

export interface RoleResponse {
  role?: PermissionsRole;
  coordinator: Coordinator;
}

export default class CoordinatorsStore extends BaseStore {
  api: CoordinatorsApi;
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Coordinators']);
    this.api = new CoordinatorsApi(rootStore.specifications['Coordinators'].url);
    makeObservable(this);
  }

  @observable
  employeesErrors: { [key in Required<PermissionsEmployee>['employee_id']]: any } = {};
  @observable
  savingEmployees: Required<PermissionsEmployee>['employee_id'][] = [];

  @observable
  rolesErrors: { [key in Required<PermissionsRole>['role_id']]: any } = {};
  @observable
  savingRoles: Required<PermissionsRole>['role_id'][] = [];

  @observable
  editingCoordinator: Coordinator | undefined;

  @observable
  fetchingPermissionsRolesError: any = '';
  @observable
  isFetchingPermissionRoles: boolean = false;
  @observable
  permissionsRolesState: CustomStore<PermissionsRole> = {
    items: [],
    page: 0,
    limit: 10,
    total: 0,
    setFilter: async (
      useCache: boolean,
      withFilter: { coordinator_id: number; $nodx_search?: string },
      orderBy: string,
      page: number,
      limit: number,
      isDetail: boolean,
      isAppend: boolean,
    ): Promise<void> => {
      this.fetchPermissionsRoles({ ...withFilter, page, limit });
    },
    fetchList: async (
      useCache: boolean,
      withFilter: { coordinator_id: number; $nodx_search?: string },
      orderBy: string,
      page: number,
      limit: number,
      isDetail: boolean,
      isAppend: boolean,
    ): Promise<void> => {
      this.fetchPermissionsRoles({ ...withFilter, page, limit }, true);
    },
  };

  @observable
  fetchingPermissionsEmployeesError: any = '';
  @observable
  isFetchingPermissionEmployees: boolean = false;
  @observable
  roleEmployeesState: CustomStore<PermissionsEmployee> = {
    items: [],
    linkedList: {},
    page: 0,
    limit: 10,
    total: 0,
    // These methods should receive the same parameters as the real store methods,
    // this is useful when we pass a store as a prop in any component that can take a store as a parameter,
    // that component can work with that store as if it were a real store
    setFilter: async (
      useCache: boolean,
      withFilter: { coordinator_id: number; $nodx_search?: string; role_id: number },
      orderBy: string,
      page: number,
      limit: number,
      isDetail: boolean,
      isAppend: boolean,
    ): Promise<void> => {
      // fetch employees list with prev filter
      this.fetchPermissionsEmployees({ ...withFilter, page, limit });
    },
    fetchList: async (
      useCache: boolean,
      withFilter: { coordinator_id: number; role_id: number; $nodx_search?: string },
      orderBy: string,
      page: number,
      limit: number,
      isDetail: boolean,
      isAppend: boolean,
    ): Promise<void> => {
      // fetch employees list and reset prev filter
      this.fetchPermissionsEmployees({ ...withFilter, page, limit }, true);
    },
    fetchLinkedList: async (fieldName, filter, fields, orderBy, uid) => {
      // fetch employees list and store them in linked list by {uid} key
      this.fetchPermissionsEmployees({ ...filter, page: 0, limit: 10 }, true, uid);
    },
  };

  @action
  resetEditingCoordinator() {
    runInAction(() => {
      this.editingCoordinator = undefined;
    });
  }

  @action
  setEditingCoordinator(coord: Coordinator) {
    runInAction(() => {
      this.editingCoordinator = coord;
    });
  }

  @action
  async updateEditingCoordinator(updates: Partial<Coordinator>) {
    if (this.editingCoordinator?.update) {
      await this.editingCoordinator.update(updates);
    }
    if (this.addingError) return;
    runInAction(() => {
      this.editingCoordinator = {
        ...this.editingCoordinator,
        ...updates,
      };
    });
  }

  @action
  async fetchPermissionsRoles(
    { page, limit, ...rest }: { coordinator_id: Coordinator['id']; page: number; limit: number },
    reset: boolean = false,
  ): Promise<void> {
    runInAction(() => {
      this.isFetchingPermissionRoles = true;
      this.fetchingPermissionsRolesError = '';
      this.permissionsRolesState = {
        ...this.permissionsRolesState,
        filter: {
          ...(reset ? {} : this.permissionsRolesState?.filter || {}),
          ...(rest || {}),
        },
        items: [],
        limit: limit || 10,
        page: page || 0,
      };
    });
    try {
      const { items, total } = await this.api.fetchPermission({
        ...(this.permissionsRolesState?.filter || {}),
        page: this.permissionsRolesState.page,
        limit: this.permissionsRolesState.limit,
      });
      runInAction(() => {
        this.isFetchingPermissionRoles = false;
        this.permissionsRolesState = {
          ...this.permissionsRolesState,
          items,
          total,
        };
      });
    } catch (err) {
      runInAction(() => {
        this.isFetchingPermissionRoles = false;
        this.fetchingPermissionsRolesError = err;
        this.permissionsRolesState = {
          ...this.permissionsRolesState,
          total: 0,
        };
      });
    }
  }

  @action
  async fetchPermissionsEmployees(
    { limit, page, ...rest }: { coordinator_id: Coordinator['id']; role_id?: Role['id']; page: number; limit: number },
    reset: boolean = false,
    uid?: string,
  ): Promise<void> {
    runInAction(() => {
      this.isFetchingPermissionEmployees = true;
      this.fetchingPermissionsEmployeesError = '';
      this.roleEmployeesState = {
        ...this.roleEmployeesState,
        filter: {
          ...(reset ? {} : this.roleEmployeesState?.filter || {}),
          ...(rest || {}),
        },
        // if {uid} is passed then handle linked list, else handle items
        ...(uid
          ? {
              linkedList: {
                ...this.roleEmployeesState.linkedList,
                [uid]: undefined,
              },
            }
          : { items: [] }),
        limit: limit || 10,
        page: page === 0 ? 0 : page,
      };
    });
    try {
      const { items, total } = await this.api.fetchEmployees({
        ...(this.roleEmployeesState?.filter || {}),
        page: this.roleEmployeesState.page,
        limit: this.roleEmployeesState.limit,
      });
      runInAction(() => {
        this.isFetchingPermissionEmployees = false;
        this.roleEmployeesState = {
          ...this.roleEmployeesState,
          // if {uid} is passed then handle linked list, else handle items and total
          ...(uid
            ? {
                linkedList: {
                  ...this.roleEmployeesState.linkedList,
                  [uid]: items,
                },
              }
            : {
                items,
                total,
              }),
        };
      });
    } catch (err) {
      runInAction(() => {
        this.isFetchingPermissionEmployees = false;
        this.fetchingPermissionsEmployeesError = err;
        this.roleEmployeesState = {
          ...this.roleEmployeesState,
          // if {uid} is passed then do nothing, else set total to {0}
          ...(uid ? {} : { total: 0 }),
        };
      });
    }
  }

  @action
  async updateEmployee(data: EmployeeUpdates, uid?: string): Promise<void> {
    runInAction(() => {
      this.savingEmployees = [...this.savingEmployees, data.employee_id];
      if (this.employeesErrors[data.employee_id]) {
        this.employeesErrors = omit(this.employeesErrors, data.employee_id);
      }
    });
    try {
      const response: EmployeeResponse = await this.api.updateEmployee(data);
      runInAction(() => {
        this.roleEmployeesState = {
          ...this.roleEmployeesState,
          ...(uid
            ? {
                linkedList: {
                  ...this.roleEmployeesState.linkedList,
                  [uid]:
                    this.roleEmployeesState.linkedList &&
                    this.roleEmployeesState.linkedList[uid]?.map((empl) =>
                      response?.employee && empl.employee_id === response.employee.employee_id
                        ? response.employee
                        : empl,
                    ),
                },
              }
            : {
                items: this.roleEmployeesState.items.map((empl) =>
                  response?.employee && empl.employee_id === response.employee.employee_id ? response.employee : empl,
                ),
              }),
        };
        this.permissionsRolesState = {
          ...this.permissionsRolesState,
          items: this.permissionsRolesState.items.map((role) =>
            response?.role && role?.role_id === response.role.role_id ? response.role : role,
          ),
        };
        this.savingEmployees = this.savingEmployees?.filter((emplId) => emplId !== data.manage_employee);
      });
      if (response?.coordinator) {
        this.setEditingCoordinator(response.coordinator);
      }
    } catch (err) {
      runInAction(() => {
        this.savingEmployees = this.savingEmployees?.filter((emplId) => emplId !== data.manage_employee);
        this.employeesErrors = {
          ...(this.employeesErrors || {}),
          [data.employee_id]: err,
        };
      });
    }
  }

  @action
  async updateRole(data: RoleUpdates): Promise<void> {
    runInAction(() => {
      this.savingRoles = [...this.savingRoles, data.role_id];
      if (this.rolesErrors[data.role_id]) {
        this.rolesErrors = omit(this.rolesErrors, data.role_id);
      }
    });
    try {
      const response: RoleResponse = await this.api.updateRole(data);
      // Reload role employees on role change. (if filter has {coordinator_id} and {role_id})
      if (this.roleEmployeesState?.filter?.coordinator_id && this.roleEmployeesState?.filter?.role_id) {
        const empls = await this.fetchPermissionsEmployees({
          ...(this.roleEmployeesState?.filter || {}),
          limit: this.roleEmployeesState?.limit || 10,
          page: this.roleEmployeesState?.page || 0,
        });
      }
      runInAction(() => {
        this.savingRoles = this.savingRoles.filter((roleId) => roleId !== data.role_id);
        this.permissionsRolesState = {
          ...this.permissionsRolesState,
          items: this.permissionsRolesState.items.map((empl) =>
            response.role && empl.role_id === response.role?.role_id ? response.role : empl,
          ),
        };
      });
      if (response?.coordinator) {
        this.setEditingCoordinator(response.coordinator);
      }
    } catch (err) {
      runInAction(() => {
        this.savingRoles = this.savingRoles.filter((roleId) => roleId !== data.role_id);
        this.rolesErrors = {
          ...(this.rolesErrors || {}),
          [data.role_id]: err,
        };
      });
    }
  }
}

/** IMPORTANT!
 * Each car class MUST HAVE its own image
 * if its name is used the path to the image,
 *   * for example:
 *
 *      'assets/images/cars-top/${slassName}.png
 *
 * This classes also are used in the {isCarClassNameKnown} utility function for the same reason
 */

export const ECONOM = 'econom';
export const COMFORT = 'comfort';
export const EXCLUSIVE = 'exclusive';
export const DELIVERY = 'delivery';
export const COMFORTPLUS = 'comfortplus';
export const MINIVAN = 'minivan';
export const CARGO = 'cargo';
export const PREMIUM = 'premium';
export const CHILD = 'child';
export const LUX = 'lux';
export const BUSINESS_MINIVAN = 'business_minivan';

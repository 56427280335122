import React, { useEffect, useMemo, useState } from 'react';
import { useStores } from 'stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { ReactComponent as LogoutSvg } from '../../svg/logout.svg';
import Settings from './settings';
import Info from './info';
import { ProfileDropdown } from 'components/Profile/ProfileDropdown';
import { ProfileCard } from 'components/Profile/ProfileCard';
import { isEmpty } from 'lodash';
import { ProfileRole } from 'types/types';

export const AppHeader: React.FC<{}> = observer(() => {
  const { usersStore, ordersStore } = useStores();
  const [isModalShowed, setIsModalShowed] = useState<boolean>(false);
  const [visibleMenu, setVisibleMenu] = useState<boolean>();
  const [visibleSettings, setVisibleSettings] = useState<boolean>(false);
  const [visibleInfo, setVisibleInfo] = useState<boolean>(false);
  const { t } = useTranslation('nav');
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (visibleMenu && !isModalShowed) setIsModalShowed(true);
    document.body.style.overflow = visibleMenu ? 'hidden' : 'unset';
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [visibleMenu, isModalShowed]);

  function logoutHandler(): void {
    localStorage.clear();
    document.location.href = '/';
  }

  const navigate = (e: any, path: string): void => {
    e.preventDefault();
    history.push(path);
    closeMenu();
    if (visibleSettings) {
      closeSettings();
    }
    if (visibleInfo) {
      closeInfo();
    }
  };

  const onNewOrder = (e: any): void => {
    navigate(e, '/customer/new-order');
    ordersStore?.resetNewOrderInfo();
    if (ordersStore?.estimateInfo) {
      ordersStore.resetEstimateInfo();
    }
  };

  const closeMenu = (): void => {
    setVisibleMenu(false);
  };

  const toggleMenu = (): void => {
    setVisibleMenu(!visibleMenu);
  };

  const onLogoPress = (e: any): void => {
    e.stopPropagation();
    navigate(e, '/customer/');
    window.scrollTo({ top: 0, behavior: 'smooth' });
    if (visibleSettings) {
      closeSettings();
    }
    if (visibleInfo) {
      closeInfo();
    }
  };

  const closeSettings = (): void => {
    setVisibleSettings(false);
  };

  const closeInfo = (): void => {
    setVisibleInfo(false);
  };

  const openSettings = (): void => {
    setVisibleSettings(true);
    closeMenu();
  };
  const openInfo = (): void => {
    setVisibleInfo(true);
    closeMenu();
  };

  return (
    <nav className="bg-white shadow-thin fixed left-0 top-0 w-full z-40">
      <div className="container">
        <div className="flex items-center relative h-[52px] lg:h-[68px]">
          <button className="xl:hidden text-black" type="button" onClick={toggleMenu}>
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
            </svg>
          </button>
          <div className="flex justify-center items-center w-full h-full">
            <ProfileDropdown>
              <ProfileCard onClick={onLogoPress} profile={usersStore?.currentProfile || {}} />
            </ProfileDropdown>
            <div className="flex-grow item-center flex justify-center h-full hidden xl:block pr-4 pl-4 flex-shrink-0">
              {usersStore?.isCustomer ? (
                <ul className="nav-list" style={{}}>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/analytics')}
                      className={`nav-link ${location.pathname === '/customer/analytics' ? 'active' : ''}`}
                      href="/customer/analytics"
                    >
                      {t('analytics')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/employees')}
                      className={`nav-link ${location.pathname === '/customer/employees' ? 'active' : ''}`}
                      href="/customer/employees"
                    >
                      {t('employees')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/favourites')}
                      className={`nav-link ${location.pathname === '/customer/favourites' ? 'active' : ''}`}
                      href="/customer/favourites"
                    >
                      {t('favourites')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/coordinators')}
                      className={`nav-link ${location.pathname === '/customer/coordinators' ? 'active' : ''}`}
                      href="/customer/coordinators"
                    >
                      {t('coordinators')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/rides')}
                      className={`nav-link ${location.pathname === '/customer/rides' ? 'active' : ''}`}
                      href="/customer/rides"
                    >
                      {t('rides')}
                    </a>
                  </li>
                  {usersStore?.me?.detail?.is_promo_available || usersStore?.isPromo ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/promocodes')}
                        className={`nav-link ${location.pathname === '/customer/promocodes' ? 'active' : ''}`}
                        href="/customer/promocodes"
                      >
                        {t('promocodes')}
                      </a>
                    </li>
                  ) : null}
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/policy')}
                      className={`nav-link ${location.pathname === '/customer/policy' ? 'active' : ''}`}
                      href="/customer/policy"
                    >
                      {t('rides_policy')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/reports')}
                      className={`nav-link ${location.pathname === '/customer/reports' ? 'active' : ''}`}
                      href="/customer/reports"
                    >
                      {t('reports')}
                    </a>
                  </li>
                </ul>
              ) : null}
              {usersStore?.isCoordinator ? (
                <ul className="nav-list">
                  {usersStore?.me?.detail?.permissions?.view_analytics ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/analytics')}
                        className={`nav-link ${location.pathname === '/customer/analytics' ? 'active' : ''}`}
                        href="/customer/analytics"
                      >
                        {t('analytics')}
                      </a>
                    </li>
                  ) : null}

                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/employees')}
                      className={`nav-link ${location.pathname === '/customer/employees' ? 'active' : ''}`}
                      href="/customer/employees"
                    >
                      {t('employees')}
                    </a>
                  </li>
                  {usersStore?.me?.detail?.permissions?.create_coordinators ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/coordinators')}
                        className={`nav-link ${location.pathname === '/customer/coordinators' ? 'active' : ''}`}
                        href="/customer/coordinators"
                      >
                        {t('coordinators')}
                      </a>
                    </li>
                  ) : null}
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/favourites')}
                      className={`nav-link ${location.pathname === '/customer/favourites' ? 'active' : ''}`}
                      href="/customer/favourites"
                    >
                      {t('favourites')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/rides')}
                      className={`nav-link ${location.pathname === '/customer/rides' ? 'active' : ''}`}
                      href="/customer/rides"
                    >
                      {t('rides')}
                    </a>
                  </li>
                  {usersStore?.me?.detail?.permissions?.create_policy_groups ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/policy')}
                        className={`nav-link ${location.pathname === '/customer/policy' ? 'active' : ''}`}
                        href="/customer/policy"
                      >
                        {t('rides_policy')}
                      </a>
                    </li>
                  ) : null}
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/reports')}
                      className={`nav-link ${location.pathname === '/customer/reports' ? 'active' : ''}`}
                      href="/customer/reports"
                    >
                      {t('reports')}
                    </a>
                  </li>
                  {usersStore?.me?.detail?.permissions?.manage_promocodes || usersStore?.isPromo ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/promocodes')}
                        className={`nav-link ${location.pathname === '/customer/promocodes' ? 'active' : ''}`}
                        href="/customer/promocodes"
                      >
                        {t('promocodes')}
                      </a>
                    </li>
                  ) : null}
                </ul>
              ) : null}
              {usersStore?.isOperator ? (
                <ul className="nav-list">
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/rides')}
                      className={`nav-link ${location.pathname === '/customer/rides' ? 'active' : ''}`}
                      href="/customer/rides"
                    >
                      {t('rides')}
                    </a>
                  </li>
                </ul>
              ) : null}
              {usersStore?.isEmployee ? (
                <ul className="nav-list">
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/rides')}
                      className={`nav-link ${location.pathname === '/customer/rides' ? 'active' : ''}`}
                      href="/customer/rides"
                    >
                      {t('rides')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/favourites')}
                      className={`nav-link ${location.pathname === '/customer/favourites' ? 'active' : ''}`}
                      href="/customer/favourites"
                    >
                      {t('favourites')}
                    </a>
                  </li>
                  {usersStore?.isPromo ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/promocodes')}
                        className={`nav-link ${location.pathname === '/customer/promocodes' ? 'active' : ''}`}
                        href="/customer/promocodes"
                      >
                        {t('promocodes')}
                      </a>
                    </li>
                  ) : null}
                </ul>
              ) : null}
            </div>
            <div className="flex items-center hidden xl:flex space-x-5 flex-shrink-0">
              {usersStore?.me?.role?.name && !usersStore?.isOperator && (
                <button className="btn btn-light btn-icon" onClick={openInfo}>
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    strokeWidth="1.3"
                    stroke="currentColor"
                    viewBox="0 0 50 50"
                    width="30px"
                    height="30px"
                  >
                    <path d="M 25 8 C 15.611 8 8 15.611 8 25 C 8 34.389 15.611 42 25 42 C 34.389 42 42 34.389 42 25 C 42 15.611 34.389 8 25 8 z M 25 9 C 33.837 9 41 16.163 41 25 C 41 33.837 33.837 41 25 41 C 16.163 41 9 33.837 9 25 C 9 16.163 16.163 9 25 9 z M 25 17 C 24.448 17 24 17.448 24 18 C 24 18.552 24.448 19 25 19 C 25.552 19 26 18.552 26 18 C 26 17.448 25.552 17 25 17 z M 25 22 C 24.724 22 24.5 22.224 24.5 22.5 L 24.5 34.5 C 24.5 34.776 24.724 35 25 35 C 25.276 35 25.5 34.776 25.5 34.5 L 25.5 22.5 C 25.5 22.224 25.276 22 25 22 z" />
                  </svg>
                </button>
              )}
              <button className="btn btn-light btn-icon" onClick={openSettings}>
                <svg
                  className="w-6 h-6"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="1.3"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                >
                  <path d="M12 15a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
                  <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 1 1-2.83 2.83l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-4 0v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 1 1-2.83-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 1 1 0-4h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 1 1 2.83-2.83l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 1 1 4 0v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 1 1 2.83 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 0 4h-.09a1.65 1.65 0 0 0-1.51 1v0Z"></path>
                </svg>
              </button>
              <button className="btn btn-light btn-icon" onClick={logoutHandler}>
                <LogoutSvg width={22} height={22} style={{ opacity: 0.5 }} />
              </button>
              <button className="btn btn-yellow w-36 font-medium" onClick={onNewOrder} type="button">
                {t('new_order')}
              </button>
            </div>
          </div>
          <div
            className={`navbar-backdrop ${visibleMenu ? 'show' : isModalShowed ? 'hide' : 'hidden'} xl:hidden`}
            onClick={closeMenu}
          ></div>
          <div
            className={`navbar-collapse flex ${visibleMenu ? 'slide-in' : isModalShowed ? 'slide-out' : 'hidden'} xl:hidden`}
          >
            <div className="shadow-thin flex items-center relative h-[52px] lg:h-[68px] px-4 flex-shrink-0">
              <button className="mr-4 text-black" type="button" onClick={closeMenu}>
                <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
                </svg>
              </button>
              <span className="text-xl font-medium tracking-tight text-black">SwiftDrive</span>
            </div>
            <div className="px-4 pt-2 pb-8 flex-grow flex flex-col lg:min-h-[calc(100%-68px)] settings">
              {usersStore?.isCustomer ? (
                <ul className="nav-list mb-4">
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/analytics')}
                      className={`nav-link ${location.pathname === '/customer/analytics' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('analytics')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/employees')}
                      className={`nav-link ${location.pathname === '/customer/employees' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('employees')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/favourites')}
                      className={`nav-link ${location.pathname === '/customer/favourites' ? 'active' : ''}`}
                      href="/customer/favourites"
                    >
                      {t('favourites')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/coordinators')}
                      className={`nav-link ${location.pathname === '/customer/coordinators' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('coordinators')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/rides')}
                      className={`nav-link ${location.pathname === '/customer/rides' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('rides')}
                    </a>
                  </li>
                  {usersStore?.me?.detail?.is_promo_available || usersStore?.isPromo ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/promocodes')}
                        className={`nav-link ${location.pathname === '/customer/promocodes' ? 'active' : ''}`}
                        href="/customer/promocodes"
                      >
                        {t('promocodes')}
                      </a>
                    </li>
                  ) : null}
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/policy')}
                      className={`nav-link ${location.pathname === '/customer/policy' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('rides_policy')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/reports')}
                      className={`nav-link ${location.pathname === '/customer/reports' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('reports')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={openSettings} href="#">
                      {t('settings.title')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={logoutHandler} href="#">
                      {t('exit')}
                    </a>
                  </li>
                </ul>
              ) : null}
              {usersStore?.isCoordinator ? (
                <ul className="nav-list mb-4">
                  {usersStore?.me?.detail?.permissions?.view_analytics ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/analytics')}
                        className={`nav-link ${location.pathname === '/customer/analytics' ? 'active' : ''}`}
                        href="#"
                      >
                        {t('analytics')}
                      </a>
                    </li>
                  ) : null}
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/employees')}
                      className={`nav-link ${location.pathname === '/customer/employees' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('employees')}
                    </a>
                  </li>
                  {usersStore?.me?.detail?.permissions?.create_coordinators ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/coordinators')}
                        className={`nav-link ${location.pathname === '/customer/coordinators' ? 'active' : ''}`}
                        href="#"
                      >
                        {t('coordinators')}
                      </a>
                    </li>
                  ) : null}
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/favourites')}
                      className={`nav-link ${location.pathname === '/customer/favourites' ? 'active' : ''}`}
                      href="/customer/favourites"
                    >
                      {t('favourites')}
                    </a>
                  </li>
                  {usersStore?.me?.detail?.permissions?.create_policy_groups ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/policy')}
                        className={`nav-link ${location.pathname === '/customer/policy' ? 'active' : ''}`}
                        href="#"
                      >
                        {t('rides_policy')}
                      </a>
                    </li>
                  ) : null}
                  {usersStore?.me?.detail?.permissions?.manage_promocodes || usersStore?.isPromo ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/promocodes')}
                        className={`nav-link ${location.pathname === '/customer/promocodes' ? 'active' : ''}`}
                        href="#"
                      >
                        {t('promocodes')}
                      </a>
                    </li>
                  ) : null}
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/rides')}
                      className={`nav-link ${location.pathname === '/customer/rides' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('rides')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/reports')}
                      className={`nav-link ${location.pathname === '/customer/reports' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('reports')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={openSettings} href="#">
                      {t('settings.title')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={logoutHandler} href="#">
                      {t('exit')}
                    </a>
                  </li>
                </ul>
              ) : null}
              {usersStore?.isOperator ? (
                <ul className="nav-list mb-4">
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/rides')}
                      className={`nav-link ${location.pathname === '/customer/rides' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('rides')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={openSettings} href="#">
                      {t('settings.title')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={logoutHandler} href="#">
                      {t('exit')}
                    </a>
                  </li>
                </ul>
              ) : null}
              {usersStore?.isEmployee ? (
                <ul className="nav-list mb-4">
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/rides')}
                      className={`nav-link ${location.pathname === '/customer/rides' ? 'active' : ''}`}
                      href="#"
                    >
                      {t('rides')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      onClick={(e) => navigate(e, '/customer/favourites')}
                      className={`nav-link ${location.pathname === '/customer/favourites' ? 'active' : ''}`}
                      href="/customer/favourites"
                    >
                      {t('favourites')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={openSettings} href="#">
                      {t('settings.title')}
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className="nav-link" onClick={logoutHandler} href="#">
                      {t('exit')}
                    </a>
                  </li>
                  {usersStore?.isPromo ? (
                    <li className="nav-item">
                      <a
                        onClick={(e) => navigate(e, '/customer/promocodes')}
                        className={`nav-link ${location.pathname === '/customer/promocodes' ? 'active' : ''}`}
                        href="/customer/promocodes"
                      >
                        {t('promocodes')}
                      </a>
                    </li>
                  ) : null}
                </ul>
              ) : null}
              <div
                className={
                  // @ts-ignore
                  /iPhone|iPod|iPad/.test(navigator?.userAgentData?.platform) ? 'mb-10' : ''
                }
              >
                <button className="btn btn-yellow w-full font-medium" onClick={onNewOrder} type="button">
                  {t('new_order')}
                </button>
              </div>
            </div>
          </div>
        </div>
        <Settings visible={visibleSettings} onClose={closeSettings} />
        <Info visible={visibleInfo} onClose={closeInfo} />
      </div>
    </nav>
  );
});

/*
    <Header as="div">
      <div className={"title"}>{t("title")}</div>
      {authStore.isAuthenticated ? (
        <div className={"logout"}>
          <Button
            onClick={logoutHandler}
            className={"logout-button"}
          >
            {t("login.logout")}
          </Button>
        </div>
      ) : null}
    </Header>
    */

export default AppHeader;

import { isArray } from 'lodash';
import moment from 'moment';

type FormatOptions = {
  format?: string;
  separotor?: string;
  offset?: number;
};

export const formatDateValue = (value: Date | Date[] | number, options: FormatOptions = {}): string => {
  if (typeof value === 'string') {
    return value;
  }
  const opt = {
    format: 'DD.MM.YY',
    separotor: ' - ',
    ...options,
  };
  if (!value) return '';
  if (value instanceof Date) {
    return moment(value).format(opt.format) || '';
  } else if (typeof value === 'number') {
    return moment.utc(((value || 0) + (opt.offset || 0) * 60 * 60) * 1000).format(opt.format) || '';
  } else if (isArray(value)) {
    const start = value[0] ? moment(value[0]).format(opt.format) : '';
    const end = value[1] ? moment(value[1]).format(opt.format) : '';
    return `${start}${start && end ? opt.separotor : ''}${end}`;
  }
  return new Date(value)?.toString();
};

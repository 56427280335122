import BaseCRUD from 'stores/BaseCRUD';
import WebApiClient from '../WebApi';

export class EmployeesApi extends BaseCRUD {
  async resendSMS(id: number | string) {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/${id}/resend-sms`,
    });
  }
}

export default EmployeesApi;

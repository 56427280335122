import React, { useEffect, useState } from 'react';
import GroupItem from './groupItem';
import { useStores } from 'stores';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Pagination } from 'components/Pagination';
import { useWindowSize } from 'hooks/useWindowSize';
import { GROUPS_LIST_LIMIT_PER_RAGE } from 'constants/policy';
import { SearchBar } from 'components/SearchBar';

interface GroupsProps {
  showGroup?: boolean;
  onHide?: any;
}

const Groups = observer(({ showGroup, onHide }: GroupsProps): JSX.Element => {
  const { usersStore, rolesStore } = useStores();
  const { t } = useTranslation('policy');
  const [deleted, setDeleted] = useState<string[]>([]);
  const windowSize = useWindowSize();

  const onDelete = (group: any): void => {
    if (rolesStore.deletingError) return;
    if (group?.id === rolesStore.activeGroup?.id) {
      rolesStore.setActiveGroup(null);
    }
    setDeleted([...deleted, group?.id]);
    // update groups to show correct count of users
    rolesStore.fetchList(false, {}, 'id desc', rolesStore?.page || 0, GROUPS_LIST_LIMIT_PER_RAGE, true, false);
    // onHide();
  };
  const onEdit = (group: any): void => {
    rolesStore.setActiveGroup(group);
  };
  const onNewGroup = (): void => {
    rolesStore.setActiveGroup({ id: 0 });
    rolesStore.resetUsers();
  };

  const hasBottomDivider = (index: number): boolean => {
    const activeIndex = rolesStore.list?.findIndex((grp: any) => grp.id === rolesStore.activeGroup?.id);
    return !(index === activeIndex || index + 1 === activeIndex);
  };

  const renderGroup = (grp: any, index: number): React.ReactElement => (
    <GroupItem
      key={grp?.id}
      bottomDivider={hasBottomDivider(index)}
      group={grp}
      onDelete={onDelete}
      onEdit={onEdit}
      isActive={rolesStore.activeGroup?.id === grp.id}
    />
  );
  return (
    <div
      className={`area mb-0 shadow-thin ${showGroup ? 'hidden' : ''} lg:flex flex-col top-0 left-0 flex-grow flex-shrink-0 lg:w-[380px] xl:w-[418px] lg:shadow-area`}
      style={(windowSize?.width || 0) > 991 ? { height: window.innerHeight - 108, top: 88, position: 'sticky' } : {}}
    >
      <div className="area-inner pt-5 pb-0">
        <div className="flex items-center mb-4 md:mb-5">
          <h3 className="text-2lg leading-6 font-medium text-black lg:hidden">{t('list.title_new')}</h3>
          <h3 className="text-2lg leading-6 font-medium text-black hidden lg:block">{t('list.title')}</h3>
          {usersStore?.isCustomer || usersStore?.me?.detail?.permissions?.create_policy_groups ? (
            <button
              className="btn btn-light ml-auto px-1.5 w-10 space-x-1.5 md:px-4 md:w-auto"
              type="button"
              onClick={onNewGroup}
            >
              <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                <path d="M10 2A7.953 7.953 0 0 0 4.34 4.342c-3.12 3.12-3.122 8.195-.001 11.316A7.951 7.951 0 0 0 9.998 18c2.14 0 4.15-.832 5.661-2.344 3.121-3.118 3.121-8.193 0-11.314A7.956 7.956 0 0 0 10 2Zm0 1.359c1.776 0 3.445.69 4.698 1.944a6.648 6.648 0 0 1 0 9.393 6.605 6.605 0 0 1-4.7 1.945 6.6 6.6 0 0 1-4.697-1.944 6.649 6.649 0 0 1 .001-9.394A6.604 6.604 0 0 1 10 3.359Zm0 3.287a.68.68 0 0 0-.68.68V9.32H7.324a.68.68 0 1 0 0 1.358H9.32v1.996a.68.68 0 1 0 1.36 0v-1.996h1.996a.68.68 0 1 0 0-1.358H10.68V7.325a.68.68 0 0 0-.68-.68Z"></path>
              </svg>
              <span className="hidden md:inline">{t('list.actions.new')}</span>
            </button>
          ) : null}
        </div>
        <SearchBar containerClass="mb-5" store={rolesStore} placeholder={t('search_placeholder')} />
        {rolesStore.isFetchingListInProgress && <div className="mb-5">{t('loading')}</div>}
      </div>

      {rolesStore.isFetchingDefaultGroupCompleted && !rolesStore.defaultGroup && usersStore.role !== 'coordinator' && (
        <div className="mb-2 px-5 error">{t('no_default')}</div>
      )}
      {!rolesStore.isFetchingListInProgress ? (
        <ul className="list-none m-0 p-0 mb-5 flex flex-col flex-grow" style={{ overflowY: 'scroll' }}>
          {rolesStore.list?.filter((r) => deleted.indexOf(r.id) < 0).map(renderGroup)}
        </ul>
      ) : (
        <div className="flex flex-grow"></div>
      )}
      {rolesStore?.list?.length > 0 && !rolesStore.isFetchingListInProgress && (
        <div className="px-4 mb-5">
          <Pagination store={rolesStore} hideItemsLength compact />
        </div>
      )}
    </div>
  );
});

export default Groups;

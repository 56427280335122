import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';
import { notify } from 'utils/lib/notify';

interface RulesModalProps {
  visible?: boolean;
  modalStyle?: any;
  store: any;
  onClose?: any;
}

interface Rule {
  id: string;
  name?: string;
  description?: string;
}

const defaultStyle = {
  position: 'fixed',
  height: 0,
  overflow: 'hidden',
  borderWidth: 0,
  zIndex: 50,
};

const daySchedule = {
  since: {
    hour: 0,
    mins: 1,
  },
  until: {
    hour: 23,
    mins: 59,
  },
  disabled: false,
};

const defaultSchedule = {
  mon: daySchedule,
  tue: daySchedule,
  wed: daySchedule,
  thu: daySchedule,
  fri: daySchedule,
  sat: daySchedule,
  sun: daySchedule,
};
const RulesModal = ({ visible, modalStyle, onClose = () => {} }: RulesModalProps) => {
  const { rolesStore } = useStores();
  const { t } = useTranslation(['policy', 'errors']);
  const [styles, setStyles] = useState<any>(defaultStyle);
  const modalRef = useRef<HTMLDivElement>(null);
  let modalStyles = { ...modalStyle };
  const rules: Rule[] = [
    //{id: "class", name: 'Класс', description: 'Выберите классы, доступные этой группе'},

    { id: 'schedule', name: t('rule_dialog.schedule.title'), description: t('rule_dialog.schedule.text') },
    //    {id: "address", name: 'Адреса', description: 'Укажите, откуда и&nbsp;куда можно ездить членам группы'},
    { id: 'zones', name: t('rule_dialog.zones.title'), description: t('rule_dialog.zones.text') },
    {
      id: 'month_amount_group',
      name: t('rule_dialog.month_amount_group.title'),
      description: t('rule_dialog.month_amount_group.title'),
    },

    { id: 'distance', name: t('rule_dialog.distance.title'), description: t('rule_dialog.distance.text') },
    { id: 'radius', name: t('rule_dialog.radius.title'), description: t('rule_dialog.radius.text') },
    // {id: "month_amount", name: t('rule_dialog.month_amount.title'), description: t('rule_dialog.month_amount.title')}
  ];

  useEffect(() => {
    if (visible) {
      setStyles((prevState: any) => ({
        ...prevState,
        height: 'auto',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      }));
    } else {
      setStyles(defaultStyle);
    }
  }, [visible]);

  if (modalRef.current) {
    const height = modalRef.current.scrollHeight;
    const top = modalStyle?.top || 0;
    if (height > window.innerHeight) {
      modalStyle.top = 10;
    } else if (height + top > window.innerHeight) {
      modalStyle.top =
        top - (height + top - window.innerHeight - 10) >= 10 ? top - (height + top - window.innerHeight) - 10 : 10;
    }
  }

  const addRule = async (rule: any): Promise<void> => {
    let ruleName: string = '';
    if (rule.id === 'distance') {
      await rolesStore.updateActiveGroup({ distance: 10 });
      ruleName = 'distance';
    }
    // if (rule.id === "radiuses") {
    //     await rolesStore.updateActiveGroup({ radiuses: [{
    //         title: "",
    //         radius: 4,
    //         coords: []
    //     }] });
    //     ruleName = 'radiuses';
    // }
    if (rule.id === 'radius') {
      await rolesStore.updateActiveGroup({ radius: 10 });
      ruleName = 'radius';
    }
    // if (rule.id === "month_amount") {
    //     await rolesStore.updateActiveGroup({ month_amount: 1000 });
    //     ruleName = 'amount';
    // }
    if (rule.id === 'schedule') {
      await rolesStore.updateActiveGroup({ schedule: defaultSchedule });
      ruleName = 'schedule';
    }
    if (rule.id === 'zones') {
      await rolesStore.updateActiveGroup({ zones: { from: [], to: [] } });
      ruleName = 'zones';
    }
    if (rule.id === 'month_amount_group') {
      await rolesStore.updateActiveGroup({ month_amount_group: 10000 });
      ruleName = 'amount_group';
    }
    if (rolesStore?.addingError) {
      notify({
        type: 'danger',
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedRuleAdding', { ns: 'errors' })} \"${rule.name || ''}\": ${formatError(rolesStore?.addingError, t)}`,
      });
    }
  };

  const renderRule = (rule: Rule, index: number, compact: boolean = false): React.ReactElement => {
    const elId = `rules-option-${compact ? '' : 'd-'}${index}`;
    return (
      <li
        key={rule.id}
        className={`${compact ? '-mx-4' : ''} custom-control custom-control-radio`}
        onMouseDown={() => {
          addRule(rule);
          onClose();
        }}
      >
        <input className="custom-control-input" type="radio" id={elId} name="rules" />
        <label className="custom-control-label" htmlFor={elId}>
          {compact && <span className="custom-control-box custom-control-box-radio"></span>}
          <div className="text-black font-medium mb-1">{rule.name}</div>
          <div>{rule.description}</div>
        </label>
      </li>
    );
  };
  return (
    <div style={styles}>
      <div
        aria-labelledby="modal-title"
        className="lg:hidden"
        aria-modal="true" /*x-cloak x-show="isOpen" /*@keydown.window.escape="isOpen = false; document.body.classList.remove('overflow-hidden')"*/
      >
        <div
          className="modal-backdrop"
          onClick={onClose}
          aria-hidden="true" /*x-show="isOpen" x-transition:enter="ease-out duration-300" x-transition:enter-start="opacity-0" x-transition:enter-end="opacity-100" x-transition:leave="ease-in duration-200" x-transition:leave-start="opacity-100" x-transition:leave-end="opacity-0"*/
        ></div>
        <div
          className="modal panel" /*x-show="isOpen" x-transition:enter="ease-out duration-300" x-transition:enter-start="opacity-0 translate-y-8" x-transition:enter-end="opacity-100 translate-y-0" x-transition:leave="ease-in duration-200" x-transition:leave-start="opacity-100 translate-y-0" x-transition:leave-end="opacity-0 translate-y-8"*/
        >
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h4 className="text-2lg leading-6 font-medium text-black" id="modal-title">
                  {t('rule_dialog.title')}
                </h4>
                <button
                  className="btn-close"
                  type="button"
                  aria-label="Закрыть"
                  onClick={onClose} /*@click="isOpen = false; document.body.classList.remove('overflow-hidden')"*/
                >
                  <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                    <path d="M16.28 7.72a.75.75 0 0 0-1.06 0L12 10.94 8.78 7.72a.75.75 0 1 0-1.06 1.06L10.94 12l-3.22 3.22a.75.75 0 1 0 1.06 1.06L12 13.06l3.22 3.22a.75.75 0 0 0 1.06-1.06L13.06 12l3.22-3.22a.75.75 0 0 0 0-1.06Z"></path>
                    <path d="M15.75 1.5h-7.5A6.75 6.75 0 0 0 1.5 8.25v7.5a6.75 6.75 0 0 0 6.75 6.75h7.5a6.75 6.75 0 0 0 6.75-6.75v-7.5a6.75 6.75 0 0 0-6.75-6.75ZM21 15.75A5.25 5.25 0 0 1 15.75 21h-7.5A5.25 5.25 0 0 1 3 15.75v-7.5A5.25 5.25 0 0 1 8.25 3h7.5A5.25 5.25 0 0 1 21 8.25v7.5Z"></path>
                  </svg>
                </button>
              </div>
              <div className="modal-body">
                <div className="-mt-4 mb-1 md:mb-3">
                  <ul className="list-none m-0 p-0 divide-y divide-graystroke">
                    {rules.map((r, i) => renderRule(r, i, true))}
                  </ul>
                </div>
                <div className="flex justify-end space-x-5 md:pt-4">
                  <button className="btn btn-blue w-full" type="submit">
                    {t('rule_dialog.actions.add')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal hidden lg:block modal-inline" /*x-show="isOpen" x-transition:enter="ease-out duration-300" x-transition:enter-start="opacity-0 translate-y-2" x-transition:enter-end="opacity-100 translate-y-0" x-transition:leave="ease-in duration-200" x-transition:leave-start="opacity-100 translate-y-0" x-transition:leave-end="opacity-0 translate-y-2"*/
      >
        <div
          className="modal-content"
          style={modalStyles}
          ref={modalRef} /*x-ref="addRule" @click.away="isOpen = false"*/
        >
          <ul className="list-none m-0 p-0 divide-y divide-graystroke">{rules.map((r, i) => renderRule(r, i))}</ul>
        </div>
      </div>
    </div>
  );
};

export default RulesModal;

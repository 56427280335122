import { Pagination } from 'components/Pagination';
import { useWindowSize } from 'hooks/useWindowSize';
import { observer } from 'mobx-react-lite';
import { useHistory } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import Filter from './filter/index';
import TableHeader from './tableHeader';
import TableRow from './tableRow';

const Rides = observer((): JSX.Element => {
  const { ordersStore, rolesCostCentersStore, usersStore } = useStores();
  const history = useHistory();
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const { t } = useTranslation('rides');
  const isFilterInitialized = useRef<boolean>(false);
  const windowSize = useWindowSize();

  const [filter, setFilter] = useState<object>({});
  const loadData = (): Promise<void> => {
    return ordersStore.fetchList(false, filter, 'id desc', ordersStore?.page, ordersStore?.limit, true, false);
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(history.location.search);
    const cuser_id: string | null = urlParams.get('cuser_id');
    const employee_id: string | null = urlParams.get('employee_id');
    if (cuser_id && employee_id) {
      isFilterInitialized.current = true;
      setFilter({
        $nodx_or: {
          cuser_id,
          employee_id,
        },
        employee_id,
      });
    } else {
      loadData().then(() => setIsDataReady(true));
    }
    rolesCostCentersStore?.resetLists();
    ordersStore.startReloadingList();

    return () => {
      ordersStore.stopReloadingList();
    };
  }, []);

  useEffect(() => {
    setFilter(ordersStore?.filter);
  }, [ordersStore?.filter]);

  useEffect(() => {
    if (!isFilterInitialized.current) {
      isFilterInitialized.current = true;
      return;
    }
    if (!isDataReady && !filter['$nodx_or']) {
      return;
    }
    loadData().then(() => {
      if (!isDataReady) {
        setIsDataReady(true);
      }
    });
  }, [filter]);

  const getListHeight = (): number | string => {
    if (!windowSize?.height) return 'auto';
    return windowSize.height - 340 - ((windowSize.width || 0) >= 992 ? 0 : 24);
  };

  return (
    <main className="flex-grow flex flex-col">
      <section className="pt-6 lg:pt-7.5">
        <div className="container">
          <div className="mb-6 lg:hidden">
            <h2 className="text-2lg leading-6 font-medium text-black">{t('title')}</h2>
          </div>
          <div className="mb-6 lg:mb-5 flex table-bar">
            {/*
                        <SearchBar store={ordersStore}/>
                        */}
            <Filter filter={filter} />
          </div>
          {ordersStore.isFetchingNewPage || !isDataReady ? (
            <span>{t('loading')}</span>
          ) : (
            <div className="area mb-5 area__relative">
              <div
                className="loader-container table_loader"
                style={{ opacity: Number(ordersStore?.isFetchingFilteredData) }}
              >
                <div className="loader">
                  <div className="loader-line" />
                </div>
              </div>
              <div className="overflow-x-auto">
                <div className={`data data-rides w-[${usersStore?.isOperator ? 70.5 : 86.25}rem]`}>
                  <TableHeader />
                  <ul style={{ maxHeight: getListHeight() }} className="data-body overflow-y-scroll">
                    {ordersStore?.list?.map((ride: any, index: number) => (
                      <TableRow key={ride?.id || index} ride={ride} />
                    ))}
                  </ul>
                </div>
              </div>
              <div className="area-inner">
                <Pagination store={ordersStore} />
              </div>
            </div>
          )}
        </div>
      </section>
    </main>
  );
});

export default Rides;

import { observable, action, runInAction, computed } from 'mobx';
import { ExportResponse, NumberOrString, ReportData } from 'types/types';
import { loadFile } from 'utils/lib/fileHandlers/loadFile';
import { notify } from 'utils/lib/notify';
import { RootStoreInterface } from '../interfaces';
import BaseCRUD from './BaseCRUD';
import { getErrorMessage } from './WebApi';
import * as Sentry from '@sentry/react';
import Storage from 'utils/storage';

export interface StoreInterface {
  rootStore: RootStoreInterface;
  apiClient?: BaseCRUD;
}

export interface SpecificationInterface {
  url: string;
  schema: string;
  moduleName: string;
  fields: Array<any>;
  columns: {
    add: Array<any>;
    list: Array<any>;
  };
  statuses?: any;
}

export interface AuthResponse {
  result: string;
  error: string;
}

class BaseStore implements StoreInterface {
  rootStore: RootStoreInterface;

  apiClient: BaseCRUD;

  specification: SpecificationInterface;

  total?: number = 0;
  filter?: any = {};

  filterTimer: number = 0;

  orderBy?: string = '';
  page?: number = 0;
  limit?: number = 10;
  isDetail?: boolean = false;
  editingRecord?: any;

  @observable exportData: ReportData | undefined = undefined;
  checkExportIntervalId: any;
  isCheckingExportInProggress: boolean = false;

  @observable list?: any;
  @observable isFetchingListInProgress = false;
  @observable fetchingListError: string | undefined;

  @observable record?: any;
  @observable isFetchingRecordInProgress = false;
  @observable fetchingRecordError: string | undefined;

  @observable isAddingInProgress: boolean = false;
  @observable addingError: string | undefined;

  @observable isDeletingInProgress: boolean = false;
  @observable deletingError: string | undefined;

  @observable isSavingSettingInProgress: boolean = false;
  @observable savingSettingsError: string | undefined;

  @observable isFetchingSettingInProgress: boolean = false;
  @observable fetchingSettingsError: string | undefined;

  @observable linkedList: any = {};
  @observable isFetchingLinkedListInProgress: boolean = false;
  @observable fetchingLinkedListError?: string;

  @observable fetchingExportError?: string;
  @observable isFetchingExportInProgress: boolean = false;

  @observable importError?: string;
  @observable isImportInProgress: boolean = false;

  @observable theme: string = process.env.REACT_APP_THEME || 'main';

  @observable isFetchingNewPage: boolean = false;
  @observable isFetchingFilteredData: boolean = false;

  @observable savedItem: any = null;

  @observable wholeList: any[] = [];
  @observable importErrors?: any[];
  @observable importStep: number = 0;
  @observable importTotal: number = 0;
  @observable importSucceeded: number | undefined;

  constructor(rootStore: RootStoreInterface, specification?: any, apiClient?: BaseCRUD) {
    this.rootStore = rootStore;

    if (!specification && this.constructor.name !== 'UsersStore' && this.constructor.name !== 'SpecificationsStore') {
      console.error("Specification isn't defined: " + this.constructor.name);
    }

    this.specification = specification;

    if (apiClient) {
      this.apiClient = apiClient;
    } else {
      this.apiClient = new BaseCRUD(this.specification.url);
    }

    //    makeObservable(this);
  }

  setApiClient(apiClient: BaseCRUD) {
    this.apiClient = apiClient;
    if (this.specification && this.specification.url) this.apiClient.setSchemaUrl(this.specification.url);
  }

  @action
  async dispatch(item: any, type: string, isShift: boolean = true) {
    //console.log("DISPATCH IN STORE", this.schema, type, JSON.stringify(item).substr(0,100) , isShift);
    //console.log("DISPATCH IN STORE", this.schema, type, JSON.stringify(item) , isShift);
    runInAction(() => {
      if (!item) return;
      if (!this.record) {
        this.record = this.recordHandlers({ ...item });
      }
      if (!item.id && this.record.id) {
        item.id = this.record.id;
      }
      if (this.record.id === item.id) {
        this.record = this.recordHandlers({ ...this.record, ...item });
        this.recordUpdated(this.record);
      }
      if (this.fetchingListError !== 'Forbidden') {
        if (!this.list) {
          this.list = [];
        }
        //TODO
        if (type === 'delete') {
          this.list = this.list.filter((element: any) => element.id !== item.id);
          return;
        }

        let found = false;
        this.list.find((element: any, index: number): boolean => {
          if (element.id === item.id) {
            this.list[index] = this.recordHandlers({ ...this.list[index], ...item });
            found = true;
          }
          return found; //TODO. maybe delete it?
        });
        if (!found) {
          if (isShift) {
            this.list.unshift(this.recordHandlers({ ...item }));
          } else {
            this.list.push(this.recordHandlers({ ...item }));
          }
        }

        if (this.record.id !== item.id) {
          this.recordUpdated(this.list.find((element: any) => element.id === item.id));
        }
      }
    });
  }

  async recordUpdated(record: any) {}

  async setFilter(
    useCache: boolean,
    filter: any,
    orderBy: string = 'id desc',
    page: number = 0,
    limit: number = 20,
    isDetail: boolean = false,
    isAppend: boolean = false,
    isUpdateCurrentRecord: boolean = false,
  ) {
    console.log('SET', this.filterTimer);
    runInAction(() => {
      this.isFetchingFilteredData = true;
    });
    try {
      if (this.filterTimer) {
        try {
          console.log('CLEAR', this.filterTimer);
          clearTimeout(this.filterTimer);
        } catch (e) {}
      }

      this.filterTimer = setTimeout(async () => {
        console.log('CALL', this.filterTimer);
        const newFilter = Object.entries({ ...this.filter, ...filter } || {}).reduce(
          (acc, [key, val]) => (val || key === 'is_disabled' ? { ...acc, [key]: val } : acc),
          {},
        );

        const isNewPage: boolean = page !== this.page;
        if (isNewPage) {
          runInAction(() => {
            this.isFetchingNewPage = true;
          });
        }
        await this.fetchList(useCache, newFilter, orderBy, page, limit, isDetail, isAppend, isUpdateCurrentRecord);
        if (isNewPage) {
          runInAction(() => {
            this.isFetchingNewPage = false;
          });
        }
        runInAction(() => {
          this.isFetchingFilteredData = false;
        });
      }, 2 * 1000) as unknown as number;
    } catch (e) {
      runInAction(() => {
        this.isFetchingNewPage = false;
      });
    }
  }

  async clearFilter() {
    runInAction(() => {
      this.filter = {};
    });
  }

  async reloadList() {
    this.fetchList(false, this.filter, this.orderBy, this.page, this.limit, this.isDetail, false, true);
  }

  @action
  async fetchList(
    useCache: boolean,
    filter: any,
    orderBy: string = 'id desc',
    page: number = 0,
    limit: number = 20,
    isDetail: boolean = false,
    isAppend: boolean = false,
    isUpdateCurrentRecord: boolean = false,
  ): Promise<void> {
    //alert("FETCH LIST");
    if (useCache === true && this.list) {
      return;
    }

    runInAction(() => {
      this.filter = filter || {};
      this.orderBy = orderBy || undefined;
      this.page = page;
      this.limit = limit || this.limit || 20;
      this.isDetail = isDetail || false;
      this.fetchingListError = undefined;
      this.isFetchingListInProgress = true;
    });

    const usersStore = this.rootStore?.modulesStore?.usersStore;
    const role = usersStore?.role;
    const is_v2 = !!usersStore?.me?.detail?.customer?.is_use_api_v2;

    try {
      const { items } = await this.apiClient.list(this.filter, this.orderBy, this.page, this.limit, isDetail, {
        role,
        is_v2,
      });
      runInAction(() => {
        if (isAppend) {
          if (!this.list) this.list = [];
          this.list = [...this.list, ...items];
        } else {
          this.list = [...items];
        }
        if (this.savedItem?.id) {
          this.list = this.list.map((item) => (this.savedItem.id === item.id ? { ...item, ...this.savedItem } : item));
        }
        this.total = this.list.length;

        //TODO: it's not correct!!!!!
        this.rootStore.modulesStore.webSocketStore.subscribe(this.specification.moduleName, 'list', this.filter);

        if (this.list && this.list.length) {
          this.list = this.list.map((record: any) => this.recordHandlers(record));
        }
        if (isUpdateCurrentRecord && this.record) {
          const [currentRecord] = this.list.filter((r) => this.record.id === r.id);
          if (currentRecord) {
            this.record = {
              ...this.record,
              ...currentRecord,
            };
          }
        }

        this.isFetchingListInProgress = false;
      });
    } catch (err) {
      console.error(err);
      this.captureError(err);

      runInAction(() => {
        this.fetchingListError = err;
        this.isFetchingListInProgress = false;
      });
      this.rootStore.modulesStore.logsStore.debug(JSON.stringify(err));
    }
  }

  @action
  async fetchRecord(id: number, detail: boolean = false): Promise<void> {
    runInAction(() => {
      this.record = undefined;
      this.fetchingRecordError = undefined;
      this.isFetchingRecordInProgress = true;
    });
    try {
      const role = this.rootStore?.modulesStore?.usersStore?.role;
      const data = await this.apiClient.get(id, detail, role);
      this.rootStore.modulesStore.webSocketStore.subscribe(this.specification.moduleName, 'record', { id: data.id });

      this.dispatch(data, 'fetch');
      runInAction(() => {
        this.isFetchingRecordInProgress = false;
      });
      return data;
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.fetchingRecordError = err;
        this.isFetchingRecordInProgress = false;
      });
      this.rootStore.modulesStore.logsStore.debug(JSON.stringify(err));
    }
  }

  @action
  async addRecord(params: any, isShift: boolean = true): Promise<any> {
    runInAction(() => {
      this.addingError = '';
      this.isAddingInProgress = true;
    });

    try {
      const data = await this.apiClient.add({ ...params });
      this.dispatch(data, 'new', isShift);
      runInAction(() => {
        this.isAddingInProgress = false;
      });
      return data;
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.addingError = err;
        this.isAddingInProgress = false;
      });
      this.rootStore.modulesStore.logsStore.debug(JSON.stringify(err));
    }
  }

  @action
  async updateRecord(id: any, record: any): Promise<void> {
    //const prevRecord = this.record && this.record.id === id ? this.record :
    //    this.list && this.list ? this.list.find(item => item.id == id) : {};

    runInAction(() => {
      this.addingError = '';
      this.isAddingInProgress = true;
      //this.dispatch(record, "update");
    });

    try {
      const data = await this.apiClient.update(id, record);
      this.dispatch(data, 'update');
      runInAction(() => {
        this.isAddingInProgress = false;
      });
      return data;
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        //this.dispatch(prevRecord, "update");
        this.addingError = err;
        this.isAddingInProgress = false;
      });
      this.rootStore.modulesStore.logsStore.debug(JSON.stringify(err));
    }
  }

  @action
  async deleteRecord(id: number): Promise<void> {
    runInAction(() => {
      this.deletingError = '';
      this.isDeletingInProgress = true;
    });

    try {
      const data = await this.apiClient.delete(id);
      //TODO: dispatch?
      this.dispatch({ id }, 'delete');
      runInAction(() => {
        this.isDeletingInProgress = false;
      });
      return data;
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.deletingError = err;
        this.isDeletingInProgress = false;
      });
      this.rootStore.modulesStore.logsStore.debug(JSON.stringify(err));
    }
  }

  @action
  async fetchExport(filter: any, orderBy: string, fields: Array<string>, fileType: string): Promise<void> {
    runInAction(() => {
      this.fetchingExportError = '';
      this.isFetchingExportInProgress = true;
    });

    try {
      const data = await this.apiClient.export(this.moduleName, filter, orderBy, fields, fileType);
      if (data?.status === 'success' && data?.data?.report?.status === 'new' && data?.data?.report?.id) {
        runInAction(() => {
          this.checkExportIntervalId = setInterval(() => {
            this.checkExport(data.data.report.id);
          }, 5000);
        });
        return data;
      }
      runInAction(() => {
        this.isFetchingExportInProgress = false;
      });
      return data;
    } catch (err) {
      console.log(err);
      this.captureError(err);
      runInAction(() => {
        this.fetchingExportError = err;
        this.isFetchingExportInProgress = false;
      });
      throw getErrorMessage(err);
    }
  }

  @action
  async checkExport(id: NumberOrString): Promise<void> {
    if (this.isCheckingExportInProggress) return;
    runInAction(() => {
      this.isCheckingExportInProggress = true;
    });
    try {
      const response: ExportResponse = await this.apiClient.checkExport(id);
      const { report } = response || {};
      runInAction(() => {
        this.exportData = {
          ...(this.exportData || {}),
          ...(report || {}),
        };
        if (!report || report.status === 'fail') {
          clearInterval(this.checkExportIntervalId);
          this.resetExportData();
          runInAction(() => {
            this.isFetchingExportInProgress = false;
          });
          this.showError(report?.error_message);
        }
        if (report?.link && report?.status === 'ready') {
          clearInterval(this.checkExportIntervalId);
          try {
            loadFile(
              window?.location?.origin?.indexOf('prime') >= 0
                ? `${window?.location?.origin.replace('prime.', '')}${report.link}`
                : report.link,
              this.moduleName || 'report',
            );
          } catch (er) {
            this.showError((er as any)?.message || (er as any)?.toString());
          }
          runInAction(() => {
            this.isFetchingExportInProgress = false;
          });
          this.resetExportData();
        }
      });
    } catch (err) {
      this.captureError(err);
      clearInterval(this.checkExportIntervalId);
      this.resetExportData();
      runInAction(() => {
        this.isFetchingExportInProgress = false;
      });
      this.showError(err);
    }
    runInAction(() => {
      this.isCheckingExportInProggress = false;
    });
  }

  showError(err): void {
    notify({
      title: 'Error!',
      message: getErrorMessage(err),
      type: 'danger',
    });
  }

  /**
   * Update any store field and save [key, value] pair to Storage
   *
   * @param {string} key Store key
   * @param {any} value
   */
  @action
  async updateWithStorage<T extends keyof this>(key: T, value: this[T] | null | string) {
    let _value = value;
    try {
      if (!_value) {
        await Storage.removeItem(key as string);
      } else {
        await Storage.setItem(key as string, JSON.stringify(_value));
      }
      runInAction(() => {
        this[key] = _value as any;
      });
    } catch (err) {
      this.captureError(err);
    }
  }

  @action
  resetExportData() {
    runInAction(() => {
      this.exportData = undefined;
    });
  }

  @action
  async importProcess(data): Promise<any> {
    runInAction(() => {
      this.importErrors = [];
    });
    while (data?.process?.uuid && data?.process?.total > data?.process?.step) {
      await this.timeout(3000);
      data = await this.apiClient.importProcess(data?.process?.uuid);
      runInAction(() => {
        if (data?.process?.step) this.importStep = data?.process?.step;
      });
      if (Array.isArray(data.process?.errors) && data.process?.errors?.length) {
        runInAction(() => {
          this.importErrors = [...(this.importErrors || []), ...data.process?.errors];
        });
      }
    }
    return data;
  }
  resetImportError() {
    runInAction(() => {
      this.importError = undefined;
      this.importErrors = [];
      this.isImportInProgress = false;
      this.importStep = 0;
      this.importSucceeded = undefined;
    });
  }
  async import(formData: any, onFinish: (() => void) | undefined = undefined): Promise<any> {
    runInAction(() => {
      this.importErrors = [];
      this.importStep = 0;
      this.importTotal = 0;
      this.importSucceeded = 0;
      this.isImportInProgress = true;
    });

    try {
      let res = await this.apiClient.import(formData);
      let { data } = res?.data || {};
      if (data?.process) {
        this.importStep = data?.process?.step || 0;
        if (this.importStep) {
          this.importStep += 1;
        }
        this.importTotal = data?.process?.total || 0;
        this.importSucceeded = data?.process?.imported || 0;
      }

      data = await this.importProcess(data);
      runInAction(() => {
        if (Array.isArray(data.process?.errors) && data.process?.errors?.length) {
          this.importErrors = data.process?.errors;
        } else {
          this.importErrors = [];
        }
        this.importStep = this.importTotal;
        this.isImportInProgress = false;
        if (data?.process) {
          this.importSucceeded = data?.process.imported;
        }
      });
      if (data?.process?.imported > 0) {
        if (onFinish) await onFinish();
        await this.fetchList(false, this.filter, this.orderBy, 0, this.limit || 10, true, false);
      }
      return data;
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.importError = err;
        this.isImportInProgress = false;
      });
    }
  }

  recordHandlers(record: any) {
    record = this.formatRecord(record);

    record.reload = async () => {
      return await this.fetchRecord(record.id);
    };
    record.update = async (data: any) => {
      return await this.updateRecord(record.id, data);
    };
    record.delete = async () => {
      await this.deleteRecord(record.id);
    };

    return record;
  }

  formatRecord(record: any) {
    return record;
  }

  @action
  async fetchLinkedList(
    fieldName: string = '',
    filter: object = {},
    fields: Array<string> = [],
    orderBy: string = '',
    uid: string = '',
    nested: boolean = false,
    limit: number = 10,
  ): Promise<void> {
    runInAction(() => {
      this.fetchingLinkedListError = undefined;
      this.isFetchingLinkedListInProgress = true;
    });
    try {
      const search = filter['$nodx_search'];
      if (nested && filter['$nodx_search']) {
        delete filter['$nodx_search'];
      }
      if (nested && filter['id']) {
        delete filter['id'];
      }
      let data;
      const store = fieldName.replace('_id', '').replace(/^./, (str) => str.toUpperCase()) + 'Store';
      if (nested && this.rootStore.modulesStore[store]?.wholeList?.length) {
        data = this.rootStore.modulesStore[store]?.wholeList;
      } else {
        data = await this.apiClient.linkedList(fieldName, filter, fields, orderBy, nested ? 1000 : limit);
      }
      runInAction(() => {
        this.linkedList[uid] = nested && data?.length > 1 ? this.processNestedItems(data, search) : [...data];

        this.isFetchingLinkedListInProgress = false;
      });
      return data;
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.fetchingLinkedListError = err;
        this.isFetchingLinkedListInProgress = false;
      });
      this.rootStore.modulesStore.logsStore.debug(JSON.stringify(err));
    }
  }

  @action
  async resetLinkedList(fieldName: string = ''): Promise<void> {
    runInAction(() => {
      delete this.linkedList[fieldName];
    });
  }

  @action
  resetList(): void {
    runInAction(() => {
      this.list = [];
    });
  }

  @action
  setEditingRecord(record: any) {
    runInAction(() => {
      this.editingRecord = { ...record };
      delete this.editingRecord.ctime;
      delete this.editingRecord.mtime;
      delete this.editingRecord.cuser_id;
      delete this.editingRecord.muser_id;
    });
  }

  @action
  reset() {
    runInAction(() => {
      this.list = undefined;
    });
  }

  @action
  captureError(err: any) {
    if (process.env.REACT_APP_API_BASE_URL && process.env.REACT_APP_API_BASE_URL.toString().indexOf('stage') < 0) {
      Sentry.captureException(err);
    }
  }
  @computed
  get moduleName(): string {
    return this.specification.moduleName || '';
  }

  @computed
  get isVip() {
    return this.theme === 'vip';
  }

  processNestedItems(data, search = '') {
    let nestedItems = data;
    let itemMap = {};
    let result: any[] = [];
    let filterLower = search ? search.toLowerCase() : '';
    let filterActive = filterLower.length > 0;

    nestedItems.forEach((item) => {
      itemMap[item.id] = { ...item, children: [] };
    });

    nestedItems.forEach((item) => {
      if (item.parent_id !== null) {
        if (itemMap[item.parent_id]) {
          itemMap[item.parent_id].children.push(itemMap[item.id]);
        }
      }
    });

    function sortChildren(item) {
      if (item.children.length > 0) {
        item.children.sort((a, b) => a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' }));
        item.children.forEach((child) => sortChildren(child));
      }
    }

    let roots = nestedItems.filter((item) => item.parent_id === null).map((item) => itemMap[item.id]);

    roots.sort((a, b) => a.name.localeCompare(b.name, 'ru', { sensitivity: 'base' }));

    roots.forEach((root) => {
      sortChildren(root);
    });

    function processDept(item, level) {
      let isMatched = filterActive ? item.name.toLowerCase().includes(filterLower) : true;

      let childMatches = false;
      let childrenResults: any[] = [];
      item.children.forEach((child) => {
        let childResult = processDept(child, level + 1);
        if (childResult.matched) {
          childMatches = true;
          childrenResults.push(...childResult.nestedItems);
        }
      });

      let includeDept = isMatched || childMatches;

      if (includeDept) {
        let resultDept: any = {
          name: item.name,
          id: item.id,
          level: level,
          parent_id: item.parent_id,
        };
        if (isMatched && filterActive) {
          resultDept.is_matched = true;
        }
        let itemResult = [resultDept];
        itemResult.push(...childrenResults);

        return {
          matched: true,
          nestedItems: itemResult,
        };
      } else {
        return {
          matched: false,
          nestedItems: [],
        };
      }
    }

    roots.forEach((root) => {
      let rootResult = processDept(root, 1);
      if (rootResult.matched) {
        result.push(...rootResult.nestedItems);
      }
    });

    return result;
  }

  timeout(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
}

export default BaseStore;

import { LimitHelperOptions, Me } from 'types/types';
import { formatPrice } from '../formatPrice';

export const getRemainder = (user: Me['detail'], opt: LimitHelperOptions = {}): number | string | undefined => {
  const limit = user?.limit_month_amount || user?.limit_group_month_amount;
  const options: LimitHelperOptions = {
    formatted: true,
    format: '',
    fixedNum: 0,
    withCurrency: true,
    ...opt,
  };
  if (!user || typeof user !== 'object' || !limit) return;
  const usedLimit = user.limit_month_amount
    ? user.limit_month_amount_used || 0
    : user.limit_group_month_amount
      ? user.limit_group_month_amount_used || 0
      : 0;
  const remainder = limit - usedLimit;

  return options?.formatted
    ? formatPrice(remainder, options?.format, options?.fixedNum, options.withCurrency)
    : options?.fixedNum || options?.fixedNum === 0
      ? remainder.toFixed(options.fixedNum)
      : remainder;
};

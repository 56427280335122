import React, { useState, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import EditDelete from 'components/EditDelete';
import Dialog from './costCentersDialog';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import ConfirmModal from 'components/Modal/confirmModal';
import { notify } from 'utils/lib/notify';
import { formatError } from 'utils/lib/formatError';
import FASpinner from 'components/FASpinner';

interface CostCentersProps {}

const CostCenters = observer(({}: CostCentersProps): JSX.Element | null => {
  const { usersStore, rolesStore, rolesCostCentersStore } = useStores();
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const [isShowDialog, setShowDialog] = useState<boolean>(false);
  const [editingRecord, setEditingRecord] = useState<any>(null);
  const { t } = useNestedTranslation(['policy.current.cost_centers', 'confirmation', 'errors']);
  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
  const recordRef = useRef<any>();

  useEffect(() => {
    if (rolesStore?.activeGroup?.id === 0) {
      rolesCostCentersStore.resetList();
      return;
    }
    rolesCostCentersStore
      .fetchList(false, { role_id: rolesStore.activeGroup.id }, 'id desc', 0, 100, true, false)
      .then(() => setIsDataReady(true));
  }, [rolesStore.activeGroup.id]);

  const closeDialog = (): void => {
    setShowDialog(false);
  };

  const showDialog = (): void => {
    setEditingRecord(null);
    setShowDialog(true);
  };

  const onEdit = (record: any): void => {
    setEditingRecord(record);
    setShowDialog(true);
  };

  const onDelete = (record: any): void => {
    setVisibleConfirm(true);
    recordRef.current = record;
  };

  const closeConfirm = (): void => {
    setVisibleConfirm(false);
    recordRef.current = undefined;
  };

  const onCCDelete = async (e: any): Promise<void> => {
    e.stopPropagation();
    setVisibleConfirm(false);
    await recordRef.current?.delete();
    if (rolesCostCentersStore?.deletingError) {
      notify({
        type: 'danger',
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedCCDeletion', { ns: 'errors' })} \"${recordRef.current?.name || ''}\": ${formatError(rolesCostCentersStore?.deletingError, t)}`,
      });
    }
    recordRef.current = undefined;
  };

  return (
    <>
      {usersStore?.isCustomer || usersStore?.me?.detail?.permissions?.create_cost_centers ? (
        <button className="btn btn-link mb-5" type="button" onClick={showDialog}>
          <svg className="w-5 h-5" viewBox="0 0 24 24" fill="none">
            <circle cx="12" cy="12" r="12" fill="#FDCD03"></circle>
            <path
              d="M19 12L12 12L5 12"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
            <path
              d="M12 19L12 12L12 5"
              stroke="#000"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            ></path>
          </svg>
          <span>{t('actions.add')}</span>
        </button>
      ) : null}

      <Dialog visible={isShowDialog} onCancel={closeDialog} onClose={closeDialog} record={editingRecord} />

      {rolesCostCentersStore.isFetchingListInProgress || !isDataReady ? <p>{t('loading')}</p> : null}

      {!rolesCostCentersStore.isFetchingListInProgress && isDataReady && rolesCostCentersStore?.list?.length ? (
        <div className="area" style={{ overflow: 'auto' }}>
          <table className="table" style={{ width: '100%', minWidth: 450 }}>
            <thead>
              <tr>
                <th>{t('thead.name')}</th>
                <th>{t('thead.type')}</th>
                <th>{t('thead.required')}</th>
                <th style={{ width: 40 }}>&nbsp;</th>
              </tr>
            </thead>

            <tbody>
              {rolesCostCentersStore?.list?.map((cc: any, index: number) => (
                <tr>
                  <td>{cc.name}</td>
                  <td>{cc.type.name}</td>
                  <td>{t(!!cc?.required ? 'trow.yes' : 'trow.no')}</td>
                  <td style={{ width: 40, paddingLeft: 0 }} className="text-right">
                    {usersStore?.isCustomer || usersStore?.me?.detail?.permissions?.create_cost_centers ? (
                      rolesCostCentersStore?.isDeletingInProgress && cc?.id === recordRef.current?.id ? (
                        <FASpinner show />
                      ) : (
                        <EditDelete onEdit={() => onEdit(cc)} onDelete={() => onDelete(cc)} />
                      )
                    ) : null}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <ConfirmModal
            title={t('deletion_title', { ns: 'confirmation' })}
            text={t('deletion_confirmation', { ns: 'confirmation' })}
            visible={visibleConfirm}
            onCancel={closeConfirm}
            onClose={closeConfirm}
            onConfirm={onCCDelete}
          />
        </div>
      ) : null}
    </>
  );
});

export default CostCenters;

import { omitBy } from 'lodash';
import { WithAnyProperty } from 'types/types';
import { sortObjectByKeys } from '../format/sortObjectByKeys';
import { valuesToString } from '../format/valuesToString';
import { isNullable } from '../isNullable';

/**
 * Convert POJO to URL params string.
 * Properties that equal to null or undefined will be removed
 * and will not be included into URL params string
 *
 * @param {object} value Object to convert to URL params string
 * @param {boolean} sorted If `true` then passed object will be sorted by keys (using `Array.prototype.sort`).
 * Default value is `true`
 * @returns {string} URL params string
 * @example
 *
 * objectToNonNullableURLParamsString({
 *  lat: 55.78131762773906,
 *  lng: 37.60662604126775,
 *  show_arrival: 1,
 *  show_pickup_points: 1
 * })
 *
 * // => lat=55.78131762773906&lng=37.60662604126775&show_arrival=1&show_pickup_points=1
 */
export const objectToNonNullableURLParamsString = (
  value: WithAnyProperty,
  sorted: boolean = true
): string => {
  let _value = sorted ? sortObjectByKeys(value) : value;
  return new URLSearchParams(valuesToString(omitBy(_value, isNullable))).toString();
}

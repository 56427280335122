import React, { useEffect, useRef, useState } from 'react';
import AddRuleButton from './addRuleButton';
import RulesModal from './rulesModal';
import CarClasses from './carClasses';
import RulesShedule from './rules/schedule';
import RulesZones from './rules/zones';
import RulesRadiuses from './rules/radiuses';
import CostCenters from './costCenters';
import { useStores } from 'stores';
import { observer } from 'mobx-react-lite';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import UsersList from './users/usersList';
import { isEmpty } from 'lodash';
import { formatError } from 'utils/lib/formatError';
import FASpinner from 'components/FASpinner';
import InputRule from './rules/inputField';
import Title from './rules/inputField';
import InputField from './rules/inputField';
import { GROUPS_LIST_LIMIT_PER_RAGE } from 'constants/policy';

interface CurrentGroupProps {}

interface Validation {
  submit?: string;
}

const CurrentGroup = observer(({}: CurrentGroupProps): JSX.Element | null => {
  const { rolesStore, usersStore, employeesStore } = useStores();
  const { activeGroup } = rolesStore;
  const [errros, setErrors] = useState<Validation>({});
  const [visible, setVisible] = useState<boolean>(false);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const RulesModalWrapperRef = useRef<HTMLDivElement>(null);
  const [modalBodyStyle, setModalBodyStyle] = useState({});
  const { t } = useNestedTranslation(['policy.current', 'errors']);
  const isDefault = !!rolesStore.list?.find((g: any) => g.id === activeGroup?.id)?.default;
  const isNewGroup: boolean = activeGroup?.id === 0;
  const [isSaving, setIsSaving] = useState<boolean>(false);

  const showModal = (): void => {
    setVisible(true);
  };

  useEffect(() => {
    const closeOnBackDrop = ({ target }: any): void => {
      if (RulesModalWrapperRef.current?.contains(target)) {
        hideModal();
      }
    };
    document.addEventListener('mousedown', closeOnBackDrop);
    return () => {
      employeesStore.clearFilter();
      document.removeEventListener('mousedown', closeOnBackDrop);
    };
  });

  const onModalInlineShow = (event: any): void => {
    const pos = event.target.getBoundingClientRect();
    const wrapperWidth = wrapperRef.current?.getBoundingClientRect().width || 0;
    setModalBodyStyle({
      left: pos.left + wrapperWidth / 3.5,
      top: pos.top - 96,
    });
    showModal();
  };

  const hideModal = (): void => {
    setVisible(false);
  };

  const createGroup = async (): Promise<void> => {
    setIsSaving(true);
    const newGroup = await rolesStore.createGroup();
    if (isNewGroup) {
      if (rolesStore?.newGroupUsers?.length > 0) {
        const updates = rolesStore.newGroupUsers.map((user: any): void => {
          employeesStore?.updateRecord(user.id, { role_id: newGroup.id });
        });
        await Promise.all(updates);
        rolesStore.resetUsers();
      }
      // update groups to show correct count of users
      rolesStore.fetchList(
        false,
        {},
        'id desc',
        isNewGroup || !rolesStore?.page ? 0 : rolesStore.page,
        GROUPS_LIST_LIMIT_PER_RAGE,
        true,
        false,
      );
    }
    if (rolesStore.addingError) {
      setErrors({ submit: rolesStore.addingError });
      window.scrollTo({ top: 0, behavior: 'smooth' });
      setIsSaving(false);
      return;
    }
    rolesStore.setActiveGroup(rolesStore.list?.find((g: any) => g.id === newGroup?.id) || null);
    setIsSaving(false);
    setErrors({});
  };

  return (
    <div ref={wrapperRef} className="area mb-0 flex-grow  overflow-hidden lg:flex lg:flex-col lg:w-full">
      <div className="area-inner flex-grow flex flex-col p-0">
        <form className="flex-grow flex flex-col">
          <div className="divide-y divide-graystroke">
            <div className="py-5 px-4 lg:py-7 lg:px-5">
              <div className="mb-5">
                {!!errros.submit && <span className="error">{formatError(rolesStore.addingError, t)}</span>}
                <h3 className="text-2lg leading-6 font-medium text-black mb-1">
                  {activeGroup?.id !== 0 ? t('title_edit') : t('title_new')}
                </h3>
                <div className="md:max-w-[484px] lg:max-w-2xl">
                  <p>{t('description')}</p>
                </div>
              </div>
              <Title placeholder={t('name_placeholder')} inputOnly field="name" type={'text'} title={t('name')} />
            </div>
            {!isNewGroup && (
              <>
                <div className="py-5 px-4 lg:py-7 lg:px-5">
                  <UsersList />
                </div>
                <div className="py-5 px-4 lg:py-7 lg:px-5">
                  <h3 className="text-2lg leading-6 font-medium text-black mb-1">{t('classes.title')}</h3>
                  <div className="mb-5 lg:mb-7 md:max-w-[484px]">
                    <p>{t('classes.text')}</p>
                  </div>
                  <CarClasses />
                </div>
                {usersStore?.me?.detail?.customer?.is_toll_roads_available && (
                  <div className="py-5 px-4 lg:py-7 lg:px-5">
                    <h3 className="text-2lg leading-6 font-medium text-black mb-1">{t('is_toll_roads_available')}</h3>
                    <div className="mb-5 lg:mb-7">
                      <p>{t('toll_roads_description')}</p>
                    </div>
                    <InputField
                      allowEmpty
                      placeholder={t('is_toll_roads_available')}
                      inputOnly
                      field="is_toll_roads_available"
                      type={'checkbox'}
                      id="is_toll_roads_available"
                      title={t('is_toll_roads_available')}
                    />
                  </div>
                )}
                <div className="py-5 px-4 lg:py-7 lg:px-5">
                  <h3 className="text-2lg leading-6 font-medium text-black mb-1">{t('cost_centers.title')}</h3>
                  <div className="mb-5 lg:mb-7 md:max-w-[484px]">
                    <p>{t('cost_centers.text')}</p>
                  </div>

                  <CostCenters />
                </div>
                <div className="py-5 px-4 lg:py-7 lg:px-5">
                  <h3 className="text-2lg leading-6 font-medium text-black mb-1">{t('driver.comment')}</h3>
                  <InputField
                    allowEmpty
                    placeholder={t('driver.comment')}
                    inputOnly
                    field="driver_comment"
                    type={'text'}
                    title={''}
                  />
                </div>
                <div className="py-5 px-4 lg:py-7 lg:px-5">
                  <h3 className="text-2lg leading-6 font-medium text-black mb-1">{t('rules.title')}</h3>
                  <div className="mb-5 lg:mb-7 md:max-w-[484px]">
                    <p>{t('rules.text')}</p>
                  </div>
                  <div className="bg-graylighter py-3.5 px-5 rounded-lg">
                    {/* TODO place rules here from /rules/* */}
                    <AddRuleButton onInlineModalShow={onModalInlineShow} onModalShow={showModal} />
                    <div ref={RulesModalWrapperRef}>
                      <RulesModal visible={visible} modalStyle={modalBodyStyle} store={undefined} onClose={hideModal} />
                    </div>
                  </div>
                </div>
                <div>
                  {activeGroup?.distance > 0 ? (
                    <div className="py-5 lg:py-7 lg:px-5">
                      <div className="bg-graylighter py-3.5 px-5 lg:rounded mt-5">
                        <InputRule placeholder="10" title={t('rules.distance.max_distance')} field="distance" />
                      </div>
                    </div>
                  ) : null}
                  {activeGroup?.month_amount > 0 ? (
                    <div className="py-5 lg:py-7 lg:px-5">
                      <div className="bg-graylighter py-3.5 px-5 lg:rounded mt-5">
                        <InputRule placeholder="10" title={t('rules.amount.month_limit')} field="month_amount" />
                      </div>
                    </div>
                  ) : null}
                  {activeGroup?.month_amount_group > 0 ? (
                    <div className="py-5 lg:py-7 lg:px-5">
                      <div className="bg-graylighter py-3.5 px-5 lg:rounded mt-5">
                        <InputRule
                          placeholder="10"
                          title={t('rules.amount_group.month_limit')}
                          field="month_amount_group"
                        />
                      </div>
                    </div>
                  ) : null}
                  {!isEmpty(activeGroup?.schedule || {}) ? (
                    <div className="py-5 w-full lg:py-7 lg:px-5">
                      <div className="bg-graylighter py-3.5 lg:rounded mt-5">
                        <RulesShedule />
                      </div>
                    </div>
                  ) : null}
                  {!isEmpty(activeGroup?.zones || {}) ? (
                    <div className="py-5 w-full lg:py-7 lg:px-5">
                      <div className="bg-graylighter py-3.5 lg:rounded mt-5">
                        <RulesZones />
                      </div>
                    </div>
                  ) : null}
                  {/*activeGroup?.radiuses?.length ? (
                                        <div className="py-5 w-full lg:py-7 lg:px-5">
                                            <div className="bg-graylighter py-3.5 lg:rounded mt-5">
                                                <RulesRadiuses />
                                            </div>
                                        </div>
                                    ) : null*/}
                  {activeGroup?.radius > 0 ? (
                    <div className="py-5 lg:py-7 lg:px-5">
                      <div className="bg-graylighter py-3.5 px-5 lg:rounded mt-5">
                        <InputRule placeholder="1000" title={t('rules.radius.name')} field="radius" />
                      </div>
                    </div>
                  ) : null}
                </div>
              </>
            )}
          </div>
          <div className="flex flex-wrap space-x-4 mt-auto p-4 justify-end">
            {usersStore.isCustomer && !isDefault && !isNewGroup && (
              <button
                className={`btn ${!!activeGroup?.default ? 'btn-blue' : 'btn-light'} default-policy-btn`}
                type="button"
                onClick={async () => {
                  await rolesStore.updateActiveGroup({ default: +!activeGroup?.default });
                  // update groups to show correct default group
                  rolesStore.fetchList(
                    false,
                    {},
                    'id desc',
                    rolesStore?.page || 0,
                    GROUPS_LIST_LIMIT_PER_RAGE,
                    true,
                    false,
                  );
                }}
              >
                {t('actions.make_default')}
              </button>
            )}
            {isNewGroup && (
              <div className="flex justify-end space-x-4 mt-auto">
                <button
                  className="btn btn-light"
                  type="button"
                  onClick={() => {
                    rolesStore.setActiveGroup(null);
                  }}
                >
                  {t('actions.cancel')}
                </button>
                <button
                  className="btn btn-blue"
                  type="button"
                  disabled={!activeGroup?.name || isSaving}
                  onClick={createGroup}
                >
                  <FASpinner containerClass="mr-2" show={isSaving} />
                  {isNewGroup ? t('actions.create') : t('actions.save')}
                </button>
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
});

export default CurrentGroup;

export default {
  title: 'Отчеты',
  loading: 'Загрузка данных',
  error: 'Ошибка',
  actions: {
    export: 'Экспорт',
    exporting: 'Экспортирую',
  },
  thead: {
    id: 'ID',
    status: 'Статус',
    address: 'Адрес',
    full_name: 'ФИО',
    class: 'Класс',
    comment: 'Комментарий',
    cost_centers: 'Дополнительные поля',
    details: 'Детали поездки',
    department_id: 'Департамент',
    department_code: 'Код филиала',
    department_name: 'Отдел',
  },
  trow: {
    personal_coordinator: 'Персональный координатор',
    paid_waiting: 'Платное ожидание',
    ride_time: 'Время поездки',
    order_time: 'Время создания заказа',
    full_time: 'Общее время',
    distance: 'Расстояние',
    cost: 'Стоимость с НДС',
    rating: 'Оценка',
    wishes: 'Пожелания',
    cancel_reason: 'Причина отмены',
    minutes_short: 'мин',
    km_short: 'км',
    guest: '(пассажир / гость)',
    orderer: 'Кто оформил заказ',
    support: 'Служба поддержки',
    cancelled_time: 'Время отмены',
    entrance: 'подъезд',
  },
  filter: {
    title: 'Фильтры',
    status: 'Статус',
    full_name: 'ФИО',
    coordinator_id: 'Координатор',
    id: 'ID',
    class: 'Класс',
    date: 'ДД.ММ.ГГ-ДД.ММ.ГГ',
    search_placeholder: 'ФИО пассажира / гостя',
    department_id: 'Департамент',
    department_code: 'Код филиала',
    department_name: 'Отдел',
    actions: {
      apply: 'Применить',
    },
  },
};

import React from 'react';

interface NumberIconProps {
  number: number;
}

const NumberIcon = ({ number }: NumberIconProps): JSX.Element => {
  return (
    <div className="stop-icon">
      <span>{number}</span>
    </div>
  );
};

export default NumberIcon;

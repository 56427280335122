import React, { useState, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { TariffCard } from 'components/TariffCard';
import { notify } from 'utils/lib/notify';
import { useTranslation } from 'react-i18next';
import { formatError } from 'utils/lib/formatError';

interface ClassProps {}

const CarClasses = observer(({}: ClassProps): JSX.Element | null => {
  const { usersStore, ordersClassesStore, ordersStore, rolesStore } = useStores();
  const { t } = useTranslation();
  const [inProgressName, setInProgressName] = useState<string[]>([]);
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const classesList =
    usersStore.me.detail && usersStore.me.detail.classes_ids
      ? usersStore.me.detail.classes_ids
      : usersStore.me.detail && usersStore.me.detail.customer
        ? usersStore.me.detail.customer.classes_ids
        : [];

  //const availClasses = ordersStore.specification.fields.find((item: any) => item.name === 'class_id').link.list.filter((item: any) => classesList.includes(item.name));

  useEffect(() => {
    ordersClassesStore.fetchList(false, {}, 'id asc', 0, 20, true, false).then(() => setIsDataReady(true));
  }, []);

  const enableClass = async (name: string, title: string): Promise<void> => {
    setInProgressName((state) => [...state, name]);
    const deleting: boolean = !!rolesStore.activeGroup[name];
    if (rolesStore.activeGroup[name]) {
      await rolesStore.updateActiveGroup({ [name]: 0 });
    } else {
      await rolesStore.updateActiveGroup({ [name]: 1 });
    }
    if (rolesStore?.addingError) {
      notify({
        type: 'danger',
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t(`${deleting ? 'failedCarDeletion' : 'failedCarAdding'}`, { ns: 'errors' })} \"${title || ''}\": ${formatError(rolesStore?.addingError, t)}`,
      });
    }
    setInProgressName((state) => state.filter((n: string) => n !== name));
  };

  return (
    <div className="overflow-x-auto">
      <div className="cars-list" style={{ margin: 10 }}>
        {ordersClassesStore.isFetchingListInProgress || !isDataReady ? (
          <span>{t('loading')}</span>
        ) : (
          ordersClassesStore?.list.map((cls: any) => (
            <TariffCard
              inProgress={inProgressName.includes(cls.name)}
              onClick={() => enableClass(cls.name, cls.title)}
              active={rolesStore.activeGroup[cls.name]}
              tariff={{ name: cls.name, title: cls.title, icon512: cls.icon512 }}
            />
          ))
        )}
      </div>
    </div>
  );
});

export default CarClasses;

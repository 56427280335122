import { makeObservable } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';

class ManagersStore extends BaseStore {
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Managers']);
    makeObservable(this);
  }
}

export default ManagersStore;

import icons from '../../../modules/NewOrder/assets/base64/numbers';
import vipIcons from '../../../modules/NewOrder/assets/base64/vip/numbers';
import { getCoords } from './getCoords';

export const addWayPointsPlacemarksAsGeo = (() => {
  const marks: any[] = [];
  return (mapApi: any, map: any, places: any[] = [], size: number = 50, isVip): void => {
    if (!mapApi || !map) return;
    // remove map marks
    if (marks.length) {
      marks.forEach((mark: any) => {
        map.geoObjects.remove(mark);
      });
    }
    if (!places?.length) return;
    // add map marks
    places.forEach((place: any, i: number) => {
      const placemark = new mapApi.Placemark(
        Array.isArray(place) ? place : getCoords(place),
        {},
        {
          iconLayout: 'default#image',
          iconImageHref: isVip ? vipIcons[i] : icons[i],
          iconImageSize: [size, size],
          iconImageOffset: [-size / 2, -size / 2],
        },
      );
      marks.push(placemark);
      map.geoObjects.add(placemark);
    });
  };
})();

import BaseCRUD from 'stores/BaseCRUD';
import WebApiClient from '../WebApi';
import { Coordinator } from 'modules/Coordinators';
import { Role } from 'types/types';
import { EmployeeUpdates, RoleUpdates } from '.';

export class CoordinatorsApi extends BaseCRUD {
  async resendSMS(id: number | string) {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/${id}/resend-sms`,
    });
  }
  async fetchPermission({
    coordinator_id,
    limit = 10,
    page = 0,
    $nodx_search = '',
  }: {
    coordinator_id: Coordinator['id'];
    limit: number;
    page;
    $nodx_search?: string;
  }): Promise<any> {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/permissions_roles`,
      data: {
        coordinator_id,
        limit,
        page,
        ...($nodx_search ? { $nodx_search } : {}),
      },
    });
  }

  async fetchEmployees({
    coordinator_id,
    role_id,
    limit = 10,
    page = 0,
    $nodx_search = '',
  }: {
    coordinator_id: Coordinator['id'];
    role_id: Role['id'];
    limit: number;
    page;
    $nodx_search?: string;
  }): Promise<any> {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/permissions_roles/employees`,
      data: {
        coordinator_id,
        role_id,
        limit,
        page,
        ...($nodx_search ? { $nodx_search } : {}),
      },
    });
  }

  async updateEmployee(data: EmployeeUpdates) {
    return await WebApiClient.fetch({
      method: 'put',
      url: `/${this.schema}/permissions_roles/employees`,
      data,
    });
  }

  async updateRole(data: RoleUpdates) {
    return await WebApiClient.fetch({
      method: 'put',
      url: `/${this.schema}/permissions_roles`,
      data,
    });
  }
}

export default CoordinatorsApi;

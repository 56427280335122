export default {
  title: 'Favourites',
  action: {
    add: 'Add address',
  },
  search_placeholder: 'Search',
  loading: 'Loading data',
  error: 'Error',
  thead: {
    name: 'Name',
    address: 'Address',
  },
  dialog: {
    title_new: 'New address',
    title_edit: 'Editing',
    name: 'Address',
    name_placeholder: 'Enter address name',
    address_placeholder: 'Enter address',
    address: 'Address',
    search_placeholder: 'Search',
    map: {
      title: 'Select address',
    },
  },
};

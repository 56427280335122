import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';

const TableHeader = (): JSX.Element => {
  const { t } = useTranslation('rides');
  const { usersStore } = useStores();
  return (
    <div className="data-head">
      <div className="data-row">
        <div className="w-[10.5rem]">
          {`${t('thead.id')} ${usersStore?.isOperator ? '' : `/ ${t('thead.status')}`}`}
        </div>
        {usersStore.isOperator && <div className={`w-[9.25rem]`}>{`${t('thead.status')} / ${t('thead.class')}`}</div>}
        <div className={`w-[${usersStore.isOperator ? 3 : 1}5.5rem]`}>{t('thead.address')}</div>
        <div className={`w-[${usersStore.isOperator ? 17 : 9}.5rem]`}>{t('thead.full_name')}</div>
        {usersStore.isOperator && <div className={`w-[9.5rem]`}>{t('thead.company')}</div>}
        {!usersStore.isOperator && <div className={`w-[12.5rem]`}>{t('thead.driver')}</div>}
        {usersStore.isOperator && <div className={`w-[12.5rem]`}>{t('thead.driver_name')}</div>}
        {usersStore.isOperator && <div className={`w-[12.5rem]`}>{t('thead.driver_car')}</div>}
        {!usersStore.isOperator && <div className="w-[6.25rem]">{t('thead.class')}</div>}
        {!usersStore.isOperator && (
          <>
            <div className="w-[9.25rem]">{t('thead.cost')}</div>
            <div className="w-[9.5rem]">{t('thead.comment')}</div>
            <div className="data-cell">{t('thead.time')}</div>
          </>
        )}
      </div>
    </div>
  );
};

export default TableHeader;

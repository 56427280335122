import React, { useCallback, useEffect, useState } from 'react';
import ModalSelectStore from 'components/ModalSelectStore';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { useTranslation } from 'react-i18next';
import moment from 'moment/moment';
import ModalBase from 'components/ModalBase';
import { InputField } from 'components/Inputs/InputField';
import { DateTimeField } from 'components/Inputs/DateTimeField';
import { Checkbox } from 'components/Inputs/Checkbox';
import { ReactComponent as PromoIcon } from '../../../assets/svg/promocode.svg';

interface FilterProps {
  filter?: any;
}

export const operatorListOrdersOrderBy = 'msk_datetime desc NULLS LAST';
export const operatorListOrdersFilter = {
  msk_datetime: ['<', moment().utcOffset(180).add(30, 'minutes').format('YYYY-MM-DD HH:mm:ss.000000')],
};

const Filter = observer(({ filter: outerFilter }: FilterProps): JSX.Element => {
  const { ordersStore, usersStore } = useStores();
  const [filter, setFilter] = useState<any>({ ...ordersStore?.filter });
  const [visible, setVisible] = useState<boolean>(false);
  const { t } = useTranslation('rides');
  const [dateRange, setDateRange] = useState<any[]>([]);

  const changeFilter = useCallback(
    (name: string, value: any, shouldCallOnFilterChangeProp: boolean = false) => {
      const newFilter = { ...filter };
      if (value) {
        if (name === 'phone' || name === 'source' || name === 'employee_name') {
          newFilter[name] = ['like', value];
        } else if (name === 'is_toll_road') {
          newFilter['route'] = {
            is_toll_road: !!value,
          };
        } else if (name === 'is_promo') {
          newFilter['isPromo'] = !!value;
        } else {
          newFilter[name] = value;
        }
        if (name === 'employee_id') {
          if (ordersStore?.linkedList && ordersStore.linkedList['employee_id_id']) {
            const [current] = ordersStore.linkedList['employee_id_id'].filter((item: any) => item.id === value);
            if (current?.user_id) {
              newFilter[name] = undefined;
              newFilter['$nodx_or'] = {
                cuser_id: current?.user_id,
                employee_id: value,
              };
            } else {
              newFilter['$nodx_or'] = undefined;
            }
          } else {
            newFilter['$nodx_or'] = undefined;
          }
        }
      } else {
        if (name === 'employee_id') {
          newFilter['$nodx_or'] = undefined;
        } else if (name === 'is_toll_road') {
          newFilter['route'] = undefined;
        } else if (name === 'is_promo') {
          newFilter['isPromo'] = undefined;
        }
        newFilter[name] = undefined;
      }
      if (shouldCallOnFilterChangeProp) {
        ordersStore.setFilter(
          false,
          newFilter,
          'id desc',
          ordersStore?.page,
          ordersStore?.limit || 10,
          true,
          false,
          true,
        );
      }
      setFilter(newFilter);
    },
    [filter],
  );

  useEffect(() => {
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible]);

  useEffect(() => {
    if (outerFilter) {
      setFilter(outerFilter);
    }
  }, [outerFilter]);

  const onApply = useCallback((): void => {
    setVisible(false);
    ordersStore.setFilter(false, filter, 'id desc', ordersStore?.page, ordersStore?.limit || 10, true, false, true);
  }, [filter]);

  const handleSelectRange = (value, shouldCallOnFilterChangeProp: boolean = false): void => {
    const [dateStart, dateEnd] = value || [];
    if (!dateStart) {
      setDateRange([]);
      changeFilter('local_datetime', undefined, shouldCallOnFilterChangeProp);
      return;
    }
    const newDateRange = [new Date(dateStart), ...(dateEnd ? [new Date(dateEnd)] : [])];
    setDateRange(newDateRange);
    const startDate = moment(dateStart);
    const endDate = dateEnd ? moment(dateEnd) : moment();
    changeFilter(
      'local_datetime',
      ['between', startDate.format('YYYY-MM-DD 00:00:00'), endDate.format('YYYY-MM-DD 23:59:59')],
      shouldCallOnFilterChangeProp,
    );
  };

  return (
    <div className="flex flex-grow">
      {/*
      <div className="flex-grow">
        <SearchBar containerClass='w-44 sm:w-80 mr-2' placeholder={t('filter.search_placeholder')} store={ordersStore} />
      </div>
      */}
      <div className="xl:hidden">
        <button
          className="btn btn-light px-1.5 w-10 xl:px-4 xl:w-auto"
          type="button"
          onClick={() => setVisible(true)} /*@click="isOpen = true; document.body.classList.add('overflow-hidden')"*/
        >
          <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
            <path d="M18.22 2.6c-1.14-.44-4.4-.76-8.22-.76-3.83 0-7.08.32-8.22.75-.24.08-.46.22-.63.4a.68.68 0 0 0 0 .86l6.2 8.22v3.45a1.52 1.52 0 0 0 .84 1.36L10.44 18a1.52 1.52 0 0 0 2.2-1.36v-4.57l6.2-8.22a.68.68 0 0 0 0-.86 1.42 1.42 0 0 0-.62-.4Zm-6.53 8.98c-.06.09-.1.2-.1.31v4.75a.46.46 0 0 1-.67.41l-2.25-1.12a.46.46 0 0 1-.26-.41v-3.63a.53.53 0 0 0-.1-.31L3.03 4.56c2.3.33 4.63.48 6.96.45 2.33.03 4.65-.12 6.96-.45l-5.27 7.02ZM10 3.95c-2.44.05-4.87-.12-7.28-.53 2.4-.4 4.84-.58 7.28-.52 2.44-.06 4.87.12 7.28.52-2.4.4-4.84.58-7.28.53Z"></path>
          </svg>
        </button>
        <ModalBase
          title={t('filter.title')}
          onClose={() => {
            setVisible(false);
            setFilter(ordersStore?.filter || {});
          }}
          parentClassName="panel"
          visible={visible}
          unmountContentOnHide={false}
          contentAnimationShowClass="bottom-slide-in-600"
          contentAnimationHideClass="bottom-slide-out"
          contentClass="overflow-visible"
        >
          <form className="md:w-[30.25rem] mx-auto">
            <div className="mb-6">
              {
                <div className="mb-4">
                  <InputField
                    onReset={() => changeFilter('id', '')}
                    value={filter?.id || ''}
                    onChange={(e) => changeFilter('id', e.target.value)}
                    containerClass="w-full"
                    placeholder={t('filter.id')}
                  />
                </div>
              }
              {usersStore?.isOperator && (
                <div className="mb-4">
                  <ModalSelectStore
                    store={ordersStore}
                    fieldName={'customer_id'}
                    placeholder={t('filter.company')}
                    filter={{ id: filter?.customer_id }}
                    omitValuesOnFocus={['id']}
                    value={filter.customer_id}
                    optionsWithId={usersStore?.isOperator}
                    onChange={(id: number) => changeFilter('customer_id', id)}
                  />
                </div>
              )}
              {!usersStore?.isEmployee && !usersStore?.isOperator && (
                <div className="mb-4">
                  <ModalSelectStore
                    store={ordersStore}
                    fieldName={'employee_id'}
                    placeholder={t('filter.full_name')}
                    filter={{
                      id: filter['$nodx_or']?.employee_id || filter.employee_id,
                      customer_id: filter?.customer_id,
                    }}
                    omitValuesOnFocus={['id']}
                    value={filter['$nodx_or']?.employee_id || filter.employee_id}
                    onChange={(id: number) => changeFilter('employee_id', id)}
                  />
                </div>
              )}
              {usersStore?.isOperator && (
                <div className="mb-4">
                  <DateTimeField
                    onChange={(ev) => handleSelectRange(ev, false)}
                    dateFormat="DD.MM.YYYY"
                    selectsRange
                    startDate={dateRange && dateRange[0]}
                    endDate={dateRange && dateRange[1]}
                    placeholderText={t('filter.date')}
                  />
                </div>
              )}
              {/*
              <div className="mb-4">
                <FilterItem options={[]} label='Телефон' />
              </div>
              */}
              <div className="mb-4">
                <ModalSelectStore
                  store={ordersStore}
                  fieldName={'status_id'}
                  placeholder={t('filter.status')}
                  filter={{ id: filter?.status_id }}
                  limit={20}
                  omitValuesOnFocus={['id']}
                  value={filter.status_id}
                  multi={usersStore?.isOperator}
                  onChange={(id) => changeFilter('status_id', id)}
                />
              </div>
              <div className="mb-4">
                <ModalSelectStore
                  store={ordersStore}
                  fieldName={'class_id'}
                  placeholder={t('filter.class')}
                  filter={{ id: filter?.class_id }}
                  omitValuesOnFocus={['id']}
                  value={filter.class_id}
                  onChange={(id: number) => changeFilter('class_id', id)}
                />
              </div>
              <div className="mb-4">
                <InputField
                  onReset={() => changeFilter('source', '')}
                  value={filter?.source || ''}
                  onChange={(e) => changeFilter('source', e.target.value)}
                  containerClass="w-full"
                  placeholder={t('filter.source')}
                />
              </div>
              <div className="mb-4 leading-10">
                <Checkbox
                  checked={!!filter?.route?.is_toll_road}
                  onChange={(e) => changeFilter('is_toll_road', e.target.checked)}
                  label={t('filter.is_toll_road')}
                />
              </div>
              {usersStore?.isOperator && (
                <div className="mb-4 leading-10">
                  <Checkbox
                    checked={!!filter?.isPromo}
                    onChange={(e) => changeFilter('is_promo', e.target.checked)}
                    label={<PromoIcon className={'inline-block'} />}
                  />
                </div>
              )}
            </div>
            <div className="flex justify-end space-x-5">
              <button className="btn btn-blue w-full" type="button" onClick={onApply}>
                {t('filter.actions.apply')}
              </button>
            </div>
          </form>
        </ModalBase>
      </div>
      <div className="flex space-x-4 lg:space-x-5 hidden xl:flex">
        {
          <div className="w-[7rem]">
            <InputField
              onReset={() => changeFilter('id', '', true)}
              value={filter?.id || ''}
              onChange={(e) => changeFilter('id', e.target.value, true)}
              containerClass="w-full"
              placeholder={t('filter.id')}
            />
          </div>
        }
        <div className="w-[9.25rem]">
          <ModalSelectStore
            store={ordersStore}
            fieldName={'status_id'}
            placeholder={t('filter.status')}
            filter={{ id: filter?.status_id }}
            omitValuesOnFocus={['id']}
            value={filter.status_id}
            multi={usersStore?.isOperator}
            limit={20}
            onChange={(id) => changeFilter('status_id', id, true)}
          />
        </div>

        {usersStore?.isOperator && (
          <div className="w-[10.5rem]">
            <ModalSelectStore
              store={ordersStore}
              fieldName={'customer_id'}
              placeholder={t('filter.company')}
              filter={{ id: filter?.customer_id }}
              omitValuesOnFocus={['id']}
              value={filter.customer_id}
              optionsWithId={usersStore?.isOperator}
              onChange={(id: number) => changeFilter('customer_id', id, true)}
            />
          </div>
        )}
        {/*
        {!usersStore?.isEmployee && (
          <div className="w-[10.5rem]">
            <ModalSelectStore
              store={ordersStore}
              fieldName={"employee_id"}
              placeholder={t('filter.full_name')}
              filter={{ id: filter["$nodx_or"]?.employee_id || filter.employee_id, customer_id: filter?.customer_id }}
              omitValuesOnFocus={['id']}
              value={filter["$nodx_or"]?.employee_id || filter.employee_id}
              onChange={(id: number) => changeFilter("employee_id", id, true)}
            />
          </div>
        )}
        */}
        {usersStore?.isOperator && (
          <div className="w-[10.5rem]">
            <DateTimeField
              onChange={(ev) => handleSelectRange(ev, true)}
              dateFormat="DD.MM.YYYY"
              selectsRange
              startDate={dateRange && dateRange[0]}
              endDate={dateRange && dateRange[1]}
              placeholderText={t('filter.date')}
            />
          </div>
        )}
        {/*
        <div className="w-48 hidden xl:block">
          <ModalSelectStore
            store={ordersStore}
            fieldName={"phone"}
            filter={{}}
            placeholder={"Телефон"}
            value={filter.phone}
            onChange={(id:number)=>changeFilter("phone", id)}
          />
          */}
        {!usersStore?.isEmployee && (
          <>
            <div className="w-[9.25rem]">
              <InputField
                onReset={() => changeFilter('employee_name', '', true)}
                value={filter?.employee_name && filter?.employee_name.length ? filter?.employee_name[1] : ''}
                onChange={(e) => changeFilter('employee_name', e.target.value, true)}
                containerClass="w-full"
                placeholder={t('filter.employee_name')}
              />
            </div>

            <div className="w-[9.25rem]">
              <InputField
                onReset={() => changeFilter('phone', '', true)}
                value={filter?.phone && filter?.phone.length ? filter?.phone[1] : ''}
                onChange={(e) => changeFilter('phone', e.target.value, true)}
                containerClass="w-full"
                placeholder={t('filter.phone')}
              />
            </div>

            <div className="w-[9.25rem]">
              <InputField
                onReset={() => changeFilter('source', '', true)}
                value={filter?.source && filter?.source.length ? filter?.source[1] : ''}
                onChange={(e) => changeFilter('source', e.target.value, true)}
                containerClass="w-full"
                placeholder={t('filter.source')}
              />
            </div>

            <div className="w-[9.5rem] leading-10">
              <Checkbox
                checked={!!filter?.route?.is_toll_road}
                onChange={(e) => changeFilter('is_toll_road', e.target.checked, true)}
                label={t('filter.is_toll_road')}
              />
            </div>
            {usersStore?.isOperator && (
              <div className="w-[6rem] leading-10">
                <Checkbox
                  checked={!!filter?.isPromo}
                  onChange={(e) => changeFilter('is_promo', e.target.checked, true)}
                  label={<PromoIcon className={'inline-block'} />}
                />
              </div>
            )}
          </>
        )}

        {/*
        <div className="w-44">
          <ModalSelectStore
            store={ordersStore}
            fieldName={"class_id"}
            placeholder={t('filter.class')}
            filter={{ id: filter?.class_id }}
            omitValuesOnFocus={['id']}
            value={filter.class_id}
            onChange={(id: number) => changeFilter("class_id", id, true)}
          />
        </div>
        */}

        {/*
        {usersStore?.isOperator && (
        <div className="w-44">
          <ModalSelectStore
            store={ordersStore}
            fieldName={"city_id"}
            placeholder={t('filter.city')}
            filter={{ id: filter?.city_id }}
            omitValuesOnFocus={['id']}
            value={filter.city_id}
            onChange={(id: number) => changeFilter("city_id", id, true)}
          />
        </div>
        )}
        */}
      </div>
      <div className="flex space-x-4 lg:space-x-5 hidden xl:flex"></div>
    </div>
  );
});

export default Filter;

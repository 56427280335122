import React, { ReactChild, useState } from 'react';

import { ReactComponent as CopyIcon } from '../assets/svg/copy.svg';

interface MarkerProps {
  text?: string;
  textToCopy?: string;
  copiedText?: string;
  className?: string;
  hasIcon?: boolean;
}

const CopiedText = ({ text, textToCopy, copiedText, className, hasIcon }: MarkerProps) => {
  const [copied, setCopied] = useState(false);
  const copyToClipboard = (e, text: string = '') => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(text || '');
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  };
  return (
    <>
      <a className={className} onClick={(e) => copyToClipboard(e, textToCopy || text)}>
        {text || textToCopy}
        {hasIcon && <CopyIcon className={`inline status-icon-active`} />}
      </a>
      {copied && <div className="copied-text">{copiedText || 'Скопировано'}</div>}
    </>
  );
};

CopiedText.defaultProps = {
  onClick: null,
};

export default CopiedText;

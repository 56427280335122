import { makeObservable, observable, action, runInAction } from 'mobx';
import BaseStore from '../BaseStore';
import AnalyticsAPI from './api';
import { RootStoreInterface } from '../../interfaces';

export default class AnalyticsStore extends BaseStore {
  api: AnalyticsAPI;

  @observable isCustomerStatsFetched: boolean = false;
  @observable customerStatsFetchingError: string = '';
  @observable customerStats: any = {};

  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Analytics']);
    this.api = new AnalyticsAPI(rootStore.specifications['Analytics'].url);
    this.setApiClient(this.api);
    makeObservable(this);
  }

  @action
  async fetchCustomerStats(customerId: number, dateStart: number, dateEnd: number): Promise<void> {
    runInAction(() => {
      this.customerStatsFetchingError = '';
      this.isCustomerStatsFetched = false;
    });

    try {
      const response = await this.api.getCustomerStats(customerId, dateStart, dateEnd);
      runInAction(() => {
        this.customerStats = response;
        this.isCustomerStatsFetched = true;
      });
    } catch (err) {
      this.captureError(err);
      runInAction(() => {
        this.customerStatsFetchingError = err;
        this.isCustomerStatsFetched = true;
      });
    }
  }
}

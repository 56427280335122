import { DropdownIcon } from 'components/Buttons/DropdownIcon';
import React, { HTMLAttributes, PropsWithChildren, useRef, useState } from 'react';

interface HoverDropdownProps extends HTMLAttributes<HTMLDivElement> {
  outerContent: React.ReactNode | ((close?: () => void) => React.ReactNode);
  outerContentClass?: HTMLAttributes<HTMLDivElement>['className'];
  onOpen?: () => void;
  onClose?: () => void;
  containerClass?: HTMLAttributes<HTMLDivElement>['className'];
}

export const HoverDropdown = ({
  children,
  outerContent,
  className = '',
  outerContentClass = '',
  containerClass = '',
  onOpen,
  onClose,
  onClick,
  onMouseLeave,
  onMouseEnter,
  ...props
}: PropsWithChildren<HoverDropdownProps>): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const wrapperRef = useRef<HTMLDivElement>(null);

  const togglePopup = (_isOpen: boolean = false): void => {
    setIsOpen(_isOpen);
    if (_isOpen && onOpen) onOpen();
    if (!_isOpen && onClose) onClose();
  };

  const _onClick = (e): void => {
    if (onClick) onClick(e);
    togglePopup(!isOpen);
  };

  const _onMouseEnter = (e): void => {
    if (onMouseEnter) onMouseEnter(e);
    togglePopup(true);
  };

  const _onMouseLeave = (e): void => {
    if (onMouseLeave) onMouseLeave(e);
    togglePopup(false);
  };

  return (
    <div
      className={`hovered-dropdown ${className}`}
      onClick={_onClick}
      ref={wrapperRef}
      onMouseEnter={_onMouseEnter}
      onMouseLeave={_onMouseLeave}
      {...props}
    >
      <div className={`hovered-dropdown__area ${containerClass}`}>
        {children}
        <DropdownIcon onClick={_onClick} isOpen={isOpen} />
      </div>
      <div
        onClick={(e) => e.stopPropagation()}
        style={{
          maxHeight: window.innerHeight - (wrapperRef.current?.getBoundingClientRect()?.bottom || 0) - 20,
        }}
        className={`hovered-dropdown__popup ${isOpen ? 'hovered-dropdown__popup_open' : ''} ${outerContentClass}`}
      >
        {typeof outerContent === 'function' ? outerContent(() => togglePopup(false)) : outerContent}
      </div>
    </div>
  );
};

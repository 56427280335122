import React from 'react';
import { useTranslation } from 'react-i18next';

interface ProgressProps {
  total: number;
  value: number;
  translation: string;
}
const Progress = ({ value, total, translation }: ProgressProps): JSX.Element => {
  const { t } = useTranslation(translation);
  return (
    <>
      <p className="mb-3">{t('import.import_process')}...</p>
      <div className="loader loader-percent">
        <div className="percent-line" style={{ width: `${(value * 100) / total}%` }} />
      </div>
      {total ? (
        <p className="mt-3 mb-5">
          {value} {t('import.from')} {total}
        </p>
      ) : null}
    </>
  );
};

export default Progress;

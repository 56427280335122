import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import { useDebouncedEffect } from '../../hooks/useDebouncedEffect';
import { Time } from '../DayCard';

interface MaskedInputProps {
  onChangeValue?: (value: Time | null) => void;
  style?: React.CSSProperties;
  placeholder?: string;
  id?: string;
  value: Time | null;
  className?: string;
}

const formatDaySchedule = (time: Time | null): string => {
  if (!time) return '';
  let hour: string = time.hour?.toString();
  hour = hour.length > 1 ? hour : `0${hour}`;
  let mins: string = time.mins?.toString();
  mins = mins.length > 1 ? mins : `0${mins}`;
  return `${hour}:${mins}`;
};

const TimeInput = ({ id, value, style, className, placeholder, onChangeValue }: MaskedInputProps): JSX.Element => {
  const [stringValue, setStringValue] = useState(formatDaySchedule(value));

  useDebouncedEffect(
    () => {
      const [hour, mins] = stringValue.split(':');
      let numberHour = Number(hour);
      let numberMins = Number(mins);
      if (isNaN(numberHour) || isNaN(numberMins)) return;
      if (numberHour > 23) numberHour = 23;
      if (numberMins > 59) numberMins = 59;
      const v: Time | null = { hour: numberHour, mins: numberMins };
      setStringValue(formatDaySchedule(v));
      if (onChangeValue) onChangeValue(v);
    },
    [stringValue],
    1000,
  );

  return (
    <InputMask
      className={className}
      style={style}
      placeholder={placeholder}
      value={stringValue}
      id={id}
      mask="99:99"
      onChange={(e) => setStringValue(e.target.value)}
    />
  );
};

export default TimeInput;

import { CURRENT_CURRENCY } from 'constants/currenciesSigns';
import numeral from 'numeral';

export const formatPrice = (
  price: number | undefined,
  format: string = '0,0',
  fixedNum: number = 0,
  withCurrency = true,
) => {
  if (!price) return;
  let parsedLimit = typeof price === 'number' ? price : parseInt(price);
  const value = numeral(parsedLimit.toFixed(fixedNum)).format(format)?.replace(/,/g, ' ');
  return value ? `${value}${withCurrency ? ' ' + CURRENT_CURRENCY : ''}` : value;
};

export default {
  title: 'Departments',
  action: {
    add: 'Add department',
    edit: 'Editing',
    import: 'Import employees',
    export: 'Export',
    exporting: 'Exporting',
  },
  search_placeholder: 'Search',
  loading: 'Loading data',
  error: 'Error',
  thead: {
    full_name: 'Full name',
    email: 'E-mail',
  },
  dialog: {
    name: 'Department name',
    parent_id: 'Upper level Department',
    error: 'Department error, try again',
    email: 'E-mail',
    add: 'Add',
    save: 'Save',
    cancel: 'Cancel',
  },
  class: {
    all: 'All classes',
  },
  group: {
    search_placeholder: 'Find groups',
    all: 'All groups',
    default: 'Default',
    add_user: 'Add employees',
  },
  employees: {
    search_placeholder: 'Find employees',
    all: 'All employees',
  },
};

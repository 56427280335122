import FASpinner from 'components/FASpinner';
import ModalBase from 'components/ModalBase';
import { ROUTE_MAP } from '../../../constants/colors';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { YMaps, Map, Polygon, ZoomControl } from 'react-yandex-maps';
import { Region } from '../rules/zones';
import { MOSCOW_CENTER } from 'constants/map';

interface RuleRegionMapProps {
  visible: boolean;
  region?: Region;
  onClose: any;
  onStopEditing?: any;
}

export const RuleRegionMap = ({ visible, region, onClose, onStopEditing }: RuleRegionMapProps): JSX.Element => {
  const polygonRef = useRef<any>(null);
  const startedDrawningRef = useRef<boolean>(false);
  const { t } = useNestedTranslation(['policy.current.rules.zones.dialog']);
  const [currentRegion, setCurrentRegion] = useState(region?.coords || null);
  const [loading, setLoading] = useState<boolean>(true);
  const [valid, setValid] = useState<boolean>(region ? true : false);

  useEffect(() => {
    if (region && !currentRegion && visible) {
      setCurrentRegion(region.coords);
      setValid(true);
    }
  }, [region, visible]);

  const initPolygonRef = useMemo(
    () =>
      (ref: any): void => {
        polygonRef.current = ref;
        if (!ref || startedDrawningRef.current) return;
        ref.geometry.events.add('change', (event) => {
          setCurrentRegion(event.originalEvent.newCoordinates);
          setValid(event.originalEvent.newCoordinates?.every((c: any[]) => c?.length > 3) || false);
        });
        if (region && region?.coords?.length) {
          ref.editor.stopDrawing();
          ref.editor.startEditing();
          return;
        }
        ref.editor.startDrawing();
        startedDrawningRef.current = true;
      },
    [region],
  );

  useEffect(() => {
    if (!visible) {
      startedDrawningRef.current = false;
    }
  }, [visible]);

  const stopEditing = (): void => {
    onStopEditing(currentRegion);
    close();
  };

  const close = (): void => {
    setCurrentRegion(null);
    onClose();
  };

  /**
   * @param coords - region coordinates
   * @return coordinates for the first region point
   */
  const getMapCenter = useCallback((coords) => {
    if (Array.isArray(coords) && Array.isArray(coords[0])) {
      return getMapCenter(coords[0]);
    }
    return coords;
  }, []);

  return (
    <ModalBase
      onAnimationCloseEnd={() => setLoading(true)}
      modalWrapperClass="max-w-none ml-4 mr-4"
      bodyClass="flex flex-grow"
      contentClass="absolute top-0 bottom-0 flex flex-col"
      visible={visible}
      onClose={close}
      title={t('map_title')}
    >
      <div className="flex flex-grow flex-col">
        <div className="flex flex-grow relative">
          <YMaps>
            <Map
              style={{ width: '100%', height: '100%' }}
              defaultState={{
                center: getMapCenter(region?.coords) || MOSCOW_CENTER,
                zoom: 10,
              }}
              modules={['geoObject.addon.editor']}
              onLoad={() => setLoading(false)}
              options={{
                yandexMapDisablePoiInteractivity: true,
              }}
            >
              <ZoomControl />
              {visible && (
                <Polygon
                  instanceRef={initPolygonRef}
                  // @ts-ignore
                  geometry={currentRegion || ([] as any)}
                  options={{
                    editorDrawingCursor: 'crosshair',
                    fillColor: ROUTE_MAP,
                    strokeColor: ROUTE_MAP,
                    strokeWidth: 5,
                    fillOpacity: 0.2,
                  }}
                />
              )}
            </Map>
          </YMaps>
          <FASpinner
            show={loading}
            containerClass="flex justify-center items-center absolute w-full h-full"
            className="fa-2x"
          />
        </div>
        <div className="flex justify-end mt-5 space-x-5">
          <button className="btn btn-light" type="button" onClick={close}>
            {t('actions.cancel')}
          </button>
          <button disabled={!valid} className="btn btn-blue" type="button" onClick={stopEditing}>
            {t('actions.done')}
          </button>
        </div>
      </div>
    </ModalBase>
  );
};

import React from 'react';
import YandexOrderRoute from './yandexOrderRoute';

export interface OrderRouteProps {
  record: any;
  mapRef?: any;
}

const AddressAutoComplete = (props: OrderRouteProps): JSX.Element => {
  return <YandexOrderRoute {...props} />;
};

export default AddressAutoComplete;

import { COST_CENTER_STRING_MAX_LENGTH } from 'constants/policy';
import { isEqual, omit } from 'lodash';
import { StringTypeIdState } from 'types/types';

export const regexps = {
  all: '^.*$',
  allDefault: '^.{0,100}$',
  digits: '^\\d*$',
  symbols: '^[^\\p{L}\\d]*$',
  letters: '^[\\p{L}\\s]*$',
  lettersAndDigits: '^[\\p{L}\\d\\s]*$',
} as const;

export const defaultState: StringTypeIdState = {
  stringHasLimit: false,
  digits: true,
  letters: true,
  symbols: true,
  stringLimitMin: 0,
  stringLimitMax: COST_CENTER_STRING_MAX_LENGTH,
};

export const getRegExpData = (record): StringTypeIdState => {
  const { regexp } = record || {};
  if (!regexp) return defaultState;
  let state: StringTypeIdState = { ...defaultState, regexpWithoutLimit: regexp };
  const limit = regexp.match(/\{\d{1,},\d{1,}\}/g); // returns {min,max} part of regexp
  if (limit && limit.length > 0) {
    const reg: string = regexp.replace(limit[0], '*');
    // find allowed characters.
    state = {
      digits:
        (reg.includes('[^') ? !reg.includes('\\d') : reg.includes('\\d')) ||
        reg === regexps.all ||
        regexp === regexps.allDefault,
      letters:
        (reg.includes('[^') ? !reg.includes('\\p{L}') : reg.includes('\\p{L}')) ||
        reg === regexps.all ||
        regexp === regexps.allDefault,
      symbols: reg.includes('[^') || reg === regexps.all || regexp === regexps.allDefault,
      regexpWithoutLimit: reg,
    };
    const [min, max] = limit[0].replace(/[\{\}]/g, '').split(',');
    state =
      min && max
        ? {
            ...state,
            stringLimitMin: +min,
            stringLimitMax: +max,
            stringHasLimit: +min !== 0 || +max !== COST_CENTER_STRING_MAX_LENGTH,
          }
        : state;
    state = { ...state, isStringLimited: !isEqual(defaultState, omit(state, 'regexpWithoutLimit')) };
  }
  return state;
};

export const getRexExpByStringState = (stringTypeState: StringTypeIdState): string => {
  const {
    digits,
    letters,
    symbols,
    stringLimitMin = 0,
    stringLimitMax = COST_CENTER_STRING_MAX_LENGTH,
    stringHasLimit,
  } = stringTypeState;
  const limit = stringHasLimit ? `{${stringLimitMin},${stringLimitMax}}` : '{0,100}';
  let regexp = '';
  if (digits && letters && symbols) {
    regexp = regexps.all;
  } else {
    regexp = symbols ? regexps.symbols : '';
    regexp = letters && !symbols ? regexps.letters : letters ? regexp.replace('\\p{L}', '') : regexp;
    regexp =
      digits && !symbols
        ? letters
          ? regexps.lettersAndDigits
          : regexps.digits
        : digits
          ? regexp.replace('\\d', '')
          : regexp;
  }
  return regexp?.replace(/\*/g, limit);
};

import axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';
import Storage from 'utils/storage';
import { APP_PLATFORM, APP_VERSION } from 'utils/deviceInfo';

/**
 * Универсальный парсер ошибок axios запросов
 * @param err
 * @returns {string}
 */

const BAD_REQUEST_ERROR = 'Bad request';
const UNAUTHORIZED_ERROR = 'Unauthorized';
const NOT_FOUND_ERROR = 'Not found';
const FORBIDDEN_ERROR = 'Forbidden';
const CONNECTION_REFUSED_ERROR = 'Connection refused';
const CONFLICT_ERROR = 'Conflict';
const SERVER_ERROR = 'Server error';
const INVALID_TOKEN_ERROR = 'Invalid token';
const UNKNOWN_ERROR = 'Unknown error';
export const getErrorMessage = (err: any) => {
  if (!err) {
    return UNKNOWN_ERROR;
  }
  if (err.response) {
    if (err.response.data.message) {
      return `${err.response.data.message}`;
    }

    if (err.response.data.errors) {
      const firstErrorField = Object.keys(err.response.data.errors)[0];
      if (Array.isArray(err.response.data.errors[firstErrorField])) {
        return `${err.response.data.errors[firstErrorField][0].message}`;
      }
      return `${err.response.data.errors[firstErrorField]}`;
    }

    if (err.response.status === 400) {
      return BAD_REQUEST_ERROR;
    }

    if (err.response.status === 401) {
      return UNAUTHORIZED_ERROR;
    }

    if (err.response.status === 404) {
      return NOT_FOUND_ERROR;
    }

    if (err.response.status === 403) {
      return FORBIDDEN_ERROR;
    }

    if (err.response.status === 409) {
      return CONFLICT_ERROR;
    }
  } else if (err.request) {
    return CONNECTION_REFUSED_ERROR;
  } else if (err.message && err.message !== '') {
    return `${err.message}`.replace(/Error: /g, '');
  } else if (err !== '') {
    return `${err}`.replace(/Error: /g, '');
  }
  return SERVER_ERROR;
};

export const getValidationErrors = (err: any) => {
  if (!err || !err.response) {
    return null;
  }

  const {
    data: { errors },
  } = err.response;
  if (!errors) {
    return null;
  }

  return Object.keys(errors).reduce((acc, field) => {
    return {
      ...acc,
      [field]: errors[field][0].message,
    };
  }, {});
};

class WebApi {
  api: AxiosInstance | undefined = undefined;
  auth = undefined;
  jti: string = '';
  axiosProps: any = undefined;

  constructor() {
    this.axiosProps = {
      baseUrl: process.env.REACT_APP_API_BASE_URL,
      timeout: 60000,
      withCredentials: true,
      paramsSerializer: (params: any) => qs.stringify(params),
    };

    this.api = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      timeout: 60000,
      withCredentials: true,
      paramsSerializer: (params: any) => qs.stringify(params),
    });

    this.jti = '';
    this.api.interceptors.request.use(
      async (response) => ({ ...(await this.transformRequest(response)) }),
      async (error) => {
        try {
          let config = error.config;
          console.log('onResponse');
          console.log({
            'config._retried': config._retried,
            'config.headers.Authorization': config.headers.Authorization,
          });
          return Promise.reject(error);
        } catch (error) {
          console.debug('Error in handleRefreshOnExpiredToken', error);
          return Promise.reject(error);
        }
      },
    );
    /*this.api.interceptors.request.use(
      async (config: any) => ({
        ...(await this.transformRequest(config)),
      }),
      (err: any) => {
        console.error(err);
        Promise.reject(err);
      },
    );*/
    /*
        axiosRetry(this.api, {
            retries: 3,
            shouldResetTimeout: true,
            retryDelay: (retryCount) => retryCount * 300,
            retryCondition: (error) => {

                alert(error);
                return false;
                if (isNetworkOrIdempotentRequestError(error)) {
                    return true;
                }

                if (!error.config || !isRetryableError(error)) {
                    return false;
                }

                if (isIdempotentRequestError(error)) {
                    // Повторная отправка всех запросов с методом, отличным от POST
                    return true;
                }
                return false;
                // Если это запрос получения токена - разрешена повторная отправка
                retu333rn isTokenRequest(error.config);
            },
        });
        */
  }

  async fetch(config: any, isReturnBody = false) {
    let result: any;

    let baseUrlVersion = config.urlVersion ? config.urlVersion : 1;
    config.url = '/v' + baseUrlVersion + config.url;
    config.url = config.url + (config.url.match(/\?/) ? '&' : '?') + 'platform=' + APP_PLATFORM + '&ver=' + APP_VERSION;
    try {
      console.log('CONFIG', config);

      result = await this.api?.request(config);
      if (isReturnBody) {
        return result.data;
      }
    } catch (err) {
      const error = err.response && err.response.data ? err.response.data.error : err.response;
      console.log('=======================================================');
      console.error('Server response error: ', config.method, config.url, getErrorMessage(error), this.axiosProps);
      console.error('CONFIG', config);
      console.error('TOKEN', await Storage.getItem('access_token'));
      if (getErrorMessage(error) === INVALID_TOKEN_ERROR) {
        await Storage.removeItem('access_token');
        console.error('INVALID_TOKEN');
        //return "INVALID_TOKEN";
      }

      const errorV = new Error(getErrorMessage(error));
      if (err?.response?.status === 409) {
        //@ts-ignore
        errorV.code = err?.response?.status;
      }
      throw errorV;
    }

    if (result.status !== 200 || result.data.status !== 'success') {
      if (result.data.error === INVALID_TOKEN_ERROR) {
        await Storage.removeItem('access_token');
        console.error('INVALID_TOKEN');
      }
      //NotificationManager.error('Error: ' + result);
      console.error('--------------------------------------------------------');
      console.error('Server response error: ', result);
      console.error('Server response error: ', config.method, config.url, result, this.axiosProps);
      console.error('CONFIG', config);
      console.error('TOKEN', await Storage.getItem('access_token'));
      const err = new Error(result);
      if (result.status === 409) {
        //@ts-ignore
        err.code = result.status;
      }
      throw err;
    }
    return result.data.data;
  }

  async uploadFile(config: any, formData: any) {
    const baseUrlVersion = config.urlVersion ? config.urlVersion : 1;
    const { url } = config;
    let _url = url + (url.match(/\?/) ? '&' : '?') + 'platform=' + APP_PLATFORM + '&ver=' + APP_VERSION;
    _url = `v${baseUrlVersion}/${_url}`; // 'v' + baseUrlVersion + _url;
    return await this.api?.post(`${process.env.REACT_APP_API_BASE_URL}/${_url}`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: 'Bearer ' + (await Storage.getItem('access_token')),
      },
    });
  }

  setJti(jti: string) {
    this.jti = jti;
  }

  async checkToken() {
    try {
      await this.fetch({ method: 'get', url: `/auth/token` });
    } catch (err) {
      if (err === INVALID_TOKEN_ERROR) {
        return false;
      }
    }
    return true;
  }

  async fetchSpecifications() {
    return await this.fetch({ method: 'get', url: `/specifications` });
  }

  /**
   * Метод добавляет content-type и форматирует тело post и put запроса в json.
   * Подставляет csrf токен к запросам типа post, put, delete
   * Необходимо для того, чтобы можно было повторно отправлять запрос из axiosRetry, т.к.
   * библиотека отключает любые трансформации запроса, в том числе автоматическое добавление
   * content type и приведение объекта к json в axios
   * @param axiosConfig
   * @returns {*}
   */
  async transformRequest(axiosConfig: any) {
    const config = {
      ...axiosConfig,
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
        csrfToken: this.jti,
        ...(axiosConfig.headers ? axiosConfig.headers : {}),
      },
    };

    if ((await Storage.getItem('websocket_uuid')) !== null) {
      config.headers['Web-Socket-Id'] = await Storage.getItem('websocket_uuid');
    }

    console.log('transformRequest -->', await Storage.getItem('access_token'));
    if ((await Storage.getItem('access_token')) !== null) {
      config.headers['Authorization'] = 'Bearer ' + (await Storage.getItem('access_token'));
    }

    return config;
  }
}

export default new WebApi();

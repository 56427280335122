import { makeObservable } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';

class AirportsStore extends BaseStore {
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['CitiesAirports']);
    makeObservable(this);
  }
}

export default AirportsStore;

import ModalBase from 'components/ModalBase';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Portal } from './Portal';

interface ConfirmModalProps {
  title: string;
  text: string;
  onConfirm: any;
  onCancel: any;
  onClose: any;
  visible: boolean;
}

const ConfirmModal = ({
  onClose,
  onCancel,
  onConfirm,
  title,
  text,
  visible,
}: ConfirmModalProps): JSX.Element | null => {
  const { t } = useTranslation('actions');

  return visible ? (
    <Portal className="transparent">
      <ModalBase visible={visible} title={title} onClose={onClose}>
        <p className="">{text}</p>
        <div className="flex justify-end space-x-5 mt-5">
          <button type="button" onClick={onCancel} className="btn btn-light">
            {t('cancel')}
          </button>
          <button type="button" onClick={onConfirm} className="btn btn-blue">
            {t('yes')}
          </button>
        </div>
      </ModalBase>
    </Portal>
  ) : null;
};

export default ConfirmModal;

import { observer } from 'mobx-react-lite';
import React, { HTMLAttributes, useCallback, useMemo } from 'react';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';
import { formatError } from 'utils/lib/formatError';
import { notify } from 'utils/lib/notify';
import { useStores } from 'stores';
import { ReactComponent as ExportIcon } from '../../../assets/svg/exportIcon.svg';

interface ExportButtonProps {
  fields: string[];
  filter?: any;
  fileType?: string;
  sortBy?: string;
  fileName?: string;
  store?: any;
  title?: string;
}

export const ExportButton = observer(
  ({
    filter = {},
    fields,
    fileType = 'xlsx',
    sortBy = 'id desc',
    fileName = 'report',
    store,
    title,
    ...rest
  }: ExportButtonProps & HTMLAttributes<HTMLButtonElement>): JSX.Element => {
    const { t } = useTranslation('actions');
    const { usersStore } = useStores();

    const isLoading = useMemo(() => {
      return store?.isFetchingExportInProgress || store.exportData;
    }, [store.isFetchingExportInProgress, store.exportData]);

    const handleExportData = useCallback(
      async (e) => {
        if (rest?.onClick) rest.onClick(e);
        try {
          await store.fetchExport(filter, sortBy, fields, fileType);
        } catch (err) {
          usersStore.captureError(err);
          notify({
            title: t('error', { ns: 'errors' }) + '!',
            message: `${t('failedExport', { ns: 'errors' })}: ${formatError(err, t)}`,
            type: 'danger',
          });
        }
      },
      [store, filter, sortBy, fields, fileType, rest],
    );

    return (
      <button
        disabled={isLoading}
        {...rest}
        className={`btn btn-outline-blue px-1.5 w-10 ${rest.className || ''}`}
        type="button"
        onClick={handleExportData}
      >
        <ExportIcon />
        <span className="hidden lg:block">{!isLoading ? title || t('export') : t('exporting')}</span>
      </button>
    );
  },
);

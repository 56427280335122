export const getStatusClassAndTitle = (statusId: string): string[] => {
  if (!statusId) {
    return [];
  }
  let status, classSuffix;
  switch (statusId) {
    case 'error':
      classSuffix = 'inactive';
      status = 'Ошибка';
      break;
    case 'driving':
      classSuffix = 'active';
      status = 'В пути';
      break;
    case 'waiting':
      classSuffix = 'active';
      status = 'Ожидает';
      break;
    case 'transporting':
      classSuffix = 'active';
      status = 'Выполняется';
      break;
    case 'complete':
      classSuffix = 'done';
      status = 'Завершен';
      break;
    case 'feedback':
      classSuffix = 'done';
      status = 'Завершен';
      break;
    case 'cancelled':
      classSuffix = 'inactive';
      status = 'Отменен';
      break;
    case 'search':
    case 'new':
      classSuffix = 'active';
      status = 'Поиск';
      break;
    case 'failed':
      classSuffix = 'inactive';
      status = 'Сбой';
      break;
    case 'expired':
      classSuffix = 'inactive';
      status = 'Просрочен';
      break;
    case 'scheduling':
    case 'scheduled':
      classSuffix = 'active';
      status = 'Зарезервирован';
      break;
    default:
      return [];
  }
  return [classSuffix, status];
};

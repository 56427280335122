import React, { InputHTMLAttributes, useEffect, useRef, useCallback } from 'react';
import { observer } from 'mobx-react-lite';
import { debounce } from 'lodash';

interface SearchBarProps extends InputHTMLAttributes<HTMLInputElement> {
  store?: any;
  containerClass?: string;
  onTextChange?: any;
  onReset?: any;
  debounceTime?: number;
  preventDefaultSearch?: boolean;
  initValue?: string | number;
}

export const SearchBar = observer(
  ({
    store,
    debounceTime = 700,
    containerClass,
    onTextChange,
    onReset,
    // don't fetch list if true
    preventDefaultSearch = false,
    initValue = '',
    ...rest
  }: SearchBarProps): JSX.Element => {
    const inputRef = useRef<HTMLInputElement>(null);
    const handleFilterList = (value: string) => {
      if (preventDefaultSearch) return;
      store.setFilter(false, { $nodx_search: value }, store.orderBy, 0, store.limit, true);
    };

    useEffect(() => {
      if (inputRef.current && !inputRef.current.value && initValue) inputRef.current.value = initValue?.toString();
    }, [initValue]);

    const reset = (): void => {
      if (inputRef.current) {
        inputRef.current.value = '';
      }
      if (onReset) onReset();
      handleFilterList('');
    };

    const inputChange = useCallback(
      debounce((text: string) => {
        handleFilterList(text);
        if (onTextChange) onTextChange(text);
      }, debounceTime),
      [preventDefaultSearch, onTextChange],
    );

    return (
      <div className={`relative  flex-grow md:flex-grow-0 search ${containerClass}`}>
        <svg
          className="w-5 h-5 absolute left-3 top-1/2 -translate-y-1/2"
          fill="currentColor"
          viewBox="0 0 20 20"
          aria-hidden="true"
        >
          <path d="M8.994 15.986c1.515 0 2.99-.494 4.198-1.409l3.134 3.136a.98.98 0 0 0 1.248.114c.092-.077.176-.161.253-.253a.982.982 0 0 0-.114-1.249L14.58 13.19a6.996 6.996 0 0 0-4.163-11.043 6.985 6.985 0 0 0-7.758 3.885 6.997 6.997 0 0 0 1.997 8.447 6.986 6.986 0 0 0 4.339 1.507Zm0-12.22a5.216 5.216 0 0 1 4.821 3.224 5.224 5.224 0 0 1-3.803 7.12 5.216 5.216 0 0 1-5.358-2.22 5.224 5.224 0 0 1 4.34-8.123Z"></path>
        </svg>
        <input
          ref={inputRef}
          className="form-control search-field"
          type="search"
          {...rest}
          onChange={(ev) => inputChange(ev.target.value)}
        />
        <button
          onClick={reset}
          className="absolute right-4 top-1/2 -translate-y-1/2 text-black text-opacity-40 hover:text-opacity-50"
          type="button"
        >
          <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
        {/*
      <div className="dropdown-list" id="suggestions">
        <ul className="dropdown-inner" role="listbox">
          <li className="dropdown-item" id="suggestions-option-0" role="option">Иванов Иван</li>
          <li className="dropdown-item" id="suggestions-option-1" role="option">Ивченко Сергей</li>
        </ul>
      </div>
        */}
      </div>
    );
  },
);

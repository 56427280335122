import React, { useRef, useState } from 'react';
import { DayCardValue, TimeRange } from './index';
import { observer } from 'mobx-react-lite';
import { useNestedTranslation } from '../../hooks/useNestedTranslations';
import TimeInput from '../Inputs/TimeInput';

export interface TimeRangeProps {
  range: TimeRange;
  index: number;
  value: DayCardValue;
  onChange: (value: TimeRange, ind: number) => void;
  onDelete?: (value: TimeRange) => void;
  onRangeButton: (i: number) => void;
}
const defaultEmptyDay = {
  since: null,
  until: null,
};

const TimeRangeComponent = observer(({ range, index, value, onChange, onRangeButton }: TimeRangeProps): JSX.Element => {
  const { t } = useNestedTranslation(['policy.current.rules.schedule', 'actions', 'errors']);

  const getInitialInteravls = () =>
    value?.intervals
      ? [...value.intervals, defaultEmptyDay]
      : [
          {
            since: value?.since || { hour: 0, mins: 0 },
            until: value?.until || { hour: 0, mins: 0 },
          },
          defaultEmptyDay,
        ];

  const [intervals, setIntervals] = useState<TimeRange[]>(getInitialInteravls());
  const currentTimeRef = useRef<{ time: 'since' | 'until'; index: number; changed: boolean }>();

  const filterValidIntervals = (): TimeRange[] => intervals?.filter((int) => int?.since && int?.until);
  const resetCurrentIndex = (): void => {
    if (currentTimeRef.current)
      currentTimeRef.current = {
        ...currentTimeRef.current,
        index: 0,
        changed: false,
      };
  };

  return (
    <div className={`${index ? 'mt-3' : ''} flex items-center`}>
      {range.since || range.until ? (
        <button
          type="button"
          className="pt-5 pr-4"
          onClick={() => onRangeButton(index)}
          style={{ alignSelf: 'stretch' }}
        >
          <i
            className={`fa fa-${filterValidIntervals().length > 1 || index ? 'trash' : 'refresh'}`}
            style={{
              color: filterValidIntervals().length > 1 || index ? 'red' : '',
              opacity: filterValidIntervals().length > 1 || index ? 0.5 : 1,
            }}
            aria-hidden="true"
          ></i>
        </button>
      ) : (
        <div className="pt-5 pr-4">
          <i className="fa fa-trash" style={{ color: 'transparent' }} aria-hidden="true"></i>
        </div>
      )}
      <div className={`mb-5  lg:mb-0 lg:flex-1`}>
        <label className="form-label" htmlFor="time">
          {t('since')}
        </label>
        <div className="relative">
          <TimeInput
            id="since"
            className="form-control form-control--lg"
            value={range?.since}
            onChangeValue={(v) => {
              onChange({ ...range, since: v }, index);
            }}
          />
          <svg
            className="w-6 h-6 text-black text-opacity-40 absolute right-3 top-3 pointer-events-none"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M12 21a9 9 0 1 1 9-9 9.01 9.01 0 0 1-9 9Zm0-16.5a7.5 7.5 0 1 0 7.5 7.5A7.509 7.509 0 0 0 12 4.5Zm2.67 6.75h-1.92V7.5a.75.75 0 1 0-1.5 0V12a.75.75 0 0 0 .75.75h2.67a.75.75 0 1 0 0-1.5Z"></path>
          </svg>
        </div>
      </div>
      <div className={`ml-5 mb-5 lg:mb-0 lg:flex-1`}>
        <label className="form-label" htmlFor="time">
          {t('until')}
        </label>
        <div className="relative">
          <TimeInput
            id="until"
            className="form-control form-control--lg"
            value={range?.until}
            onChangeValue={(v) => {
              onChange({ ...range, until: v }, index);
            }}
          />
          <svg
            className="w-6 h-6 text-black text-opacity-40 absolute right-3 top-3 pointer-events-none"
            viewBox="0 0 24 24"
            fill="currentColor"
          >
            <path d="M12 21a9 9 0 1 1 9-9 9.01 9.01 0 0 1-9 9Zm0-16.5a7.5 7.5 0 1 0 7.5 7.5A7.509 7.509 0 0 0 12 4.5Zm2.67 6.75h-1.92V7.5a.75.75 0 1 0-1.5 0V12a.75.75 0 0 0 .75.75h2.67a.75.75 0 1 0 0-1.5Z"></path>
          </svg>
        </div>
      </div>
    </div>
  );
});

export default TimeRangeComponent;

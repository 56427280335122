import React, { useCallback, useEffect, useState } from 'react';
import { useStores } from 'stores';
import { Option, StringTypeIdState } from 'types/types';
import ModalSelect from 'components/ModalSelect';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import { formatError } from 'utils/lib/formatError';
import ModalBase from 'components/ModalBase';
import FASpinner from 'components/FASpinner';
import { observer } from 'mobx-react-lite';
import readXlsxFile from 'read-excel-file';
import writeXlsxFile from 'write-excel-file';
import { notify } from 'utils/lib/notify';
import { omit } from 'lodash';
import { saveFile } from 'utils/lib/fileHandlers/saveFile';
import { useWindowSize } from 'hooks/useWindowSize';
import { getRegExpData, getRexExpByStringState } from 'utils/lib/regExp/stringCostCenter';
import { COST_CENTER_STRING_MAX_LENGTH } from 'constants/policy';

const example = require('../../assets/import-examples/list.xlsx');

export interface CostCenter {
  id?: string | number;
  name?: string;
  list?: Array<string>;
  type_id?: string;
  role_id?: string;
  regexp?: string;
  required?: number;
  update?: any;
  delete?: any;
}

interface DialogProps {
  visible: boolean;
  onCancel: any;
  onAdd?: any;
  onClose?: any;
  record?: CostCenter;
}

interface Validation {
  type_id?: string;
  submit?: string;
  format?: string;
  limit_length?: string;
}

const defaultCostCenter: CostCenter = {
  name: '',
  list: [],
  type_id: '',
  required: 0,
};

const Dialog = observer(({ visible, onCancel, onAdd, onClose, record }: DialogProps) => {
  const getCostCenterData = useCallback(
    (): CostCenter => ({
      name: record?.name || '',
      list: record?.list || [],
      type_id: record?.type_id || '',
      required: record?.required || 0,
    }),
    [record],
  );

  const { rolesCostCentersStore, rolesStore } = useStores();
  const [costCenter, setCostCenter] = useState<CostCenter>(getCostCenterData());
  const [errors, setErrors] = useState<Validation>({});
  const [stringTypeState, setStringTypeState] = useState<StringTypeIdState>(getRegExpData(record));
  const { t } = useNestedTranslation(['policy.current.cost_centers.dialog', 'errors', 'validation', 'actions']);
  const types = [
    { id: '1', value: 'string', label: t('types.string') },
    { id: '2', value: 'dropdown', label: t('types.dropdown') },
    { id: '3', value: 'date', label: t('types.date') },
  ];
  const [isLimitedString, setIsLimitedString] = useState<boolean>(false);
  const windowSize = useWindowSize();

  let listItems = costCenter?.list ? [...costCenter?.list, ''] : ['', ''];

  useEffect(() => {
    if (visible) {
      setCostCenter(getCostCenterData());
      const data = getRegExpData(record);
      setStringTypeState(data);
      setIsLimitedString(data.isStringLimited || false);
    }
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible, record]);

  const handleChangeByField = (data: Partial<CostCenter>): void => {
    if (data.type_id) setErrors(omit(errors, 'format'));
    setCostCenter((prevState) => ({
      ...prevState,
      ...data,
    }));
  };

  const onFocus = (): void => {
    setErrors({});
  };

  const add = async (e: any): Promise<void> => {
    e.preventDefault();
    const { stringLimitMin = 0, stringLimitMax = 0, stringHasLimit } = stringTypeState;
    if (stringHasLimit && stringLimitMin > stringLimitMax) {
      setErrors({ limit_length: t('error.wrong_limits', { ns: 'validation' }) });
      return;
    }
    let regexp: any = {};
    if (costCenter.type_id === 'string') {
      regexp = {
        regexp: isLimitedString ? getRexExpByStringState(stringTypeState) : `^.{0,${COST_CENTER_STRING_MAX_LENGTH}}$`,
      };
    }
    if (onAdd) {
      onAdd({ ...costCenter, ...regexp });
    }
    let data: CostCenter = {
      role_id: rolesStore.activeGroup.id,
      type_id: costCenter?.type_id,
      list: costCenter?.list,
      name: costCenter?.name?.trim(),
      required: costCenter?.required,
      ...regexp,
    };
    if (costCenter.type_id !== 'dropdown') {
      data.list = [];
    }
    if (record) {
      await record.update(data);
    } else {
      await rolesCostCentersStore.addRecord(data);
    }
    if (rolesCostCentersStore.addingError) {
      setErrors({
        submit: formatError(rolesCostCentersStore.addingError, t),
      });
      return;
    }
    setCostCenter(defaultCostCenter);
    setStringTypeState({});
    onClose();
    listItems = ['', ''];
  };

  const cancel = (): void => {
    setCostCenter({});
    setStringTypeState({});
    setErrors({});
    if (onCancel) {
      onCancel();
    }
  };

  const addList = (value?: string, index?: number) => {
    setErrors(omit(errors, 'format'));
    setCostCenter((prevCC) => {
      const p = { ...prevCC };
      if (!p.list || !p.list.length) p.list = [];
      p.list[index || 0] = value || '';

      p.list = p.list.filter((item) => item !== '');
      return p;
    });
  };

  const onFileSelect = (e) => {
    setErrors(omit(errors, 'format'));
    const [file] = e.target.files;
    if (!file?.name?.endsWith('xlsx')) {
      setErrors({
        ...errors,
        format: t('error.wrong_format_xlsx', { ns: 'validation' }),
      });
      return;
    }
    try {
      readXlsxFile(file).then((rows) => {
        setCostCenter((prevCC) => ({
          ...prevCC,
          // Use Set to get unique values
          // Use .flat() to create a new array with all sub-array elements concatenated into it
          // @ts-ignore
          list: [...new Set([...rows].flat())],
        }));
      });
    } catch (er) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: er?.message || er?.toString() || formatError('Unknown error', t),
        type: 'danger',
      });
    }
    // Reset input value to allow user reselect previously selected file
    e.target.value = '';
  };

  const importToExcel = async (): Promise<void> => {
    const { list } = costCenter || {};
    if (!isImportAllowed()) {
      return;
    }
    try {
      await writeXlsxFile(
        // @ts-ignore
        [...new Set([...list].flat())].map((item) => [{ type: String, value: item }]),
        {
          fileName: `${costCenter?.name || 'file'}.xlsx`,
        },
      );
    } catch (er) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: er?.message || er?.toString() || formatError('Unknown error', t),
        type: 'danger',
      });
    }
  };

  const isImportAllowed = (): boolean => (costCenter?.list || []).filter((item) => !!item)?.length > 0;

  const downloadSample = (): void => {
    saveFile(t('file_name'), example);
  };

  const resetList = () => {
    setCostCenter((cc) => ({
      ...cc,
      list: [],
    }));
  };

  const handleStringStateChange = (state: Partial<StringTypeIdState>): void => {
    setStringTypeState((prev) => ({
      ...prev,
      ...state,
    }));
  };

  const handleStringLimitChange = (key: 'stringLimitMax' | 'stringLimitMin', value: string): void => {
    const limit = value.replace(/\D/g, '');
    if (+limit > 100) return;
    setStringTypeState((prev) => ({
      ...prev,
      [key]: +limit,
    }));
  };

  return (
    <ModalBase
      title={t('title')}
      disabledClose={rolesCostCentersStore?.isAddingInProgress}
      visible={visible}
      onClose={cancel}
    >
      <form>
        {!!errors?.submit && <p className="mb-5 error">{errors.submit}</p>}
        <div className="mb-5" style={{ paddingBottom: 30 }}>
          <div className="mb-5">
            <label className="form-label">{t('cc_header')}</label>
            <input
              onFocus={onFocus}
              className="form-control form-control--lg"
              value={costCenter?.name}
              onChange={(e: any) => handleChangeByField({ name: e.target.value })}
              type="text"
              id="name"
              placeholder=""
            />
          </div>
          <div className="mb-5">
            <label className="form-label">{t('cc_type')}</label>
            <ModalSelect
              onOpen={onFocus}
              toggleWrapperClass="h-12 dark-form-control"
              options={types}
              onChange={(option: Option) => handleChangeByField({ type_id: option.value })}
              value={costCenter?.type_id ? t(`types.${costCenter.type_id}`) : ''}
            />
            {errors.type_id && <span className="error">{errors.type_id}</span>}
          </div>
          {costCenter.type_id === 'dropdown' ? (
            <>
              <div className="flex justify-between mb-2">
                <div className="">
                  <label htmlFor="from" className="text-underline text-hover cursor-pointer ml-2">
                    {t('export_xlsx')}
                  </label>
                  <input className="hidden" id="from" type="file" onChange={onFileSelect} />
                </div>
                <div className="">
                  {isImportAllowed() ? (
                    <label onClick={importToExcel} className={`text-underline cursor-pointer text-hover`}>
                      {t('import_xlsx')}
                    </label>
                  ) : (
                    <label onClick={downloadSample} className={`text-underline cursor-pointer text-hover`}>
                      {t('load_exmaple')}
                    </label>
                  )}
                </div>
              </div>
              {!!errors.format && <p className="error">{errors.format}</p>}
              <label className="form-label">{t('cc_elements')}</label>
              <div style={{ maxHeight: 500 }} className="overflow-x-auto">
                {listItems?.map((item, index) => (
                  <div className="mb-5">
                    <input
                      className="form-control form-control--lg"
                      value={item}
                      onChange={(e: any) => addList(e.target.value, index)}
                    />
                  </div>
                ))}
              </div>
            </>
          ) : null}
          {costCenter.type_id === 'string' ? (
            <>
              <div className="mb-4">
                <input
                  type="checkbox"
                  id="limits"
                  checked={isLimitedString}
                  onChange={() => setIsLimitedString(!isLimitedString)}
                />
                <label className="text-black text-opacity-40 ml-2.5" htmlFor="limits">
                  {t('set_limits')}
                </label>
              </div>
              {isLimitedString ? (
                <div className="ml-2 mb-6">
                  <div className="mb-4">
                    <input
                      type="checkbox"
                      id="symbols"
                      checked={!!stringTypeState.symbols}
                      onChange={({ target }) =>
                        handleStringStateChange({
                          symbols: target.checked,
                        })
                      }
                    />
                    <label className="custom-radio-label" htmlFor="symbols">
                      <span className="custom-radio-box"></span>
                      <span className="text-black text-opacity-40 ml-2.5">{t('symbols')}</span>
                    </label>
                  </div>
                  <div className="mb-4">
                    <input
                      type="checkbox"
                      id="letters"
                      checked={!!stringTypeState.letters}
                      onChange={({ target }) =>
                        handleStringStateChange({
                          letters: target.checked,
                        })
                      }
                    />
                    <label className="custom-radio-label" htmlFor="letters">
                      <span className="custom-radio-box"></span>
                      <span className="text-black text-opacity-40 ml-2.5">{t('letters')}</span>
                    </label>
                  </div>
                  <div className="mb-4">
                    <input
                      type="checkbox"
                      id="digits"
                      checked={!!stringTypeState.digits}
                      onChange={({ target }) =>
                        handleStringStateChange({
                          digits: target.checked,
                        })
                      }
                    />
                    <label className="custom-radio-label" htmlFor="digits">
                      <span className="custom-radio-box"></span>
                      <span className="text-black text-opacity-40 ml-2.5">{t('digits')}</span>
                    </label>
                  </div>
                  <div className="mb-4">
                    <input
                      type="checkbox"
                      id="limit"
                      checked={!!stringTypeState.stringHasLimit}
                      onChange={({ target }) =>
                        handleStringStateChange({
                          stringHasLimit: target.checked,
                        })
                      }
                    />
                    <label className="text-black text-opacity-40 ml-2.5" htmlFor="limit">
                      {t('string_limit')}
                    </label>
                  </div>
                  {!!stringTypeState.stringHasLimit && (
                    <div className="mb-4">
                      {errors.limit_length && <span className="error block mb-2">{errors.limit_length}</span>}
                      <span className="text-black text-opacity-40">{t('string_length')}</span>
                      <b className="text-black text-opacity-40 ml-2.5">{t('string_length_min')}</b>
                      <input
                        onFocus={onFocus}
                        className="ml-2.5 form-control form-control--lg w-16 inline"
                        value={stringTypeState.stringLimitMin?.toString()}
                        onChange={(e: any) => handleStringLimitChange('stringLimitMin', e.target.value)}
                        type="text"
                        id="stringLimitMin"
                        placeholder=""
                      />
                      <b className="text-black text-opacity-40 ml-2.5">{t('string_length_max')}</b>
                      <input
                        onFocus={onFocus}
                        className="ml-2.5 form-control form-control--lg w-16 inline"
                        value={stringTypeState.stringLimitMax?.toString()}
                        onChange={(e: any) => handleStringLimitChange('stringLimitMax', e.target.value)}
                        type="text"
                        id="stringLimitMin"
                        placeholder=""
                      />
                    </div>
                  )}
                </div>
              ) : null}{' '}
            </>
          ) : null}
          <div className="mb-4">
            <input
              type="checkbox"
              id="order-self"
              checked={!!costCenter.required}
              onChange={({ target }) => handleChangeByField({ required: +target.checked })}
            />
            <label className="text-black text-opacity-40 ml-2.5" htmlFor="order-self">
              {t('cc_required')}
            </label>
          </div>
        </div>
        <div className="flex justify-end space-x-5 flex-wrap">
          {costCenter.type_id === 'dropdown' && listItems.length > 1 && (
            <button
              className={`btn btn-light ${(windowSize?.width || 0) < 410 ? 'w-full mb-4' : ''}`}
              type="button"
              disabled={rolesCostCentersStore?.isAddingInProgress}
              onClick={resetList}
            >
              {t('reset', { ns: 'actions' })}
            </button>
          )}
          <button
            className="btn btn-light"
            type="button"
            disabled={rolesCostCentersStore?.isAddingInProgress}
            onClick={cancel}
          >
            {t('actions.cancel')}
          </button>
          <button
            className="btn btn-blue"
            type="submit"
            onClick={add}
            disabled={
              !costCenter?.name?.trim() || !costCenter?.type_id?.trim() || rolesCostCentersStore?.isAddingInProgress
            }
          >
            <FASpinner containerClass="mr-2" show={rolesCostCentersStore?.isAddingInProgress} />
            {record ? t('actions.save') : t('actions.add')}
          </button>
        </div>
      </form>
    </ModalBase>
  );
});

export default Dialog;

import React from 'react';
import AppHeader from './AppHeader';
import { useHistory } from 'react-router-dom';

const Layout: React.FC<{}> = ({ children }) => {
  const history = useHistory();
  return (
    <>
      {history.location.pathname && history.location.pathname.match(/\/map\/\d{4,}/) ? '' : <AppHeader />}
      {children}
    </>
  );
};

export default Layout;

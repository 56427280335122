export default {
  title: 'Report',
  loading: 'Loading data',
  error: 'Error',
  actions: {
    export: 'Export',
    exporting: 'Exporting',
  },
  thead: {
    id: 'ID',
    status: 'Status',
    address: 'Address',
    full_name: 'Full name',
    class: 'Class',
    comment: 'Comment',
    cost_centers: 'Cost centers',
    details: 'Trip details',
    department_id: 'Department',
    department_code: 'Department code',
    department_name: 'Department name',
  },
  trow: {
    personal_coordinator: 'Personal coordinator',
    paid_waiting: 'Paid waiting',
    ride_time: 'Travel time',
    order_time: 'Order time',
    full_time: 'Full time',
    distance: 'Distance',
    cost: 'Price with VAT',
    rating: 'Rating',
    wishes: 'Wishes',
    cancel_reason: 'Reason for cancellation',
    minutes_short: 'min',
    km_short: 'km',
    guest: '(guest)',
    orderer: 'Who placed the order',
    support: 'Support',
    cancelled_time: 'Cancellation time',
    entrance: 'entrance',
  },
  filter: {
    title: 'Filters',
    status: 'Status',
    full_name: 'Full name',
    coordinator_id: 'Coordinator',
    id: 'ID',
    class: 'Class',
    date: 'DD.MM.YY-DD.MM.YY',
    search_placeholder: 'Guest name',
    department_id: 'Department',
    department_code: 'Department code',
    department_name: 'Department name',
    actions: {
      apply: 'Apply',
    },
  },
};

import { CURRENT_CURRENCY } from 'constants/currenciesSigns';
import { COMPLETE, DRIVING, SEARCH, TRANSPORTING, WAITING } from 'constants/orderStatuses';
import { observer } from 'mobx-react-lite';
import moment from 'moment';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Place } from 'types/types';
import { getCoords } from 'utils/lib/map/getCoords';
import OrderRoute from '../Rides/OrderRoute';
import { Li } from '../Rides/li';
import { formatPhone } from '../../utils/lib/formatPhone';
import declension from '../../utils/declension';
import NumberIcon from '../NewOrder/numberIcon';
import { CurrentField } from '../../stores/OrdersStore';
import { isRideCallable } from '../../utils/lib/isRideCallable';
import { formatDateValue } from '../../utils/lib/formatDateValue';
import CopiedText from '../../components/CopiedText';
import { CONTACT_PHONE_NUMBER, CONTACT_PHONE_NUMBER_PROMO } from '../../constants/info';

interface DetailsProps {
  selected: boolean;
  ride: any;
  isInfo?: boolean;
  isGettingPhone?: boolean;
  error?: string;
  getDriverPhone: any;
}

const fullTime = 'DD.MM.YY / HH:mm';
const time = 'HH:mm:ss';

const SourceIconVip = () => (
  <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="7" cy="7" r="5" fill="white" stroke="#0B0D0F" strokeWidth="4" />
  </svg>
);
const SourceIcon = () => (
  <svg className="w-6 h-6 flex-shrink-0" style={{ margin: '0.75rem' }} viewBox="0 0 24 24" fill="none">
    <path
      d="M12 23C18.0751 23 23 18.0751 23 12C23 5.92487 18.0751 1 12 1C5.92487 1 1 5.92487 1 12C1 18.0751 5.92487 23 12 23ZM9.51459 6.90881L16.5708 11.1425C17.2182 11.5308 17.2182 12.469 16.5709 12.8574L9.51465 17.0913C8.84812 17.4913 8.00014 17.0112 8.00014 16.2339L8.00011 7.76631C8.00011 6.98902 8.84807 6.50891 9.51459 6.90881Z"
      fill="#FDCD03"
    ></path>
    <path
      d="M16.5709 11.1424L9.51468 6.90873C8.84815 6.50883 8.00019 6.98894 8.00019 7.76623L8.00022 16.2338C8.00022 17.0111 8.84821 17.4912 9.51473 17.0913L16.571 12.8574C17.2183 12.4689 17.2183 11.5308 16.5709 11.1424Z"
      fill="#000"
    ></path>
  </svg>
);

const RoutePlace = ({ source, isInterim = false, index = 0, begin = false, isVip = false }) => {
  const DestinationIcon = () =>
    isInterim ? (
      <div className="w-6 h-6 flex-shrink-0" style={{ margin: '0.75rem' }}>
        <NumberIcon number={index + 1} />
      </div>
    ) : isVip ? (
      <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="2" width="10" height="10" fill="white" stroke="#0B0D0F" strokeWidth="4" />
      </svg>
    ) : (
      <div style={{ margin: '0.75rem' }}>
        <svg className="w-6 h-6" viewBox="0 0 24 24" fill="none">
          <path
            d="M2 2.82387C2 2.82387 5.17647 1.41211 8 1.41211C10.8235 1.41211 13.3529 3.52976 16 3.52976C17.5218 3.52976 19.2768 2.94653 20.494 2.45066C21.1909 2.16674 22 2.6689 22 3.42141V15.0437C22 15.415 21.7951 15.755 21.4586 15.912C20.4035 16.4045 18.0002 17.4121 16 17.4121C13.3529 17.4121 10.4706 15.2945 8 15.2945C5.52941 15.2945 2 16.7062 2 16.7062V2.82387Z"
            fill="#FDCD03"
          ></path>
          <rect x="2" width="2" height="24" rx="1" fill="#000"></rect>
        </svg>
      </div>
    );

  const city = source?.detail && (source?.detail[2] || source?.detail[1] || source?.detail[0]);
  return (
    <div className="flex-row route-place">
      <div className="point-icon">{begin ? isVip ? <SourceIconVip /> : <SourceIcon /> : <DestinationIcon />}</div>
      <div className="point-address">
        <div className="flex-col">{source?.address}</div>
        <div className="flex-col point-address-city">{city?.name}</div>
      </div>
    </div>
  );
};
const DrivingRoute = ({ ride, isVip = false }): JSX.Element => {
  return (
    <div className="driving-route">
      <RoutePlace begin source={ride?.source} isVip={isVip} />
      {ride?.interim_destinations?.map((s) => <RoutePlace source={s} isInterim isVip={isVip} />)}
      <RoutePlace source={ride?.destination} />
    </div>
  );
};
const DriverInfo = ({
  performer,
  t,
  id,
  phone,
  help_phone,
  showPhone,
  isGettingPhone,
  status_id,
  finished_time,
}): JSX.Element => {
  const carInfoItems = performer?.car?.split(' ');

  return (
    <div className="driver-info">
      <div className="flex-row text-center center-position mb-5">
        <div className="flex-row mx-auto">
          <img className="driver-avatar" src={`${process.env.PUBLIC_URL}/i/logo.svg`}></img>
          <div className="driver-name">{performer?.fullname}</div>
        </div>
      </div>
      <div className="flex-row">
        <div className="flex-col" style={{ minWidth: '50%' }}>
          <div className="driver-info-header">{t('info.car')}</div>
          <div className="driver-info-value">{carInfoItems.slice(0, carInfoItems.length - 1).join(' ')}</div>
        </div>
        <div className="flex-col" style={{ minWidth: '50%' }}>
          <div className="driver-info-header">{t('info.car_number')}</div>
          <div className="driver-info-value">
            {carInfoItems.slice(carInfoItems.length - 1, carInfoItems.length).join(' ')}
          </div>
        </div>
      </div>
      <div className="flex-row mt-3">
        {isRideCallable(status_id, finished_time) && (
          <div className="flex-col" style={{ minWidth: '50%' }}>
            <div className="driver-info-header">{t('info.phone')}</div>
            {!phone ? (
              <button className="status ride-active min-w-24 mr-2" onClick={showPhone} disabled={isGettingPhone}>
                {t('details.actions.show')}
              </button>
            ) : (
              <div className="driver-info-value">
                <CopiedText text={phone} />
              </div>
            )}
          </div>
        )}
        <div className="flex-col" style={{ minWidth: '50%' }}>
          <div className="driver-info-header">{t('info.order_id')}</div>
          <div className="driver-info-value">
            <CopiedText text={id} hasIcon />
          </div>
        </div>
      </div>
      <div className="flex-row mt-3">
        <div className="flex-col" style={{ minWidth: '50%' }}>
          <div className="driver-info-header">{t('info.help_phone')}</div>
          <div className="driver-info-value">
            <CopiedText text={help_phone} textToCopy={String(help_phone).replace(/\(|\)|-|\s/g, '')} />
          </div>
        </div>
      </div>
    </div>
  );
};
const Details = observer(
  ({ selected, ride, isInfo, error, getDriverPhone, isGettingPhone }: DetailsProps): JSX.Element => {
    const containerRef = useRef<HTMLDivElement>(null);
    const { t } = useTranslation('rides');
    const mapRef = useRef<any>(null);
    const [rowHeight, setRowHwight] = useState<number>(0);
    const calcRowHeight = () => setRowHwight(selected ? containerRef.current?.scrollHeight || 0 : 0);

    const [windowSizes, setWindowSizes] = useState({ width: window?.innerWidth, height: window.innerHeight });
    const bottomSheetContentRef = useRef<HTMLDivElement>(null);
    const mapHandlerRef = useRef<HTMLDivElement>(null);
    const contentWrapperRef = useRef<HTMLDivElement>(null);
    const toucheStartYRef = useRef<number>(0);
    const touchHandleDiff = useRef<number>(0);
    const lastPositionRef = useRef<number>(0);
    const [isBottomSheetDisabled, setIsBottomSheetDisabled] = useState<boolean>(false);
    const currentDirectionRef = useRef<Omit<CurrentField, 'unset'>>();
    const bottomSnap = 250;
    const topSnap = 380;
    const wrapperRef = useRef<HTMLDivElement>(null);

    const onResize = () => {
      if (window) {
        setWindowSizes((state) => ({ ...state, width: window.innerWidth }));
      }
    };

    const onTouchStart = (e: any): void => {
      if (e.touches?.length > 1) return;
      toucheStartYRef.current = e.changedTouches[0].pageY;
      touchHandleDiff.current =
        toucheStartYRef.current - (bottomSheetContentRef.current?.getBoundingClientRect()?.top || 0);
    };

    const onTouchMove = (e: any): void => {
      if (!bottomSheetContentRef.current || isBottomSheetDisabled || e.touches?.length > 1) return;
      const diff = e.changedTouches[0].pageY - toucheStartYRef.current;
      bottomSheetContentRef.current.style.top = `${lastPositionRef.current}px`;
      bottomSheetContentRef.current.classList.remove('swipe');
      if (Math.abs(diff) < 5) return;
      if (diff > 0) {
        if (
          (wrapperRef.current?.getBoundingClientRect()?.top || 0) - 20 <=
          (bottomSheetContentRef.current?.getBoundingClientRect()?.top || 0)
        )
          return;
        bottomSheetContentRef.current.style.top = `${window.innerHeight - e.changedTouches[0].pageY + touchHandleDiff.current > bottomSnap ? e.changedTouches[0].pageY - touchHandleDiff.current : window.innerHeight - bottomSnap < 0 ? 52 : window.innerHeight - bottomSnap}px`;
        lastPositionRef.current =
          window.innerHeight - e.changedTouches[0].pageY + touchHandleDiff.current > bottomSnap
            ? e.changedTouches[0].pageY - touchHandleDiff.current
            : window.innerHeight - bottomSnap;
        if (lastPositionRef.current === window.innerHeight - bottomSnap) {
          if (contentWrapperRef.current) contentWrapperRef.current.style.overflow = 'hidden';
          bottomSheetContentRef.current.scrollTo(0, 0);
        }
      } else {
        if (e.changedTouches[0].pageY - touchHandleDiff.current <= 52) return;
        bottomSheetContentRef.current.style.top = `${(window.innerHeight - topSnap >= 52 ? window.innerHeight - topSnap : 52) < bottomSheetContentRef.current?.getBoundingClientRect()?.top ? e.changedTouches[0].pageY - touchHandleDiff.current : window.innerHeight - topSnap >= 52 ? window.innerHeight - topSnap : 52}px`;
        lastPositionRef.current =
          (window.innerHeight - topSnap >= 52 ? window.innerHeight - topSnap : 52) <
          bottomSheetContentRef.current?.getBoundingClientRect()?.top
            ? e.changedTouches[0].pageY - touchHandleDiff.current
            : window.innerHeight - topSnap >= 52
              ? window.innerHeight - topSnap
              : 52;
      }
    };

    const onTouchEnd = (e: any): void => {
      if (!bottomSheetContentRef.current || isBottomSheetDisabled || e.touches?.length > 1) return;
      if (
        (wrapperRef.current?.getBoundingClientRect()?.top || 0) - 20 <=
        (bottomSheetContentRef.current?.getBoundingClientRect()?.top || 0)
      )
        return;
      const diff = e.changedTouches[0].pageY - toucheStartYRef.current;
      if (Math.abs(diff) < 5) return;
      let topEnd = bottomSnap;
      if (diff > 0) {
        if (Math.abs(diff) < 60) topEnd = topSnap;
        if (window.innerHeight - e.changedTouches[0].pageY + touchHandleDiff.current < bottomSnap) return;
        document.documentElement.style.setProperty(
          '--top-start',
          `${bottomSheetContentRef.current?.getBoundingClientRect()?.top}px`,
        );
        document.documentElement.style.setProperty('--top-end', `${window.innerHeight - topEnd}px`);
        bottomSheetContentRef.current.classList.add('swipe');
        if (contentWrapperRef.current)
          contentWrapperRef.current.style.overflow = topEnd === topSnap ? 'scroll' : 'hidden';
        bottomSheetContentRef.current.scrollTo(0, 0);
        lastPositionRef.current =
          window.innerHeight - e.changedTouches[0].pageY > bottomSnap
            ? e.changedTouches[0].pageY
            : window.innerHeight - topEnd;
      } else {
        if (Math.abs(diff) > 60) topEnd = topSnap;
        if (e.changedTouches[0].pageY - touchHandleDiff.current <= 52) return;
        document.documentElement.style.setProperty(
          '--top-start',
          `${bottomSheetContentRef.current?.getBoundingClientRect()?.top}px`,
        );
        document.documentElement.style.setProperty(
          '--top-end',
          `${window.innerHeight - topSnap >= 52 ? window.innerHeight - topEnd : 52}px`,
        );
        bottomSheetContentRef.current.classList.add('swipe');
        if (contentWrapperRef.current)
          contentWrapperRef.current.style.overflow = topEnd === topSnap ? 'scroll' : 'hidden';

        lastPositionRef.current = window.innerHeight - topSnap >= 52 ? window.innerHeight - topEnd : 52;
      }
    };

    useEffect(() => {
      document.addEventListener('resize', onResize);
      bottomSheetContentRef.current?.addEventListener('touchstart', onTouchStart);
      bottomSheetContentRef.current?.addEventListener('touchmove', onTouchMove);
      bottomSheetContentRef.current?.addEventListener('touchend', onTouchEnd);
      return () => {
        bottomSheetContentRef.current?.removeEventListener('touchstart', onTouchStart);
        bottomSheetContentRef.current?.removeEventListener('touchmove', onTouchMove);
        bottomSheetContentRef.current?.removeEventListener('touchend', onTouchEnd);
        document.removeEventListener('resize', onResize);
      };
    });
    useEffect(() => {
      calcRowHeight();
    }, [selected]);
    useEffect(() => {
      if (!mapRef?.current || !ride?.source) return;
      mapRef.current?.setCenter(getCoords((isInfo && ride?.current_location) || ride?.source), undefined, {}, true);
    }, [mapRef?.current, isInfo && ride?.current_location]); //, ride?.source, isInfo && ride?.current_location

    const getDate = (): string => {
      let date = '';
      if (ride?.scheduled_datetime) {
        date = ride?.scheduled_datetime;
      } else if (ride?.scheduled_time) {
        date = formatDateValue(ride?.scheduled_time, {
          format: 'DD.MM.YY на HH:mm',
          offset: ride?.city_timezone_offset,
        });
      }
      return date;
    };

    /**
     * print interim destinations
     * @returns a fragment with an array of <li> elements containing interim destination name and value
     */
    const renderInterimDests = (): JSX.Element | null =>
      ride?.interim_destinations?.length > 0 ? (
        <>
          {ride.interim_destinations.map((dest: any, i: number) =>
            dest?.address ? (
              <Li
                onClick={(e) => onPlacePress(e, dest)}
                titleClass="w-24"
                customTitle={`${t('details.interim_destination')} ${i + 1}`}
              >
                {dest.address || ''}
              </Li>
            ) : null,
          )}
        </>
      ) : null;

    /**
     * Move map center to passed place
     * @param e onClick event
     * @param place can be source, interim_destination or destination
     */
    const onPlacePress = (e, place: Place): void => {
      e.stopPropagation();
      if (!mapRef?.current) return;
      mapRef.current?.setCenter(getCoords(place));
    };
    const showDriverPhone = (e: any): void => {
      e.stopPropagation();
      if (getDriverPhone) getDriverPhone(ride?.id);
    };
    const Info = () => (
      <>
        {ride?.status_id === SEARCH && (
          <>
            <div className="loader-container">
              <div className="loader">
                <div className="loader-line" />
              </div>
            </div>
            <h3 className="search-header">{t('info.search_header')}</h3>
            <p>{t('info.search')}</p>
          </>
        )}
        {ride?.status_id === TRANSPORTING && (
          <>
            <h3 className="popup-driving-header">
              {t('info.remaining_time')}
              <br />
              <b className="popup-driving-header">
                {declension('info.mins', parseInt((ride?.estimate_finish_s! / 60) as any), t)}
              </b>
            </h3>
          </>
        )}
        {ride?.status_id === DRIVING && (
          <>
            <h3 className="popup-driving-header">
              {t('info.will_arrive')}
              <br />
              <b className="popup-driving-header">
                {declension('info.mins', parseInt((ride?.arrival_time_s! / 60) as any), t)}
              </b>
            </h3>
          </>
        )}
        {ride?.status_id === WAITING && (
          <>
            <h3 className="popup-driving-header">{t('info.arrived')}</h3>
          </>
        )}
        {ride?.performer && (
          <DriverInfo
            phone={ride?.driver_phone}
            help_phone={ride?.promo_id ? CONTACT_PHONE_NUMBER_PROMO : CONTACT_PHONE_NUMBER}
            t={t}
            performer={ride?.performer}
            status_id={ride?.status_id}
            id={ride?.id}
            finished_time={ride?.finished_time}
            showPhone={showDriverPhone}
            isGettingPhone={isGettingPhone}
          />
        )}
        <div className="route-info">
          <h3>{t('info.route')}</h3>
          <DrivingRoute ride={ride} />
        </div>
      </>
    );

    return (
      <div ref={containerRef} className="overflow-hidden transition-all h-0 duration-300">
        <div
          className="full-screen"
          style={{
            width: windowSizes.width < 992 ? '100vw' : 'calc(100vw - 425px)',
            margin: windowSizes.width < 992 ? '0' : '20px 0 20px',
          }}
        >
          <OrderRoute mapRef={mapRef} record={{ ...ride }} />
        </div>
        {windowSizes.width < 992 ? (
          <>
            <div
              className="fixed"
              style={{
                zIndex: 111,
                backgroundColor: '#FAFAFA',
                left: 0,
                padding: '20px',
                top: windowSizes.height - 170,
                bottom: 0,
                width: '100vw',
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
              }}
            >
              <div className="mb-5 px-4 lg:px-5">
                <div className="lg:w-full md:mx-auto md:w-[30.25rem] relative border border-graystroke bg-graylight bg-opacity-94 rounded-lg">
                  <div ref={wrapperRef} className="h-12 p-3"></div>
                </div>
              </div>
            </div>
            <div
              className={`fixed flex`}
              style={{
                zIndex: 111,
                backgroundColor: '#FAFAFA',
                left: 0,
                padding: '20px',
                top: windowSizes.height - topSnap,
                bottom: 0,
                width: '100vw',
                borderTopLeftRadius: '0.5rem',
                borderTopRightRadius: '0.5rem',
              }}
              ref={bottomSheetContentRef}
            >
              <div
                ref={mapHandlerRef}
                className="flex justify-center w-full"
                style={{
                  background: 'white',
                  height: 20,
                  left: 0,
                  top: 0,
                  position: 'absolute',
                  borderTopLeftRadius: '0.5rem',
                  borderTopRightRadius: '0.5rem',
                  zIndex: 1,
                }}
              >
                <div className="relative" style={{ width: 28, height: 3, marginTop: 5, backgroundColor: '#D5D5DD' }} />
              </div>
              <div ref={contentWrapperRef} className="pb-20" style={{ flex: 1 }}>
                {error ? <div className="order-error">{t('unAuthedOrderError')}</div> : <Info />}
              </div>
            </div>
          </>
        ) : (
          <div className="order-info-popup">
            {error ? <div className="order-error">{t('unAuthedOrderError')}</div> : <Info />}
          </div>
        )}
      </div>
    );
  },
);

export default Details;

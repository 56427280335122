import React, { HTMLAttributes } from 'react';
import { formatPrice } from 'utils/lib/formatPrice';
import { useNestedTranslation } from 'hooks/useNestedTranslations';

interface LimitRowProps extends HTMLAttributes<HTMLDivElement> {
  limit?: number;
  used?: number;
  tKey?: 'personal' | 'group';
}

export const LimitRow = ({ limit, used = 0, tKey, ...divProps }: LimitRowProps): JSX.Element | null => {
  const { t } = useNestedTranslation(['nav.settings.limit']);

  return limit ? (
    <div {...divProps}>
      <p className="one-line-text">
        <b>{formatPrice(limit - used)}</b>
        {' ' + t('of')} {formatPrice(limit)}
      </p>
      <p className="opacity-50 text-xs">{t(`remainder.${tKey}`)}</p>
    </div>
  ) : null;
};

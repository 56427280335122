export default {
  title: 'Travel policy',
  title_new: 'New group',
  search_placeholder: 'Search',
  list: {
    title: 'Travel policy',
    title_new: 'New group',
    actions: {
      new: 'New group',
    },
    group: {
      default: 'Default',
      users: 'users',
      user: 'user',
      users_2_4: 'users',
    },
  },
  no_default: 'Default group not selected',
  loading: 'Loading',
  empty: {
    text: 'Select a group to view and edit it, or create a new one',
    actions: {
      new: 'New group',
    },
  },
  rule_dialog: {
    title: 'Add rule',
    distance: {
      title: 'Distance',
      text: 'Specify how far members of the group can travel',
    },
    month_amount: {
      title: 'Monthly limit per employee',
      text: 'Specify what monthly limit each member of the group should have',
    },
    month_amount_group: {
      title: 'Monthly limit for all group members',
      text: 'Specify a monthly limit for all members of the group',
    },
    schedule: {
      title: 'Time',
      text: 'Choose a time available for group members to order a taxi',
    },
    radius: {
      title: 'Radius',
      text: 'Establish radius and start point',
    },
    zones: {
      title: 'Geofences',
      text: 'Establish zones within which boarding and disembarking is allowed',
    },
    actions: {
      add: 'Add',
    },
  },
  current: {
    title_new: 'New group',
    title_edit: 'Editing a group',
    description: 'Group users and set rules for different situations: delay at the office, business trip, etc.',
    name: 'Name',
    default: 'Use by default',
    name_placeholder: 'e.g. Employees, Management, etc.',
    users: {
      title: 'Users',
      actions: {
        add_user: 'Add user',
      },
      dialog: {
        title: 'Add user',
        placeholder: 'Employee',
      },
      loading: 'Loading data',
      empty: 'Users not found',
    },
    classes: {
      title: 'Vehicle classes',
      text: 'Select the classes available to this group',
    },
    cost_centers: {
      title: 'Cost centers',
      text: 'Set up a list of additional fields that must be specified when creating an order',
      actions: {
        add: 'Add a cost center',
      },
      confirm_msg: 'Are you sure you want to delete the highlighted entry?',
      loading: 'Loading data',
      thead: {
        name: 'Name',
        type: 'Type',
        required: 'Required field',
      },
      trow: {
        yes: 'Yes',
        no: 'No',
      },
      dialog: {
        title: 'Add a cost center',
        cc_header: 'Header',
        cc_type: 'Type',

        export_xlsx: 'Download from excel',
        import_xlsx: 'Upload to excel',
        load_example: 'Download sample Excel file',
        cc_elements: 'List Items',
        file_name: 'List',
        cc_required: 'Required field',
        set_limits: 'Set limits',
        symbols: 'Symbols',
        letters: 'Lettes',
        digits: 'Digits',
        string_limit: 'Limit the number of characters',
        string_length: 'Number of characters',
        string_length_min: 'from',
        string_length_max: 'to',
        actions: {
          cancel: 'Cancel',
          add: 'Add',
          save: 'Save',
        },
        types: {
          string: 'String',
          date: 'Date',
          dropdown: 'List',
        },
      },
    },
    rules: {
      name: 'Radius, m',
      title: 'Travel rules',
      text: "Set rules based on your company's travel policy. When rules are added, a script is created. You can create up to 5 scenarios for each group.",
      actions: {
        add: 'Add rule',
      },
      address: {
        name: 'Scenario name',
        addresses: 'Address',
        addresses_text: 'Users in this group can book trips within a radius of 500 m from the selected addresses',
        allow: 'Allow travel',
        from: 'from',
        to: 'to',
        actions: {
          add: 'Add route',
        },
      },
      driver: {
        comment: 'Комментарий к водителю',
        save: 'Сохранить',
      },
      amount: {
        month_limit: 'Monthly limit (rubles)',
        save: 'Save',
      },
      amount_group: {
        month_limit: 'Monthly limit for group (rubles)',
        save: 'Save',
      },
      distance: {
        max_distance: 'Max distance (kilometers)',
        save: 'Save',
      },
      schedule: {
        schedule: 'Schedule',
        since: 'since',
        until: 'until',
        done: 'Done',
        intervals: 'intervals',
        intervals2_4: 'intervals',
        time_placeholder: 'HH:MM',
      },
      radius: {
        name: 'Radius, km',
        title: 'Points and radius',
        coords: 'Coordinates',
        max_radius: 'Max radius from start point (kilometers)',
        save: 'Save',
        add: 'Add radius`',
        dialog: {
          region_title: 'title',
          map_title: 'Place the point',
          title_new: 'new radius',
          title_editing: 'Edition',
          coords: 'Coordinates',
          map: 'Map',
          actions: {
            add: 'Add',
            cancel: 'Cancel',
            save: 'Save',
            done: 'Done',
          },
        },
      },
      zones: {
        zones: 'Geo-zones of embarkation and disembarkation',
        add_from: 'Add landing zone',
        add_to: 'Add drop zone',
        dialog: {
          region_title: 'Geo-zone name',
          map_title: 'Select geofence',
          title_new: 'New geofence',
          title_editing: 'Editing',
          coords: 'Coordinates',
          map: 'Map',
          actions: {
            add: 'Add',
            cancel: 'Cancel',
            save: 'Save',
            done: 'Done',
          },
        },
      },
    },
    actions: {
      create: 'Create group',
      save: 'Save',
      cancel: 'Cancel',
      make_default: 'Make default',
    },
  },
};

import { makeObservable, observable, action, runInAction } from 'mobx';
import Storage from '../../utils/storage';
import { RootStoreInterface } from '../../interfaces';

const reportRelatedTypes = ['process_report', 'ready_report', 'crash_report'];

class WebSocketStore {
  rootStore: RootStoreInterface;

  @observable
  isOpened = false;

  @observable
  isAuthorized = false;

  isInitializing = false;

  ws: any = undefined;

  subscriptions: any = [];

  constructor(rootStore: RootStoreInterface) {
    makeObservable(this);
    this.rootStore = rootStore;
    setInterval(() => {
      this.setupWebSocket();
    }, 60 * 1000);
    this.setupWebSocket();
  }

  subscribe(moduleName: string, type: string, filter: any) {
    //TODO!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    // !!!check maybe user already subscribed to this filter
    runInAction(() => {
      this.subscriptions = this.subscriptions.filter(
        (subscription: any) => subscription.moduleName !== moduleName || subscription.type !== type,
      );
      this.subscriptions.push({ moduleName, type, filter });
    });
    if (this.isOpened && this.ws && this.ws.readyState && this.isAuthorized) {
      this.ws.send(
        JSON.stringify({
          type: 'subscribe',
          payload: { moduleName, type, filter },
        }),
      );
    }
  }

  @action
  async wssAuth() {
    if (!(await Storage.getItem('access_token'))) {
      return;
    }
    if (!this.isOpened || (this.ws && !this.ws.readyState) || this.isAuthorized) return;
    runInAction(() => {
      this.isAuthorized = true;
    });

    const msg = {
      type: 'authenticate',
      payload: { token: await Storage.getItem('access_token') },
    };
    console.log('WSS', 'Authorization');
    //alert("CONNECTED");
    try {
      this.ws.send(JSON.stringify(msg));
      //resend all subscriptions
      setTimeout(() => {
        this.subscriptions.forEach((subscription: any) => {
          this.ws.send(JSON.stringify({ type: 'subscribe', payload: subscription }));
        });
      }, 1000);
    } catch (e) {
      console.error('WSS', "Couldn't auth websocket", e);
    }
  }

  @action
  setupWebSocket() {
    return;

    if (!this.rootStore.modulesStore.authStore || !this.rootStore.modulesStore.authStore.isAuthenticated) return;
    if (this.isOpened) return;
    if (this.isInitializing) return;

    runInAction(() => {
      this.isInitializing = true;
    });
    /*
        if ( this.ws ) {
            this.ws.close();
            delete this.ws;
        }
         */

    this.ws = new WebSocket(process.env.REACT_APP_API_WSS_URL || '');
    this.ws.onmessage = ({ data }: { data: any }) => {
      let json = JSON.parse(data);
      console.log(
        '============================================== DISPATCH ===============================================',
      );
      console.log('WSS', 'NEW MESSAGE', data.substr(0, 150), '...');
      if (json.type === 'uuid') {
        console.log('WEBSOCKET SET UUID');
        Storage.setItem('websocket_uuid', json.payload.uuid);
      }

      if (json.type === 'dispatch') {
        console.log(
          'DISPATCH',
          'WEBSOCKET RECEIVED',
          json.payload.moduleName,
          JSON.stringify(json.payload.item).substr(0, 100),
        );
        Object.keys(this.rootStore.modulesStore).forEach((storeName) => {
          const store = this.rootStore.modulesStore[storeName];
          if (store.specification && store.specification.moduleName === json.payload.moduleName) {
            //console.log("WSS","DISPATCH", "FOUND", store.specification.moduleName);
            store.dispatch(json.payload.item);
          }
        });
      }
      if (reportRelatedTypes.includes(json.type)) {
        Object.keys(this.rootStore.modulesStore).forEach((storeName) => {
          const store = this.rootStore.modulesStore[storeName];
          if (store.specification && store.specification.moduleName === json.payload.moduleName) {
            store.updateExportData(json.payload);
          }
        });
      }
      /*
            let find = false;
            let newQueue = state.queue.map((it) => {
                if (it.id !== newItem.id) {
                    return it;
                }
                find = true;
                return newItem;
            });
            if (!find) {
                newQueue = [newItem, ...newQueue];
            }
            setQueue(newQueue);
            */
    };

    const interval = setInterval(() => {
      //console.log("WSS PING", moment());
      try {
        if (this.isOpened) {
          this.ws.send(JSON.stringify({ type: 'ping' }));
        } else {
          console.log('WSS. Setup new socket');
          clearInterval(interval);
          setTimeout(() => {
            if (!this.isOpened) {
              this.setupWebSocket();
            }
          }, 10 * 1000);
        }
      } catch (e) {
        console.error('PINg error', e);
      }
    }, 60 * 1000);

    this.ws.onopen = () => {
      console.log('WSS', 'ONOPEN');
      runInAction(() => {
        this.isOpened = true;
        this.isInitializing = false;
      });
      this.wssAuth();
    };

    this.ws.onclose = () => {
      console.log('WSS', 'RECONNECT');
      runInAction(() => {
        this.isOpened = false;
        this.isAuthorized = false;
        this.isInitializing = false;
      });
      setTimeout(() => {
        this.setupWebSocket();
      }, 1000 * 10);
      //alert(11);
    };
  }

  /*
        subscribe (scheme, id) {
            this.ws.send (JSON.stringify({
                type   : 'dispatch',
                payload: {
                    schema: 'psychologists',
                    id: 1
                }
            }));
        }
        */
}

/*

decorate(WebSocketStore, {

    setupWebSocket: action,
    wssAuth: action,
    isOpened: observable,
    isAuthorized: observable,
});
*/

export default WebSocketStore;

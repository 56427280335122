import React, { useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import moment from 'moment';
import { DayCard } from 'components/DayCard';
import { upperCaseFirstChar } from 'utils/lib/upperCaseFirstChar';
import { notify } from 'utils/lib/notify';
import { formatError } from 'utils/lib/formatError';
import { omit } from 'lodash';

interface ScheduleProps {}

interface DaySchedule {
  since: string;
  until: string;
  disabled: boolean;
}

const keys: string[] = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];

const Schedule = observer(({}: ScheduleProps): JSX.Element | null => {
  const { rolesStore } = useStores();
  const days: string[] = moment.weekdaysShort();
  const sunday: string = days.shift() as string;
  days.push(sunday);
  let daysLong: string[] = moment.weekdays();
  const sundayLong: string = daysLong.shift() as string;
  daysLong.push(sundayLong);
  daysLong = daysLong.map((day: string) => upperCaseFirstChar(day));
  const { activeGroup } = rolesStore || {};
  const [inProgressName, setInProgressName] = useState<string[]>([]);
  const { t } = useNestedTranslation(['policy', 'errors']);
  const stateRef = useRef<any>({});

  const onCardPress = async (key: string, index: number): Promise<void> => {
    setInProgressName((state) => [...state, key]);
    const deleting: boolean = !activeGroup.schedule[key]?.disabled;
    stateRef.current[key] = {
      ...activeGroup.schedule[key],
      disabled: !activeGroup.schedule[key]?.disabled,
    };
    if (!activeGroup.schedule) return;
    await rolesStore.updateActiveGroup({
      schedule: {
        ...activeGroup.schedule,
        ...stateRef.current,
        [key]: {
          ...activeGroup.schedule[key],
          disabled: !activeGroup.schedule[key]?.disabled,
        },
      },
    });
    if (rolesStore?.addingError) {
      notify({
        type: 'danger',
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t(`${deleting ? 'failedDayDeletion' : 'failedDayAdding'}`, { ns: 'errors' })} \"${daysLong[index] || ''}\": ${formatError(rolesStore?.addingError, t)}`,
      });
    }
    setInProgressName((state) => state.filter((n: string) => n !== key));
    stateRef.current = omit(stateRef.current, key);
  };

  const onDeleteRule = async (): Promise<void> => {
    await rolesStore.updateActiveGroup({ schedule: {} });
    if (rolesStore?.addingError) {
      notify({
        type: 'danger',
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedRuleDeletion', { ns: 'errors' })} \"${t('rule_dialog.schedule.title') || ''}\": ${formatError(rolesStore?.addingError, t)}`,
      });
    }
  };

  return (
    <div className="pt-[54px] w-full pb-5 relative md:px-0">
      <button className="btn-close absolute right-8 top-4 md:right-4 md:top-5" type="button" onClick={onDeleteRule}>
        <svg className="w-[22px] h-[22px]" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M16.28 7.72a.75.75 0 0 0-1.06 0L12 10.94 8.78 7.72a.75.75 0 1 0-1.06 1.06L10.94 12l-3.22 3.22a.75.75 0 1 0 1.06 1.06L12 13.06l3.22 3.22a.75.75 0 0 0 1.06-1.06L13.06 12l3.22-3.22a.75.75 0 0 0 0-1.06Z"></path>
          <path d="M15.75 1.5h-7.5A6.75 6.75 0 0 0 1.5 8.25v7.5a6.75 6.75 0 0 0 6.75 6.75h7.5a6.75 6.75 0 0 0 6.75-6.75v-7.5a6.75 6.75 0 0 0-6.75-6.75ZM21 15.75A5.25 5.25 0 0 1 15.75 21h-7.5A5.25 5.25 0 0 1 3 15.75v-7.5A5.25 5.25 0 0 1 8.25 3h7.5A5.25 5.25 0 0 1 21 8.25v7.5Z"></path>
        </svg>
      </button>
      <label className="form-label absolute top-9 left-4" htmlFor="scenario">
        {t('current.rules.schedule.schedule')}
      </label>
      <div className="mb-5 w-full flex flex-shrink py-4 overflow-x-auto px-5">
        {activeGroup?.schedule &&
          keys.map((key: string, index: number) => (
            <div className={`${index && 'ml-2.5'}`}>
              <DayCard
                dayKey={key}
                inProgress={inProgressName.includes(key)}
                isWeekendDay={index === 5 || index === 6}
                value={activeGroup.schedule[key]}
                day={days[index]}
                dayLong={daysLong[index]}
                active={!activeGroup.schedule[key]?.disabled}
                onClick={() => onCardPress(key, index)}
              />
            </div>
          ))}
      </div>
    </div>
  );
});

export default Schedule;

import BaseCRUD from '../BaseCRUD';
import WebApiClient from '../WebApi';

class Index extends BaseCRUD {
  async getDefault(): Promise<any> {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/list`,
      data: {
        filter: { default: 1 },
      },
    });
  }
}

export default Index;

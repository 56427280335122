import React, { InputHTMLAttributes, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useStores } from 'stores';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import { formatError } from 'utils/lib/formatError';
import { notify } from 'utils/lib/notify';
import FASpinner from 'components/FASpinner';
import { useWindowSize } from 'hooks/useWindowSize';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  field: string;
  title: string;
  inputOnly?: boolean;
  allowEmpty?: boolean;
}

const InputField = observer(
  ({
    field,
    title,
    type = 'number',
    inputOnly = false,
    allowEmpty = false,
    ...rest
  }: InputFieldProps): JSX.Element | null => {
    const { rolesStore } = useStores();
    const { activeGroup } = rolesStore || {};
    const { t } = useNestedTranslation(['errors', 'actions']);
    const [value, setValue] = useState<string>('');
    const [isSaving, setSaving] = useState<boolean>(false);
    const windowSize = useWindowSize();
    const isNewGroup: boolean = activeGroup?.id === 0;

    useEffect(() => {
      setValue(!(activeGroup && activeGroup[field]) ? '' : activeGroup[field]?.toString());
    }, [activeGroup]);

    const onChange = (event: any) => {
      if (isNewGroup) {
        rolesStore.updateActiveGroup({
          [field]:
            type === 'number' ? +event.target.value : type === 'checkbox' ? event.target.checked : event.target.value,
        });
        return;
      }
      setValue(type === 'checkbox' ? event.target.checked : event.target.value || '');
    };

    const onSave = async (): Promise<void> => {
      setSaving(true);
      await rolesStore.updateActiveGroup({
        [field]: type === 'number' ? +value : type === 'checkbox' ? Boolean(value) : value,
      });
      if (rolesStore?.addingError) {
        notify({
          type: 'danger',
          title: t('error') + '!',
          message: `${t('failedRuleEditing')} \"${title || ''}\": ${formatError(rolesStore?.addingError, t)}`,
        });
      }
      setSaving(false);
    };

    const onDeleteRule = async (): Promise<void> => {
      await rolesStore.updateActiveGroup({ [field]: 0 });
      if (rolesStore?.addingError) {
        notify({
          type: 'danger',
          title: t('error') + '!',
          message: `${t('failedRuleDeletion')} \"${title || ''}\": ${formatError(rolesStore?.addingError, t)}`,
        });
      }
    };

    const isDisabled =
      isSaving || (isNewGroup ? String(activeGroup[field])?.trim() : !String(value)?.trim() && !allowEmpty);
    const content: JSX.Element = (
      <div className="flex items-center">
        <div
          className="mb-5 md:max-w-[464px] lg:max-w-[398px] flex-grow"
          style={type === 'checkbox' ? { height: '30px', lineHeight: '30px' } : {}}
        >
          {type !== 'checkbox' && (
            <label className="form-label" htmlFor={rest.id || 'scenario'}>
              {title || ''}
            </label>
          )}
          <div className="flex">
            <input
              className="form-control form-control--lg"
              style={type === 'checkbox' ? { width: '30px', height: '34px' } : {}}
              type={type}
              value={isNewGroup ? activeGroup[field] || '' : value}
              onChange={onChange}
              id={rest.id || 'scenario'}
              {...(type === 'checkbox' ? { checked: !!value } : {})}
              {...rest}
            />
            {type === 'checkbox' ? (
              <label className="text-black text-opacity-40 ml-2.5" htmlFor={rest.id || 'scenario'}>
                {title}
              </label>
            ) : null}
            {(type === 'number' ? +value : value) !== (activeGroup && activeGroup[field]) &&
              (((windowSize.width || 0) < 1068 && (windowSize.width || 0) >= 992) || (windowSize.width || 0) < 767) &&
              !isNewGroup && (
                <button type="button" disabled={!!isDisabled} className="btn btn-blue ml-1 h-12" onClick={onSave}>
                  <FASpinner containerClass="mr-2" show={isSaving} />
                  {t('save', { ns: 'actions' })}
                </button>
              )}
          </div>
        </div>
        {(type === 'number' ? +value : value) !== (activeGroup && activeGroup[field]) &&
          ((windowSize.width || 0) >= 1068 || ((windowSize.width || 0) < 992 && (windowSize.width || 0) > 767)) &&
          !isNewGroup && (
            <button type="button" disabled={!!isDisabled} className="btn btn-blue ml-1 h-12" onClick={onSave}>
              <FASpinner containerClass="mr-2" show={isSaving} />
              {t('save', { ns: 'actions' })}
            </button>
          )}
      </div>
    );

    return inputOnly ? (
      <>{content}</>
    ) : (
      <div className="pt-[54px] pb-5 px-4 relative md:py-5 md:px-0">
        <button
          className="btn-close absolute right-4 top-4 md:right-0 md:top-5"
          type="button"
          aria-label="Удалить"
          onClick={onDeleteRule}
        >
          <svg className="w-[22px] h-[22px]" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
            <path d="M16.28 7.72a.75.75 0 0 0-1.06 0L12 10.94 8.78 7.72a.75.75 0 1 0-1.06 1.06L10.94 12l-3.22 3.22a.75.75 0 1 0 1.06 1.06L12 13.06l3.22 3.22a.75.75 0 0 0 1.06-1.06L13.06 12l3.22-3.22a.75.75 0 0 0 0-1.06Z"></path>
            <path d="M15.75 1.5h-7.5A6.75 6.75 0 0 0 1.5 8.25v7.5a6.75 6.75 0 0 0 6.75 6.75h7.5a6.75 6.75 0 0 0 6.75-6.75v-7.5a6.75 6.75 0 0 0-6.75-6.75ZM21 15.75A5.25 5.25 0 0 1 15.75 21h-7.5A5.25 5.25 0 0 1 3 15.75v-7.5A5.25 5.25 0 0 1 8.25 3h7.5A5.25 5.25 0 0 1 21 8.25v7.5Z"></path>
          </svg>
        </button>
        {content}
      </div>
    );
  },
);

export default InputField;

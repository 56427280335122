/**All possible order statuses */

export const ERROR = 'error';
export const DRIVING = 'driving';
export const WAITING = 'waiting';
export const TRANSPORTING = 'transporting';
export const COMPLETE = 'complete';
export const FEEDBACK = 'feedback';
export const CANCELLED = 'cancelled';
export const SEARCH = 'search';
export const NEW = 'new';
export const FAILED = 'failed';
export const EXPIRED = 'expired';
export const SCHEDULING = 'scheduling';
export const SCHEDULED = 'scheduled';
export const ASSIGNED = 'assigned';

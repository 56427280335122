import { action, makeObservable, observable, runInAction } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';
import PromocodeAPI from './api';
import { Promocode } from '../../types/types';

class PromocodesStore extends BaseStore {
  api: PromocodeAPI;

  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Promocodes']);
    this.api = new PromocodeAPI(rootStore.specifications['Promocodes'].url);
    makeObservable(this);
  }

  @observable promocodeInfo: Promocode = {};
  @observable chosenPromocode: Promocode | null = null;
  @observable isActivateProgress: boolean = false;
  @observable promocodeError: string = '';
  @action
  async activateCode(code) {
    runInAction(() => {
      this.isActivateProgress = true;
      this.promocodeError = '';
      this.promocodeInfo = {};
    });
    try {
      const promocodeInfo = await this.api.activate({ code });
      if (promocodeInfo) {
        runInAction(() => {
          this.promocodeInfo = promocodeInfo;
          this.chosenPromocode = promocodeInfo;
        });
      }
      if (promocodeInfo && promocodeInfo.id) {
        this.fetchList(false, {});
      } else {
        runInAction(() => {
          this.promocodeError = promocodeInfo.error;
        });
      }
    } catch (e) {
      console.error(e);
      runInAction(() => {
        this.promocodeError = JSON.stringify(e);
      });
    }
    runInAction(() => {
      this.isActivateProgress = false;
    });
    return this.promocodeInfo;
  }
  @action
  clearActivateError() {
    runInAction(() => {
      this.isActivateProgress = true;
      this.promocodeError = '';
      this.promocodeInfo = {};
    });
  }
  @action
  setPromocode(promocode: Promocode) {
    runInAction(() => {
      this.chosenPromocode = promocode;
    });
  }
}

export default PromocodesStore;

export default {
  title: 'Новый заказ',
  favourites: 'Избранное',
  edit_title: 'Редактирование заказ № {{id}}',
  source: 'Откуда',
  destination: 'Куда',
  interim_destination: 'Остановка',
  employee: 'Выберите cотрудника',
  success_msg: 'Ваш отложенный заказ успешно {{action}}. Машина прибудет в назначенное время',
  changed: 'изменен',
  added: 'добавлен',
  guest_full_name: 'ФИО пассажира / гостя',
  guest: 'Васильев Василий Васильевич',
  phone_number: 'Номер телефона',
  phone_recipient: 'Телефон получателя',
  phone_sender: 'Телефон отправителя',
  scheduled_order: 'Отложенный заказ',
  choose_payment_method: 'Выбрать способ оплаты',
  payment_method: 'Способ оплаты',
  manager: 'Менеджер',
  scheduled_order_placeholder: 'ДД.ММ.ГГ/ ЧЧ:ММ',
  classes_loading: 'Загрузка классов',
  car_class: 'Класс автомобиля',
  customer: 'Компания',
  wishes: 'Ваши пожелания для водителя',
  actions: {
    save: 'Сохранить',
    new_order: 'Новый заказ',
    done: 'Готово',
  },
  tariff_mins: 'мин',
  without_vat: 'без учета НДС',
  with_vat: 'с учетом НДС',
  vat: 'С учетом НДС',
  field_can_contain: 'Поле может содержать ',
  any_chars: 'любые символы ',
  only: 'только ',
  letters: 'буквы ',
  digits: 'цифры ',
  symbols: 'символы',
  symbols_count: 'симв.',
  and: 'и ',
  min: 'от',
  max: 'до',
  provider_price_from: 'от',
  provider_time_from: 'от',
  provider_time_minutes: 'мин',
  requirements: {
    only_car_delivery: 'Курьер на машине',
    child_chair: 'Детское кресло',
  },
  entrance: 'Подъезд',
  distance: {
    km: 'км',
    m: 'м',
  },
};

import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';
import moment, { Moment } from 'moment';
import { Area, AreaChart, BarChart, Bar, CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis } from 'recharts';
import { observer } from 'mobx-react-lite';

import { isEmpty } from 'lodash';
import numeral from 'numeral';
import { useTranslation } from 'react-i18next';
import { DateTimeField } from '../../components/Inputs/DateTimeField';

const Analytics = observer((): JSX.Element => {
  const { analyticsStore, usersStore } = useStores();
  const [dateStart, setDateStart] = useState<Moment>(moment().startOf('week'));
  const [dateEnd, setDateEnd] = useState<Moment>(moment().endOf('week'));
  const [type, setType] = useState<moment.unitOfTime.DurationConstructor | 'custom'>('week');
  const [isOpenCalendar, setIsOpenCalendar] = useState(false);
  const [dateRange, setDateRange] = useState<any[]>([]);
  const { t } = useTranslation('analytics');

  useEffect(() => {
    changePeriod(dateStart, dateEnd, type);
  }, []);

  const handleSelectRange = (value: any): void => {
    const [dateStart, dateEnd] = value || [];
    if (!dateStart) {
      setDateRange([]);
      return;
    }
    const newDateRange = [new Date(dateStart), ...(dateEnd ? [new Date(dateEnd)] : [])];
    setDateRange(newDateRange);
    setType('custom');
  };

  useEffect(() => {
    if (!isEmpty(dateRange)) {
      const startDate = moment(dateRange[0]);
      const endDate = dateRange[1] ? moment(dateRange[1]) : moment();
      changePeriod(
        moment.utc(startDate.format('YYYY-MM-DD 00:00:00')),
        moment.utc(endDate.format('YYYY-MM-DD 23:59:59')),
        'custom',
      );
    }
  }, [dateRange]);

  const formatDateRange = (): string =>
    `${(dateRange[0] && moment(dateRange[0]).format('DD.MM.YY')) || ''}${(dateRange[1] && moment(dateRange[1]).format(' - DD.MM.YY')) || ''}`;

  const closeCalendar = (): void => {
    setIsOpenCalendar(false);
  };

  const onDateRangeClick = (): void => {
    setIsOpenCalendar(true);
  };

  const changePeriod = (dateStart: Moment, dateEnd: Moment, type: moment.unitOfTime.DurationConstructor | 'custom') => {
    const d = new Date();
    const timezone_offset = type === 'custom' ? 0 : -1 * d.getTimezoneOffset();

    setDateStart(dateStart);
    setDateEnd(dateEnd);
    if (usersStore.role === 'customer') {
      analyticsStore.fetchCustomerStats(
        usersStore.me?.detail?.id,
        dateStart.unix() + timezone_offset * 60,
        dateEnd.unix() + timezone_offset * 60,
      );
    } else if (usersStore.role === 'employee') {
      analyticsStore.fetchCustomerStats(
        usersStore.me?.detail?.customer_id,
        dateStart.unix() + timezone_offset * 60,
        dateEnd.unix() + timezone_offset * 60,
      );
    } else if (usersStore.role === 'coordinator') {
      analyticsStore.fetchCustomerStats(
        usersStore.me?.detail?.customer_id,
        dateStart.unix() + timezone_offset * 60,
        dateEnd.unix() + timezone_offset * 60,
      );
    }
    setType(type);
  };

  const handleSetRange = (range: 'week' | 'month' | 'year') => {
    changePeriod(moment().startOf(range), moment().endOf(range), range);
  };

  const getDateString = () => {
    if (type === 'week')
      return `${moment(dateStart).add(1, 'day').format('WW')} ${t('period.week').toLowerCase()} ${dateStart.format('YYYY')}`;
    if (type === 'month') return moment(dateStart.valueOf()).format('MMM YYYY');
    if (type === 'year') return dateStart.format('YYYY');
    if (type === 'custom') return formatDateRange();
  };

  const handlePrev = () => {
    if (type === 'custom') return;
    changePeriod(dateStart.subtract(1, type), dateEnd.subtract(1, type).endOf(type), type);
  };
  const handleNext = () => {
    if (type === 'custom') return;
    changePeriod(dateStart.add(1, type), dateEnd.add(1, type).endOf(type), type);
  };

  /**
   * @param arr array of data ({date, count})
   * @returns if first arr element doesn't match to startDate then arr without this element else arr
   */
  const checkFirsDate = (arr: any): any[] => {
    let orders = ([] = arr);
    const startDate = moment(dateStart.unix() * 1000).format(type === 'year' ? 'MM.YYYY' : 'DD.MM');
    orders = orders[0]?.date === startDate ? orders : orders.slice(1);
    return orders;
  };

  const getPath = (x: number, y: number, width: number, height: number) => {
    let radius = width >= 16 ? 8 : width / 3;
    radius = !!height ? (height - radius >= 0 ? radius : height) : 0;

    return `M ${x} ${y + height} L ${x} ${y + radius} a${radius},${radius} 0 0 1 ${radius},${-radius} L ${x + width - radius} ${y} a${radius},${radius} 0 0 1 ${radius},${radius} L ${x + width} ${height + y}`;
  };

  const RoundedBar = (props: any) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  return (
    <main>
      <section className="pt-6 lg:pt-7.5">
        <div className="container">
          <div className="flex items-stretch mb-6 lg:hidden">
            <h2 className="text-2lg font-medium text-black flex-grow">{t('title')}</h2>
            <DateTimeField
              onChange={handleSelectRange}
              dateFormat="DD.MM.YYYY"
              selectsRange
              startDate={dateRange && dateRange[0]}
              endDate={dateRange && dateRange[1]}
              maxDate={new Date()}
              placeholderText={t('filter.date_placeholder')}
              withPortal
              isClearable={false}
              customInput={
                <button className="ml-auto text-gray" type="button" onClick={onDateRangeClick}>
                  <svg
                    className="w-6 h-6"
                    viewBox="0 0 24 24"
                    fill={`${type === 'custom' ? 'rgb(0, 122, 255)' : 'currentColor'}`}
                    aria-hidden="true"
                  >
                    <path d="M20.24 3.85h-1.71v1.87c0 .8-.67 1.45-1.5 1.45-.82 0-1.5-.65-1.5-1.45V3.85H8.5v1.87c0 .8-.68 1.45-1.5 1.45-.83 0-1.5-.65-1.5-1.45V3.85H3.76c-.98 0-1.76.76-1.76 1.7V20.3c0 .94.78 1.7 1.76 1.7h16.48c.98 0 1.76-.76 1.76-1.7V5.55c0-.94-.78-1.7-1.76-1.7ZM21 20.3c0 .4-.34.71-.76.71H3.76a.72.72 0 0 1-.74-.71V8.94H21V20.3ZM7 6.42c.41 0 .74-.32.74-.72V2.72c0-.4-.33-.72-.74-.72a.72.72 0 0 0-.74.72V5.7c0 .4.33.72.74.72Zm10.04 0c.41 0 .74-.32.74-.72V2.72c0-.4-.33-.72-.74-.72a.72.72 0 0 0-.73.72V5.7c.02.4.34.72.73.72Z"></path>
                  </svg>
                </button>
              }
            />
          </div>
          <div className="lg:flex lg:items-center mb-6 lg:mb-5">
            <div className="mb-6 md:flex md:space-x-5 md:items-center justify-center md:w-120 md:mx-auto lg:w-auto lg:mr-0 lg:mb-0 lg:order-2">
              <ul className="tabs">
                <li className="tabs-item">
                  <a
                    className={`tabs-link ${type === 'week' ? 'active' : ''}`}
                    href="javascript:void(0)"
                    onClick={() => handleSetRange('week')}
                  >
                    {t('period.week')}
                  </a>
                </li>
                <li className="tabs-item">
                  <a
                    className={`tabs-link ${type === 'month' ? 'active' : ''}`}
                    href="javascript:void(0)"
                    onClick={() => handleSetRange('month')}
                  >
                    {t('period.month')}
                  </a>
                </li>
                <li className="tabs-item">
                  <a
                    className={`tabs-link ${type === 'year' ? 'active' : ''}`}
                    href="javascript:void(0)"
                    onClick={() => handleSetRange('year')}
                  >
                    {t('period.year')}
                  </a>
                </li>
              </ul>
              <div className="hidden relative w-[12.5rem] flex-shrink-0 lg:block">
                <DateTimeField
                  onChange={handleSelectRange}
                  dateFormat="DD.MM.YYYY"
                  selectsRange
                  startDate={dateRange && dateRange[0]}
                  endDate={dateRange && dateRange[1]}
                  maxDate={new Date()}
                  placeholderText={t('filter.date_placeholder')}
                />
              </div>
            </div>
            <div className="relative flex justify-center items-center h-10 md:w-120 md:mx-auto lg:justify-start lg:order-1 lg:mx-0 lg:space-x-5">
              <div className="font-medium text-black text-center w-40 lg:order-0 lg:w-auto lg:text-2lg">
                {getDateString()}
              </div>
              {type !== 'custom' && (
                <div className="lg:flex lg:-space-x-px">
                  <button
                    onClick={handlePrev}
                    className="btn btn-light btn-icon rounded-lg text-black text-opacity-40 absolute top-0 left-0 lg:relative lg:rounded-r-none hover:z-10"
                    type="button"
                  >
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                      <path d="m8.256 12.574 6.23 5.595c.362.307.917.307 1.258 0 .341-.306.341-.804 0-1.13l-5.61-5.04 5.61-5.04a.752.752 0 0 0 0-1.13c-.341-.306-.896-.306-1.259 0l-6.229 5.615c-.341.306-.341.804 0 1.13Z"></path>
                    </svg>
                  </button>
                  <button
                    onClick={handleNext}
                    className="btn btn-light btn-icon rounded-lg text-black text-opacity-40 absolute top-0 right-0 lg:relative lg:rounded-l-none hover:z-10"
                    type="button"
                  >
                    <svg className="w-6 h-6" viewBox="0 0 24 24" fill="currentColor" aria-hidden="true">
                      <path d="M15.744 11.425 9.514 5.83c-.362-.307-.917-.307-1.258 0-.341.306-.341.804 0 1.13l5.61 5.04-5.61 5.04a.752.752 0 0 0 0 1.13c.341.306.896.306 1.259 0l6.229-5.615c.341-.306.341-.804 0-1.13Z"></path>
                    </svg>
                  </button>
                </div>
              )}
            </div>
          </div>

          {!analyticsStore.isCustomerStatsFetched && <div>{t('loading')}</div>}
          {analyticsStore.customerStatsFetchingError && (
            <div>
              {t('error')}: {analyticsStore.customerStatsFetchingError}
            </div>
          )}

          {analyticsStore.isCustomerStatsFetched && analyticsStore.customerStatsFetchingError === '' && (
            <>
              <div className="area">
                <div className="area-inner">
                  <h3>{t('orders.count')}</h3>
                  <div className="text-2xl font-semibold text-black mb-5">
                    {analyticsStore.customerStats.orders.reduce((acc: number, item: any) => acc + item.count, 0)}
                  </div>
                  <div className="overflow-x-auto md:overflow-visible">
                    <div className="w-[580px] md:w-auto">
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          data={checkFirsDate(analyticsStore.customerStats.orders)}
                          margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                        >
                          <XAxis dataKey="date" />
                          <YAxis type="number" />
                          <CartesianGrid strokeDasharray="0" stroke="#DCDEDE" />

                          <Tooltip />
                          <defs>
                            <linearGradient id="ordersStatistic" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#007AFF" stopOpacity={1} />
                              <stop offset="95%" stopColor="#007AFF" stopOpacity={0.8} />
                            </linearGradient>
                          </defs>

                          <Bar
                            type="monotone"
                            dataKey="count"
                            strokeWidth={2}
                            stroke="#007AFF"
                            fill="url(#ordersStatistic)"
                            shape={<RoundedBar />}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
              <div className="xl:flex xl:space-x-5">
                <div className="area xl:flex-1">
                  <div className="area-inner">
                    <h3>{t('orders.cancelled_count')}</h3>
                    <div className="text-2xl font-semibold text-black mb-5">
                      {analyticsStore.customerStats.ordersCancelled.reduce(
                        (acc: number, item: any) => acc + item.count,
                        0,
                      )}
                    </div>
                    <div className="overflow-x-auto md:overflow-visible">
                      <div className="w-[580px] md:w-auto">
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            data={checkFirsDate(analyticsStore.customerStats.ordersCancelled)}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                          >
                            <XAxis dataKey="date" />
                            <YAxis type="number" />
                            <CartesianGrid strokeDasharray="0" stroke="#DCDEDE" />

                            <Tooltip />
                            <defs>
                              <linearGradient id="ordersStatistic2" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#FF9900" stopOpacity={1} />
                                <stop offset="95%" stopColor="#FF9900" stopOpacity={0.8} />
                              </linearGradient>
                            </defs>

                            <Bar
                              type="monotone"
                              dataKey="count"
                              strokeWidth={2}
                              stroke="#FF9900"
                              fill="url(#ordersStatistic2)"
                              shape={<RoundedBar />}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="area xl:flex-1">
                  <div className="area-inner">
                    <h3>{t('orders.total_cost')}</h3>
                    <div className="text-2xl font-semibold text-black mb-5">
                      ₽{' '}
                      {numeral(
                        analyticsStore.customerStats.ordersCost.reduce((acc: number, item: any) => acc + item.cost, 0),
                      )
                        .format('0,0')
                        .replace(/,/g, ' ')}
                    </div>
                    <div className="overflow-x-auto md:overflow-visible">
                      <div className="w-[580px] md:w-auto">
                        <ResponsiveContainer width="100%" height={300}>
                          <BarChart
                            data={checkFirsDate(analyticsStore.customerStats.ordersCost)}
                            margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
                          >
                            <XAxis dataKey="date" />
                            <YAxis type="number" />
                            <CartesianGrid strokeDasharray="0" stroke="#ffb1b1" />

                            <Tooltip />
                            <defs>
                              <linearGradient id="amountStatistic" x1="0" y1="0" x2="0" y2="1">
                                <stop offset="5%" stopColor="#EE1A1A" stopOpacity={1} />
                                <stop offset="95%" stopColor="#EE1A1A" stopOpacity={0.8} />
                              </linearGradient>
                            </defs>

                            <Bar
                              type="monotone"
                              dataKey="cost"
                              strokeWidth={2}
                              stroke="#FF6767"
                              fill="url(#amountStatistic)"
                              shape={<RoundedBar />}
                            />
                          </BarChart>
                        </ResponsiveContainer>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*

            <div className="area">
              <div className="area-inner">
                <h3>Количество заказов</h3>
                <div className="text-2xl font-semibold text-black mb-5">{analyticsStore.customerStats.orders.reduce((acc: number, item: any) => acc + item.count, 0)}</div>
                <div className="overflow-x-auto md:overflow-visible">
                  <div className="w-[580px] md:w-auto">
                    <ResponsiveContainer width="100%" height={300}>
                      <BarChart data={analyticsStore.customerStats.orders}
                                 margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                        <XAxis dataKey="date"/>
                        <YAxis type="number" />
                        <CartesianGrid strokeDasharray="0" stroke="#DCDEDE"/>

                        <Tooltip/>
                        <defs>
                          <linearGradient id="ordersStatistic" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#007AFF" stopOpacity={1}/>
                            <stop offset="95%" stopColor="#007AFF" stopOpacity={0.8}/>
                          </linearGradient>
                          <linearGradient id="ordersStatistic2" x1="0" y1="0" x2="0" y2="1">
                            <stop offset="5%" stopColor="#FF9900" stopOpacity={1}/>
                            <stop offset="95%" stopColor="#FF9900" stopOpacity={0.8}/>
                          </linearGradient>
                        </defs>

                        <Bar type='monotone' dataKey='cancelled' strokeWidth={2} stroke='#FF9900' fill="url(#ordersStatistic2)"/>

                        <Bar type='monotone' dataKey='count' strokeWidth={2} stroke='#007AFF' fill="url(#ordersStatistic)"/>
                      </BarChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </div>
            </div>
            <div className="xl:flex xl:space-x-5">
              <div className="area xl:flex-1">
                <div className="area-inner">
                  <h3>Общий расход по заказам</h3>
                  <div className="text-2xl font-semibold text-black mb-5">₽ {analyticsStore.customerStats.ordersCost.reduce((acc: number, item: any) => acc + item.cost, 0)}</div>
                  <div className="overflow-x-auto md:overflow-visible">
                    <div className="w-[580px] md:w-auto">
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart data={analyticsStore.customerStats.ordersCost}
                                   margin={{top: 10, right: 30, left: 0, bottom: 0}}>
                          <XAxis dataKey="date"/>
                          <YAxis type="number" />
                          <CartesianGrid strokeDasharray="0" stroke="#ffb1b1"/>

                          <Tooltip/>
                          <defs>
                            <linearGradient id="amountStatistic" x1="0" y1="0" x2="0" y2="1">
                              <stop offset="5%" stopColor="#EE1A1A" stopOpacity={1}/>
                              <stop offset="95%" stopColor="#EE1A1A" stopOpacity={0.8}/>
                            </linearGradient>
                          </defs>

                          <Bar type='monotone' dataKey='cost' strokeWidth={2} stroke='#FF6767' fill="url(#amountStatistic)"/>
                        </BarChart>
                      </ResponsiveContainer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            */}
            </>
          )}
        </div>
      </section>
    </main>
  );
});

export default Analytics;

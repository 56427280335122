import React from 'react';
import { useTranslation } from 'react-i18next';
import ru from '../../i18n/locales/ru';

interface Props {
  children: React.ReactNode;
  titleClass?: string;
  contentClass?: string;
  className?: string;
  ns?: keyof typeof ru;
  customTitle?: string;
  tKey?: string;
}

export const Li = ({
  children,
  tKey,
  customTitle,
  titleClass = '',
  className = '',
  contentClass = '',
  ...rest
}: Props & React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement>): JSX.Element | null => {
  const { t } = useTranslation('rides');

  return (
    <li className={`flex py-1 ${className}`} {...rest}>
      <span className={`text-gray flex-shrink-0 mr-2 ${titleClass}`}>
        {customTitle ? customTitle : t(`details.${tKey}`)}:
      </span>
      <span className={`flex-grow text-black ${contentClass}`}>{children}</span>
    </li>
  );
};
export default Li;

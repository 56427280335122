import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import CurrentGroup from './currentGroup';
import Empty from './empty';
import Groups from './groups';
import Title from './title';
import { useStores } from 'stores';
import { GROUPS_LIST_LIMIT_PER_RAGE } from 'constants/policy';

const Policy = observer((): JSX.Element => {
  const { rolesStore } = useStores();

  useEffect(() => {
    rolesStore.fetchList(false, {}, 'id desc', 0, GROUPS_LIST_LIMIT_PER_RAGE, true, false);
    rolesStore.fetchDefaultGroup();
  }, []);

  return (
    <main className="h-full">
      <section className="pt-6 lg:pt-5 flex-grow flex flex-col h-full">
        <div className="container flex-grow flex flex-col">
          <Title />
          <div className="lg:mb-5 relative flex-grow flex flex-col lg:flex-row lg:space-x-5">
            <Groups />
            {rolesStore.activeGroup === null ? <Empty /> : <CurrentGroup />}
          </div>
        </div>
      </section>
    </main>
  );
});

export default Policy;

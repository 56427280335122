import { ListItemProps } from 'components/List';
import React from 'react';
import { ProfileRole } from 'types/types';
import { ProfileCard } from '../ProfileCard';

interface ProfileLiItemProps {
  profileProps?: Omit<React.ComponentProps<typeof ProfileCard>, 'profile'>;
}

export const ProfileLiItem = ({
  item,
  index,
  profileProps = {},
  bottomDivider = false,
  className = '',
  ...props
}: ProfileLiItemProps & ListItemProps<ProfileRole>): JSX.Element | null => {
  return (
    <li
      tabIndex={index}
      className={`profile__item ${className} ${bottomDivider ? 'border-b border-b-graystroke' : ''}`}
      {...props}
    >
      <ProfileCard profile={item} keepIcon showLimits {...profileProps} />
    </li>
  );
};

import Storage from './storage';
import { APP_PLATFORM, APP_VERSION } from './deviceInfo';

export default async (msg: string, info: any = {}) => {
  console.debug(
    '>LOG: ' +
      JSON.stringify({
        msg,
        info,
        push_token: await Storage.getItem('push_token'),
        platform: APP_PLATFORM,
        version: APP_VERSION,
      }),
  );

  fetch('https://swiftdrive.ru/api/v1/expo-logs', {
    headers: {
      'content-type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify({
      msg,
      info,
      push_token: await Storage.getItem('push_token'),
      platform: APP_PLATFORM,
      version: APP_VERSION,
    }),
    method: 'POST',
  });
};

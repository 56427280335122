import EditDelete from 'components/EditDelete';
import FASpinner from 'components/FASpinner';
import ConfirmModal from 'components/Modal/confirmModal';
import { GROUPS_LIST_LIMIT_PER_RAGE } from 'constants/policy';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';
import { formatPhone } from 'utils/lib/formatPhone';
import { notify } from 'utils/lib/notify';

interface UserItemProps {
  user?: any;
  bottomDivider?: boolean;
}

const UserItem = ({ user, bottomDivider }: UserItemProps): JSX.Element | null => {
  const { t } = useNestedTranslation(['policy.lcurrent', 'confirmation', 'errors']);
  const { usersStore, rolesStore, employeesStore } = useStores();
  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
  const [isActive, setIsActive] = useState<boolean>(false);
  const isNewGroup: boolean = rolesStore?.activeGroup?.id === 0;
  const isDefault = !!rolesStore?.activeGroup?.default || false;
  const [deleting, setDeleting] = useState<boolean>(false);

  useEffect(() => {
    setIsActive(false);
  }, [user]);

  const onDeleteUser = (): void => {
    setVisibleConfirm(true);
  };

  const closeConfirm = (): void => {
    setVisibleConfirm(false);
  };

  const onConfirmDelete = async (e: any): Promise<void> => {
    setDeleting(true);
    e.stopPropagation();
    setVisibleConfirm(false);
    if (isNewGroup) {
      rolesStore.deleteUser(user.id);
      return;
    }
    if (!rolesStore?.defaultGroup?.id) {
      await rolesStore?.fetchDefaultGroup();
    }
    if (usersStore.isCustomer && !rolesStore?.defaultGroup?.id) {
      notifyError(t('noDefaultGroup', { ns: 'errors' }));
      return;
    }
    await user.update({ role_id: 0 });
    if (employeesStore?.addingError) {
      notifyError(formatError(employeesStore?.addingError, t));
    }
    rolesStore.fetchList(false, {}, 'id desc', rolesStore?.page || 0, GROUPS_LIST_LIMIT_PER_RAGE, true, false);
    setDeleting(false);
  };

  const notifyError = (msg: string): void => {
    notify({
      type: 'danger',
      title: t('error', { ns: 'errors' }) + '!',
      message: `${t('failedUserDeletion', { ns: 'errors' })} \"${user?.name || ''}\": ${msg}`,
    });
  };

  if (!user) return null;

  return (
    <li className={`px-4 ${isActive || visibleConfirm ? 'active-group' : ''}`} onClick={() => {}}>
      <div className={`flex items-center ${bottomDivider ? 'border-b border-b-graystroke' : ''} py-4 md:py-5`}>
        <div className="mr-2 md:mr-5">
          <div className="mb-1 text-black">{user.name}</div>
          <div className="flex items-center space-x-1.5">
            <span className="whitespace-nowrap">{formatPhone(user.phone)}</span>
          </div>
        </div>
        {!isDefault && (usersStore?.isCustomer || usersStore?.me?.detail?.permissions?.create_policy_groups) && (
          <div className="ml-auto flex items-center space-x-3 md:space-x-4 lg:space-x-5">
            {deleting ? (
              <FASpinner show />
            ) : (
              <EditDelete onOpen={() => setIsActive(true)} onClose={() => setIsActive(false)} onDelete={onDeleteUser} />
            )}
          </div>
        )}
      </div>
      <ConfirmModal
        title={t('deletion_title', { ns: 'confirmation' })}
        text={t('deletion_confirmation', { ns: 'confirmation' })}
        visible={visibleConfirm}
        onCancel={closeConfirm}
        onClose={closeConfirm}
        onConfirm={onConfirmDelete}
      />
    </li>
  );
};

export default UserItem;

import React from 'react';

interface FASpinnerProps {
  show: boolean;
  containerClass?: string;
  className?: string;
}

const FASpinner = ({ show, containerClass = '', className = '' }: FASpinnerProps): JSX.Element | null => {
  return show ? (
    <div className={containerClass}>
      <i className={`fa fa-circle-o-notch fa-spin ${className}`} />
    </div>
  ) : null;
};

export default FASpinner;

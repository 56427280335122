import { makeObservable, observable, action, runInAction } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';

interface IDepartment {
  id: string;
  name?: string;
  parent_id?: string;
  customer_id?: number;
  is_disabled?: boolean;
}
export default class DepartmetsStore extends BaseStore {
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Departments']);
    makeObservable(this);
  }

  @action
  async fetchWholeList(): Promise<void> {
    try {
      await this.fetchList(false, {}, 'id desc', 0, 1000, true, false);
      if (this.list) {
        runInAction(() => {
          this.wholeList = this.list;
        });
      }
    } catch (err) {
      console.error(err);
      this.captureError(err);
    }
  }

  getDepartmentById(id): string {
    if (!id) return '';
    const [dept] = this.wholeList.filter((d) => d.id === id);
    return dept?.name || id;
  }
  getParentDepartments(department_id): string {
    if (!department_id) return '';
    let deptMap = {};

    this.wholeList.forEach((dept) => {
      deptMap[dept.id] = dept;
    });

    let breadcrumbs: IDepartment[] = [];
    let currentDept = deptMap[department_id];

    if (!currentDept) {
      return '';
    }

    currentDept = deptMap[currentDept.parent_id];

    while (currentDept) {
      breadcrumbs.unshift(currentDept.name);
      currentDept = deptMap[currentDept.parent_id];
    }

    return breadcrumbs.join(' // ');
  }
}

import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { RequirementType } from 'types/enums';
import { TariffClass, Requirement, Requirements } from 'types/types';
import { Checkbox } from '../../../components/Inputs/Checkbox';

interface Props {
  containerClass?: string;
}

export const RequirementsBlock = ({ containerClass = '' }: Props): JSX.Element => {
  const { ordersStore } = useStores();
  const { t } = useTranslation('new_order');
  const { newOrderInfo, estimateInfo } = ordersStore || {};
  const { class_id } = newOrderInfo || {};
  const { classes = [] } = estimateInfo || {};
  const { requirements = {} } = newOrderInfo || {};

  const requirementsList: Requirements | undefined = useMemo(() => {
    return classes.find((c: TariffClass) => c?.id === class_id)?.requirements;
  }, [class_id, classes]);

  const renderRequirement = ([name, req]: [string, Requirement], index: number): JSX.Element => (
    <div className={index ? 'mt-5' : ''} key={index}>
      {/* @TODO - implement other types (string, list, date)*/}
      {req.type === RequirementType.String && <></>}
      {req.type === RequirementType.Checkbox && (
        <Checkbox
          onChange={() =>
            ordersStore.updateNewOrderInfo({
              requirements: {
                ...(requirements || {}),
                [name]: !requirements[name],
              },
            })
          }
          checked={requirements[name]}
          label={req.label}
        />
      )}
    </div>
  );

  return <div className={containerClass}>{Object.entries(requirementsList || {}).map(renderRequirement)}</div>;
};

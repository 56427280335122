import { observer } from 'mobx-react-lite';
import { useParams } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import Details from './details';
import { DRIVING, SEARCH, TRANSPORTING, WAITING } from '../../constants/orderStatuses';

const OrderInfo = observer((): JSX.Element => {
  const { authStore } = useStores();
  const { t } = useTranslation('rides');
  const intervalRef = useRef<any | null>(null);

  let { orderId } = useParams<any>();

  const updateData = () => {
    console.log(
      'updateData',
      authStore.unAuthedOrderError,
      authStore?.unAuthedOrderInfo && JSON.parse(JSON.stringify(authStore?.unAuthedOrderInfo)),
    );
    if (orderId) {
      if (
        !authStore.unAuthedOrderError &&
        (!authStore.unAuthedOrderInfo ||
          authStore.unAuthedOrderInfo.status_id === TRANSPORTING ||
          authStore.unAuthedOrderInfo.status_id === DRIVING ||
          authStore.unAuthedOrderInfo.status_id === SEARCH ||
          authStore.unAuthedOrderInfo.status_id === WAITING)
      ) {
        authStore?.getOrderInfo(orderId);
      } else {
        if (intervalRef.current) {
          clearInterval(intervalRef.current);
        }
      }
    }
  };
  useEffect(() => {
    updateData();
    intervalRef.current = setInterval(updateData, 10000);
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [orderId]);

  return (
    <main>
      <Details
        isInfo
        selected
        ride={authStore.unAuthedOrderInfo}
        error={authStore.unAuthedOrderError}
        getDriverPhone={(id) => authStore?.getDriverPhone(id)}
        isGettingPhone={authStore?.isGettingPhone}
      />
    </main>
  );
});

export default OrderInfo;

import EditDelete from 'components/EditDelete';
import FASpinner from 'components/FASpinner';
import ConfirmModal from 'components/Modal/confirmModal';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import { observer } from 'mobx-react-lite';
import React, { useState } from 'react';
import { useStores } from 'stores';
import { formatError } from 'utils/lib/formatError';
import { notify } from 'utils/lib/notify';
import ZoneDialog from './zoneDialog';

interface ZoneProps {
  zone: any;
  index: number;
  direction: 'from' | 'to';
}

export interface Region {
  title: string;
  coords: (number | string)[];
}

const Zone = observer(({ zone, index, direction }: ZoneProps): JSX.Element => {
  const { t } = useNestedTranslation(['policy.current.rules.zones', 'confirmation', 'errors']);
  const { rolesStore } = useStores();
  const { activeGroup } = rolesStore || {};
  const [visibleDialog, setVisibleDialog] = useState<boolean>(false);
  const [visibleConfirm, setVisibleConfirm] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const closeDialog = (): void => setVisibleDialog(false);
  const closeConfirm = (): void => setVisibleConfirm(false);

  const openDialog = (): void => setVisibleDialog(true);
  const openConfirm = (): void => setVisibleConfirm(true);

  const deleteRegion = async (): Promise<void> => {
    setIsDeleting(true);
    if (!direction) return;
    await rolesStore.updateActiveGroup({
      zones: {
        ...activeGroup.zones,
        [direction]: activeGroup.zones[direction].filter((r: Region, i: number) => i !== index),
      },
    });
    if (rolesStore?.addingError) {
      notify({
        title: t('error', { ns: 'errors' }) + '!',
        message: `${t('failedZoneDeletion', { ns: 'errors' })} \"${zone?.title || ''}\": ${formatError(rolesStore.addingError, t)}`,
        type: 'danger',
      });
    }
    setIsDeleting(false);
  };

  return (
    <>
      <td>{zone?.title || ''}</td>
      <td style={{ width: 40, paddingLeft: 0 }} className="text-right">
        {zone && (isDeleting ? <FASpinner show /> : <EditDelete onEdit={openDialog} onDelete={openConfirm} />)}
        <ZoneDialog zone={zone} index={index} visible={visibleDialog} onClose={closeDialog} direction={direction} />
        <ConfirmModal
          title={t('deletion_title', { ns: 'confirmation' })}
          text={t('deletion_confirmation', { ns: 'confirmation' })}
          visible={visibleConfirm}
          onCancel={closeConfirm}
          onClose={closeConfirm}
          onConfirm={() => {
            closeConfirm();
            deleteRegion();
          }}
        />
      </td>
    </>
  );
});

export default Zone;

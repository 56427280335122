import React, { forwardRef, InputHTMLAttributes, useState } from 'react';

interface InputFieldProps extends InputHTMLAttributes<HTMLInputElement> {
  containerClass?: string;
  withReset?: boolean;
  onReset: any;
  renderLeftIcon?: any;
  renderRightIcon?: any;
  debounceDelay?: number;
}

export const InputField = forwardRef(
  (
    {
      containerClass,
      onReset,
      className = '',
      withReset = true,
      renderLeftIcon = () => null,
      renderRightIcon = () => null,
      ...rest
    }: InputFieldProps,
    ref: any,
  ): JSX.Element => {
    const handleOnReset = () => {
      // @ts-ignore
      rest.onChange({ target: { value: '' } });
      if (onReset) onReset();
    };
    return (
      <div className={`relative flex-grow md:flex-grow-0 ${containerClass}`}>
        {renderLeftIcon()}
        <input ref={ref} type="text" {...rest} className={`form-control ${withReset ? 'pr-10' : ''}`} />
        {withReset && (
          <button
            onClick={handleOnReset}
            className="absolute right-4 top-1/2 -translate-y-1/2 text-black text-opacity-40 hover:text-opacity-50"
            type="button"
          >
            <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
            </svg>
          </button>
        )}
        {renderRightIcon()}
      </div>
    );
  },
);

import { Pagination } from 'components/Pagination';
import { SearchBar } from 'components/SearchBar';
import { GROUP_USERS_LIST_LIMIT_PER_RAGE } from 'constants/policy';
import { useNestedTranslation } from 'hooks/useNestedTranslations';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useStores } from 'stores';
import AddUserDialog from './addUserDialog';
import UserItem from './userItem';

const UsersList = observer((): JSX.Element => {
  const { usersStore, rolesStore, employeesStore } = useStores();
  const { t } = useNestedTranslation(['policy.current.users', 'employees']);
  const { activeGroup } = rolesStore;
  const isNewGroup: boolean = activeGroup?.id === 0;
  const [isAddDialogVisible, setIsAddDialogVisible] = useState<boolean>(false);

  useEffect(() => {
    activeGroup &&
      employeesStore.fetchList(
        false,
        { role_id: activeGroup.id },
        'id desc',
        0,
        GROUP_USERS_LIST_LIMIT_PER_RAGE,
        true,
        false,
      );
  }, [activeGroup?.id]);

  const closeDialog = (): void => {
    setIsAddDialogVisible(false);
  };

  const openDialog = (): void => {
    setIsAddDialogVisible(true);
  };

  const getUsersList = (): any[] =>
    isNewGroup
      ? rolesStore.newGroupUsers
      : employeesStore?.list?.filter((item: any): any => item.role_id === activeGroup.id) || [];

  return (
    <>
      <div className="flex mb-1 justify-between">
        <h3 className="text-2lg leading-6 font-medium text-black mb-1">{t('title')}</h3>
      </div>
      <div className="flex justify-between">
        <SearchBar
          containerClass="w-44 md:w-80"
          store={employeesStore}
          placeholder={t('search_placeholder', { ns: 'employees' })}
        />
        {usersStore?.isCustomer || usersStore?.me?.detail?.permissions?.create_policy_groups ? (
          <button
            className="btn ml-5 btn-light px-1.5 w-10 space-x-1.5 md:px-4 md:w-auto"
            type="button"
            onClick={openDialog}
          >
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M10 2A7.953 7.953 0 0 0 4.34 4.342c-3.12 3.12-3.122 8.195-.001 11.316A7.951 7.951 0 0 0 9.998 18c2.14 0 4.15-.832 5.661-2.344 3.121-3.118 3.121-8.193 0-11.314A7.956 7.956 0 0 0 10 2Zm0 1.359c1.776 0 3.445.69 4.698 1.944a6.648 6.648 0 0 1 0 9.393 6.605 6.605 0 0 1-4.7 1.945 6.6 6.6 0 0 1-4.697-1.944 6.649 6.649 0 0 1 .001-9.394A6.604 6.604 0 0 1 10 3.359Zm0 3.287a.68.68 0 0 0-.68.68V9.32H7.324a.68.68 0 1 0 0 1.358H9.32v1.996a.68.68 0 1 0 1.36 0v-1.996h1.996a.68.68 0 1 0 0-1.358H10.68V7.325a.68.68 0 0 0-.68-.68Z"></path>
            </svg>
            <span className="hidden md:inline">{t('actions.add_user')}</span>
          </button>
        ) : null}
      </div>
      {employeesStore?.isFetchingListInProgress && <p className="mt-5">{t('loading')}</p>}
      {!employeesStore?.isFetchingListInProgress && !!getUsersList().length && (
        <ul className="list-none m-0 p-0 mb-5 mt-5 bg-graylighter rounded-lg overflow-hidden">
          {getUsersList().map(
            (user: any, index: number): JSX.Element => (
              <UserItem bottomDivider={index !== getUsersList().length - 1} user={user} />
            ),
          )}
        </ul>
      )}
      {!isNewGroup && !employeesStore?.isFetchingListInProgress && !getUsersList()?.length && (
        <p className="mt-5">{t('empty')}</p>
      )}
      {!!getUsersList()?.length && !employeesStore?.isFetchingListInProgress && (
        <Pagination store={employeesStore} hideItemsLength />
      )}
      <AddUserDialog onCancel={closeDialog} onClose={closeDialog} visible={isAddDialogVisible} />
    </>
  );
});

export default UsersList;

export default {
  title: 'Employees',
  action: {
    add: 'Add employee',
    edit: 'Editing',
    import: 'Import employees',
    export: 'Export',
    exporting: 'Exporting',
  },
  search_placeholder: 'Search',
  loading: 'Loading data',
  error: 'Error',
  thead: {
    full_name: 'Full name',
    phone: 'Phone',
    code: 'Employee code',
    department_id: 'Department',
    department_code: 'Department code',
    department_name: 'Department name',
    branch: 'Branch',
    role: 'Role',
    limit: 'Limit',
    remainder: 'Remainder',
    order_right: 'Rights to order',
    status: 'Status',
    app_last_visit: 'Last login time',
  },
  trow: {
    can_order: 'Corp. trips for himself',
    can_order_others: 'Corp. trips to others',
    limit_personal: 'personal',
    limit_group: 'for group',
  },
  import: {
    imported_number: 'Records imported',
    failed_number: 'Records skipped',
    errors: 'Import errors',
    line: 'Line',
    import_process: 'Import',
    from: 'from',
    close: 'Close',
    dropzone_text: 'Click or drag a file into this area',
    import_example: 'Import file example',
    import_file_name: 'import',
    end: 'The import has completed',
    success: 'Successfully imported',
    lines: 'lines',
    lines_s1: 'line',
    lines_s2: 'lines',
  },
  filter: {
    actions: {
      apply: 'Apply',
    },
    role: 'Role',
    title: 'Filters',
  },
  dialog: {
    full_name: 'Full name',
    phone: 'Phone',
    email: 'E-mail',
    employee_code: 'Employee code',
    department_id: 'Department',
    department_code: 'Department code',
    department_name: 'Department name',
    order_right: 'Rights to order',
    add: 'Add',
    save: 'Save',
    cancel: 'Cancel',
    policy_group: 'Travel policy group',
    passenger: 'Passenger',
    coordinator: 'Personal coordinator',
    not_selected: 'Not selected',
    can_order: 'Order for himself',
    can_order_text: 'Can order corporate trips for himself',
    can_order_others: 'Order for others',
    can_order_others_text: 'Can order trips for other employees',
    active: 'Activity',
    active_text: 'Active',
    send_welcome_sms: 'Send invitation by SMS',
    limit_month_amount: 'Limit, rub. with VAT',
    without_limit: 'Without limita',
    active_from_time: 'Active from',
    active_to_time: 'Active to',
    time: 'Time',
  },
  status: {
    active: 'Active',
    inactive: 'Inactive',
  },
};

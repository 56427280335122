import React, { useCallback, useEffect, useState } from 'react';
import { HoverDropdown } from 'components/HoverDropdown';
import { PropsWithChildren } from 'react';
import { ProfileList } from '../ProfileList';
import { useStores } from 'stores';
import { ProfileRole } from 'types/types';
import { observer } from 'mobx-react-lite';
import ModalBase from 'components/ModalBase';
import { DropdownIcon } from 'components/Buttons/DropdownIcon';
import { useTranslation } from 'react-i18next';
import { ProfileLiItem } from '../ProfileLiItem';

interface ProfileDropdownProps {}

export const ProfileDropdown = observer(({ children }: PropsWithChildren<ProfileDropdownProps>): JSX.Element => {
  const { usersStore } = useStores();
  const { t } = useTranslation('profile');

  const [visible, setVisible] = useState<boolean>(false);

  const [profilesList, setProfilesList] = useState<ProfileRole[]>([]);

  useEffect(() => {
    if (!usersStore?.fetchProfiles) return;
    usersStore?.fetchProfiles();
  }, [usersStore?.fetchProfiles]);

  useEffect(() => {
    const list = usersStore?.profilesList || [];
    // .filter((p) =>
    //   usersStore?.isEmployee ? p.name === 'employee' : p.name !== 'employee',
    // );
    setProfilesList(list);
  }, [usersStore?.profilesList, usersStore?.isEmployee]);

  const selectProfile = useCallback(
    async (profile?: ProfileRole) => {
      await usersStore?.seletcProfile(profile!);
    },
    [usersStore?.getRoleToken],
  );

  const renderItem = useCallback(
    (closePopup: () => void) => (profile: ProfileRole, i, list?: ProfileRole[]) => {
      return (
        <ProfileLiItem
          item={profile}
          index={i}
          onClick={async () => {
            await selectProfile(profile);
            closePopup();
          }}
          bottomDivider={i < list!.length - 1}
          profileProps={{
            className: profile?.id == usersStore?.activeProfileId ? 'pointer-events-none' : '',
          }}
          className={profile?.id == usersStore?.activeProfileId ? 'pointer-events-none profile__item_active' : ''}
        />
      );
    },
    [usersStore?.activeProfileId],
  );
  // Show profile list only if user has multiple accounts
  return !profilesList?.length || profilesList?.length === 1 ? (
    <>{children}</>
  ) : (
    <>
      {/*Show dropdown for md and higher (>= 768)*/}
      <HoverDropdown
        className="rounded hidden md:block"
        containerClass="rounded overflow-hidden shadow-box"
        outerContentClass="border-t border-t-graystroke"
        outerContent={(closeDropdown) => (
          <ProfileList data={profilesList || []} customeRowRender={renderItem(closeDropdown!)} />
        )}
      >
        {children}
      </HoverDropdown>
      {/* Show modal for less then md (<768) */}
      <div className="md:hidden rounded overflow-hidden shadow-box flex">
        {children}
        <DropdownIcon isOpen={visible} onClick={() => setVisible(!visible)} />
      </div>
      <ModalBase
        parentClassName="md:hidden"
        visible={visible}
        title={t('change_profile')}
        onClose={() => setVisible(false)}
      >
        <ProfileList data={profilesList || []} customeRowRender={renderItem(() => setVisible(false))} />
      </ModalBase>
    </>
  );
});

import React, { HTMLAttributes } from 'react';

export type ListItemProps<ItemType> = {
  item: ItemType;
  index: number;
  bottomDivider?: boolean;
} & HTMLAttributes<HTMLLIElement>;

export type ListItem<ItemType> = React.ElementType<ListItemProps<ItemType>>;

export interface ListProps<T> extends HTMLAttributes<HTMLUListElement> {
  data: T[];
  ListItem?: ListItem<T>;
  keyExtractor?: (item: T, index?: number) => React.Key;
  customeRowRender?: (item: T, index?: number, data?: T[]) => JSX.Element;
  onItemClick?: (item: T) => void;
  bottomDivider?: boolean;
}

export const List = <T,>({
  data = [],
  ListItem,
  keyExtractor,
  customeRowRender,
  onItemClick,
  bottomDivider = false,
  ...props
}: ListProps<T>): JSX.Element => {
  return (
    <ul {...props}>
      {data.map((item, i) =>
        customeRowRender ? (
          customeRowRender(item, i, data)
        ) : ListItem ? (
          <ListItem
            onClick={() => onItemClick && onItemClick(item)}
            item={item}
            index={i}
            bottomDivider={bottomDivider && i < data?.length - 1}
            key={keyExtractor ? keyExtractor(item) : (item as any)?.id || i}
          />
        ) : null,
      )}
    </ul>
  );
};

import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import Auth from './Auth';
import moment from 'moment';
import 'moment/min/locales';
import BaseLayout from 'components/Layout/BaseLayout';
import Analytics from 'modules/Analytics';
import Employees from 'modules/Employees';

import Coordinators from 'modules/Coordinators';
import Rides from 'modules/Rides';
import NewOrder from 'modules/NewOrder';
import OrderInfo from 'modules/OrderInfo';
import Reports from 'modules/Reports';
import Policy from 'modules/Policy';
import Promocodes from 'modules/Promocodes';
import { ReactNotifications } from 'react-notifications-component';
import 'font-awesome/css/font-awesome.min.css';
//import History from 'modules/History';
//import Calculator from 'modules/Calculator';

//import 'semantic-ui-css/semantic.min.css';
import 'react-notifications-component/dist/theme.css';
import { useStores } from 'stores';
import 'styles/app.scss';
import { getLocale } from 'utils/lib/getLocale';
import { useTranslation } from 'react-i18next';
import Favourites from 'modules/Favourites';

moment.locale(localStorage.getItem('lang')?.split('-')[0] || 'ru');

const App: React.FC<{}> = observer(() => {
  const stores = useStores();
  const { authStore, usersStore, providersStore } = stores;
  const { i18n } = useTranslation();
  const showRides: boolean = usersStore?.isOperator || usersStore?.isEmployee;
  const [isOrderInfo, serIsOrderInfo] = useState(false);

  useEffect(() => {
    if (!usersStore?.me?.id) return;
    usersStore.fetchSettings();
  }, [usersStore?.me?.id]);

  useEffect(() => {
    if (usersStore?.isOperator && providersStore) {
      providersStore.fetchList(false, {});
    }
  }, [providersStore]);

  useEffect(() => {
    if (usersStore?.settings?.lang_id) {
      const storageLocale = localStorage.getItem('lang')?.split('-')[0] || 'ru';
      const locale = getLocale(usersStore?.settings?.lang_id);
      if (locale === storageLocale && moment.locale() === locale) return;
      i18n.changeLanguage(locale);
      moment.locale(locale);
      window.location.reload();
    }
  }, [usersStore?.settings]);

  useEffect(() => {
    const { pathname } = window?.location || {};
    const checkAuth = async (): Promise<void> => {
      const isAuth = await authStore.checkAuth();
      if (isAuth) return;
      localStorage.clear();
      document.location.href = '/';
    };
    if (pathname && pathname.match(/\/map\/\w{4,}/)) {
      const storageLocale = localStorage.getItem('lang')?.split('-')[0] || 'ru';
      i18n.changeLanguage(storageLocale);
      serIsOrderInfo(true);
    } else {
      checkAuth();
    }
  }, []);

  // useEffect(() => {
  //   if (document?.location?.hostname.indexOf("localhost") < 0) {
  //     if (document?.location?.hostname && document?.location?.hostname.indexOf("prime") >= 0 && usersStore?.isPrime === false) {
  //       document.location.href = `https://swiftdrive.ru${document?.location?.pathname}`;
  //     } else if (document?.location?.hostname && document?.location?.hostname.indexOf("prime") < 0 && usersStore?.isPrime === true) {
  //       document.location.href = `https://prime.swiftdrive.ru${document?.location?.pathname}`;
  //     }
  //   }
  // }, [usersStore?.isPrime]);

  if (!authStore?.isAuthenticated && !isOrderInfo) {
    return null;
  }

  return (
    <div id="app" className={`w-full h-full ${usersStore?.theme || ''}`}>
      <ReactNotifications />
      <Router>
        <Auth>
          <BaseLayout>
            <Switch>
              {/*
              <Route path="/login" component={Login} />
              <PrivateRoute path="/">
                <PrivateLayout>
                  <Switch>
                                  */}
              <Route path="/map/:orderId" component={OrderInfo} exact />
              {stores.isStoresInitialized ? (
                <>
                  <Route
                    exact
                    path="/"
                    render={() => {
                      return <Redirect to="/customer/new-order" />;
                    }}
                  />
                  <Route path="/customer/" component={showRides ? Rides : NewOrder} exact />
                  {!usersStore?.isCoordinator || usersStore?.me?.detail?.permissions?.view_analytics ? (
                    <Route path="/customer/analytics" component={Analytics} />
                  ) : null}
                  <Route path="/customer/employees" component={Employees} />
                  <Route path="/customer/favourites" component={Favourites} />
                  {!usersStore?.isCoordinator || usersStore?.me?.detail?.permissions?.create_coordinators ? (
                    <Route path="/customer/coordinators" component={Coordinators} />
                  ) : null}
                  <Route path="/customer/rides" component={Rides} />
                  <Route path="/customer/new-order" component={NewOrder} exact />
                  <Route path="/customer/reports" component={Reports} />
                  {usersStore?.isCustomer ||
                  (usersStore?.isCoordinator && usersStore?.me?.detail?.permissions?.manage_promocodes) ||
                  usersStore?.isPromo ? (
                    <Route path="/customer/promocodes" component={Promocodes} />
                  ) : null}
                  {!usersStore?.isCoordinator || usersStore?.me?.detail?.permissions?.create_policy_groups ? (
                    <Route path="/customer/policy" component={Policy} />
                  ) : null}
                </>
              ) : null}

              {/*
                  </Switch>
                </PrivateLayout>
              </PrivateRoute>
              */}
            </Switch>
          </BaseLayout>
        </Auth>
      </Router>
    </div>
  );
});

export default App;

import { observer } from 'mobx-react-lite';
import React, { HTMLAttributes, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';

interface CopyButtonProps {
  children?: React.ReactNode;
  disabled?: boolean;
  className?: string;
  title?: string;
  copyText: string;
  getCopiedText?: () => Promise<string>;
}

export const CopyButton = observer(
  ({
    children,
    disabled,
    className,
    title,
    copyText,
    getCopiedText,
  }: CopyButtonProps & HTMLAttributes<HTMLButtonElement>): JSX.Element => {
    const { t } = useTranslation('actions');
    const { usersStore } = useStores();

    const onCopy = async (event: any): Promise<void> => {
      event.preventDefault();
      event.stopPropagation();
      const inp = document.createElement('input');
      document.body.appendChild(inp);
      if (getCopiedText) inp.value = (await getCopiedText()) || copyText;
      else inp.value = copyText;
      inp.select();
      document.execCommand('copy', false);
      inp.remove();
    };

    return (
      <button disabled={disabled} className={className} type="button" onClick={onCopy}>
        {title || children || t('copy')}
      </button>
    );
  },
);

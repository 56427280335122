import { action, makeObservable, observable, runInAction } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';
import apiClient, { EmployeesApi } from './api';
import { AxiosError } from 'axios';
import { Employee } from 'types/types';

export default class EmployeesStore extends BaseStore {
  api: EmployeesApi;
  @observable resendSMSError: AxiosError | undefined;
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Employees']);
    this.api = new apiClient(rootStore.specifications['Employees'].url);
    makeObservable(this);
  }

  @observable isFetschingallEmployeesIdsInProgress: boolean = false;

  async resendSMS(id: number | string): Promise<void> {
    try {
      runInAction(() => {
        this.resendSMSError = undefined;
      });
      await this.api.resendSMS(id);
    } catch (err) {
      this.captureError(err);
      this.resendSMSError = err as AxiosError;
    }
  }
}

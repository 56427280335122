import React, { HTMLAttributes } from 'react';
import { LimitRow } from './LimitRow';
import { useStores } from 'stores';
import { MeDetail } from 'types/types';

interface LimitProps extends HTMLAttributes<HTMLDivElement> {
  limits: Pick<
    MeDetail,
    'limit_month_amount' | 'limit_month_amount_used' | 'limit_group_month_amount' | 'limit_group_month_amount_used'
  >;
}

export const Limit = ({ limits, className = '', ...divProps }: LimitProps): JSX.Element | null => {
  const { usersStore } = useStores();
  const { limit_month_amount, limit_month_amount_used, limit_group_month_amount, limit_group_month_amount_used } =
    limits || {};

  if (
    usersStore?.isPromo ||
    !(limit_month_amount || limit_month_amount_used || limit_group_month_amount || limit_group_month_amount_used)
  )
    return null;

  return (
    <div className={`flex flex-wrap justify-between gap-4 ${className}`} {...divProps}>
      <LimitRow limit={limit_month_amount} used={limit_month_amount_used} tKey="personal" />
      <LimitRow limit={limit_group_month_amount} used={limit_group_month_amount_used} tKey="group" />
    </div>
  );
};

import { action, makeObservable } from 'mobx';
import logServerMessage from '../../utils/logs';
import { RootStoreInterface } from '../../interfaces';

class LogsStore {
  constructor(rootStore: RootStoreInterface) {
    makeObservable(this);
  }

  @action
  async debug(msg: string): Promise<void> {
    const info = {};
    logServerMessage(msg, info);
  }
}

export default LogsStore;

export default {
  title: 'Координаторы',
  action: {
    add: 'Добавить координатора',
    edit: 'Редактирование координатора',
  },
  search_placeholder: 'Поиск',
  loading: 'Загрузка данных',
  error: 'Ошибка',
  thead: {
    full_name: 'ФИО',
    email: 'E-mail',
  },
  dialog: {
    full_name: 'ФИО',
    full_name_placeholder: 'Введите ФИО координатора',
    email_placeholder: 'Введите E-mail',
    email: 'E-mail',
    add: 'Добавить',
    save: 'Cохранить',
    cancel: 'Отменить',
    permissions: 'Права доступа',
    create_policy_groups: 'Создание групп политики-поездок',
    create_cost_centers: 'Создание кост-центров',
    create_favourites: 'Создание избранного',
    create_employees: 'Создание сотрудников',
    manage_can_order: 'Добавление сотрудникам право заказа для себя',
    manage_can_order_others: 'Добавление сотрудникам право заказа для других',
    view_analytics: 'Просмотр аналитики',
    create_coordinators: 'Создание координаторов',
    manage_promocodes: 'Управление промокодами',
    select_employee: 'Выбрать сотрудников',
    close: 'Закрыть',
    reset: 'Очистить',
    select: 'выбрать',

    employees_policy: 'Управление сотрудниками и группами политики поездок',
    select_employees: 'Сотрудники не выбраны',
    manage_all_employees: 'Все сотрудники',
    selected_employees: 'Доступно сотрудников',
    selected_classes: 'Доступно классов',
    select_classes: 'Классы не выбраны',
    manage_all_classes: 'Все классы',
    select_class: 'Выбрать доступные классы',
    select_group: 'Выбрать доступные группы',

    select_roles: 'Группы не выбраны',
    manage_all_roles: 'Все группы',
    selected_roles: 'Доступно групп',
    search_placeholder: 'Поиск',
  },
  class: {
    all: 'Все классы',
  },
  group: {
    search_placeholder: 'Найти группы',
    all: 'Все группы',
    default: 'По умолчанию',
    add_user: 'Выбрать сотрудников',
  },
  employees: {
    search_placeholder: 'Найти сотрудников',
    all: 'Все сотрудники',
  },
};

import { Pagination } from 'components/Pagination';
import { useWindowSize } from 'hooks/useWindowSize';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { ExportButton } from 'components/Buttons/ExportButton';
import { SearchBar } from 'components/SearchBar';
import Filter from './filter/index';
import ImportDialog from 'components/ImportData/ImportDialog';
import ActivateDialog from './activateDialog';
import { Dialog } from './dialog';
import { Promocode } from '../../types/types';
import FASpinner from '../../components/FASpinner';
import EditDelete from '../../components/EditDelete';
import Table from '../../components/Table';
import { CONTROLLER } from '../../constants/common';
import { formatPrice } from '../../utils/lib/formatPrice';
import moment from 'moment/moment';

const example = require('../../assets/import-examples/promocodes.xlsx');

const exportFields = [
  'code',
  'is_activated',
  'activated_by_employee_id',
  'activated_time',
  'prefix',
  'total_cost',
  'max_order_cost',
  'orders_count',
  'max_employee_count',
  'discount_percent',
  'is_used',
  'used_time',
  'used_orders_count',
  'count_activated',
  'active_from',
  'active_to',
  'role',
  'ctime',
];

interface PromocodeDialog {
  promocode: Promocode | null;
  isOpen: boolean;
}

const Promocodes = observer((): JSX.Element => {
  const { rolesCostCentersStore, promocodesStore, promocodesOrdersStore, ordersClassesStore, usersStore } = useStores();
  const [isDataReady, setIsDataReady] = useState<boolean>(false);
  const { t } = useTranslation(['promocodes', 'order']);
  const [showImportDialog, setShowImportDialog] = useState<boolean>(false);
  const windowSize = useWindowSize();
  const [isDeactivating, setIsDeactivating] = useState<boolean>(false);
  const [deactivatingPromo, setDeactivatingPromo] = useState<any>();
  const [promocodeDialog, setPromocodeDialog] = useState<PromocodeDialog>({
    promocode: null,
    isOpen: false,
  });
  const [activatePromocodeDialog, setActivatePromocodeDialog] = useState<boolean>(false);

  const loadData = async (): Promise<void> => {
    ordersClassesStore.fetchList(false, {}, 'id desc', 0, 20, true, false);
    return promocodesStore.fetchList(false, {}, 'id desc', 0, promocodesStore?.limit || 10, true, false);
  };

  const closeDialog = (): void => {
    setPromocodeDialog({
      promocode: null,
      isOpen: false,
    });
  };

  const getTableHeight = (): number | string => {
    if (!windowSize?.height) return 'auto';
    return windowSize.height - 178 - ((windowSize.width || 0) >= 992 ? 0 : 24);
  };

  useEffect(() => {
    loadData().then(() => setIsDataReady(true));
    rolesCostCentersStore?.resetLists();
  }, []);

  const onEdit = (promocode: any): void => {
    setPromocodeDialog({
      promocode,
      isOpen: true,
    });
  };
  const onChangeAvailability = async (promocode: any): Promise<void> => {
    await promocodesStore.updateRecord(promocode.id, { is_disabled: !promocode?.is_disabled });
    await promocodesStore.fetchList(false, {}, 'id desc', 0, promocodesStore?.limit || 10, true, false);
  };
  const renderController = (record: any): JSX.Element =>
    record?.is_guest || usersStore?.me?.role?.name === 'coordinator' ? (
      <div></div>
    ) : (
      <div className="text-right">
        {record?.id === deactivatingPromo?.id && isDeactivating ? (
          <FASpinner show />
        ) : (
          <>
            <EditDelete onEdit={() => onEdit(record)} closeOnBackdropPress appendChildren>
              <button className="edit-delete-item" onClick={() => onChangeAvailability(record)} type="button">
                {record?.is_disabled ? t('action.enable') : t('action.disable')}
              </button>
            </EditDelete>
          </>
        )}
      </div>
    );

  const renderCode = (promocode: any): JSX.Element => (
    <div className="space-y-1">
      <div>{promocode?.code}</div>
      <div className="mt-1"></div>
      {promocode.is_activated && <span className={`status ride-active`}>{t('trow.activated')}</span>}
      {promocode.is_disabled && <span className={`status ride-inactive`}>{t('trow.deactivated')}</span>}
      {promocode.is_used && <span className={`status ride-done`}>{t('trow.used')}</span>}
    </div>
  );

  const renderTotalCost = (promocode: any) => (
    <>{promocode?.total_cost ? formatPrice(+promocode.total_cost, '0,0.[00]', 2) : '-'}</>
  );

  const renderMaxOrderCost = (promocode: any) => (
    <>{promocode?.max_order_cost ? formatPrice(+promocode.max_order_cost, '0,0.[00]', 2) : '-'}</>
  );

  const renderOrdersCount = (promocode: any) => <>{promocode?.orders_count ? promocode?.orders_count : '-'}</>;

  const renderActiveFrom = (promocode: any) => (
    <>{promocode?.active_from ? moment.utc(promocode?.active_from * 1000).format('DD.MM.YYYY') : '-'}</>
  );
  const renderActiveTo = (promocode: any) => (
    <>{promocode?.active_to ? moment.utc(promocode?.active_to * 1000).format('DD.MM.YYYY') : '-'}</>
  );
  const renderClasses = (promocode: any) => (
    <>
      {promocode?.classes
        ? promocode.classes.map((cl: any) => (
            <span className="tariff-class">{t(cl?.class?.name, { ns: 'tariff' })}</span>
          ))
        : '-'}
    </>
  );
  const renderMaxEmployeeCount = (promocode: any) => (
    <>{promocode?.max_employee_count ? promocode?.max_employee_count : '-'}</>
  );
  const renderDiscountPercent = (promocode: any) => (
    <>{promocode?.discount_percent ? promocode?.discount_percent : '-'}</>
  );

  const renderUsedTime = (promocode: any) => (
    <>{promocode?.used_time ? moment.utc(promocode?.used_time * 1000).format('DD.MM.YYYY') : '-'}</>
  );
  const renderUsedOrdersCount = (promocode: any) => (
    <>{promocode?.used_orders_count ? promocode?.used_orders_count : '-'}</>
  );
  const renderCountActivated = (promocode: any) => <>{promocode?.count_activated ? promocode?.count_activated : '-'}</>;
  const renderRole = (promocode: any) => <>{promocode?.role?.name || promocode?.role_id || '-'}</>;

  const getListHeight = (): number | string => {
    if (!windowSize?.height) return 'auto';
    return windowSize.height - 340 - ((windowSize.width || 0) >= 992 ? 0 : 24);
  };

  return (
    <main className="flex-grow flex flex-col">
      <section className="pt-6 lg:pt-7.5">
        <div className="container">
          <div className="mb-6 lg:hidden">
            <h2 className="text-2lg leading-6 font-medium text-black">{t('title')}</h2>
          </div>
          <div className="mb-6 lg:mb-5 flex table-bar flex">
            <SearchBar containerClass="w-44 md:w-80" store={promocodesStore} placeholder={t('search_placeholder')} />
            <div className="flex space-x-4 lg:space-x-5 md:ml-auto">
              <Filter />
              {(usersStore?.isCustomer && usersStore?.me?.detail?.is_promo_available) ||
              (usersStore?.isCoordinator && usersStore?.me?.detail?.permissions?.manage_promocodes) ? (
                <button
                  className="btn btn-outline-blue px-1.5 w-10 flex items-center lg:px-4 lg:w-auto"
                  type="button"
                  onClick={() => setShowImportDialog(true)}
                >
                  <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                    <path d="M17 17.25a.75.75 0 0 1-.75.75H3.75a.75.75 0 1 1 0-1.5h12.5a.75.75 0 0 1 .75.75ZM10 2a.75.75 0 0 1 .75.75v10.19l2.72-2.72a.75.75 0 1 1 1.06 1.06l-3.996 3.997a.747.747 0 0 1-1.068 0L5.47 11.28a.75.75 0 1 1 1.06-1.06l2.72 2.72V2.75A.75.75 0 0 1 10 2Z"></path>
                  </svg>
                  <span className="hidden lg:block lg:ml-1.5">{t('action.import')}</span>
                </button>
              ) : null}
              {(usersStore?.isCustomer && usersStore?.me?.detail?.is_promo_available) ||
              (usersStore?.isCoordinator && usersStore?.me?.detail?.permissions?.manage_promocodes) ? (
                <ExportButton
                  className="flex items-center lg:px-4 lg:w-auto"
                  fields={exportFields}
                  filter={promocodesStore?.filter || {}}
                  store={promocodesStore}
                />
              ) : null}
              {(usersStore?.isCustomer && usersStore?.me?.detail?.is_promo_available) ||
              (usersStore?.isCoordinator && usersStore?.me?.detail?.permissions?.manage_promocodes) ? (
                <ExportButton
                  className="flex items-center lg:px-4 lg:w-auto"
                  fields={exportFields}
                  filter={promocodesStore?.filter || {}}
                  store={promocodesOrdersStore}
                  title={t('action.export_orders')}
                />
              ) : null}
              {(usersStore?.isCustomer && usersStore?.me?.detail?.is_promo_available) ||
              (usersStore?.isCoordinator && usersStore?.me?.detail?.permissions?.manage_promocodes) ? (
                <div>
                  <button
                    className="btn btn-blue px-1.5 w-10 flex items-center md:px-4 md:w-auto"
                    type="button"
                    onClick={() => {
                      setPromocodeDialog({ promocode: null, isOpen: true });
                    }}
                  >
                    <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                      <path d="M10 2A7.953 7.953 0 0 0 4.34 4.342c-3.12 3.12-3.122 8.195-.001 11.316A7.951 7.951 0 0 0 9.998 18c2.14 0 4.15-.832 5.661-2.344 3.121-3.118 3.121-8.193 0-11.314A7.956 7.956 0 0 0 10 2Zm0 1.359c1.776 0 3.445.69 4.698 1.944a6.648 6.648 0 0 1 0 9.393 6.605 6.605 0 0 1-4.7 1.945 6.6 6.6 0 0 1-4.697-1.944 6.649 6.649 0 0 1 .001-9.394A6.604 6.604 0 0 1 10 3.359Zm0 3.287a.68.68 0 0 0-.68.68V9.32H7.324a.68.68 0 1 0 0 1.358H9.32v1.996a.68.68 0 1 0 1.36 0v-1.996h1.996a.68.68 0 1 0 0-1.358H10.68V7.325a.68.68 0 0 0-.68-.68Z"></path>
                    </svg>
                    <span className="hidden md:block md:ml-1.5">{t('action.add')}</span>
                  </button>
                  <Dialog
                    promocode={promocodeDialog.promocode}
                    visible={promocodeDialog.isOpen}
                    onCancel={closeDialog}
                    onClose={closeDialog}
                  />
                </div>
              ) : null}
              {usersStore?.isPromo ? (
                <div>
                  <button
                    className="btn btn-blue px-1.5 w-10 flex items-center md:px-4 md:w-auto"
                    type="button"
                    onClick={() => {
                      setActivatePromocodeDialog(true);
                    }}
                  >
                    <span className="hidden md:block md:ml-1.5">{t('action.activate')}</span>
                  </button>
                  <ActivateDialog
                    visible={activatePromocodeDialog}
                    onCancel={() => setActivatePromocodeDialog(false)}
                    onClose={() => setActivatePromocodeDialog(false)}
                  />
                </div>
              ) : null}
            </div>
          </div>
          {promocodesStore.isFetchingListInProgress || !isDataReady ? (
            <span>{t('loading')}</span>
          ) : (
            <div className="area overflow-hidden">
              <div className="overflow-x-auto flex" style={{ maxHeight: getTableHeight() }}>
                <Table
                  stickyHeader
                  tableClass="table w-max-content"
                  keySelectors={{
                    full_name: 'name',
                    role: 'role.name',
                  }}
                  colClasses={[
                    'w-[9.5rem]',
                    'w-[9.5rem]',
                    'w-[9.5rem]',
                    'w-[8.5rem]',
                    'w-[10.5rem]',
                    'w-[10.5rem]',
                    'w-[12.5rem]',
                    'w-[8.5rem]',
                    'w-[8.5rem]',
                    'w-[10.5rem]',
                    'w-[8.5rem]',
                    'w-[12.5rem]',
                    'w-[12.5rem]',
                  ]}
                  columnsKeys={[
                    'code',
                    'total_cost',
                    'max_order_cost',
                    'orders_count',
                    'active_from',
                    'active_to',
                    'classes',
                    'max_employee_count',
                    'discount_percent',
                    'used_time',
                    'used_orders_count',
                    'count_activated',
                    'role',
                    CONTROLLER,
                  ]}
                  data={promocodesStore?.list || []}
                  translateFunc={(key: string) => (key && key !== CONTROLLER ? t(`thead.${key}`) : '')}
                  customTdInnerRenderer={{
                    code: renderCode,
                    total_cost: renderTotalCost,
                    max_order_cost: renderMaxOrderCost,
                    orders_count: renderOrdersCount,
                    active_from: renderActiveFrom,
                    active_to: renderActiveTo,
                    classes: renderClasses,
                    max_employee_count: renderMaxEmployeeCount,
                    discount_percent: renderDiscountPercent,
                    used_time: renderUsedTime,
                    used_orders_count: renderOrdersCount,
                    count_activated: renderCountActivated,
                    role: renderRole,
                    [CONTROLLER]: renderController,
                  }}
                >
                  <div className="area-inner">
                    <Pagination store={promocodesStore} />
                  </div>
                </Table>
              </div>
            </div>
          )}
        </div>
        <ImportDialog
          onClose={() => setShowImportDialog(false)}
          store={promocodesStore}
          visible={showImportDialog}
          example={example}
          translation="promocodes"
        />
      </section>
    </main>
  );
});

export default Promocodes;

import { NumberOrString } from 'types/types';
import WebApiClient from './WebApi';
import { serialize } from '../utils/lib/serialize';

class BaseCRUD {
  schema: string = '';
  urlVersion: number = 1;

  constructor(schema: string) {
    this.setSchemaUrl(schema);
  }

  setSchemaUrl(schema: string) {
    this.schema = schema;
  }

  setUrlVersion(v: number = 1) {
    this.urlVersion = v;
  }

  async list(
    filter: any,
    orderBy: string = 'id desc',
    page: number = 0,
    limit: number = 10,
    detail: boolean = false,
    query: object = {},
  ) {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/list${Object.keys(query).length ? `?${serialize(query)}` : ``}`,
      data: {
        filter,
        orderBy,
        page,
        limit,
        detail: detail ? 1 : 0,
      },
    });
  }

  async linkedList(fieldName: string, filter: any, fields: Array<string> = [], orderBy = 'id desc', limit = 10) {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/linked-list/${fieldName}`,
      data: { filter, fields, orderBy, limit },
    });
  }

  async get(id: number, detail: boolean, role: string = '') {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/${id}?detail=${detail ? 1 : 0}${role ? `&role=${role}` : ''}`,
      //urlVersion: this.urlVersion,
    });
  }

  async add(params: any) {
    const { query, ...data } = params || {};
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}${query && Object.keys(query).length ? `?${serialize(query)}` : ``}`,
      data,
    });
  }

  async update(id: number, data: any) {
    return await WebApiClient.fetch({
      method: 'put',
      url: `/${this.schema}/${id}`,
      data,
    });
  }

  async delete(id: number) {
    return await WebApiClient.fetch({
      method: 'delete',
      url: `/${this.schema}/${id}`,
    });
  }

  async export(moduleName: string, filter: any, orderBy: string, fields: Array<string>, fileType: string) {
    return await WebApiClient.fetch(
      {
        method: 'post',
        url: `/reports`,
        urlVersion: 1,
        data: {
          moduleName,
          filter,
          orderBy,
          fields,
          fileType,
        },
        returnBody: true,
      },
      true,
    );
  }

  async checkExport(id: NumberOrString) {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/reports/${id}`,
    });
  }

  async import(formData: any) {
    return await WebApiClient.uploadFile({ method: 'post', url: `${this.schema}/import` }, formData);
  }

  async importProcess(uuid: string) {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/import/${uuid}`,
    });
  }
}

export default BaseCRUD;

import { makeObservable, runInAction } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';

class FavouritesStore extends BaseStore {
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['EmployeesFavourites']);
    makeObservable(this);
  }

  async resetList() {
    runInAction(() => {
      this.list = [];
    });
  }
}

export default FavouritesStore;

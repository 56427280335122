export enum RequirementType {
  Checkbox = 'checkbox',
  String = 'string',
  List = 'list',
  Date = 'date',
}

export enum CancelledByUserId {
  Driver = -2,
  DriverNotFound = -3,
}

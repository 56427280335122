import { makeObservable, observable, runInAction, action } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';
import RolesApi from './api';
import { Role } from 'types/types';

export default class RolesStore extends BaseStore {
  api: RolesApi;
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Roles']);
    this.api = new RolesApi(rootStore.specifications['Roles'].url);
    makeObservable(this);
  }

  @observable activeGroup?: any = null; //{id: 0};
  @observable newGroupUsers: any[] = [];
  @observable defaultGroup: any;
  @observable isFetchingDefaultGroupCompleted: boolean = false;

  @observable isFetschingallGroupsIdsInProgress: boolean = false;

  @action
  async fetchDefaultGroup(): Promise<void> {
    runInAction(() => {
      this.defaultGroup = null;
      this.isFetchingDefaultGroupCompleted = false;
    });
    try {
      const { items, total } = await this.api.getDefault();
      runInAction(() => {
        this.defaultGroup = (items && items[0]) || null;
      });
    } catch (err) {
      console.error('ERROR FETCHING DEFAULT GROUP', err);
      this.captureError(err);
    }
    runInAction(() => {
      this.isFetchingDefaultGroupCompleted = true;
    });
  }

  @action
  setActiveGroup(group: any) {
    if (group !== null) {
      group.econom = !!group.econom;
      group.comfort = !!group.comfort;
      group.comfortplus = !!group.comfortplus;
      group.exclusive = !!group.exclusive;
      group.delivery = !!group.delivery;
      group.minivan = !!group.minivan;
      group.cargo = !!group.cargo;
      group.premium = !!group.premium;
      group.default = !!group.default;
    }

    this.activeGroup = group;
  }

  @action
  addUser(user: any) {
    runInAction(() => {
      this.newGroupUsers = [user, ...this.newGroupUsers];
    });
  }

  @action
  deleteUser(id: number) {
    runInAction(() => {
      this.newGroupUsers = this.newGroupUsers.filter((i: any) => i.id !== id);
    });
  }

  @action
  resetUsers() {
    runInAction(() => {
      this.newGroupUsers = [];
    });
  }

  @action
  async updateActiveGroup(updates: any) {
    if (this.activeGroup?.id !== 0) {
      await this.activeGroup.update(updates);
      //const activeGroup = this.list?.find((g: any) => g.id === this.activeGroup.id);
      //if (activeGroup) {
      //this.setActiveGroup(activeGroup)
      //}
      //return
    }

    runInAction(() => {
      this.activeGroup = { ...this.activeGroup, ...updates };
    });
  }

  @action
  async createGroup() {
    const record = {
      name: this.activeGroup.name,
      econom: this.activeGroup.econom,
      comfort: this.activeGroup.comfort,
      comfortplus: this.activeGroup.comfortplus,
      exclusive: this.activeGroup.exclusive,
      delivery: this.activeGroup.delivery,
      minivan: this.activeGroup.minivan,
      cargo: this.activeGroup.cargo,
      premium: this.activeGroup.premium,
      distance: this.activeGroup.distance,
      month_amount: this.activeGroup.month_amount,
      default: !!this.activeGroup.default,
      schedule: this.activeGroup.schedule || {},
      zones: this.activeGroup.zones || {},
      month_amount_group: this.activeGroup.month_amount_group,
      //cost_centers: this.activeGroup.cost_centers,
    };
    if (!!record.default) {
      const defaultGroup = this.list?.find((g: any) => !!g.default);
      if (defaultGroup) {
        this.updateRecord(defaultGroup.id, { default: 0 });
      }
    }
    if (this.activeGroup.id === 0) {
      return await this.addRecord(record);
    } else {
      return await this.updateRecord(this.activeGroup.id, record);
    }
  }
}

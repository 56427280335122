import React from 'react';
import Discussion from '../../images/discussion.png';
import Discussion2x from '../../images/discussion.png';
import { useStores } from 'stores';
import { useNestedTranslation } from 'hooks/useNestedTranslations';

interface EmptyProps {
  onNewGroup?: any;
}

const Empty = ({}: EmptyProps): JSX.Element | null => {
  const { rolesStore } = useStores();
  const { t } = useNestedTranslation('policy.empty');
  return (
    <div className="area mb-0 hidden flex-grow lg:flex lg:flex-col lg:w-full">
      <div className="area-inner flex-grow flex flex-col items-center justify-center">
        <div className="w-80 text-center">
          <div className="mb-5">
            <img className="mx-auto" src={Discussion} /*srcSet={`${Discussion2x} 2x`}*/ alt="" />
          </div>
          <div className="mb-5">
            <p>{t('text')}</p>
          </div>
          <button
            className="btn btn-light space-x-1.5 px-4"
            type="button"
            onClick={() => {
              rolesStore.setActiveGroup({ id: 0 });
            }}
          >
            <svg className="w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
              <path d="M10 2A7.953 7.953 0 0 0 4.34 4.342c-3.12 3.12-3.122 8.195-.001 11.316A7.951 7.951 0 0 0 9.998 18c2.14 0 4.15-.832 5.661-2.344 3.121-3.118 3.121-8.193 0-11.314A7.956 7.956 0 0 0 10 2Zm0 1.359c1.776 0 3.445.69 4.698 1.944a6.648 6.648 0 0 1 0 9.393 6.605 6.605 0 0 1-4.7 1.945 6.6 6.6 0 0 1-4.697-1.944 6.649 6.649 0 0 1 .001-9.394A6.604 6.604 0 0 1 10 3.359Zm0 3.287a.68.68 0 0 0-.68.68V9.32H7.324a.68.68 0 1 0 0 1.358H9.32v1.996a.68.68 0 1 0 1.36 0v-1.996h1.996a.68.68 0 1 0 0-1.358H10.68V7.325a.68.68 0 0 0-.68-.68Z"></path>
            </svg>
            <span>{t('actions.new')}</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Empty;

export const isRideCancelable = (statusId: string): boolean => {
  if (!statusId) {
    return false;
  }
  switch (statusId) {
    case 'driving':
    case 'waiting':
    case 'search':
    case 'scheduled':
    case 'assigned':
    case 'new':
      return true;
    default:
      return false;
  }
};

import React, { useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';

export interface PortalProps {
  children: React.ReactChild | React.ReactChild[];
  className?: string;
  element?: string;
  onBackDropPress?: any;
  contentWrapperClass?: string;
}

export const Portal = ({
  children,
  className = 'portal',
  contentWrapperClass = '',
  element = 'div',
  onBackDropPress,
}: PortalProps): JSX.Element | null => {
  const [container] = useState(() => {
    const el = document.createElement(element);
    el.classList.add(className);
    return el;
  });

  const childrenWrapperRef = useRef<HTMLDivElement>(null);

  const [childrenWrapper] = useState(() => (
    <div
      className={contentWrapperClass}
      ref={childrenWrapperRef}
      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => e.stopPropagation()}
    >
      {children}
    </div>
  ));

  const onBackPress = (e: any) => {
    if (onBackDropPress && !childrenWrapperRef.current?.contains(e.target)) {
      onBackDropPress();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', onBackPress);
    return () => document.removeEventListener('mousedown', onBackPress);
  });

  useEffect(() => {
    document.body.appendChild(container);
    return () => {
      document.body.removeChild(container);
    };
  }, []);

  // @ts-ignore
  return ReactDOM.createPortal(childrenWrapper, container);
};

import React, { useRef, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Option } from '../../types/types';

export interface ModalSelectProps {
  mode?: 'select' | 'dropdown';
  style?: React.CSSProperties;
  children?: React.ReactChild | React.ReactChild[];
  options?: Option[];
  onChange?: any;
  wrapperClass?: string;
  innerClass?: string;
  containerClass?: string;
  toggleWrapperClass?: string;
  optionClass?: string;
  idPrefix?: string;
  listProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement>;
  customContent?: React.ReactElement;
  withPointer?: boolean;
  pointerPositionX?: 'right' | 'left';
  pointerPositionY?: 'bottom' | 'top';
  onBackdropPress?: any;
  closeOnBackdropPress?: boolean;
  value?: string | number;
  values?: Array<string>;
  onOpen?: any;
  label?: string;
  placeholder?: string;
  filterable?: boolean;
  inputOnly?: boolean;
  inputWrapperClass?: string;
  cancelable?: boolean;
  alwaysOpen?: boolean;
  onBlur?: any;
  alwaysBottom?: boolean;
  onClose?: any;
  multi?: boolean;
}

const defaultStyles = {
  height: 0,
  overflow: 'scroll',
  borderWidth: 0,
};

const ModalSelect = ({
  mode = 'select',
  options = [],
  onChange,
  idPrefix,
  listProps = {},
  withPointer,
  toggleWrapperClass = '',
  alwaysOpen = false,
  pointerPositionX = 'right',
  value,
  onOpen = () => {},
  closeOnBackdropPress = true,
  filterable = false,
  onBlur = () => {},
  onClose,
  wrapperClass = '',
  innerClass = '',
  optionClass = 'one-line-text',
  children,
  onBackdropPress,
  inputOnly = false,
  alwaysBottom = false,
  containerClass = '',
  customContent,
  pointerPositionY = 'bottom',
  label,
  placeholder = '',
  inputWrapperClass = '',
  cancelable = false,
  multi = false,
  values = [],
}: ModalSelectProps): JSX.Element => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const innerRef = useRef<HTMLDivElement>(null);
  const [visible, setVisible] = useState<boolean>(false);
  const [search, setSearch] = useState<string>(value?.toString() || '');
  const [filteredOptions, setOptions] = useState<Option[]>(options);
  const [multiOptions, setMultiOptions] = useState<Option[]>(options);
  const portalRef = useRef<HTMLDivElement>(null);
  const openTopRef = useRef<boolean>(false);

  const [styles, setStyles] = useState<any>(defaultStyles);

  if (multi) filterable = false;

  useEffect(() => {
    if (visible || alwaysOpen) {
      setStyles(getPosition());
      if (onOpen) {
        onOpen();
      }
    } else {
      setStyles(defaultStyles);
    }
    if (onClose && !visible) onClose();
  }, [visible, alwaysOpen]);

  useEffect(() => {
    if (value && !multi) {
      setSearch(value.toString());
    }
  }, [value]);

  useEffect(() => {
    if (values && multi) {
      setMultiOptions(options.filter((opt: Option) => (values || []).indexOf(opt.value) < 0));
    }
  }, [values]);

  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (
        !wrapperRef?.current?.contains(e.target) &&
        !portalRef?.current?.contains(e.target) &&
        closeOnBackdropPress &&
        visible
      ) {
        setVisible(false);
        if (onBackdropPress) {
          onBackdropPress(e);
        }
      }
    };
    document.addEventListener('touchstart', checkIfClickedOutside);
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('touchstart', checkIfClickedOutside);
      document.removeEventListener('click', checkIfClickedOutside);
    };
  });

  const getPosition = (): React.CSSProperties => {
    if (options.length === 0 && !customContent) return defaultStyles;
    let position: any = {};
    const bodyRect = document.body.getBoundingClientRect();
    const wrapperRect: DOMRect | undefined = wrapperRef.current?.getBoundingClientRect();
    position.top = (wrapperRect?.bottom || 0) - (bodyRect?.top || 0);
    position.left =
      (wrapperRect?.right || 0) -
      (customContent ? portalRef.current?.getBoundingClientRect().width || 0 : wrapperRect?.width || 0);
    position.right = 'auto';
    position.width = customContent ? 'auto' : wrapperRect?.width || 100;

    const innerRect: DOMRect | undefined = innerRef.current?.getBoundingClientRect();
    if (!wrapperRef.current || !window || !wrapperRect || !innerRect) return position;
    if (withPointer) {
      position.top = position.top + 5;
      position.left = position.left + 17;
    }
    if (
      (!alwaysBottom && window.innerHeight - wrapperRect.bottom < (innerRef.current?.scrollHeight || 0) + 10) ||
      pointerPositionY === 'top'
    ) {
      position = {
        ...position,
        top: (wrapperRect?.top || 0) - (bodyRect?.top || 0) - (portalRef.current?.scrollHeight || 0) - 10,
      };
      openTopRef.current = true;
    } else {
      openTopRef.current = false;
    }
    if (
      (!openTopRef.current || alwaysBottom) &&
      window.innerHeight - 5 < wrapperRect?.bottom + 5 + innerRect.height &&
      !customContent
    ) {
      position.overflowY = 'scroll';
      position.bottom = '5px';
    }
    // if (pointerPositionX === 'left') {
    //     position = {
    //         ...position,
    //         // right: 'auto',
    //         // left: -10
    //     }
    // }
    return position;
  };

  const renderOption = (option: Option, index: number): React.ReactElement => {
    let attrs: any = {
      role: 'option',
      className: `${mode}-item ${optionClass}`,
      onClick: () => onPress(option),
      key: option.id,
    };
    attrs = idPrefix ? { ...attrs, id: `${idPrefix}-${index}` } : attrs;

    return (
      <li {...attrs}>
        {option.subtitle ? (
          <span className="flex flex-row">
            <span className="font-bold" style={{ width: '200px' }}>
              {option.label}
            </span>
            {option.distance && (
              <span className="text-right" style={{ width: '100px', flex: '0 0 100px' }}>
                {option.distance}
              </span>
            )}
          </span>
        ) : (
          option.label
        )}
        {option.subtitle && <span>{option.subtitle}</span>}
      </li>
    );
  };

  const onInputChange = (e: any): void => {
    const { value = '' } = e.target || {};
    if (!visible) {
      setVisible(true);
    }
    e.preventDefault();
    setSearch(value);
    setOptions(() => options.filter((op: Option) => op.value.toString().toLowerCase().includes(value.toLowerCase())));
  };

  const onPress = (option: Option): void => {
    if (onChange) {
      onChange(option);
    }
    setVisible(false);
  };

  const reset = (e: any): void => {
    e.stopPropagation();
    if (onChange) {
      onChange(undefined);
    }
    setSearch('');
    setOptions(options);
    setMultiOptions(options);
  };

  const renderResetBtn = (): React.ReactElement | null =>
    cancelable ? (
      <button onClick={reset} className=" text-black text-opacity-40 hover:text-opacity-50" type="button">
        <svg className="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
        </svg>
      </button>
    ) : null;

  const renderMultiValues = () => (
    <div>
      {(values || []).map((v) => {
        const [val] = options?.filter((o) => o.value == v);
        return (
          <span className="selected-option" onClick={() => onChange(options?.filter((o) => o.id === v)[0], true)}>
            {val?.label || val?.value || val?.id || v}
          </span>
        );
      }) || []}
    </div>
  );

  return (
    <div ref={wrapperRef} className={`${mode} ${wrapperClass}`}>
      {filterable && (
        <>
          <div
            className={`select-toggler ${visible ? 'is-open' : ''} ${inputOnly ? 'input-search' : ''} ${toggleWrapperClass} flex items-center`}
            onClick={() => !inputOnly && setVisible(!visible)}
            aria-haspopup="listbox"
            aria-labelledby="role-label"
          >
            <input
              onChange={onInputChange}
              value={search}
              className={'input-filter-field dark-form-control'}
              onBlur={onBlur}
              placeholder={placeholder}
            />
            {renderResetBtn()}
            {!inputOnly && (
              <svg className="caret w-6 h-6" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
                <path d="m12.75 16.5 7.35-7.48c.4-.44.4-1.1 0-1.51a1.05 1.05 0 0 0-1.49 0L12 14.24 5.39 7.51a1.05 1.05 0 0 0-1.49 0 1.1 1.1 0 0 0 0 1.5l7.37 7.48c.4.41 1.06.41 1.48 0Z"></path>
              </svg>
            )}
          </div>
        </>
      )}
      {!filterable && (
        <>
          {children ? (
            <div onClick={() => setVisible(true)}>{children}</div>
          ) : (
            <div>
              {label && <span className="form-label">{label}</span>}
              <button
                className={`${mode}-toggler ${visible ? 'is-open' : ''} ${toggleWrapperClass}`}
                type="button"
                onClick={() => setVisible(!visible)}
              >
                <div className="flex justify-between">
                  {multi ? (
                    renderMultiValues()
                  ) : (
                    <span className={`text-black text-opacity-${value ? 100 : 40} overflow-ellipsis`}>
                      {value || placeholder}
                    </span>
                  )}
                  {!multi && renderResetBtn()}
                </div>
                <svg className="caret w-5 h-5" fill="currentColor" viewBox="0 0 20 20" aria-hidden="true">
                  <path d="m10.539 12.808 5.245-4.672c.288-.272.288-.688 0-.944-.287-.256-.754-.256-1.06 0L10 11.4 5.275 7.192a.827.827 0 0 0-1.06 0c-.287.256-.287.672 0 .944l5.264 4.672c.287.256.755.256 1.06 0Z"></path>
                </svg>
              </button>
            </div>
          )}
        </>
      )}
      {ReactDOM.createPortal(
        <div ref={portalRef} className={`${mode}-list ${containerClass}`} style={styles}>
          {withPointer && (
            <div
              className="pointer"
              style={{
                transform: `rotate(${openTopRef.current ? 225 : 45}deg)`,
                right: pointerPositionX === 'right' ? 12 : 'auto',
                left: pointerPositionX === 'left' ? 12 : 'auto',
                top: openTopRef.current ? (innerRef.current?.getBoundingClientRect().height || 0) - 7 : -8,
              }}
            />
          )}
          <div ref={innerRef}>
            {customContent ? (
              <div onClick={() => setVisible(false)}>{customContent}</div>
            ) : (
              <ul className={`${mode}-inner ${innerClass}`} role="listbox" {...listProps}>
                {filterable
                  ? filteredOptions.map(renderOption)
                  : multi
                    ? multiOptions.map(renderOption)
                    : options.map(renderOption)}
              </ul>
            )}
          </div>
        </div>,
        document.body,
      )}
    </div>
  );
};

export default ModalSelect;

import { observable, runInAction, makeObservable } from 'mobx';
import BaseStore from '../BaseStore';
import Storage from 'utils/storage';

export type TGeoLocation = {
  lat?: number;
  lng?: number;
  latitudeDelta?: number;
  longitudeDelta?: number;
};
class GPSLocationStore extends BaseStore {
  @observable
  currentLocation: TGeoLocation = {};

  @observable
  isInitialized: boolean = false;
  isInitCalled: boolean = false;

  constructor(rootStore) {
    super(rootStore, {});
    this.rootStore = rootStore;
    makeObservable(this);
  }

  async init() {
    if (this.isInitCalled) return;
    this.isInitCalled = true;

    console.log('GPS Location Store initialization');

    Storage.getItem('LAST_LOCATION').then((location) => {
      if (location) {
        runInAction(() => {
          try {
            this.currentLocation = JSON.parse(location);
          } catch (e) {
            console.error(e);
          }
        });
      }
    });
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.currentLocation = {
          lat: pos?.coords[0],
          lng: pos?.coords[1],
          latitudeDelta: 0.005,
          longitudeDelta: 0.005,
        };
      },
      () => {
        //
      },
      { enableHighAccuracy: true },
    );
  }

  async askLocationAsync() {
    const pos: GeolocationPosition = await new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition(resolve, reject);
    });

    return pos;
  }

  async setCurrentLocation(location) {
    runInAction(() => {
      this.currentLocation = {
        lat: location.coords.latitude,
        lng: location.coords.longitude,
      };
      console.log('SET LOCATION', this.currentLocation);

      Storage.setItem('LAST_LOCATION', JSON.stringify(this.currentLocation));
    });
  }

  async getCurrentLocation() {
    const pos: GeolocationPosition = await this.askLocationAsync();
    await this.setCurrentLocation(pos);
    return { ...this.currentLocation };
  }

  timeouts = [];
  requestLocationUpdate() {
    if (!this.isInitialized) return;

    for (let i = 0; i < this.timeouts.length; i++) {
      clearTimeout(this.timeouts[i]);
    }
    this.timeouts = [];
    for (let i = 0; i < 4; i++) {
      const timeout: ReturnType<typeof setTimeout> = setTimeout(() => {
        try {
          this.requestLocation();
        } catch (e) {}
      }, i * 3000);
      //@ts-ignore
      this.timeouts = [...this.timeouts, timeout];
    }
  }
  requestLocation() {
    try {
      navigator.geolocation.getCurrentPosition((pos) => {
        try {
          this.setCurrentLocation(pos);
        } catch (e) {
          //
        }
      });
    } catch (e) {}
  }
}

export default GPSLocationStore;

import React from 'react';
import { observer } from 'mobx-react-lite';
import Form from './form';
import Map from './map';

const NewOrder = observer((): JSX.Element => {
  return (
    <main className="flex-grow">
      <section className="lg:pt-7.5 lg:pb-7.5 flex-grow">
        <div className="container flex-grow">
          <div className="flex-grow lg:flex lg:space-x-5">
            <Form />
            <Map />
          </div>
        </div>
      </section>
    </main>
  );
});

export default NewOrder;

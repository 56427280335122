import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { YMaps, Map, YMapsApi, Placemark, ZoomControl } from 'react-yandex-maps';
import { observer } from 'mobx-react-lite';
import { OrderRouteProps } from '.';
import { ROUTE_MAP } from '../../../constants/colors';
import { useStores } from 'stores';
import { ROUTE_MAP_WIDTH } from 'constants/common';
import { getCoords } from 'utils/lib/map/getCoords';
import base64CurrentPos from '../../NewOrder/assets/base64/current-position';
import base64DestinationPos from '../../NewOrder/assets/base64/destination-position';
import base64CurrentPosVip from '../../NewOrder/assets/base64/vip/current-position';
import base64DestinationPosVip from '../../NewOrder/assets/base64/vip/destination-position';
import { addWayPointsPlacemarks } from 'utils/lib/map/addWayPointsPlacemarks';
import { isCarClassNameKnown } from 'utils/lib/isCarClassNameKnown';
import { Location } from 'types/types';
import { MOSCOW_CENTER } from 'constants/map';

const YandexOrderRoute = observer(({ record, mapRef }: OrderRouteProps) => {
  const mapApiRef = useRef<any>();
  const { ordersStore, authStore } = useStores();
  const { current_location } = record || {};
  const mapsApiRef = useRef<YMapsApi>();
  const [isMapReady, setIsMapReady] = useState<boolean>(false);
  const [isZoomed, setIsZoomed] = useState<boolean>(false);
  const carPlacemarkRef = useRef<any>(null);
  const timerIdRef = useRef<NodeJS.Timer>();
  const animationTimerIdRef = useRef<NodeJS.Timer>();
  const carDirectionRef = useRef<number>();
  const routeRef = useRef<any>(null);
  const polylineRef = useRef<any>(null);
  const prevLocationRef = useRef<Location>({ lat: null, lng: null }).current;
  const timestampRef = useRef<number>();
  // car placemark coordinate change interval
  const animInterval = 100;
  const orderStatusRef = useRef<string>('');
  const [shouldUpdateRoute, setShouldUpdateRoute] = useState<boolean>(true);
  const [isTrackLoaded, setIsTrackLoaded] = useState<boolean>(false);
  useEffect(() => {
    ordersStore?.fetchTrack(record?.id).then(() => setIsTrackLoaded(true));
    return () => {
      if (animationTimerIdRef.current) clearInterval(animationTimerIdRef.current);
      if (timerIdRef.current) clearInterval(timerIdRef.current);
      ordersStore?.removeTrack(record?.id);
    };
  }, []);

  const getAnimationRange = (animCount: number): (string | number)[][] => {
    if (!prevLocationRef?.lat || !prevLocationRef?.lng) {
      return [[current_location.lat], [current_location.lng]];
    }
    const latDelta = (+current_location.lat - +prevLocationRef.lat) / animCount;
    const lngDelta = (+current_location.lng - +prevLocationRef.lng) / animCount;
    return [
      Array(animCount)
        .fill(0)
        .map((_, i: number) => +prevLocationRef.lat + i * latDelta),
      Array(animCount)
        .fill(0)
        .map((_, i: number) => +prevLocationRef.lng + i * lngDelta),
    ];
  };

  /**
   * Assign an object with some map methods to mapRef
   */
  useEffect(() => {
    if (isMapReady && mapRef) {
      mapRef.current = {
        setCenter(coords, zoom = 17, options = {}, cancelUpdateZoom: boolean = false) {
          const opt = { checkZoomRange: true, duration: 700, ...options };
          mapApiRef.current?.setCenter(coords, cancelUpdateZoom ? mapApiRef.current?.getZoom() : zoom, {
            checkZoomRange: !cancelUpdateZoom,
            duration: 700,
          });
        },
      };
    }
  }, [isMapReady]);

  const onMapInstanceInit = useCallback((ref: any): void => {
    if (!mapApiRef.current && ref) {
      ref.events?.add('boundschange', onBoundsChange);
    }
    mapApiRef.current = ref;
    if (!ref) return;
  }, []);

  const onBoundsChange = useCallback(
    (event): void => {
      if (event.get('newZoom') !== event.get('oldZoom')) {
        setIsZoomed(true);
      }
    },
    [isMapReady],
  );

  /**
   * make location object from passed props
   * @param lat lat coord
   * @param lng lng coord
   * @returns Location object
   */
  const toLocation = (lat: any, lng: any): Location => ({ lat, lng });

  useEffect(() => {
    if (!current_location || !isMapReady) return;
    timestampRef.current = timestampRef.current ? timestampRef.current : Date.now();
    if (current_location.lat === prevLocationRef?.lat && current_location.lng === prevLocationRef?.lng) return;
    let currentTime = Date.now();
    let updateCurrentLocationInterval = currentTime - timestampRef.current;
    const range = getAnimationRange(Math.ceil(updateCurrentLocationInterval / animInterval));
    if (!carPlacemarkRef.current && mapsApiRef) {
      mapApiRef.current?.geoObjects?.remove(carPlacemarkRef.current);
      addCar(
        mapsApiRef.current,
        { lat: range[0][0], lng: range[1][0] },
        getCarDirection({ lat: range[0][0], lng: range[1][0], direction: current_location?.direction }),
      );
    }
    if (animationTimerIdRef.current) clearInterval(animationTimerIdRef.current);
    let i = 0;
    animationTimerIdRef.current = setInterval(() => {
      if (i >= range[0].length && animationTimerIdRef.current) {
        clearInterval(animationTimerIdRef.current);
        return;
      }
      if (i) {
        const direction = getCarDirection(
          toLocation(range[0][i], range[1][i]),
          toLocation(range[0][i - 1], range[1][i - 1]),
        );
        if (Math.round(direction) !== Math.round(carDirectionRef.current || 0)) {
          carPlacemarkRef.current?.options?.set('iconRotate', direction);
          carDirectionRef.current = direction;
        }
      }
      carPlacemarkRef.current?.geometry?.setCoordinates([range[0][i], range[1][i]]);
      i += 1;
    }, animInterval);
    prevLocationRef.lat = current_location.lat;
    prevLocationRef.lng = current_location.lng;
    timestampRef.current = currentTime;
  }, [isMapReady, current_location, timerIdRef.current]);

  const setDrivingOrTransporting = (): void => {
    let coords = [getCoords(record?.current_location), getCoords(record?.source)] as number[][];
    if (record?.status_id === 'transporting') {
      coords = [
        getCoords(record?.source),
        getCoords(record?.current_location),
        getCoords(record?.destination),
      ] as number[][];
    }
    setRoute(coords, record?.status_id === 'transporting');
  };

  useEffect(() => {
    if (!isMapReady || !current_location?.lat || !shouldUpdateRoute || !record?.status_id) return;
    if (record?.status_id === 'driving' || record?.status_id === 'transporting') {
      setDrivingOrTransporting();
      setShouldUpdateRoute(false);
      setTimeout(() => setShouldUpdateRoute(true), 1000 * 30);
    }
  }, [isMapReady, current_location, shouldUpdateRoute, record?.status_id]);

  useEffect(() => {
    if (!isMapReady || orderStatusRef.current === record?.status_id) return;
    if (record?.status_id === 'driving' || record?.status_id === 'transporting') {
      if (!record?.current_location) return;
      setDrivingOrTransporting();
    } else {
      if (record?.status_id === 'complete' && !isTrackLoaded) return;
      // if track has coords then add polyline (addPolyline) else add route (setRoute)
      const track = ordersStore?.tracks[record.id] || record?.track;
      if (record?.status_id === 'complete' && track && track.length) {
        addPolyline([getCoords(record?.source), ...(getCoords(track) || []), getCoords(record?.destination)]);
        return;
      }
      setRoute([
        getCoords(record?.source),
        ...(getCoords((record?.status_id === 'complete' && track) || record?.interim_destinations) || []),
        getCoords(record?.destination),
      ] as number[][]);
    }
    orderStatusRef.current = record?.status_id;
  }, [record?.status_id, isMapReady, current_location, isTrackLoaded]);

  const addCar = (ymapsApi: YMapsApi | undefined, points = current_location, direction: number = 0): void => {
    if (!ymapsApi || !(points?.lat && points?.lng)) return;
    const placemark = new ymapsApi.Placemark(
      getCoords(points) as number[],
      {},
      {
        iconLayout: ymapsApi.templateLayoutFactory.createClass(
          ['<div style="transform:rotate({{options.rotate}}deg)">', '{% include "default#image" %}', '</div>'].join(''),
        ),
        iconImageHref: require(
          `../../../assets/images/cars-top/${isCarClassNameKnown(record?.class?.name) ? record.class.name : 'econom'}.png`,
        ),
        iconImageSize: [50, 50],
        iconImageOffset: [-25, -25],
        iconRotate: direction,
      },
    );
    carPlacemarkRef.current = placemark;
    mapApiRef.current?.geoObjects?.add(placemark);
  };

  const getCarDirection = (current: Location, prev?: Location): number => {
    if (current?.direction) {
      return current.direction;
    }
    if (!prev?.lat || !prev?.lng) return 0;
    const direction = 90 - (Math.atan2(current.lat - prev.lat, current.lng - prev.lng) * 180) / Math.PI;
    return direction > 0 ? direction - 5 : direction + 10;
  };

  const onLoad = (ymaps: YMapsApi): void => {
    mapsApiRef.current = ymaps;
    setIsMapReady(true);
    if (!current_location) return;
    addCar(ymaps, current_location, getCarDirection(current_location));
  };

  const addPolyline = (arr) => {
    if (polylineRef?.current) {
      mapApiRef.current.geoObjects.remove(polylineRef.current);
    }
    if (!mapsApiRef.current) return;
    polylineRef.current = new mapsApiRef.current.Polyline(
      arr,
      {},
      { strokeWidth: ROUTE_MAP_WIDTH, strokeColor: ROUTE_MAP, strokeOpacity: 0.7 },
    );
    mapApiRef.current.geoObjects.add(polylineRef.current);
    if (!polylineRef.current) return;
    if (!isZoomed) {
      mapApiRef.current.setBounds(polylineRef.current?.geometry.getBounds());
    }
  };

  const setRoute = (points: (string[] | number[])[], withPassedRoute: boolean = false): void => {
    var balloonLayout = mapsApiRef.current?.templateLayoutFactory.createClass('<div>', {
      build: function () {
        this.constructor.superclass.build.call(this);
      },
    });

    if (!mapsApiRef.current) return;
    const boundsAutoApply =
      (!routeRef.current || record?.status_id !== 'driving' || record?.status_id !== 'transporting') && !isZoomed;
    mapApiRef.current.geoObjects.remove(routeRef.current);
    routeRef.current = new mapsApiRef.current.multiRouter.MultiRoute(
      {
        referencePoints: points,
        params: {
          routingMode: 'auto',
          results: 1,
        },
      },
      {
        wayPointVisible: false,
        pinVisible: false,
        wayPointFinishVisible: false,
        wayPointStartVisible: false,
        boundsAutoApply,
        routeActiveStrokeColor: ROUTE_MAP,
        routeActiveStrokeWidth: ROUTE_MAP_WIDTH,
        editorDrawOver: false,
        editorMidPointsType: 'via',
        balloonLayout: balloonLayout,
      },
    );
    mapApiRef.current.geoObjects.add(routeRef.current);
    if (!withPassedRoute) return;
    routeRef.current?.model?.events?.add('requestsuccess', function () {
      //info - https://yandex.ru/dev/maps/jsapi/doc/2.1/dg/concepts/router/multiRouter.html
      var activeRoute = routeRef.current?.getActiveRoute();
      var activeRoutePaths = activeRoute?.getPaths();
      activeRoutePaths?.get(1)?.options?.set({
        opacity: 0.5,
      });
    });
  };

  const shouldShowInterimDestinations = (): boolean => {
    const id = record?.status_id;
    if (!id) return false;
    return id !== 'driving' && id !== 'transporting';
  };

  return (
    <YMaps query={{ apikey: process.env.REACT_APP_YANDEX_MAP_KEY, lang: 'ru_RU' }}>
      <Map
        width={'100%'}
        height={'100%'}
        defaultState={{
          controls: ['fullscreenControl'],
          zoom: 13,
          center:
            current_location && current_location.lat ? [current_location.lat, current_location.lng] : MOSCOW_CENTER,
        }}
        options={{
          behaviors: ['default', 'scrollZoom'],
          yandexMapDisablePoiInteractivity: true,
        }}
        modules={[
          'multiRouter.MultiRoute',
          'Placemark',
          'templateLayoutFactory',
          'control.FullscreenControl',
          'Polyline',
        ]}
        instanceRef={onMapInstanceInit}
        onLoad={onLoad}
      >
        <ZoomControl />
        {record.source ? (
          <Placemark
            geometry={getCoords(record?.source) as number[]}
            options={{
              iconLayout: 'default#image',
              ...(ordersStore?.isVip
                ? {
                    iconImageHref: base64CurrentPosVip,
                    iconImageSize: [20, 20],
                    iconImageOffset: [-20, -20],
                  }
                : {
                    iconImageHref: base64CurrentPos,
                    iconImageSize: [40, 40],
                    iconImageOffset: [-20, -20],
                  }),
            }}
          />
        ) : null}
        {record.destination && record?.status_id !== 'driving' ? (
          <Placemark
            geometry={getCoords(record.destination) as number[]}
            options={{
              iconLayout: 'default#image',
              ...(ordersStore?.isVip
                ? {
                    iconImageHref: base64DestinationPosVip,
                    iconImageSize: [20, 20],
                    iconImageOffset: [-2, -20],
                  }
                : {
                    iconImageHref: base64DestinationPos,
                    iconImageSize: [40, 40],
                    iconImageOffset: [-4, -40],
                  }),
            }}
          />
        ) : null}
        {record.interim_destinations?.length > 0 && shouldShowInterimDestinations()
          ? addWayPointsPlacemarks(record.interim_destinations, 40, ordersStore?.isVip)
          : null}
      </Map>
    </YMaps>
  );
});

export default memo(YandexOrderRoute);

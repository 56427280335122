import ModalBase from 'components/ModalBase';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { saveFile } from 'utils/lib/fileHandlers/saveFile';
import { observer } from 'mobx-react-lite';
import Progress from '../Progress';
import declension from '../../utils/declension';

const baseStyle = {
  flex: 1,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#ccc',
  borderStyle: 'dashed',
  backgroundColor: '#f4f4f7',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
};

const focusedStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

interface ImportDialogProps {
  store: any;
  onClose: any;
  visible: boolean;
  example?: any;
  translation: string;
  onImport?: () => void;
}

const ImportDialog = observer(
  ({ store, onClose, visible, example, translation, onImport }: ImportDialogProps): JSX.Element => {
    const { t } = useTranslation(translation);
    const [errors, setErrors] = useState([]);

    const onDrop = useCallback((acceptedFiles) => {
      const formData = new FormData();
      acceptedFiles.forEach((file: any) => {
        formData.append(file.name, file);
      });
      store.import(formData, onImport);
    }, []);

    const { getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, acceptedFiles } = useDropzone({
      onDrop,
    });

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject],
    );

    useEffect(() => {
      if (store.importErrors) {
        setErrors(store.importErrors);
      }
    }, [store.importErrors]);

    const handleClose = () => {
      store.resetImportError();
      onClose();
    };

    const downloadSample = (): void => {
      saveFile(t('import.import_file_name'), example);
    };

    return (
      <ModalBase title={t('action.import')} onClose={handleClose} visible={visible}>
        {store.isImportInProgress && (
          <Progress total={store.importTotal} value={store.importStep} translation={'promocodes'} />
        )}
        {!store.isImportInProgress && store.importSucceeded !== undefined && (
          <div>
            <b>{t('import.end')}</b>
            {store.importSucceeded > 0 && (
              <p className="success">{`${t('import.success')}: ${declension('import.lines', store.importSucceeded, t)} ${t('import.from')} ${store.importTotal}.`}</p>
            )}
          </div>
        )}
        {!!store.importError?.message ? (
          <div>
            {t('error')}: {store.importError?.message}
          </div>
        ) : null}
        <div>
          {errors.length > 0 && <p>{t('import.errors')}:</p>}
          {errors.length > 0 && (
            <div className="import-error-container">
              {errors.map((d: any) => (
                <div className="flex-row">
                  <span className="flex-col" style={{ flex: '80px 0 0' }}>
                    {t('import.line')}: {d.line}
                  </span>
                  <span className="flex-col" style={{ flex: '120px 0 0' }}>
                    {d.name}
                  </span>
                  <span className="flex-col">{t(d.desc, { ns: 'errors' }) || d.desc}</span>
                </div>
              ))}
            </div>
          )}
        </div>
        {!store.importError && !store.isImportInProgress ? (
          <div {...getRootProps({ style })} className="import-dropzone mt-3">
            <input {...getInputProps()} />
            <p>{t('import.dropzone_text')}</p>
          </div>
        ) : null}
        {!store.isImportInProgress && (
          <div className="import-dropzone-example" onClick={downloadSample}>
            {t('import.import_example')}
          </div>
        )}
        <div className="flex justify-end space-x-5 mt-5">
          <button onClick={handleClose} className="btn btn-light">
            {t('import.close')}
          </button>
        </div>
      </ModalBase>
    );
  },
);

export default ImportDialog;

import { useEffect } from 'react';

export const useClickedOutside = (ref: any, callback: any) => {
  useEffect(() => {
    const checkIfClickedOutside = (e: any) => {
      if (!ref?.contains(e.target)) {
        callback(e);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  });
};

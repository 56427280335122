export default {
  analytics: 'Аналитика',
  employees: 'Сотрудники',
  coordinators: 'Координаторы',
  favourites: 'Избранное',
  rides: 'Поездки',
  rides_policy: 'Политика поездок',
  reports: 'Отчеты',
  promocodes: 'Промокоды',
  new_order: 'Новый заказ',
  exit: 'Выход',
  info: {
    manager: 'Менеджер',
    phone: 'Телефон Контакт Центра',
    feedback: 'Ваше сообщение в службу поддержку',
    send_feedback: 'Отправить',
    sent_feedback_text:
      'Спасибо за обращение. Менеджер свяжется с вами в будний день с 10:00 до 18:00. По срочным вопросам обратитесь, пожалуйста, в службу поддержку по телефону.',
    sent_feedback_error: 'Извините, нам не удалось отправить ваше уведомление. Пожалуйста, попробуйте еще раз.',
    manager_email: 'Электронная почта менеджера',
  },
  settings: {
    title: 'Настройки',
    limit: {
      title: 'Лимит',
      of: 'из',
      remainder: {
        group: 'Остаток корпоративного лимита c НДС',
        personal: 'Остаток персонального лимита c НДС',
      },
    },
    lang: 'Язык интерфейса',
    password: {
      current: 'Текущий пароль',
      current_placeholder: 'Введите текущий пароль',
      new: 'Новый пароль',
      new_placeholder: 'Введите новый пароль',
      new_again: 'Повторите новый пароль',
      new_again_placeholder: 'Введите повторно новый пароль',
    },
    actions: {
      cancel: 'Отменить',
      save: 'Сохранить',
    },
  },
};

import React, { useEffect } from 'react';
import { useStores } from 'stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import ModalBase from 'components/ModalBase';
import { formatPrice } from '../../utils/lib/formatPrice';

interface DialogProps {
  onClose: any;
  visible: boolean;
  onCancel: any;
  toActivateDialog: any;
  ordersStore?: any;
}

interface Validation {
  code?: string;
}

const Dialog = observer(
  ({ visible, onClose, onCancel, toActivateDialog, ordersStore }: DialogProps): JSX.Element | null => {
    const { promocodesStore } = useStores();

    const { t } = useTranslation(['promocodes']);

    useEffect(() => {
      if (visible) {
        promocodesStore.fetchList(false, {}, 'id desc', 0, 20, false);
      }
      document.body.style.overflow = visible ? 'hidden' : 'unset';
    }, [visible]);

    return (
      <ModalBase visible={visible} title={t('dialog.payment_method')} onClose={onClose}>
        <div className="promocodes-container">
          {promocodesStore?.list
            ?.filter((promo) => promo?.is_activated && !promo?.is_used)
            .map((promocode: any) => (
              <div
                key={`promocode-${promocode.id}`}
                style={{ height: 'unset' }}
                className="form-control form-control--lg items-center justify-between  flex mb-5"
                onClick={() => {
                  promocodesStore.setPromocode(promocode);
                  ordersStore?.estimateOrder();
                  setTimeout(() => {
                    onClose();
                  }, 300);
                }}
              >
                <input
                  type="checkbox"
                  id="chosen_code"
                  checked={promocode.id === promocodesStore?.chosenPromocode?.id}
                />

                <div className="flex-grow flex flex-col ml-5">
                  <div className="flex-grow flex flex-col">
                    <div className="flex-grow flex flex-row">
                      <span style={{ flex: '1' }}>{promocode.code}</span>
                      <span>{formatPrice(+promocode.total_cost, '0,0.[00]', 2)}</span>
                    </div>
                  </div>
                  <div className="flex-grow flex flex-col">
                    <span>
                      {(promocode.classes || []).map((cl: any) => (
                        <span className="tariff-class">{t(cl?.class?.name, { ns: 'tariff' })}</span>
                      ))}
                    </span>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex-col">
                    {/*<span>{(promocode?.classes || []).map(cl => <span className="selected-option">{cl}</span>)}</span>*/}
                  </div>
                </div>
              </div>
            ))}
        </div>
        <div
          className="form-control form-control--lg items-center justify-between  flex mb-5"
          onClick={() => {
            toActivateDialog(true);
            onClose();
          }}
        >
          <span className="hidden md:block md:ml-1.5">{t('action.activate')}</span>
        </div>
      </ModalBase>
    );
  },
);

export default Dialog;

import BaseCRUD from '../BaseCRUD';
import WebApiClient from '../WebApi';

class RolesCostCentersAPI extends BaseCRUD {
  async getFields(data) {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/fields`,
      data,
    });
  }
}

export default RolesCostCentersAPI;

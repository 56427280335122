import BaseCRUD from '../BaseCRUD';
import WebApiClient from '../WebApi';

class AnalyticsAPI extends BaseCRUD {
  async getCustomerStats(customerId: number, dateStart: number, dateEnd: number) {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/customer/?customer_id=${customerId}&date_start=${dateStart}&date_end=${dateEnd}`,
    });
  }
}

export default AnalyticsAPI;

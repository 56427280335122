import { TariffCard } from 'components/TariffCard';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useStores } from 'stores';
import { TariffClass } from 'types/types';

interface Props {
  onPress?: (cls: any) => any;
}

export const Tariff = observer(
  ({
    onPress,
    className,
    ...rest
  }: Props & React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>): JSX.Element | null => {
    const { ordersStore } = useStores();
    const { t } = useTranslation('new_order');
    // Refs for class cards
    const cardsRefs = useRef<{
      [key in Required<TariffClass>['id']]: React.RefObject<HTMLDivElement>;
    }>({});

    // Ref for current selected car class id
    const currentTariffRef = useRef<Required<TariffClass>['id']>();

    /**
     * Save the current class_id as ref to access it inside the useEffect hook
     * to scroll to it
     */
    useEffect(() => {
      currentTariffRef.current = ordersStore?.newOrderInfo?.class_id;
    }, [ordersStore?.newOrderInfo?.class_id]);

    useEffect(() => {
      // Reset refs
      if (!ordersStore.estimateInfo?.classes) {
        cardsRefs.current = {};
        return;
      }
      // Create ref for each class and save it by class id
      ordersStore.estimateInfo?.classes.forEach((cls) => {
        if (!cls?.id) return;
        cardsRefs.current[cls.id] = React.createRef<HTMLDivElement>();
      });
      // If class id is set and there is the ref for this class id
      // then scroll class card in to view
      if (currentTariffRef.current && cardsRefs.current[currentTariffRef.current]) {
        setTimeout(() => {
          // Check condition again
          if (currentTariffRef.current && cardsRefs.current[currentTariffRef.current]) {
            cardsRefs.current[currentTariffRef.current as number].current?.scrollIntoView({
              behavior: 'smooth',
              block: 'nearest',
              inline: 'center',
            });
          }
        }, 1000);
      }
    }, [ordersStore.estimateInfo?.classes]);

    const selectTariff = (cls: TariffClass): void => {
      ordersStore.updateNewOrderInfo({ class_id: cls.id });
      if (onPress) onPress(cls);
    };

    return (
      <>
        {(ordersStore.estimateInfo?.classes?.length || 0) > 0 && ordersStore?.newOrderInfo?.source && (
          <div className={`lg:w-full md:mx-auto md:w-[30.25rem] ${className || ''}`} {...rest}>
            <label className="form-label tariff-label">{t('car_class')}</label>
            <div className="tariff-wrapper" style={{ scrollbarWidth: 'auto' }}>
              {ordersStore.estimateInfo?.classes
                ?.slice()
                .sort((a: any, b: any) => a.position - b.position)
                .map((cls: any, index: any) => (
                  <div
                    {...(cardsRefs.current && cardsRefs.current[cls.id] ? { ref: cardsRefs.current[cls.id] } : {})}
                    key={cls.id}
                    style={{ marginLeft: index ? 8 : 0, width: 'fit-content' }}
                  >
                    <TariffCard
                      onClick={selectTariff}
                      active={cls.id === ordersStore.newOrderInfo?.class_id}
                      tariff={cls}
                    />
                  </div>
                ))}
            </div>
          </div>
        )}
      </>
    );
  },
);

import BaseCRUD from '../BaseCRUD';
import WebApiClient from '../WebApi';

class PromocodesAPI extends BaseCRUD {
  async activate(data: any) {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/activate`,
      data,
    });
  }
}

export default PromocodesAPI;

import BaseCRUD from '../BaseCRUD';
import WebApiClient from '../WebApi';
import { serialize } from '../../utils/lib/serialize';

class OrdersAPI extends BaseCRUD {
  async list(
    filter: any,
    orderBy: string = 'id desc',
    page: number = 0,
    limit: number = 10,
    detail: boolean = false,
    query: object = {},
  ) {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/list${Object.keys(query).length ? `?${serialize(query)}` : ``}`,
      data: {
        filter,
        orderBy,
        page,
        limit,
        detail: detail ? 1 : 0,
        ...(this.urlVersion === 2
          ? {
              orderField: filter?.orderField || (filter?.orderBy?.split(' ') || [])[0] || 'id',
              orderType: filter?.orderType || (filter?.orderBy?.split(' ') || [])[1] || 'desc',
            }
          : {
              orderBy: filter?.orderBy || orderBy || `${filter?.orderField || 'id'} ${filter?.orderType || 'desc'}`,
            }),
      },
      urlVersion: this.urlVersion,
    });
  }

  async estimate(newOrder: any, promo_id: string = '') {
    console.log('ESTIMATE', newOrder);

    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/estimate`,
      data: {
        ...newOrder,
        ...(promo_id
          ? {
              promo_id,
            }
          : {}),
      },
    });
  }

  async getOrderTrack(id: string | number): Promise<any> {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/${id}/track`,
    });
  }

  async getReceipt(id: string | number): Promise<any> {
    return await WebApiClient.fetch(
      {
        method: 'get',
        url: `/${this.schema}/${id}/receipt`,
        responseType: 'blob',
        headers: {
          'Content-Type': 'application/pdf',
          'Content-Disposition': 'attachment; filename=receipt.pdf',
        },
        returnBody: true,
      },
      true,
    );
  }

  async getRideLink(id: string | number): Promise<any> {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/${id}/share`,
    });
  }

  async publishOrder(id: string | number, data: any): Promise<any> {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/${id}/publish`,
      data,
    });
  }
  async repost(id: string | number): Promise<any> {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/${id}/repost`,
    });
  }
  async getDriverPhone(id: string | number): Promise<any> {
    return await WebApiClient.fetch({
      method: 'GET',
      url: `/${this.schema}/${id}/driver_phone`,
    });
  }
}

export default OrdersAPI;

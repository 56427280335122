import { makeObservable, observable, action } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';

export default class OrdersClassesStore extends BaseStore {
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['OrdersClasses']);
    makeObservable(this);
  }
}

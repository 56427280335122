import React, { useMemo } from 'react';
import { getRandomId } from 'utils/lib/getRandomId';
import styles from './Checkbox.module.css';

interface Props extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  containerClass?: string;
  containerProps?: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
  label?: string | React.ReactNode;
  labelClass?: string;
  labelProps?: React.DetailedHTMLProps<React.LabelHTMLAttributes<HTMLLabelElement>, HTMLLabelElement>;
}

export const Checkbox = ({
  labelProps = {},
  containerClass = '',
  label = '',
  labelClass = '',
  containerProps = {},
  disabled = false,
  ...props
}: Props): JSX.Element => {
  const id = useMemo(() => getRandomId(), [getRandomId]);

  return (
    <div {...containerProps} className={`${containerClass} ${disabled ? styles.disabled : ''}`}>
      <input
        className={`cursor-pointer ${disabled ? styles.disabled : ''}`}
        type="checkbox"
        id={id}
        {...props}
        disabled={disabled}
      />
      <label className={`cursor-pointer ${styles.label} ${labelClass}`} htmlFor={id} {...labelProps}>
        {label}
      </label>
    </div>
  );
};

import { get } from 'lodash';
import React, { FunctionComponent } from 'react';
import styles from './Table.module.css';

interface CustomRenderer {
  [key: string]: (record: any, i?: number) => JSX.Element | string | number;
}
interface KeySelectors {
  [key: string]: string;
}

interface TableProps {
  data: any[];
  containerClass?: string;
  Header?: FunctionComponent;
  TableFooter?: FunctionComponent;
  translateFunc: any;
  stickyHeader?: boolean;
  colClasses?: string | string[];
  columnsKeys: string[];
  tableClass?: string;
  customThInnerRenderer?: CustomRenderer;
  customTdInnerRenderer?: CustomRenderer;
  keySelectors?: KeySelectors;
  onRowClick?: (info: string) => void;
  children?: React.ReactNode | React.ReactNode[];
  getIsDisabledRow?: (any) => boolean;
}

export const Table = ({
  data = [],
  containerClass = '',
  translateFunc: t,
  Header,
  TableFooter,
  stickyHeader = false,
  colClasses,
  columnsKeys = [],
  tableClass = '',
  customThInnerRenderer,
  customTdInnerRenderer,
  keySelectors,
  onRowClick,
  children,
  getIsDisabledRow,
}: TableProps) => {
  const renderColGroup = (): JSX.Element | null =>
    colClasses && columnsKeys ? (
      <colgroup>
        {columnsKeys.map((key: string, i: number) => (
          <col key={key + i} className={Array.isArray(colClasses) ? colClasses[i] || '' : colClasses} />
        ))}
      </colgroup>
    ) : null;

  return (
    <div className={styles.wrapper}>
      <div className={`${styles.container} ${containerClass}`}>
        <table className={`${tableClass}`}>
          {renderColGroup()}
          {Header ? (
            <Header />
          ) : (
            <thead className="">
              <tr className={styles.tr}>
                {columnsKeys.map((key: string, i: number) => (
                  <th
                    className={`${stickyHeader ? styles.sticky : ''} ${styles.th} ${styles.textSecondary} ${styles.text100}`}
                    key={key + i}
                  >
                    {(customThInnerRenderer && customThInnerRenderer[key] && customThInnerRenderer[key](key, i)) ||
                      t(key)}
                  </th>
                ))}
              </tr>
            </thead>
          )}
          <tbody>
            {data.map((item: any, i: number) => (
              <tr
                className={`${styles.tr} relative`}
                key={item?.id || (item && item[columnsKeys[i]] + i)}
                onClick={onRowClick ? () => onRowClick(item) : undefined}
              >
                {columnsKeys.map((key: string, i: number) => (
                  <td className={`${styles.td} ${styles.textRegular}`} key={key + i}>
                    {(customTdInnerRenderer && customTdInnerRenderer[key] && customTdInnerRenderer[key](item, i)) ||
                      (keySelectors && keySelectors[key] ? get(item, keySelectors[key]) : item[key]?.toString())}
                  </td>
                ))}
                {getIsDisabledRow && getIsDisabledRow(item) ? <div className="disabling-overlay"></div> : null}
              </tr>
            ))}
          </tbody>
          <tfoot>{TableFooter && <TableFooter />}</tfoot>
        </table>
      </div>
      {children}
    </div>
  );
};

export default Table;

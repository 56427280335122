import BaseCRUD from '../BaseCRUD';
import Storage from '../../utils/storage';
import WebApiClient from '../WebApi';
import { ProfileRole } from 'types/types';

class Index extends BaseCRUD {
  schema: string = 'users';

  async getMe(): Promise<any> {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/me?push_token=${await Storage.getItem('push_token')}`,
    });
  }

  async putMe(data: any): Promise<any> {
    return await WebApiClient.fetch({
      method: 'put',
      url: `/${this.schema}/me`,
      data,
    });
  }

  async getSettings(): Promise<any> {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/me/settings`,
    });
  }

  async saveSettings(data: any): Promise<any> {
    return await WebApiClient.fetch({
      method: 'put',
      url: `/${this.schema}/me/settings`,
      data,
    });
  }

  async savePassword(data: any): Promise<void> {
    return await WebApiClient.fetch({
      method: 'put',
      url: `/${this.schema}/me/password`,
      data,
    });
  }
  async sendFeedback(data: any): Promise<void> {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/employees/help`,
      data,
    });
  }

  async getRoles(): Promise<ProfileRole[]> {
    return await WebApiClient.fetch({
      method: 'get',
      url: `/${this.schema}/me/roles`,
    });
  }

  async getRoleToken(data): Promise<{ access_token: string }> {
    return await WebApiClient.fetch({
      method: 'post',
      url: `/${this.schema}/me/roles`,
      data,
    });
  }

  /*
    async putMe(params: {useDirectLinks?: boolean}): Promise<object> {
        const { data } = await WebApiClient.put('/userss/me', params);
        return data;
    }

    async getCoPartner() {
        const response = await WebApiClient.get('/copartner/me');
        return response.data;
    }

    async requestResetPassword(params: {
        email: string,
        backUrl: string,
        captchaType?: string,
        captchaValue?: string,
    }) {
        const { email, backUrl, captchaType, captchaValue } = params;
        const response = await WebApiClient.post('/users/me/reset-password', {
            email,
            backUrl,
            captchaType,
            captchaValue,
        });
        return response.data;
    }

    async requestChangeEmail(params: {
        email: string,
        backUrl: string,
        password: string,
    }) {
        const { email, backUrl, password } = params;
        const response = await WebApiClient.post('/users/me/change-email', {
            email,
            backUrl,
            password,
        });
        return response.data;
    }

    async changeEmail(params: { code: string }) {
        const { code } = params;
        const response = await WebApiClient.put('/users/me/change-email', {
            code,
        });
        return response.data;
    }

    async resetPassword(params: {
        code: string,
        password: string,
        captchaType: string,
        captchaValue: string,
    }) {
        const { code, password, captchaType, captchaValue } = params;
        const response = await WebApiClient.put('/users/me/reset-password', {
            code,
            password,
            captchaType,
            captchaValue,
        });
        return response.data;
    }

    async verifyEmail(code: string) {
        const response = await WebApiClient.put('/users/me/verify-email', {
            code,
        });

        return response.data;
    }

    async getBalance() {
        const { data } = await WebApiClient.get('/users/me/balance');
        return data;
    }

    async getUnreadSupportMessagesCount(): Promise<UnreadMessageResponse> {
        const { data } = await WebApiClient.get('/support/me/unread');
        return data;
    }

    async getAccessToPromoCodes(): Promise<AccessToPromoCodesResponse> {
        const { data } = await WebApiClient.get('/partner/me/promo-codes/access');
        return data;
    }

    async getManager(): Promise<UserManagerInterface> {
        const { data } = await WebApiClient.get('/users/me/manager');
        return data;
    }

    async getClientIp(): Promise<UserClientIpInterface> {
        const { data } = await WebApiClient.get('/users/me/ip');
        return data;
    }

    async addIpsToAllowAccess({ ip, netmask }: { ip: string; netmask: number }): Promise<void> {
        const { data } = await WebApiClient.post('/users/me/security/allow-ip', {
            ip,
            netmask,
        });

        return data;
    }

    async getIpsToAllowAccess({ limit, offset }: { limit: number; offset: number }):
        Promise<IpsToAllowAccessResponse> {
        const { data } = await WebApiClient.get('/users/me/security/allow-ip', {
            params: {
                offset,
                limit,
            },
        });

        return data;
    }

    async deleteIpsToAllowAccess(id: string): Promise<IpsToAllowAccessResponse> {
        const { data } = await WebApiClient.delete(`/users/me/security/allow-ip/${id}`);
        return data;
    }

    async addIpsToDisableCaptcha({ ip }: { ip: string}): Promise<any> {
        const { data } = await WebApiClient.post('/users/me/security/disable-captcha', { ip });
        return data;
    }

    async getIpsToDisableCaptcha({ limit, offset }: { limit: number; offset: number }):
        Promise<IpsToDisableCaptchaResponse> {
        const { data } = await WebApiClient.get('/users/me/security/disable-captcha', {
            params: {
                offset,
                limit,
            },
        });

        return data;
    }

    async deleteIpsToDisableCaptcha(id: string): Promise<any> {
        const { data } = await WebApiClient.delete(`/users/me/security/disable-captcha/${id}`);
        return data;
    }

    async getLoginHistory({ limit, offset }: { limit: number; offset: number }):
        Promise<LoginHistoryResponse> {
        const { data } = await WebApiClient.get('/users/me/login-history', {
            params: {
                offset,
                limit,
            },
        });

        return data;
    }
    */
}

export default Index;

export default {
  title: 'Промокоды',
  loading: 'Загрузка данных',
  error: 'Ошибка',
  action: {
    add: 'Добавить промокод',
    edit: 'Редактирование',
    import: 'Импорт промокодов',
    export: 'Экспорт',
    exporting: 'Экспортирую',
    resend_sms: 'Отправить СМС повторно',
    export_orders: 'Экспорт поездок',
    activate: 'Активировать промо-код',
    disable: 'Отключить',
    enable: 'Включить',
  },
  import: {
    imported_number: 'Импортировано записей',
    failed_number: 'Пропущено записей',
    import_process: 'Импорт',
    from: 'из',
    close: 'Закрыть',
    dropzone_text: 'Нажмите или перетащите файл в эту область',
    import_example: 'Пример файла для импорта',
    import_file_name: 'импорт',
    errors: 'Ошибки при импорте',
    line: 'Строка',
    end: 'Импорт завершён.',
    success: 'Успешно импортировано',
    lines: 'строк',
    lines_s1: 'строка',
    lines_s2: 'строки',
  },
  thead: {
    code: 'Код',
    activated_time: 'Время активации',
    total_cost: 'Полная сумма',
    prefix: 'Префикс',
    max_order_cost: 'Максимальная цена за поездку',
    orders_count: 'Количество доступных поездок',
    max_employee_count: 'Максимальное количество пользователей',
    discount_percent: 'Процент скидки',
    used_time: 'Время последнего использования',
    used_orders_count: 'Количество использований',
    activated_by_employee_id: 'Кто активировал',
    count_activated: 'Количество активаций',
    role: 'Политика поездок',
    active_from: 'Доступен для использования с',
    active_to: 'Доступен для использования до',
    classes: 'Классы',
  },
  trow: {
    activated: 'Активирован',
    deactivated: 'Отключен',
    used: 'Использован',
    employee: 'Сотрудник',
    paid_waiting: 'Платное ожидание',
    ride: 'Поездка',
    full_time: 'Общее время',
    minutes_short: 'м',
    arrival_time: 'Время подачи',
    guest: '(пассажир / гость)',
  },
  dialog: {
    code: 'Код',
    activated_time: 'Время активации',
    total_cost: 'Полная сумма',
    prefix: 'Префикс',
    max_order_cost: 'Максимальная цена за поездку',
    orders_count: 'Количество доступных поездок',
    max_employee_count: 'Максимальное количество пользователей',
    discount_percent: 'Процент скидки',
    description: 'Описание',
    used_time: 'Время последнего использования',
    used_orders_count: 'Количество использований',
    activated_by_employee_id: 'Кто активировал',
    count_activated: 'Количество активаций',
    active_from: 'Доступен для использования с',
    active_to: 'Доступен для использования до',
    type: 'Тип промокода',
    discount: 'На скидку',
    amount: 'На сумму',
    add: 'Добавить',
    save: 'Сохранить',
    cancel: 'Отменить',
    classes: 'Классы',
    classes_all: 'Выбрать все',
    add_class: 'Добавить класс',
    promocode: 'Промо-код',
    activate: 'Активировть',
    payment_method: 'Способ оплаты',
    rides_policy: 'Политика поездок',
  },
  details: {
    id: 'ID',
    manager: 'Менеджер',
    status: 'Статус',
    start_transporting_time: 'Время начала',
    finished_time: 'Время окончания',
    cancelled_time: 'Время отмены',
    order_time: 'Время создания заказа',
    time: 'Время',
    source: 'Отправление',
    interim_destination: 'Остановка',
    destination: 'Назначение',
    employee: 'Сотрудник',
    phone: 'Телефон',
    class: 'Класс',
    car: 'Автомобиль',
    cost_customer: 'Стоимость без НДС, руб.',
    cost_customer_with_vat: 'Стоимость с НДС, руб.',
    customer_name: 'Наименование клиента',
    provider: 'Поставщик',
    provider_order_id: 'Номер заказа у поставщика',
    comment: 'Ваши пожелания для водителя',
    cancelled_by: 'Кем отменен заказ',
    orderer: 'Кто оформил заказ',
    additional_fields: 'Дополнительные поля',
  },
  search_placeholder: 'Поиск',
  filter: {
    title: 'Фильтры',
    status: 'Статус',
    code: 'Код',
    activated_by_employee_id: 'Кто активировал',
    actions: {
      apply: 'Применить',
    },
  },
};

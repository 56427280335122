export default {
  title: 'Coordinators',
  action: {
    add: 'Add coordinator',
  },
  search_placeholder: 'Search',
  loading: 'Loading data',
  error: 'Error',
  thead: {
    full_name: 'Full name',
    email: 'E-mail',
  },
  dialog: {
    full_name: 'Full name',
    full_name_placeholder: 'Enter coordinator name',
    email_placeholder: 'Enter E-mail',
    email: 'E-mail',
    add: 'Add',
    save: 'Save',
    cancel: 'Cancel',
  },
  class: {
    all: 'All classes',
  },
  group: {
    search_placeholder: 'Find groups',
    all: 'All groups',
    default: 'Default',
    add_user: 'Add employees',
  },
  employees: {
    search_placeholder: 'Find employees',
    all: 'All employees',
  },
};

import employees from '../../modules/Employees/locales/en-EN';
import analytics from '../../modules/Analytics/locales/en-EN';
import nav from '../../components/Layout/locales/en-EN';
import pagination from '../../components/Pagination/locales/en-EN';
import coordinators from '../../modules/Coordinators/locales/en-EN';
import rides from '../../modules/Rides/locales/en-EN';
import reports from '../../modules/Reports/locales/en-EN';
import promocodes from '../../modules/Promocodes/locales/en-EN';
import policy from '../../modules/Policy/locales/en-EN';
import new_order from '../../modules/NewOrder/locales/en-EN';
import favourites from '../../modules/Favourites/locales/en-EN';
import profile from '../../components/Profile/locales/en-EN';
import departments from '../../modules/Departments/locales/en-EN';

export default {
  translation: {
    // title: "TEST SPA app",
    // noData: "No data",
    // loading: "Loading...",
    // menu: {
    //   quotes: "Currency rates",
    //   calculator: "Converter",
    //   history: "History"
    // },
    // login: {
    //   header: 'Login to your personal account',
    //   login: 'Login',
    //   password: 'Password',
    //   enter: 'Login',
    //   logout: 'Logout',
    //   emptyForm: "Fill in email and password",
    //   wrongEmail: "Wrong email",
    //   wrongPassword: "Wrong password (at least 7 characters, only Latin letters and numbers, no special characters except _)",
    // },
    // quotes: {
    //   asset: "Currency pair",
    //   quote: "Quotation",
    //   startDate: "Date received",
    // },
    // calculator: {
    //   title: "Currency Conversion",
    //   amount: "Amount",
    //   calc: "Calculate",
    //   total: "Total",
    //   noData: "Failed to load quotes",
    // },
    // history: {
    //   asset: "Asset",
    //   startDate: "Start",
    //   startQuote: "Quote",
    //   finishDate: "End",
    //   finishQuote: "Quote",
    //   profit: "Profit",
    // }
  },

  notification: {
    title: {
      error: 'Error',
      success: 'Success',
      warning: 'Warning',
    },
  },

  errors: {
    error: 'Error',
    'Unknown error': 'Unknown error',
    'Bad request': 'Bad request',
    Unauthorized: 'Unauthorized',
    'Not found': 'Not found',
    Forbidden: 'Forbidden',
    'Connection refused': 'Connection refused',
    'Server error': 'Server error',
    failedGroupDeletion: 'Failed to delete group',
    failedChangeGroupAccess: 'Failed to change group access',
    failedCCDeletion: 'Failed to delete cost center',
    failedZoneDeletion: 'Failed to delete zone',
    failedCarDeletion: 'Failed to delete car class',
    failedChangeUserAccess: 'Failed to change employee access',
    failedCarAdding: 'Failed to add car class',
    failedUserDeletion: 'Failed to delete user',
    noDefaultGroup: 'Default group not found',
    failedUserAdding: 'Failed to add user',
    failedCoordinatorDeletion: 'Failed to delete coordinator',
    failedRuleDeletion: 'Failed to delete rule',
    failedRuleAdding: 'Failed to add rule',
    failedRuleEditing: 'Failed to edit rule',
    failedDayDeletion: 'Failed to delete day',
    failedDayAdding: 'Failed to add day',
    failedDayEditing: 'Failed to edit day',
    failedAddressDeletion: 'Failed to delete address',
    failedExport: 'Failed to load report',
    failedUserResendSMS: 'Failed to resend message to user',
    'Update forbidden': 'The record could not be refreshed',
    failedToSendFeedback: 'Failed to send massage',
  },

  tariff: {
    econom: 'Economy',
    comfort: 'Comfort',
    comfortplus: 'Comfort +',
    exclusive: 'Business',
    delivery: 'Delivery',
    minivan: 'Minivan',
    cargo: 'Cargo',
    premium: 'Premium',
    child: 'Kids',
    lux: 'Lux',
    business_minivan: 'Business minivan',
  },

  actions: {
    edit: 'Edit',
    delete: 'Delete',
    cancel: 'Cancel',
    yes: 'Ok',
    save: 'Save',
    confirm: 'Confirm',
    add: 'Add',
    export: 'Export',
    exporting: 'Exporting',
    saving: 'Saving',
    copy: 'Copy',
    reset: 'Reset',
  },

  confirmation: {
    deletion_confirmation: 'Are you sure you want to delete the highlighted entry?',
    deletion_title: 'Confirm deletion',
  },

  order: {
    status: {
      error: 'Error',
      driving: 'Driving',
      waiting: 'Wating',
      transporting: 'In progress',
      complete: 'Completed',
      feedback: 'Completed',
      cancelled: 'Cancelled',
      search: 'Search',
      new: 'Search',
      failed: 'Failed',
      expired: 'Epired',
      scheduling: 'Scheduling',
      scheduled: 'Scheduled',
    },
  },

  // ==== Validations ====
  validation: {
    error: {
      phone: 'Phone number must contain 11 digits and have a valid value',
      email: 'Invalid email address',
      required: 'Required field',
      password: 'The password has to be minimum 6 characters long',
      "Passwords doesn't match": "Passwords doesn't match",
      'Wrong current password': 'Wrong current password',
      invalidSettings: 'Invalid setting value',
      wrong_format_xlsx: 'File must have .xlsx extension',
      wrong_limits: 'Enter correct values for the "from" and "to" fields',
    },
    not_required: 'optional field',
  },

  pagination,
  nav,
  profile,

  analytics,
  employees,
  coordinators,
  rides,
  reports,
  promocodes,
  policy,
  new_order,
  favourites,
  departments,
};

import React from 'react';
import { observable, makeObservable, runInAction } from 'mobx';
import UsersStore from './UsersStore';
import EmployeesStore from './EmployeesStore';
import RolesStore from './RolesStore';
import RolesCostCentersStore from './RolesCostCentersStore';
import CoordinatorsStore from './CoordinatorsStore';
import AnalyticsStore from './AnalyticsStore';
import AuthStore from './AuthStore';
import WelcomeStore from './WelcomeStore';
import LogsStore from './LogsStore';
import GPSLocationStore from './GPSLocationStore';
//import PushStore from './PushStore';
import GeocodeStore from './GeocodeStore';
import AirportsStore from './AirportsStore';
import WebSocketStore from './WebSocketsStore';
import TranslationsStore from './TranslationsStore';
import OrdersStore from './OrdersStore';
import OrdersClassesStore from './OrdersClassesStore';
import FavouritesStore from './FavouritesStore';
import ProvidersStore from './ProvidersStore';
import ManagersStore from './ManagersStore';
import MenuStore from './MenuStore';
import WebApiClient from './WebApi';
import { RootStoreInterface } from '../interfaces/index';
import { ModulesStores } from 'types/types';
import PromocodesStore from './PromocodesStore';
import PromocodesOrdersStore from './PromocodesOrdersStore';
import DepartmetsStore from './DepartmentsStore';

export class RootStore implements RootStoreInterface {
  @observable
  modulesStore: ModulesStores = {} as ModulesStores;

  @observable
  specifications?: object;

  @observable
  isStoresInitialized = false;

  constructor() {
    makeObservable(this);
    this.initModulesStores();
  }

  async initModulesStores() {
    runInAction(() => {
      this.modulesStore = {} as ModulesStores;

      const translations = new TranslationsStore(this);
      this.modulesStore.translationsStore = (a: string, b: string): string => {
        return translations.get(a, b);
      };

      this.modulesStore.gpsLocationStore = new GPSLocationStore(this);
      //this.modulesStore.pushStore = new PushStore(this);
      this.modulesStore.logsStore = new LogsStore(this);
      this.modulesStore.authStore = new AuthStore(this);
      this.modulesStore.welcomeStore = new WelcomeStore(this);
      this.modulesStore.menuStore = new MenuStore(this);

      this.modulesStore.isCoreStoresInitialized = true;
    });
  }

  async userAuthorized(): Promise<void> {
    console.log('userAuthorized!');
    runInAction(async () => {
      this.specifications = await WebApiClient.fetchSpecifications();
      runInAction(async () => {
        this.modulesStore.usersStore = new UsersStore(this);
        await this.modulesStore.usersStore.fetchUserInfo();
        runInAction(async () => {
          this.modulesStore.webSocketStore = new WebSocketStore(this);
          this.modulesStore.ordersStore = new OrdersStore(this);
          this.modulesStore.ordersClassesStore = new OrdersClassesStore(this);
          this.modulesStore.analyticsStore = new AnalyticsStore(this);
          this.modulesStore.employeesStore = new EmployeesStore(this);
          this.modulesStore.rolesStore = new RolesStore(this);
          this.modulesStore.rolesCostCentersStore = new RolesCostCentersStore(this);
          this.modulesStore.coordinatorsStore = new CoordinatorsStore(this);
          this.modulesStore.geocodeStore = new GeocodeStore(this);
          this.modulesStore.airportsStore = new AirportsStore(this);
          this.modulesStore.favouritesStore = new FavouritesStore(this);
          this.modulesStore.managersStore = new ManagersStore(this);
          this.modulesStore.providersStore = new ProvidersStore(this);
          this.modulesStore.promocodesStore = new PromocodesStore(this);
          this.modulesStore.promocodesOrdersStore = new PromocodesOrdersStore(this);
          this.modulesStore.departmentsStore = new DepartmetsStore(this);
          await this.modulesStore.departmentsStore.fetchWholeList();
          this.modulesStore.isStoresInitialized = true;
        });
      });
    });
  }

  resetStores(): void {
    this.initModulesStores();
    //this.userAuthorized()
    //this.init();
  }
}

export const createStores = () => {
  const rootStore = new RootStore();
  return rootStore.modulesStore;
};

export const storesContext = React.createContext(createStores());

export const useStores = (): ModulesStores => React.useContext(storesContext);

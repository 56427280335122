import { useTranslation as useTranslationBase } from 'react-i18next';

export const useNestedTranslation = (ns: string | string[], props = {}) => {
  const nspaces = Array.isArray(ns) ? ns.map((n) => n.split('.')[0]) : ns.split('.')[0];
  const trans = useTranslationBase(nspaces, props);
  const getPath = (keys: string, nspace: string = ''): string => {
    const fields: string | string[] = Array.isArray(ns)
      ? ns.map((n) => n.split('.').slice(1).join('.'))
      : ns.split('.').slice(1).join('.');
    const path = Array.isArray(fields)
      ? `${fields[nspaces.indexOf(nspace) === -1 ? 0 : nspaces.indexOf(nspace)]}.${keys}`
      : `${fields}.${keys}`;
    return path.startsWith('.') ? path.slice(1) : path;
  };

  return {
    ...trans,
    t: (keys: string, options?: any) => trans.t(getPath(keys, options?.ns), options),
  };
};

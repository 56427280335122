import { COMPLETE, DRIVING, TRANSPORTING, WAITING } from 'constants/orderStatuses';
import { StatusID } from 'types/types';

export const isRideCallable = (statusId: StatusID, finished_time: number = 0): boolean => {
  if (!statusId) {
    return false;
  }
  switch (statusId) {
    case DRIVING:
    case WAITING:
    case TRANSPORTING:
      return true;
    case COMPLETE:
      return new Date().getTime() - finished_time * 1000 < 5 * 60 * 1000;
    default:
      return false;
  }
};

import React, { ComponentProps, HTMLAttributes } from 'react';
import { InputField } from './InputField';

interface FieldProps
  extends Partial<ComponentProps<typeof InputField>>,
    Pick<HTMLAttributes<HTMLDivElement>, 'className'> {
  title?: string;
  isRequired?: boolean;
  children?: React.ReactNode;
}

export const Field = ({ isRequired, title, children, className = '', ...inputProps }: FieldProps): JSX.Element => {
  return (
    <div className={className}>
      {title ? <label className={`form-label ${isRequired ? 'form-label-required' : ''}`}>{title}</label> : null}
      {children ? (
        <>{children}</>
      ) : (
        <InputField onReset={() => console.log('Please, attach the method')} {...inputProps} />
      )}
    </div>
  );
};

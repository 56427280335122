import { DRIVING, TRANSPORTING, WAITING, SEARCH, NEW, SCHEDULED } from 'constants/orderStatuses';
import { StatusID } from 'types/types';

export const isRideActive = (statusId: StatusID): boolean => {
  if (!statusId) {
    return false;
  }
  switch (statusId) {
    case DRIVING:
    case WAITING:
    case TRANSPORTING:
    case SEARCH:
    case NEW:
    case SCHEDULED:
      return true;
    default:
      return false;
  }
};

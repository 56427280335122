import { WithAnyProperty } from "types/types"
import { isNullable } from "../isNullable"

/**
 * Convert object values to string
 *
 * @param {object} value Object to convert values to string
 * @returns {object} Object with string values
 */
export const valuesToString = (value: WithAnyProperty = {}): { [key: string]: string } => {
  return Object
    .entries(value)
    .reduce((acc, [key, val]) => ({
      ...acc,
      [key]: isNullable(val) || !val?.toString ? String(val) : val?.toString()
    }), {})
}

import React, { useState, useEffect } from 'react';
import { useStores } from 'stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { formatError } from 'utils/lib/formatError';
import ModalBase from 'components/ModalBase';
import FASpinner from '../../components/FASpinner';
import { PROMO_MASK } from '../../constants/formats';

interface DialogProps {
  onClose: any;
  visible: boolean;
  onCancel: any;
  toPaymentMethodeDialog?: any;
  ordersStore?: any;
}

interface Validation {
  code?: string;
}

const Dialog = observer(
  ({ visible, onClose, onCancel, toPaymentMethodeDialog, ordersStore }: DialogProps): JSX.Element | null => {
    const { promocodesStore } = useStores();

    const [code, setCode] = useState<string>('');
    const { t } = useTranslation(['promocodes', 'validation']);

    useEffect(() => {
      if (visible) {
        setCode('');
      }
      document.body.style.overflow = visible ? 'hidden' : 'unset';
    }, [visible]);

    const cancel = (): void => {
      setCode('');
      if (onCancel) {
        onCancel();
        if (toPaymentMethodeDialog) toPaymentMethodeDialog();
        promocodesStore.clearActivateError();
      }
    };

    const activatePromocode = async (e: any): Promise<void> => {
      e.preventDefault();
      await promocodesStore.activateCode(code);
      if (!promocodesStore.promocodeError) {
        onClose();
        if (toPaymentMethodeDialog) {
          ordersStore?.estimateOrder();
          toPaymentMethodeDialog();
        }
      }
    };

    return (
      <ModalBase
        visible={visible}
        title={t('action.activate')}
        onClose={() => {
          onClose();
          promocodesStore.clearActivateError();
          if (toPaymentMethodeDialog) toPaymentMethodeDialog();
        }}
      >
        {promocodesStore?.promocodeError && (
          <p className="error mb-4">{formatError(promocodesStore?.promocodeError, t)}</p>
        )}
        <form>
          <fieldset className="mb-1">
            <div className="mb-5">
              <label className="form-label">{t('dialog.promocode')}</label>
              <input
                className="form-control py-3 bg-graylight bg-opacity-94 h-12"
                onChange={(e) => setCode(e.target.value)}
                id="code"
                placeholder={t('dialog.promocode')}
                value={code || ''}
              />
            </div>
          </fieldset>
          <div className="flex justify-end space-x-5">
            <button className="btn btn-light" onClick={cancel} type="button">
              {t('dialog.cancel')}
            </button>
            <button
              className="btn btn-blue"
              type="submit"
              onClick={activatePromocode}
              disabled={!code || code.length < 1 || promocodesStore?.isActivateProgress}
            >
              <FASpinner containerClass="mr-2" show={promocodesStore?.isActivateProgress} />
              {t('dialog.activate')}
            </button>
          </div>
        </form>
      </ModalBase>
    );
  },
);

export default Dialog;

import React, { forwardRef, useEffect, useState } from 'react';

export interface ModalBaseProps {
  title: any;
  onClose: any;
  children: React.ReactChild | React.ReactChild[] | React.ReactNode;
  visible: boolean;
  style?: React.CSSProperties;
  modalWrapperClass?: string;
  contentClass?: string;
  bodyClass?: string;
  onAnimationCloseEnd?: () => void;
  disabledClose?: boolean;
  parentClassName?: string;
  backdropAnimationShowClass?: string;
  contentAnimationShowClass?: string;
  backdropAnimationHideClass?: string;
  contentAnimationHideClass?: string;
  unmountContentOnHide?: boolean;
  modalBackdropClass?: string;
}

export const ModalBase = forwardRef(
  (
    {
      unmountContentOnHide = true,
      visible,
      onClose,
      disabledClose = false,
      onAnimationCloseEnd,
      children,
      title,
      style,
      modalWrapperClass = '',
      contentClass = '',
      bodyClass = '',
      parentClassName = '',
      backdropAnimationShowClass = 'show',
      backdropAnimationHideClass = 'hide',
      contentAnimationShowClass = 'scaled-up',
      contentAnimationHideClass = 'scaled-down',
      modalBackdropClass = 'modal-backdrop',
    }: ModalBaseProps,
    ref: any,
  ): JSX.Element | null => {
    const [isModalShowed, setIsModalShowed] = useState<boolean>(false);
    const [show, setShow] = useState<boolean>(false);

    useEffect(() => {
      if (visible && !isModalShowed) setIsModalShowed(true);
      setTimeout(
        () => {
          setShow(visible);
          if (!visible && onAnimationCloseEnd) {
            onAnimationCloseEnd();
          }
        },
        visible ? 0 : 200,
      );
      document.body.style.overflow = visible ? 'hidden' : 'unset';
      return () => {
        document.body.style.overflow = 'unset';
      };
    }, [visible, isModalShowed]);

    return (
      <div
        ref={ref}
        className={`modal fixed ${visible ? backdropAnimationShowClass : isModalShowed ? backdropAnimationHideClass : 'hidden'} ${parentClassName}`}
        style={style}
      >
        <div className={modalBackdropClass}>
          <div className={`modal-dialog ${modalWrapperClass}`}>
            <div
              className={`modal-content ${contentClass} ${visible ? contentAnimationShowClass : contentAnimationHideClass}`}
            >
              <div className="modal-header">
                <h4 className="text-2lg font-medium text-black" id="modal-title">
                  {title}
                </h4>
                <button
                  className="btn-close"
                  type="button"
                  aria-label="Закрыть"
                  disabled={disabledClose}
                  onClick={onClose}
                >
                  <svg
                    className="w-6 h-6"
                    opacity={disabledClose ? 0.5 : 1}
                    fill="currentColor"
                    viewBox="0 0 24 24"
                    aria-hidden="true"
                  >
                    <path d="M16.28 7.72a.75.75 0 0 0-1.06 0L12 10.94 8.78 7.72a.75.75 0 1 0-1.06 1.06L10.94 12l-3.22 3.22a.75.75 0 1 0 1.06 1.06L12 13.06l3.22 3.22a.75.75 0 0 0 1.06-1.06L13.06 12l3.22-3.22a.75.75 0 0 0 0-1.06Z"></path>
                    <path d="M15.75 1.5h-7.5A6.75 6.75 0 0 0 1.5 8.25v7.5a6.75 6.75 0 0 0 6.75 6.75h7.5a6.75 6.75 0 0 0 6.75-6.75v-7.5a6.75 6.75 0 0 0-6.75-6.75ZM21 15.75A5.25 5.25 0 0 1 15.75 21h-7.5A5.25 5.25 0 0 1 3 15.75v-7.5A5.25 5.25 0 0 1 8.25 3h7.5A5.25 5.25 0 0 1 21 8.25v7.5Z"></path>
                  </svg>
                </button>
              </div>
              <div className={`modal-body ${bodyClass}`}>{(show || !unmountContentOnHide) && children}</div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default ModalBase;

import { action, makeObservable, observable } from 'mobx';
import BaseStore from '../BaseStore';
import { RootStoreInterface } from '../../interfaces';
import { Provider } from '../../types/types';

export default class ProvidersStore extends BaseStore {
  constructor(rootStore: RootStoreInterface) {
    super(rootStore, rootStore.specifications['Providers']);
    makeObservable(this);
  }

  @action
  getProviderName(type: string): string {
    const [provider] = (this.list || []).filter((provider: Provider) => provider.short_name === type);
    return provider?.name || '';
  }
}

import React, { useEffect, useRef, useState, useCallback } from 'react';
import { YMaps, Map, YMapsApi, ZoomControl, PlacemarkGeometry } from 'react-yandex-maps';
import { useStores } from 'stores';
import { observer } from 'mobx-react-lite';
import { xor } from 'lodash';
import { ReactComponent as CurrentLocationShadowPin } from '../assets/svg/current-location-shadow.svg';
import { ReactComponent as CurrentLocationPin } from '../assets/svg/current-location.svg';
import base64CurrentPos from '../assets/base64/current-position';
import base64DestinationPos from '../assets/base64/destination-position';
import base64CurrentPosVip from '../assets/base64/vip/current-position';
import base64DestinationPosVip from '../assets/base64/vip/destination-position';
import { Place } from 'types/types';
import { CurrentField } from 'stores/OrdersStore';
import { useTranslation } from 'react-i18next';
import { ROUTE_MAP } from '../../../constants/colors';
import { ROUTE_MAP_WIDTH } from 'constants/common';
import { getCoords } from 'utils/lib/map/getCoords';
import { addWayPointsPlacemarksAsGeo } from 'utils/lib/map/addWayPointsPlacemarks copy';
import { useWindowSize } from 'hooks/useWindowSize';
import { MOSCOW_CENTER } from 'constants/map';

interface YmapProps {
  theme?: string;
}

interface LocationData {
  asked: boolean;
  coords: number[];
}

const Ymap = observer(({ theme }: YmapProps) => {
  const { ordersStore, geocodeStore } = useStores();
  const [isMapMoving, setIsMapMoving] = useState<boolean>(false);
  const [isMapReady, setIsMapReady] = useState<boolean>(false);
  const { newOrderInfo, isVip } = ordersStore;
  const { t } = useTranslation(['actions', 'new_order']);
  const mapApiRef = useRef<any>();
  const nearbyCarsPlacemarkRef = useRef<PlacemarkGeometry[]>([]);
  const [mapApi, setMapApi] = useState<YMapsApi | null>(null);
  const [route, setRoute] = useState<any>();
  const [locationData, setLocationData] = useState<LocationData>({
    asked: false,
    coords: MOSCOW_CENTER,
  });
  const {
    currentField,
    currentFieldValue: place,
  }: { currentField: CurrentField; currentFieldValue: Place | undefined } = ordersStore || {};
  let color = isVip ? '#0B0D0F' : '#FDCD03';
  if (currentField !== 'source') {
    color = currentField === 'destination' ? 'black' : 'rgb(6, 150, 42)';
  }
  const windowSize = useWindowSize();

  useEffect(() => {
    if (!navigator.geolocation?.getCurrentPosition) {
      setLocationData((data) => ({
        ...data,
        asked: true,
      }));
    }
    navigator.geolocation.getCurrentPosition(
      (pos) =>
        setLocationData({
          asked: true,
          coords: [pos.coords.latitude, pos.coords.longitude],
        }),
      () =>
        setLocationData((data) => ({
          ...data,
          asked: true,
        })),
      { enableHighAccuracy: true },
    );
  }, []);

  const timerIdRef = useRef<NodeJS.Timer>();
  const shouldUpdateAddress = useRef<boolean>(false);
  const isAddressSelectedOnMap = useRef<boolean>(false);
  const [isMapMoved, setIsMapMoved] = useState<boolean>(false);
  const [center, setCenter] = useState<any[]>();
  const sourcePlacemarkRef = useRef<any>();
  const destinationPlacemarkRef = useRef<any>();

  useEffect(() => {
    if (currentField === 'unset') {
      ordersStore.updateCurrentFieldValue(undefined);
      return;
    }
    if (
      (currentField === 'source' && newOrderInfo?.source?.lat) ||
      (currentField === 'destination' && newOrderInfo?.destination?.lat)
    ) {
      setMapCenter(getCoords(newOrderInfo[currentField]) as (number | string)[]);
      return;
    } else if (
      typeof currentField === 'number' &&
      newOrderInfo?.interim_destinations[currentField] &&
      newOrderInfo?.interim_destinations[currentField]?.lat
    ) {
      setMapCenter(getCoords(newOrderInfo.interim_destinations[currentField]) as (number | string)[]);
    }
  }, [currentField]);

  const onBoundsChange = useCallback(
    ({ originalEvent }): void => {
      if (currentField === 'unset') return;
      if (timerIdRef.current) clearTimeout(timerIdRef.current);
      timerIdRef.current = setTimeout(() => {
        onMouseUp();
        setCenter([originalEvent.newCenter[0], originalEvent.newCenter[1]]);
      }, 300);
    },
    [currentField],
  );

  const onMouseDown = useCallback((): void => {
    if (timerIdRef.current) clearTimeout(timerIdRef.current);
    setIsMapMoving(true);
    shouldUpdateAddress.current = false;
  }, []);

  const onMouseUp = useCallback((): void => {
    setIsMapMoving(false);
    setIsMapMoved(true);
  }, []);

  const onActionBegin = useCallback((): void => {
    if (timerIdRef.current) clearTimeout(timerIdRef.current);
    setCenter(undefined);
    setIsMapMoving(true);
    ordersStore.updateCurrentFieldValue(undefined);
    shouldUpdateAddress.current = false;
  }, []);

  const onMapInstanceInit = useCallback(
    (ref: any): void => {
      mapApiRef.current = ref;
      if (!ref) return;
      ref.events?.add('mousedown', onMouseDown);
      ref.events?.add('mouseup', onMouseUp);
      ref.events?.add('boundschange', onBoundsChange);
      ref.events?.add('actionbegin', onActionBegin);
    },
    [currentField],
  );

  useEffect(() => {
    const [lat, lng] = center || [];
    if (center && !isMapMoving && isMapMoved) {
      onRegionChangeComplete({ lat, lng });
      setCenter(undefined);
    }
  }, [center, isMapMoved, isMapMoving]);

  const onRegionChangeComplete = async (newregion) => {
    shouldUpdateAddress.current = true;
    if (isMapMoving || currentField === 'unset') return;
    const place: Place = await geocodeStore.geocode({ location: newregion });
    // @ts-ignore
    if (!shouldUpdateAddress.current || currentField === 'unset') return;
    ordersStore.updateCurrentFieldValue({ ...place, ...newregion });
  };

  const confirmAddress = (e): void => {
    if ((!currentField && typeof currentField !== 'number') || currentField === 'unset') return;
    if (currentField === 'source' || currentField === 'destination') {
      ordersStore.updateNewOrderInfo({ [currentField]: { ...place } });
    } else {
      ordersStore.updateNewOrderInfo({
        interim_destinations: (newOrderInfo?.interim_destinations || []).map((d, i) =>
          i === currentField ? place : d,
        ),
      });
    }
    isAddressSelectedOnMap.current = true;
    ordersStore.updateCurrentFieldValue(undefined);
    ordersStore?.updateCurrentField('unset');
  };

  useEffect(() => {
    if (!newOrderInfo?.source?.address || !newOrderInfo?.destination?.address) return;
    const { interim_destinations = [] } = newOrderInfo || {};
    if (interim_destinations?.length > 0 && !interim_destinations?.every((d: Place) => d?.address)) return;
    ordersStore.estimateOrder();
  }, [
    newOrderInfo?.source?.address,
    newOrderInfo?.source?.entrance,
    newOrderInfo?.destination?.address,
    newOrderInfo?.employee_id,
    newOrderInfo?.interim_destinations,
  ]);

  const setMapCenter = (center: (number | string)[], zoom: number = 15): void => {
    mapApiRef.current?.setCenter(center, zoom, {
      checkZoomRange: true,
      duration: 700,
    });
  };

  const shouldUpdateRoute = (): boolean => {
    const newPoints: any[] = [
      getCoords(newOrderInfo.source),
      ...getCoords(newOrderInfo.interim_destinations),
      getCoords(newOrderInfo.destination),
    ].filter((i: any) => i.length > 0);
    const currentPoints: any[] = route?.model?.getReferencePoints();
    if (newPoints.length !== currentPoints.length) {
      return true;
    }
    return (
      newPoints.map((item: any, ind: number) => xor(item, currentPoints[ind])).filter((arr: any[]) => arr.length > 0)
        ?.length > 0
    );
  };

  useEffect(() => {
    const { interim_destinations = [], destination } = newOrderInfo || {};
    let wayDests = [...interim_destinations];
    if (destination) wayDests.push(destination);
    wayDests = getCoords(wayDests);
    const lastPoint = wayDests.length > 0 ? wayDests[wayDests.length - 1] : null;
    if (newOrderInfo.source?.lat && !wayDests?.length) {
      if (route) mapApiRef.current?.geoObjects?.remove(route);
      addSourcePlacemarks();
      !isAddressSelectedOnMap.current && setMapCenter(getCoords(newOrderInfo?.source) as (number | string)[]);
    } else if (newOrderInfo.destination?.lat && !newOrderInfo.source?.lat && wayDests?.length === 1) {
      if (route) mapApiRef.current?.geoObjects?.remove(route);
      addDestinationPlacemark();
      !isAddressSelectedOnMap.current && setMapCenter(getCoords(newOrderInfo?.destination) as (number | string)[]);
    } else if ((newOrderInfo.source?.lat && wayDests?.length) || wayDests?.length > 1) {
      if (getCoords(wayDests).length > wayDests.length) return;
      const firstPoint = newOrderInfo.source?.lat ? getCoords(newOrderInfo.source) : wayDests[0];
      if (newOrderInfo.source?.lat) {
        wayDests.unshift(firstPoint);
      }
      if (!isAddressSelectedOnMap.current && route && shouldUpdateRoute()) {
        setMapCenter(
          [firstPoint[0] + (lastPoint[0] - firstPoint[0]) / 2, firstPoint[1] + (lastPoint[1] - firstPoint[1]) / 2],
          mapApiRef?.current?.getZoom() || 13,
        );
      }

      if (!mapApi) return;
      if (route) {
        if (!shouldUpdateRoute()) return;
      }
      var balloonLayout = mapApi.templateLayoutFactory.createClass('<div>', {
        build: function () {
          this.constructor.superclass.build.call(this);
        },
      });
      const multiRoute = new mapApi.multiRouter.MultiRoute(
        {
          referencePoints: wayDests,
          params: {
            routingMode: 'auto',
            results: 1,
          },
        },
        {
          boundsAutoApply: !isAddressSelectedOnMap.current,
          routeActiveStrokeColor: ROUTE_MAP,
          routeActiveStrokeWidth: ROUTE_MAP_WIDTH,
          editorDrawOver: false,
          pinVisible: false,
          wayPointVisible: false,
          editorMidPointsType: 'via',
          balloonLayout: balloonLayout,
        },
      );
      setRoute(multiRoute);
      if (route) mapApiRef.current.geoObjects.remove(route);
      mapApiRef.current.geoObjects.add(multiRoute);
      if (newOrderInfo.source?.lat) addSourcePlacemarks();
      addDestinationPlacemark({ lat: lastPoint[0], lng: lastPoint[1] });
      addWayPointsPlacemarksAsGeo(
        mapApi,
        mapApiRef.current,
        wayDests.slice(newOrderInfo.source?.lat ? 1 : 0, -1),
        50,
        isVip,
      );
    } else {
      removeGeoObject(route);
      removeGeoObject(sourcePlacemarkRef.current);
      removeGeoObject(destinationPlacemarkRef.current);
      addWayPointsPlacemarksAsGeo(mapApi, mapApiRef.current, [], 50, isVip);
    }
    isAddressSelectedOnMap.current = false;
  }, [newOrderInfo.source, newOrderInfo.destination, mapApi, newOrderInfo?.interim_destinations]);

  const addSourcePlacemarks = (point: any = null): void => {
    const coords = point ?? newOrderInfo.source;
    if (!coords?.lat) {
      console.warn('Invalid source');
      return;
    }
    removeGeoObject(sourcePlacemarkRef.current);
    if (!mapApi) return;
    const source = new mapApi.Placemark(
      getCoords(coords),
      {},
      {
        iconLayout: 'default#image',
        iconImageHref: isVip ? base64CurrentPosVip : base64CurrentPos,
        iconImageSize: [50, 50],
        iconImageOffset: [-25, -25],
      },
    );
    mapApiRef.current.geoObjects.add(source);
    sourcePlacemarkRef.current = source;
  };

  const removeGeoObject = (geoObject: any): void => {
    if (mapApiRef?.current && geoObject) {
      mapApiRef.current.geoObjects.remove(geoObject);
    }
  };

  const addDestinationPlacemark = (point: any = null): void => {
    const coords = point ?? newOrderInfo.destination;
    if (!coords?.lat) {
      console.warn('Invalid destination');
      return;
    }
    removeGeoObject(destinationPlacemarkRef.current);
    if (!mapApi) return;
    const destination = new mapApi.Placemark(
      getCoords(coords),
      {},
      {
        iconLayout: 'default#image',
        iconImageHref: isVip ? base64DestinationPosVip : base64DestinationPos,
        iconImageSize: [50, 50],
        iconImageOffset: [-5, -50],
      },
    );
    mapApiRef.current.geoObjects.add(destination);
    destinationPlacemarkRef.current = destination;
  };

  if (!locationData.asked) {
    return null;
  }

  return (
    <YMaps query={{ apikey: process.env.REACT_APP_YANDEX_MAP_KEY, lang: 'ru_RU' }}>
      <div className="order-map" style={(windowSize?.width || 0) > 991 ? { top: 98, position: 'sticky' } : {}}>
        <Map
          width={'100%'}
          height={'100%'}
          defaultState={{
            zoom: 13,
            center: locationData.coords,
          }}
          modules={['multiRouter.MultiRoute', 'templateLayoutFactory', 'Placemark']}
          instanceRef={onMapInstanceInit}
          onLoad={(api: YMapsApi) => {
            setIsMapReady(true);
            setMapApi(api);
          }}
          options={{
            yandexMapDisablePoiInteractivity: true,
          }}
        >
          <ZoomControl />
        </Map>
        {isMapMoving && isMapReady && ordersStore?.currentField !== 'unset' && (
          <div className="absolute location-pin" style={{ transform: 'translate(-50%, -90%)' }}>
            <CurrentLocationShadowPin color={color} />
          </div>
        )}
        {place?.address && currentField !== 'unset' && (
          <div className="absolute bg-white hidden lg:block rounded border border-graystroke map-popup">
            <button
              className="btn-close absolute bg-white rounded map-popup-close"
              type="button"
              aria-label="Закрыть"
              onClick={() => {
                ordersStore.updateCurrentFieldValue(undefined);
              }}
            >
              <svg className="w-6 h-6" fill={isVip ? '#0B0D0F' : 'currentColor'} viewBox="0 0 24 24" aria-hidden="true">
                <path d="M16.28 7.72a.75.75 0 0 0-1.06 0L12 10.94 8.78 7.72a.75.75 0 1 0-1.06 1.06L10.94 12l-3.22 3.22a.75.75 0 1 0 1.06 1.06L12 13.06l3.22 3.22a.75.75 0 0 0 1.06-1.06L13.06 12l3.22-3.22a.75.75 0 0 0 0-1.06Z"></path>
                <path d="M15.75 1.5h-7.5A6.75 6.75 0 0 0 1.5 8.25v7.5a6.75 6.75 0 0 0 6.75 6.75h7.5a6.75 6.75 0 0 0 6.75-6.75v-7.5a6.75 6.75 0 0 0-6.75-6.75ZM21 15.75A5.25 5.25 0 0 1 15.75 21h-7.5A5.25 5.25 0 0 1 3 15.75v-7.5A5.25 5.25 0 0 1 8.25 3h7.5A5.25 5.25 0 0 1 21 8.25v7.5Z"></path>
              </svg>
            </button>
            <p className="p-3 text-center one-line-text border-b border-b-graystroke">
              {(place.address || '') +
                (place.entrance ? ', ' + t('entrance', { ns: 'new_order' }) + ' ' + place.entrance : '')}
            </p>
            <button id="address" onClick={confirmAddress} className="btn w-full border-0" type="button">
              {t('confirm')}
            </button>
            <div className="absolute bg-white border-graystroke map-popup-pointer" />
          </div>
        )}
        {!isMapMoving && isMapReady && ordersStore?.currentField !== 'unset' && (
          <div
            className={`absolute ${place ? 'lg:hidden' : ''} location-pin`}
            style={{ transform: 'translate(-50%, -100%)' }}
          >
            <CurrentLocationPin color={color} />
          </div>
        )}
      </div>
    </YMaps>
  );
});

export default Ymap;

export default {
  title: 'Политика поездок',
  title_new: 'Новая группа',
  search_placeholder: 'Поиск',
  list: {
    title: 'Политика поездок',
    title_new: 'Новая группа',
    actions: {
      new: 'Новая группа',
    },
    group: {
      default: 'По умолчанию',
      users: 'пользователей',
      user: 'пользователь',
      users_2_4: 'пользователя',
    },
  },
  no_default: 'Группа по умолчанию не выбрана',
  loading: 'Загрузка',
  empty: {
    text: 'Выберите группу, чтобы посмотреть и отредактировать ее, или создайте новую',
    actions: {
      new: 'Новая группа',
    },
  },
  rule_dialog: {
    title: 'Добавить правило',
    distance: {
      title: 'Расстояние',
      text: 'Укажите на какое расстояние могут ездить члены группы',
    },
    month_amount: {
      title: 'Месячный лимит на сотрудника',
      text: 'Укажите какой месячный лимит должен быть у каждого члена группы',
    },
    month_amount_group: {
      title: 'Месячный лимит на всех сотрудников группы',
      text: 'Укажите месячный лимит на всех сотрудников группы',
    },
    schedule: {
      title: 'Время',
      text: 'Выберите доступное членам группы время для поездок',
    },
    radius: {
      title: 'Радиус',
      text: 'Укажите на какой максимальный радиус от начальной точки могут ездить участники группы',
    },
    zones: {
      title: 'Зоны',
      text: 'Установите зоны, внутри которых разрешена посадка и высадка',
    },
    actions: {
      add: 'Добавить',
    },
  },
  current: {
    title_new: 'Новая группа',
    title_edit: 'Редактирование группы',
    description:
      'Группируйте пользователей и задавайте правила поездок для разных ситуаций: задержка в офисе, командировка и т.д.',
    name: 'Название',
    default: 'Использовать по умолчанию',
    name_placeholder: 'Напр., Сотрудники, Менеджмент и т.п',
    is_toll_roads_available: 'Платные дороги',
    toll_roads_description: 'Возможность проехать машинам по платным дорогам',
    users: {
      title: 'Пользователи',
      actions: {
        add_user: 'Добавить пользователя',
      },
      dialog: {
        title: 'Добавить пользователя',
        placeholder: 'Сотрудник',
      },
      loading: 'Загрузка данных',
      empty: 'Пользователи не найдены',
    },
    classes: {
      title: 'Классы автомобиля',
      text: 'Выберите классы, доступные этой группе',
    },
    cost_centers: {
      title: 'Дополнительные поля',
      text: 'Настройте список дополнительных полей, которые необходимо указывать при создании заказа',
      actions: {
        add: 'Добавить дополнительное поле',
      },
      confirm_msg: 'Вы действительно хотите удалить выделенную запись?',
      loading: 'Загрузка данных',
      thead: {
        name: 'Наименование',
        type: 'Тип',
        required: 'Обязательное поле',
      },
      trow: {
        yes: 'Да',
        no: 'Нет',
      },
      dialog: {
        title: 'Добавить дополнительное поле',
        cc_header: 'Заголовок',
        cc_type: 'Тип',
        cc_elements: 'Элементы списка',
        export_xlsx: ' Загрузить из Excel',
        import_xlsx: 'Выгрузить в Excel',
        load_exmaple: 'Скачать пример Excel-файла',
        file_name: 'Список',
        cc_required: 'Обязательное поле',
        set_limits: 'Задать ограничения',
        symbols: 'Символы',
        letters: 'Буквы',
        digits: 'Цифры',
        string_limit: 'Ограничить количество символов',
        string_length: 'Количество символов',
        string_length_min: 'от',
        string_length_max: 'до',
        actions: {
          cancel: 'Отменить',
          add: 'Добавить',
          save: 'Сохранить',
        },
        types: {
          string: 'Строка',
          date: 'Дата',
          dropdown: 'Список',
        },
      },
    },
    driver: {
      comment: 'Комментарий к водителю',
      save: 'Сохранить',
    },
    rules: {
      title: 'Правила поездок',
      text: 'Задавайте правила с учетом политики поездок вашей компании. При добавлении правил создается сценарий. Можно создать до 5 сценариев для каждой группы.',
      actions: {
        add: 'Добавить правило',
      },
      address: {
        name: 'Название сценария',
        addresses: 'Адреса',
        addresses_text: 'Пользователям в этой группе можно заказывать поездки в радиусе 500 м от выбранных адресов',
        allow: 'Разрешить поездки',
        from: 'из',
        to: 'в',
        actions: {
          add: 'Добавить маршрут',
        },
      },
      amount: {
        month_limit: 'Месячный лимит (рубли)',
        save: 'Сохранить',
      },
      amount_group: {
        month_limit: 'Месячный лимит для группы (рубли)',
        save: 'Сохранить',
      },
      distance: {
        max_distance: 'Максимальное расстояние (километры)',
        save: 'Сохранить',
      },
      schedule: {
        schedule: 'Расписание',
        since: 'с',
        until: 'до',
        done: 'Готово',
        intervals: 'интервалов',
        intervals2_4: 'интервала',
        time_placeholder: 'ЧЧ:ММ',
      },
      radius: {
        name: 'Радиус, км',
        title: 'Начальные точки и радиусы',
        coords: 'Координаты точки',
        max_radius: 'Максимальный радиус от начальной точки (километры)',
        save: 'Сохранить',
        add: 'Добавить радиус',
        dialog: {
          region_title: 'Название',
          map_title: 'Выберите начальную точку',
          title_new: 'Новый радиус',
          title_editing: 'Редактирование',
          coords: 'Координаты',
          map: 'Карта',
          actions: {
            add: 'Добавить',
            cancel: 'Отменить',
            save: 'Сохранить',
            done: 'Готово',
          },
        },
      },
      zones: {
        zones: 'Гео-зоны посадки и высадки',
        add_from: 'Добавить зону посадки',
        add_to: 'Добавить зону высадки',
        dialog: {
          region_title: 'Название',
          map_title: 'Выберите гео-зону',
          title_new: 'Новая гео-зона',
          title_editing: 'Редактирование',
          coords: 'Координаты',
          map: 'Карта',
          actions: {
            add: 'Добавить',
            cancel: 'Отменить',
            save: 'Сохранить',
            done: 'Готово',
          },
        },
      },
    },
    actions: {
      create: 'Создать группу',
      save: 'Сохранить',
      cancel: 'Отменить',
      make_default: 'Сделать по умолчанию',
    },
  },
};

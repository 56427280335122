export default {
  title: 'Analytics',
  period: {
    week: 'Week',
    month: 'Month',
    year: 'Year',
  },
  orders: {
    count: 'Number of orders',
    cancelled_count: 'Number of canceled orders',
    total_cost: 'Total spending on orders',
  },
  loading: 'Loading',
  error: 'Error',
  filter: {
    date_placeholder: 'DD.MM.YY-DD.MM.YY',
  },
};

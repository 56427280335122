import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useStores } from 'stores';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { formatError } from 'utils/lib/formatError';
import ModalBase from 'components/ModalBase';
import { Promocode } from '../../types/types';
import FASpinner from '../../components/FASpinner';
import { DateTimeField } from 'components/Inputs/DateTimeField';
import moment from 'moment';
import ModalSelect from '../../components/ModalSelect';
import ModalSelectStore from '../../components/ModalSelectStore';

interface DialogProps {
  onClose: any;
  visible: boolean;
  onCancel: any;
  promocode?: Promocode | null;
}

interface Validation {
  total_cost?: string;
  max_order_cost?: string;
  orders_count?: string;
  max_employee_count?: string;
  discount_percent?: string;
  active_from?: string;
  active_to?: string;
}

const defaultPromocode: Promocode = {
  code: '',
  is_activated: false,
  is_used: false,
  total_cost: 0,
  max_order_cost: 0,
  orders_count: 0,
  max_employee_count: 0,
  discount_percent: 0,
  used_time: '',
  used_orders_count: 0,
  active_from: '',
  active_to: '',
  ctime: '',
};

export const Dialog = observer(({ visible, onClose, onCancel, promocode: p }: DialogProps): JSX.Element | null => {
  const { promocodesStore, ordersClassesStore, rolesStore } = useStores();
  const getPromocodeData = useCallback(
    (): Promocode => ({
      id: p?.id || '',
      code: p?.code || '',
      is_activated: p?.is_activated || false,
      activated_by_employee_id: p?.activated_by_employee_id || undefined,
      total_cost: p?.total_cost || 0,
      max_order_cost: p?.max_order_cost || 0,
      orders_count: p?.orders_count || 0,
      max_employee_count: p?.max_employee_count || 0,
      discount_percent: p?.discount_percent || 0,
      is_used: p?.is_used || false,
      used_time: p?.used_time || '',
      used_orders_count: p?.used_orders_count || 0,
      ctime: p?.ctime || '',
      classes: p?.classes ? p.classes.map((c) => c.class?.id || c?.id) : '',
      role_id: p?.role_id || '',
      description: p?.description || '',
      type: p?.discount_percent || !p?.id ? 'discount' : 'amount',
      active_to: p?.active_to || undefined,
      active_from: p?.active_from || undefined,
    }),
    [p],
  );
  const [promocode, setPromocode] = useState<Promocode>(getPromocodeData());
  const [errors, setErrors] = useState<Validation>({});
  const { t } = useTranslation(['promocodes', 'validation']);

  useEffect(() => {
    if (visible) {
      setPromocode(getPromocodeData());
    }
    document.body.style.overflow = visible ? 'hidden' : 'unset';
  }, [visible]);

  const handleChangeByField = (data: Partial<Promocode>): void => {
    setPromocode((prevPrm) => ({
      ...prevPrm,
      ...data,
    }));
  };

  const cancel = (): void => {
    setPromocode(defaultPromocode);
    if (onCancel) {
      onCancel();
    }
  };

  const addPromocode = async (e: any): Promise<void> => {
    e.preventDefault();
    const {
      total_cost,
      max_order_cost,
      orders_count,
      active_to,
      active_from,
      classes,
      max_employee_count,
      discount_percent,
      type,
      id,
    } = promocode;
    const prm: Promocode = { ...promocode };
    const required: string = t('error.required', { ns: 'validation' });
    const error: Validation = {};
    if (!max_order_cost && !discount_percent) {
      error.max_order_cost = required;
      error.discount_percent = required;
    }
    if (classes) {
      prm.classes = `[${classes.join()}]`;
    }
    if (type === 'discount' && !discount_percent) {
      error.discount_percent = required;
    }
    if (type === 'amount' && !total_cost) {
      error.total_cost = required;
    }
    if (!active_from && p?.id && !p?.active_from) {
      error.active_from = required;
    } else {
      const dt = moment(p?.id && !active_from ? p?.active_from * 1000 : prm?.active_from * 1000);
      prm.active_from = dt.add(dt.parseZone().utcOffset(), 'minutes').unix();
    }
    if (active_to || (p?.id && p?.active_to)) {
      const dt = moment(p?.id && !active_to ? p?.active_to * 1000 : prm?.active_to * 1000);
      prm.active_to = dt.add(dt.parseZone().utcOffset(), 'minutes').unix();
    }
    if (!Object.keys(error).length) {
      const filteredData = Object.entries(prm).reduce(
        (acc: any, [key, val]: any) =>
          val === '' || (val === 0 && !(promocode && promocode[key])) ? acc : { ...acc, [key]: val },
        {},
      );
      if (filteredData.type) delete filteredData.type;
      if (promocode.code && promocode.id) {
        if (filteredData.prefix) delete filteredData.prefix;
        if (filteredData.code) delete filteredData.code;
        if (filteredData.hasOwnProperty('is_activated')) delete filteredData.is_activated;
        await promocodesStore.updateRecord(promocode.id, filteredData);
        await promocodesStore.fetchList(
          false,
          {},
          'id desc',
          promocodesStore?.page || 0,
          promocodesStore?.limit || 10,
          true,
          true,
          false,
        );
      } else {
        await promocodesStore.addRecord(filteredData);
      }
      if (promocodesStore.addingError) return;
      setPromocode({ ...promocode });
      onClose();
      setErrors({});
    } else {
      console.log('error --->', error);
      setErrors(error);
    }
  };

  const roleIdFilter = useMemo(() => ({ id: promocode?.role_id }), [promocode?.role_id]);
  const promoTypes = ['discount', 'amount'];
  return (
    <ModalBase
      visible={visible}
      title={t(`action.${promocode?.code ? 'edit' : 'add'}`)}
      onClose={() => {
        onClose();
        setErrors({});
      }}
    >
      {promocodesStore?.addingError && <p className="error mb-4">{formatError(promocodesStore?.addingError, t)}</p>}
      <form>
        <fieldset className="mb-1">
          {promocode.code && promocode.id ? (
            <div className="mb-5">
              <label className="form-label">{t('dialog.code')}</label>
              <input
                className="form-control py-3 bg-graylight bg-opacity-94 h-12"
                onChange={(e) =>
                  e.target.value?.length < 9 && handleChangeByField({ prefix: e.target.value.toUpperCase() })
                }
                id="code"
                placeholder={t('dialog.code')}
                readOnly
                value={promocode.code || ''}
              />
            </div>
          ) : (
            <div className="mb-5">
              <label className="form-label">{t('dialog.prefix')}</label>
              <input
                className="form-control py-3 bg-graylight bg-opacity-94 h-12"
                onChange={(e) =>
                  e.target.value?.length < 9 && handleChangeByField({ prefix: e.target.value.toUpperCase() })
                }
                id="prefix"
                placeholder={t('dialog.prefix')}
                value={promocode.prefix || ''}
              />
            </div>
          )}
          <div className="mb-5">
            <label className="form-label">{t('dialog.type')}</label>
            <div className="filter-container flex">
              {promoTypes.map((type) => (
                <span
                  key={`promo-type-${type}`}
                  className={`flex-grow flex flex-col  custom-control custom-control-taxi`}
                >
                  <input
                    className="custom-control-input"
                    value={type}
                    type="radio"
                    id={`promo-type-${type}`}
                    name={`promo-type-${type}`}
                    checked={promocode.type === type}
                    onChange={(e) =>
                      handleChangeByField({
                        type,
                        ...(type === 'discount'
                          ? {
                              total_cost: 0,
                            }
                          : {
                              discount_percent: 0,
                            }),
                      })
                    }
                  />
                  <label className="custom-control-label" htmlFor={`promo-type-${type}`}>
                    <span className="custom-control-box custom-control-box-radio"></span>
                    <span className="text-black">{t(`dialog.${type}`)}</span>
                  </label>
                </span>
              ))}
            </div>
          </div>
          {promocode?.type === 'discount' ? (
            <>
              <div className="mb-5">
                <label className="form-label form-label-required">{t('dialog.discount_percent')}</label>
                <input
                  className="form-control py-3 bg-graylight bg-opacity-94 h-12"
                  type="number"
                  onChange={(e) => handleChangeByField({ discount_percent: Number(e.target.value) })}
                  id="discount_percent"
                  min={0}
                  max={100}
                  step={1}
                  placeholder={t('dialog.discount_percent')}
                  value={promocode.discount_percent || ''}
                />
              </div>
            </>
          ) : (
            <>
              <div className="mb-5">
                <label className="form-label form-label-required">{t('dialog.total_cost')}</label>
                <input
                  className="form-control py-3 bg-graylight bg-opacity-94 h-12"
                  onChange={(e) => handleChangeByField({ total_cost: Number(e.target.value) })}
                  id="total_cost"
                  type="number"
                  min={0}
                  placeholder={t('dialog.total_cost')}
                  value={promocode.total_cost || ''}
                />
              </div>
            </>
          )}
          <div className="mb-5">
            <label className={`form-label ${promocode?.type === 'amount' ? 'form-label-required' : ''}`}>
              {t('dialog.orders_count')}
            </label>
            <input
              className="form-control py-3 bg-graylight bg-opacity-94 h-12"
              type="number"
              onChange={(e) =>
                handleChangeByField({
                  orders_count: Number(e.target.value),
                  ...(promocode.type === 'amount' && !isNaN(Number(e.target.value)) && Number(promocode.total_cost) > 0
                    ? {
                        max_order_cost: Math.floor(Number(promocode.total_cost) / Number(e.target.value)),
                      }
                    : {}),
                })
              }
              id="orders_count"
              min={0}
              step={1}
              placeholder={t('dialog.orders_count')}
              value={promocode.orders_count || ''}
            />
          </div>
          <div className="mb-5">
            <label className={`form-label ${promocode?.type === 'amount' ? 'form-label-required' : ''}`}>
              {t('dialog.max_order_cost')}
            </label>
            <input
              className="form-control py-3 bg-graylight bg-opacity-94 h-12"
              onChange={(e) => handleChangeByField({ max_order_cost: Number(e.target.value) })}
              id="max_order_cost"
              type="number"
              min={0}
              placeholder={t('dialog.max_order_cost')}
              value={promocode.max_order_cost || ''}
            />
          </div>
          <div className="mb-5">
            <div>
              <label className="form-label form-label-required">{t('dialog.classes')}</label>
              <div className="mt-4 mb-4">
                <input
                  type="checkbox"
                  id="all-classes"
                  checked={
                    (promocode.classes || []).length > 0 &&
                    promocode.classes?.length === ordersClassesStore.list?.length
                  }
                  onChange={({ target }) => {
                    handleChangeByField({
                      classes:
                        promocode.classes?.length === ordersClassesStore.list?.length
                          ? []
                          : ordersClassesStore.list?.map((o: any) => o.id),
                    });
                  }}
                />
                <label className="text-black text-opacity-40 ml-2.5" htmlFor="all-classes">
                  {t('dialog.classes_all')}
                </label>
              </div>
              <div className="select">
                <ModalSelect
                  options={ordersClassesStore.list?.map((orderClass: any) => ({
                    id: orderClass.id,
                    label: orderClass.title,
                    value: orderClass.id,
                  }))}
                  multi
                  values={promocode.classes || []}
                  onChange={(cl, removing) => {
                    if (!cl) return;
                    handleChangeByField({
                      classes: removing
                        ? [...promocode.classes].filter((id) => id != cl.value)
                        : [...(promocode.classes || []), cl.value],
                    });
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mb-5">
            <div>
              <label className="form-label form-label-required">{t('dialog.rides_policy')}</label>
              <div className="select">
                <ModalSelectStore
                  listProps={{
                    'aria-labelledby': 'role-label',
                    'aria-activedescendant': 'role-option-0',
                  }}
                  inputWrapperClass="h-12"
                  store={promocodesStore}
                  fieldName={'role_id'}
                  filter={roleIdFilter}
                  omitValuesOnFocus={['id']}
                  placeholder={t('dialog.rides_policy')}
                  value={promocode?.role_id}
                  onChange={(id: number) => handleChangeByField({ role_id: id ? id : defaultPromocode.role_id })}
                />
              </div>
            </div>
          </div>
          <div className="mb-5">
            <label className="form-label">{t('dialog.max_employee_count')}</label>
            <input
              className="form-control py-3 bg-graylight bg-opacity-94 h-12"
              type="number"
              onChange={(e) => handleChangeByField({ max_employee_count: Number(e.target.value) })}
              id="max_employee_count"
              min={0}
              step={1}
              placeholder={t('dialog.max_employee_count')}
              value={promocode.max_employee_count || ''}
            />
          </div>
          <div className="mb-5">
            <label className="form-label">{t('dialog.description')}</label>
            <input
              className="form-control py-3 bg-graylight bg-opacity-94 h-12"
              onChange={(e) => handleChangeByField({ description: e.target.value })}
              id="description"
              placeholder={t('dialog.description')}
              value={promocode.description || ''}
            />
          </div>
          <div className="mb-5">
            <DateTimeField
              fieldProps={{
                title: t('dialog.active_from'),
                className: '',
                isRequired: true,
              }}
              onChange={(v) => handleChangeByField({ active_from: v ? v.getTime() / 1000 : null })}
              dateFormat="DD.MM.YYYY"
              minDate={new Date()}
              maxDate={(promocode.active_to && new Date(promocode.active_to * 1000)) || undefined}
              selected={promocode.active_from && new Date(promocode.active_from * 1000)}
              placeholderText={t('dialog.active_from')}
            />
          </div>
          <div className="mb-5">
            <DateTimeField
              fieldProps={{
                title: t('dialog.active_to'),
                className: '',
                isRequired: true,
              }}
              onChange={(v) => handleChangeByField({ active_to: v ? v.getTime() / 1000 : null })}
              dateFormat="DD.MM.YYYY"
              minDate={(promocode.active_from && new Date(promocode.active_from * 1000)) || undefined}
              maxDate={(promocode.active_to && new Date(promocode.active_to * 1000)) || undefined}
              selected={promocode.active_to && new Date(promocode.active_to * 1000)}
              placeholderText={t('dialog.active_to')}
            />
          </div>
        </fieldset>
        <div className="flex justify-end space-x-5">
          <button className="btn btn-light" onClick={cancel} type="button">
            {t('dialog.cancel')}
          </button>
          <button
            className="btn btn-blue"
            type="button"
            disabled={
              (promocode.type === 'discount' &&
                (Number(promocode.discount_percent) <= 0 || Number(promocode.discount_percent) > 100)) ||
              (promocode.type === 'amount' &&
                (!promocode.total_cost || !promocode.orders_count || !promocode.max_order_cost)) ||
              (Number(promocode.max_order_cost) <= 0 &&
                (Number(promocode.discount_percent) <= 0 || Number(promocode.discount_percent) > 100)) ||
              (!promocode.max_order_cost && !promocode.discount_percent) ||
              !promocode.role_id ||
              !promocode.classes?.length ||
              (!p?.id && !promocode.active_from) ||
              (p?.id && !p?.active_from) ||
              promocodesStore?.isAddingInProgress
            }
            onClick={addPromocode}
          >
            <FASpinner containerClass="mr-2" show={promocodesStore?.isAddingInProgress} />
            {promocode?.code ? t('dialog.save') : t('dialog.add')}
          </button>
        </div>
      </form>
    </ModalBase>
  );
});

export default {
  title: 'Аналитика',
  period: {
    week: 'Неделя',
    month: 'Месяц',
    year: 'Год',
  },
  orders: {
    count: 'Количество заказов',
    cancelled_count: 'Количество отмененных заказов',
    total_cost: 'Общий расход по заказам',
  },
  loading: 'Загрузка',
  error: 'Ошибка',
  filter: {
    date_placeholder: 'ДД.ММ.ГГ-ДД.ММ.ГГ',
  },
};

import YandexAutoComplete, { YandexAutoCompleteProps } from 'components/YandexAutoComplete/AddressInput';
import React from 'react';

const AddressAutoComplete = (
  props: YandexAutoCompleteProps &
    Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLTextAreaElement>, HTMLTextAreaElement>, 'id'>,
): JSX.Element => {
  return <YandexAutoComplete {...props} />;
};

export default AddressAutoComplete;
